import React from 'react';
import { COMMON_URL } from 'constants/CommonUrl';
import { isEmpty, isString } from 'util/utils';
import classNames from 'util/classNames';

import iconPdf from 'assets/images/pdf.png';
import iconExcel from 'assets/svg/Document/excel.svg';
import iconVideo from 'assets/images/video.svg';
import iconClose from 'assets/svg/close-color.svg';
import './fileUploaded.less';
import Image from 'containers/Image';
import { viewFile } from 'util/file-utils';

function FileUploaded(props) {
  // const filePath = props?.file?.absolutePath ?? props?.file ?? '';
  const filePath = React.useMemo(() => {
    const path = props?.file?.absolutePath || props?.file;
    return isString(path) ? path : '';
  }, [props?.file]);

  const fileUrl = `${COMMON_URL.BASE_URL}${filePath}`;

  const fileName = React.useMemo(() => (isEmpty(filePath) ? '' : filePath.split('/').pop()), [filePath]);

  const fileExtension = React.useMemo(() => fileName.split('.').pop(), [fileName]);

  const fileIcon = React.useMemo(() => {
    return ['pdf', 'PDF'].includes(`${fileExtension}`.toLowerCase())
      ? iconPdf
      : ['xls', 'XLS', 'xlsx', 'XLSX'].includes(`${fileExtension}`.toLowerCase())
        ? iconExcel
        : ['mkv', 'mp4', 'wmv'].includes(`${fileExtension}`.toLowerCase())
          ? iconVideo
          : fileUrl;
  }, [fileExtension, fileUrl]);

  const handleClick = React.useCallback(() => viewFile(fileUrl), [fileUrl]);

  return (
    <div className={classNames(['fileUploadedBlockItem pointer', props?.size])} >
      <div className="fileUplodImg" onClick={handleClick}>
        <Image src={fileIcon} />
      </div>
      <div className="fileUplodContent">
        <p>{fileName}</p>
        {!props?.noDelete && (
          <div className="deleteIcon" onClick={props?.onDelete}>
            <Image src={iconClose} />
          </div>
        )}
      </div>
    </div>
  );
}
export default FileUploaded;
