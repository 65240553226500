import React from 'react';
import TextArea from 'containers/TextArea';
import CommonModal from 'containers/CommonModal';
import InputBlock from 'containers/InputBlock';

import { sendEmailAttachment, sendXRayEmailAttachment } from 'services/DiamondApi';
import { showNotification } from 'services/NotificationService';

import './action.less';
import { isFunction } from 'lodash';
import CircularProgress from 'components/CircularProgress';
import { useDispatch } from 'react-redux';

function SendEmailPopup(props) {
  const dispatch = useDispatch();
  const [subject, setSubject] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [cc, setCc] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [subjectInvalid, setSubjectInvalid] = React.useState(false);
  const [emailInvalid, setEmailInvalid] = React.useState(false);
  const [ccInvalid, setCcInvalid] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [messageInvalid, setMessageInvalid] = React.useState(false);

  React.useEffect(() => {
    setSubject(props.xRay ? 'X-Ray Detail' : 'Diamond Details');
    setMessage(
      `Dear Sir/Madam,\nGreetings of the day from Unique Diamax Pvt Ltd Team.\nPlease have a look at below ${props.xRay ? 'X-Ray' : 'stock'
      } detail.`,
    );
  }, [props?.xRay]);

  const state = React.useMemo(
    () => {
      // prettier-ignore
      return { subject, message, cc, email, subjectInvalid, emailInvalid, ccInvalid, };
    },
    // prettier-ignore
    [subject, message, cc, email, subjectInvalid, emailInvalid, ccInvalid],
  );

  const handleEmailChange = (e) => {
    const regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const result = regEx.test(String(e.target.value).toLowerCase());
    setEmail(e.target.value);
    setEmailInvalid(!result);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setSubjectInvalid(e.target.value.trim() === '');
  };

  const handleCCChange = (e) => {
    const regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const result = regEx.test(String(e.target.value).toLowerCase());
    const ccInvalid = result || e.target.value.trim() === '';
    setCc(e.target.value);
    setCcInvalid(ccInvalid);
  };

  const handleOk = async () => {
    setLoader(true);
    if (email.trim() === '') {
      setEmailInvalid(true);
    } else {
      if (emailInvalid === false && subjectInvalid === false && messageInvalid === false) {
        let obj = {
          ...state,
          id: props.ids,
          certFile: props.certFile,
          isHa: props.isHa,
          img: props.img,
          pltFile: props.isPlt,

          // isStock: props.isStock,
          videoFile: props.videoFile,
          assetFile: props.assetFile,
          isExcel: props.isExcel,
          arrowFile: props.arrowFile,
          mp4Video: props.mp4Video,
          type2: props.type2,
          roughVideo: props.roughVideo,
          darkFieldImage: props.darkFieldImage,
          flsImage: props.flsImage,
          idealWhiteImage: props.idealWhiteImage,
          roughImage: props.roughImage,
          planImg: props.planImg,
          faceUp: props.faceUp,
          b2cRealImage: props.b2cRealImage,
        };

        for (const key in obj) {
          if (obj[key] === false) {
            delete obj[key];
          }
        }

        if (props.xRay) {
          const [err, res] = await sendXRayEmailAttachment({ ...state }, props.path);
          !err && showNotification(res);

          if (res.code === 'OK') {
            props.onDetailClose();
          }
        } else {
          let request = {
            sendEmail: true,
            email: obj,
            filter: { vStnId: props.checked?.map((d) => d.vStnId), isFcCol: false },
          };
          const [err, res] = await sendEmailAttachment(request);
          !err && showNotification(res);

          if (res.code === 'OK') {
            dispatch({ type: 'RESET_SELECTED_ROWS' });
            props.onCancel(); //only email modal close
            if (isFunction(props.onClose)) props.onClose(); //whole modal close email model and main modal
          }
        }
      }
    }
    setLoader(false);
  };

  const handleMessageChange = React.useCallback((e) => {
    if (e.target.value.trim() === '') {
      setMessageInvalid(true)
    } else {
      setMessageInvalid(false)
    }
    setMessage(e.target.value);
  }, []);

  return (
    <>
      <CommonModal
        visible={props.visible ?? props.sendEmail}
        handleOk={handleOk}
        handleCancel={props.onCancel}
        title="Send Email"
        submitTitle="Send Email"
        cancelTitle="Cancel"
        footerShow
        destroyOnClose
      >
        <div className="searchPopupCommon">
          <div className="searchPopupTwoValue1">
            <InputBlock
              label="Subject *"
              placeholder="Subject"
              value={subject}
              onChange={handleSubjectChange}
              error={subjectInvalid ? ' Please enter email subject' : ''}
            />
          </div>
          <div>
            <InputBlock
              label="Email *"
              placeholder="Email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
              error={emailInvalid ? 'Please enter valid email' : ''}
            />
          </div>
          <div className="searchPopupTwoValue1">
            <InputBlock
              label="Mail CC"
              placeholder="Mail CC"
              value={cc}
              onChange={handleCCChange}
              error={ccInvalid ? 'Please enter valid cc email' : ''}
            />
          </div>
          <div className="searchPopupTwoValue1">
            <TextArea label="Message *" placeholder="Message" value={message} onChange={handleMessageChange} hasError={messageInvalid ? true : false} />
            {messageInvalid === true ? (
              <span className="required" style={{ color: 'red' }}>
                Please enter Message.
              </span>
            ) : (
              ''
            )}
          </div>
          {loader && <CircularProgress className="fixLoader" />}
        </div>
      </CommonModal>
    </>
  );
}

export default SendEmailPopup;
