import React, { Component } from 'react';
import CommonModal from '../../containers/CommonModal';
import SelectOption from '../../containers/SelectOption';
import TextArea from '../../containers/TextArea';
import { DIAMOND_BLOCK, HOLD_STATUS, USERS_TYPE } from 'constants/Common';
import { isArray, isEmpty, isFunction } from 'util/utils';
import { getHighEndClient } from 'services/UserApi';
import Messages from '../../constants/Messages';
import { InsertDiamondBlock, getDiamondBlockMaster } from 'services/DiamondBlockApi';
import { cloneDeep, find, groupBy, uniqBy } from 'lodash';
import moment from 'moment';
import DisableBlock from 'containers/DisableBlock';
import { newDiamondPrice } from './SelectStone';
import InputField from 'containers/InputBlock';
import CircularProgress from 'components/CircularProgress';
import Storage from 'services/storage';
import { showNotification } from 'services/NotificationService';
import AccountDropdown from 'components/User/AccountDropdown';
import { connect } from 'react-redux';
import { getLoginUser } from 'services/commonFunc';
import { Row, Col } from 'antd';

export const holdPopup = {
  type: [{ value: 'Sales Rep. Hold' }, { value: 'Client Hold' }],
  Duration: [{ value: '20 Minutes' }, { value: '30 Minutes' }],
};

const HoldMasterCodes = {
  B: { C: 'CB', S: 'SB', E: 'EB', A: 'B' },
  J: { C: 'CJ', S: 'SJ', E: 'EJ', A: 'J' },
  I: { C: 'CX', S: 'SX', E: 'EX', A: 'I' },
};

const revHold = { CB: 'B', SB: 'B', EB: 'B', CJ: 'J', SJ: 'J', EJ: 'J', CX: 'I', SX: 'I', EX: 'I' };

class HoldPopup extends Component {
  getState = () => ({
    clientList: [],
    client: null,
    error: {},
    remarks: null,
    typeList: [],
    type: null,
    holdDurationList: [],
    holdDuration: null,
    checked: cloneDeep(this.props.checked),
    userTerms: this.props.userTerms,
    isTimeEditable: false,
    isClientShow: false,
    isTimeShow: false,
    loader: false,
    codeMaster: [],
    initialHoldDuration: null,
    highEndClientList: [],
    highEndClient: null,
  });

  state = this.getState();

  setData() {
    if (!isEmpty(this.props.client)) {
      let clientData = this.setInitialClientData();
      this.setState({ ...clientData }, () => this.calculateDiamond());
    }
    this.getStageBlockData();
  }

  componentDidMount() {
    this.setData();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps?.visible && this.props.visible) this.setData();
  }

  setInitialClientData = () => {
    const client = this.props.client;
    return {
      clientList: client?.id
        ? [
            {
              value: `${client?.companyName} | ${client?.user?.firstName} ${client?.user?.lastName}`,
              key: this?.props?.client?.user?.id,
              ...client,
            },
          ]
        : [],
      client: client?.user?.id ? client?.user?.id : null,
      account: client?.id ? client?.id : null,
    };
  };

  getHighEndClientData = async () => {
    this.setState({ loading: !this.state.loading });

    const [, res] = await getHighEndClient();
    if (res?.code === 'OK' && res?.data) {
      this.setState({ loading: !this.state.loading });
      let highEndClientList = res.data.map((d) => ({
        value: `${d.companyName} | ${d?.user?.firstName ? d?.user?.firstName : ''} ${
          d?.user?.lastName ? d?.user?.lastName : ''
        }`,
        key: d?.user?.id,
        ...d,
      }));
      this.setState({ highEndClientList });
    }
  };

  removeError = (filed) => {
    let error = this.state.error;
    delete error[filed];
    this.setState({ error });
  };

  isValue = (value) => value || undefined;

  getStageBlockData = async () => {
    let useBlockSettings = Storage.get('user');
    const [, res] = await getDiamondBlockMaster({ filter: { wSts: 'H' } });
    if (res?.code === 'OK' && res?.data.length) {
      const diamondwSts = groupBy(this.props.checked, 'wSts');
      let typeList = [];
      res.data.forEach((type) => {
        Object.keys(diamondwSts).forEach((key) => {
          if (isArray(type?.allowSts) && type.allowSts.includes(key)) {
            typeList.push({ value: type?.webDisplay, key: type?.id, ...type });
          }
        });
      });
      if (isArray(useBlockSettings?.blockSettings)) {
        typeList = typeList.filter((d) => useBlockSettings.blockSettings.includes(d?.id));
      }
      typeList = uniqBy(typeList, 'value');
      this.setState({ typeList, type: typeList?.[0]?.key, codeMaster: res.data }, this.onSelectType);
    }
  };

  onSelectType = () => {
    let type = find(this.state.typeList, (d) => d.key === this.state.type);
    let data = {};

    if (type?.isAutoRelease) {
      data.isTimeShow = true;
      data.initialHoldDuration = data.holdDuration = type.timeInMinutes;
      data.isTimeEditable = type?.isTimeEditable ? true : false;
    } else {
      data.isTimeShow = false;
      data.isTimeEditable = false;
      data.holdDuration = null;
    }
    data.isClientShow = type?.isClientRequired ? true : false;

    this.setState({ ...this.state, ...data });
  };

  getSellerFromLogin = (isBlueNileOrJamesallen) => {
    if (isBlueNileOrJamesallen) return null;
    const loginUser = getLoginUser();

    if (loginUser?.type === USERS_TYPE.SELLER) return loginUser?.id;
    if (loginUser?.type === USERS_TYPE.ADMIN || loginUser?.type === USERS_TYPE.SUPER_ADMIN)
      return this.props.client?.user?.seller ?? null;

    return undefined;
  };

  onSaveHold = async () => {
    let error = {};

    let {
      client,
      account,
      remarks,
      type,
      holdDuration,
      isClientShow,
      isTimeShow,
      initialHoldDuration,
      highEndClient,
      typeList,
    } = this.state;
    // NOTE: Hold duration pending

    if (client == null && isClientShow) {
      error.client = Messages.clientNameSelect;
    }

    if (
      typeList.find((d) => d?.key == type)?.blockCode === 'I' &&
      highEndClient == null &&
      Storage.get('user')?.type == USERS_TYPE.SELLER
    ) {
      error.highEndClient = Messages.highEndClient;
    }
    if (holdDuration > 360 || holdDuration < 60) {
      error.holdDuration = Messages.timeSelect.replace('max', 360);
    }
    if (type == null) {
      error.type = Messages.typeSelect;
    }

    let BlockCodeSelected = typeList.find((d) => d?.key == type)?.blockCode;
    let isBlueNileOrJamesallen = BlockCodeSelected === 'EB' || BlockCodeSelected === 'EJ';

    this.setState({ error });
    if (isEmpty(error)) {
      let diamonds = this.state.checked.map((d) => {
        let avCode = find(this.state.codeMaster, { id: type }).blockCode;
        if (revHold[avCode]) avCode = revHold[avCode];
        let bCode = HoldMasterCodes[avCode] ? HoldMasterCodes[avCode] : null;
        let interCode = bCode && bCode[d.sSts] ? bCode[d.sSts] : null;
        let findCode = interCode ? find(this.state.codeMaster, { blockCode: interCode }).id : null;

        return {
          diamond: d.id,
          blockPricePerCarat: d.calcPricePerCarat ? d.calcPricePerCarat : 0,
          blockAmount: d.calcAmount ? d.calcAmount : 0,
          vnd: d.vnd,
          vStnId: d.vStnId,
          blockSetting: findCode || type,
          blockCode: interCode,
        };
      });
      this.setState({ loader: true });
      const request = {
        blockType: DIAMOND_BLOCK.TYPE.HOLD,
        diamonds,
        remarks,
        // blockSetting: type,
      };

      // NOTE: HighEnd client
      if (isClientShow || highEndClient) {
        request.user = highEndClient ? highEndClient : client;
        request.userAccount = account;
      }

      request.seller = this.getSellerFromLogin(isBlueNileOrJamesallen);

      if (isTimeShow) {
        request.timeInMin = moment().add('m', Number(holdDuration)).toISOString();
      }

      const [err, res] = await InsertDiamondBlock(request);

      !err && showNotification(res);
      if (isFunction(this.props.handleCancel)) this.props.handleCancel();

      if (res?.code === 'OK' && res?.data) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (isFunction(this.props.onSuccess)) this.props.onSuccess();
        if (isFunction(this.props.changesStatus)) this.props.changesStatus('H', res?.data?.created);
        window.location.reload();
      }

      if (isFunction(this.props.onClose)) this.props.onClose();
      this.setState({ loader: false });
    }
  };

  calculateDiamond = (client) => {
    let newPricedRows = this.state.checked.map((x) =>
      newDiamondPrice(x, client?.user?.accountTerm || client?.accountTerm),
    );
    this.setState({ checked: newPricedRows });
  };

  render() {
    return (
      <>
        <CommonModal
          handleOk={this.onSaveHold}
          handleCancel={this.props.onClose ?? this.props.handleCancel}
          title="Hold Stone"
          submitTitle="Submit"
          cancelTitle="Cancel"
          visible={this.props.visible ?? true}
          footerShow
        >
          <div className="searchPopupCommon">
            <Row>
              <Col md={12}>
                <SelectOption
                  placeholder="Select Type"
                  value={this.isValue(this.state.type)}
                  selectValue={this.state.typeList}
                  label="Type *"
                  onChange={(type = null) => {
                    this.setState({ type }, () => {
                      this.removeError('type');
                      this.onSelectType();
                      if (
                        this.state.typeList.find((d) => d?.key == type)?.blockCode === 'I' &&
                        Storage.get('user')?.type == USERS_TYPE.SELLER
                      ) {
                        this.getHighEndClientData();
                      }
                    });
                  }}
                  error={this.state.error?.type}
                />
              </Col>
              {this.state.isTimeShow && !this.state.isTimeEditable && (
                <DisableBlock className="from-group">
                  <InputField
                    className="width-100"
                    label="Time Duration"
                    value={this.state.holdDuration}
                    disabled={true}
                  />
                </DisableBlock>
              )}
              <Col md={12}>
                {this.state.isTimeShow && this.state.isTimeEditable && (
                  <InputField
                    className="width-100"
                    label="Time Duration (Minutes) *"
                    value={this.state.holdDuration}
                    max={this.state.initialHoldDuration}
                    type="number"
                    min="0"
                    onChange={(e) => {
                      this.setState({ holdDuration: e.target.value });
                      if (360 < e.target.value) {
                        let error = {
                          holdDuration: Messages.timeSelect.replace('max', 360),
                        };

                        this.setState({ error });
                      } else {
                        this.removeError('holdDuration');
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == '.') e.preventDefault();
                    }}
                    error={this.state.error?.holdDuration}
                  />
                )}
              </Col>
              <Col md={12}>
                {this.state.isClientShow && (
                  <>
                    <AccountDropdown
                      name="client"
                      label="Client Name "
                      initialValue={this.props?.client}
                      onChange={(account) => {
                        this.removeError('client');
                        this.setState({ client: account?.user?.id, account: account?.id }, () =>
                          this.calculateDiamond(account),
                        );
                      }}
                      error={this.state.error.client}
                      required
                    />
                  </>
                )}
              </Col>
              {this.state.typeList.find((d) => d?.key == this.state.type)?.blockCode === 'I' &&
                Storage.get('user')?.type == USERS_TYPE.SELLER && (
                  <SelectOption
                    placeholder="Select HighEnd Client"
                    value={this.isValue(this.state.highEndClient)}
                    selectValue={this.state.highEndClientList}
                    label="HighEnd Client *"
                    onChange={(highEndClient = null) => {
                      this.removeError('highEndClient');
                      let hightEnfDetails = this.state.highEndClientList?.find((d) => d.key == highEndClient);
                      this.setState({ account: hightEnfDetails?.id, highEndClient });
                    }}
                    error={this.state.error.highEndClient}
                  />
                )}
              <Col md={12}>
                <TextArea
                  label="Comment"
                  onChange={(e) => this.setState({ remarks: e.target.value })}
                  value={this.state.remarks}
                  placeholder="Comment"
                />
              </Col>
            </Row>
          </div>
          {this.state.loader && <CircularProgress className="fixLoader" />}
        </CommonModal>
      </>
    );
  }
}

export default connect((state) => ({ loginUser: state?.auth }))(HoldPopup);
