import React, { Component } from 'react';
import InputBlock from 'containers/InputBlock';
import BottomStickyButton from '../../BottomStickButton';
import Heading from 'containers/MainHeading';

import { isEmpty } from 'util/utils';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';

class AdditionalInfo extends Component {
  state = {
    references: [],
  };
  fields = [
    {
      name: 'references',
      label: 'References Details',
      type: 'array',
      fields: [
        { name: 'companyName', type: 'text', label: 'Company Name', initialValue: '' },
        { name: 'personName', type: 'text', label: 'Contact Person', initialValue: '' },
        { name: 'mobile', type: 'text', label: 'Phone Number', initialValue: '' },
      ],
    },
  ];

  getInitialValues = () => {
    const data = { references: this.props?.account?.references ? [...(this.props?.account?.references || 0)] : [] };

    return Object.fromEntries(
      this.fields.map(({ type, fields, name, initialValue }) =>
        ['array'].includes(type) && isEmpty(data?.[name])
          ? [name, [Object.fromEntries(fields.map(({ name, initialValue }) => [name, initialValue]))]]
          : [name, isEmpty(data?.[name]) ? initialValue : data[name]],
      ),
    );
  };

  validationSchema = Yup.object().shape({
    references: Yup.array().of(
      Yup.object().shape({
        companyName: Yup.string().trim(),
        personName: Yup.string().trim(),
        mobile: Yup.string(),
      }),
    ),
  });

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const { account } = this.props;
    let refs = values.references.filter(
      (item) => !isEmpty(item.companyName) || !isEmpty(item.mobile) || !isEmpty(item.personName),
    );
    setSubmitting(true);
    const [, res] = await this.props?.updateAccount(account?.id, { references: refs });
    setSubmitting(false);

    if (res?.code === 'OK') {
      if (this.state.saveContinue) this.props.updateSubTabIndex();
      else {
        resetForm();
        this.props.onCancel();
      }
    }
  };

  render() {
    const initialValues = this.getInitialValues();

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={this.validationSchema}
        validateOnChange
        onSubmit={this.submitForm}
      >
        {({ handleChange, handleBlur, submitForm, touched, errors, values }) => (
          <Form>
            <div className="kycFormMainBlock additionInfoBlock">
              <div className="additionTopBlock">
                <div className="width-100">
                  <Heading className="smallTitle p-0 mb-20" title="References Details"></Heading>
                  {this.fields.map(
                    (field) =>
                      field.type === 'array' &&
                      values[field.name].map((item, index) => (
                        <React.Fragment key={index}>
                          <FieldArray
                            name={field.name}
                            render={(fieldArrayHelpers) => (
                              <>
                                <div className="kycBlockWrapper formMainSubBlock mb-20 pb-0">
                                  {field.fields.map(({ name, type, label, required, props }) => {
                                    const namePrefix = `${field.name}.${index}.`;
                                    const htmlName = `${namePrefix}${name}`;

                                    return type === 'show' ? (
                                      <div key={index} className="from-group"></div>
                                    ) : (
                                      <InputBlock
                                        {...{ name: htmlName, type, ...props }}
                                        label={required ? label + ' *' : label}
                                        onKeyPress={(evt) => {
                                          if (
                                            (name == 'mobile' &&
                                              evt.which != 8 &&
                                              evt.which != 0 &&
                                              (evt.which < 48 || evt.which > 57) &&
                                              evt.which != 45) ||
                                            (name == 'mobile' && evt.target.value.length >= 10)
                                          ) {
                                            evt.preventDefault();
                                          }
                                        }}
                                        placeholder={label}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                          touched[field.name]?.[index]?.[name] && errors[field.name]?.[index]?.[name]
                                        }
                                        value={values[field.name]?.[index]?.[name]}
                                        key={htmlName}
                                      />
                                    );
                                  })}
                                </div>
                                <div className="kycNewBlockAdd">
                                  <button
                                    className="commonButton"
                                    type="button"
                                    role="button"
                                    onClick={() => {
                                      let row = values[field.name]?.[index];

                                      (!isEmpty(row.companyName) || !isEmpty(row.personName) || !isEmpty(row.mobile)) &&
                                        fieldArrayHelpers.push({ companyName: '', personName: '', mobile: '' });
                                    }}
                                  >
                                    Add
                                  </button>
                                  {values.references?.length > 1 && (
                                    <button
                                      className="commonButton ml-5"
                                      type="button"
                                      role="button"
                                      onClick={() => values[field.name]?.length > 1 && fieldArrayHelpers.remove(index)}
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </>
                            )}
                          />
                        </React.Fragment>
                      )),
                  )}
                </div>
              </div>
            </div>
            <BottomStickyButton className={!this.props?.account?.id && 'disableBlock'}>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: true }, submitForm)}
              >
                Save & Continue
              </button>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: false }, submitForm)}
              >
                Save
              </button>
              <button className="commonOutine" type="reset" onClick={this.props.onCancel}>
                Cancel
              </button>
            </BottomStickyButton>
          </Form>
        )}
      </Formik>
    );
  }
}

export default AdditionalInfo;
