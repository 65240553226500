import React from 'react';
import { Select } from 'antd';
import SelectCurrency from './SelectCurrency';
import { isArray, isEmpty } from 'util/utils';
import './selectOption.less';

function SelectOption({ orderDetail, required, ...props }) {
  const {
    label,
    selectValue,
    defaultValue,
    defaultSelect,
    options,
    nameKey,
    valueKey = 'key',
    className,
    error,
    ...rest
  } = props;

  const optionList = React.useMemo(() => (isArray(options) ? options : selectValue), [options, selectValue]);

  const value = React.useMemo(() => {
    if (rest?.mode == 'multiple') {
      return isArray(optionList) &&
        optionList?.filter((option) =>
          rest?.value?.includes(isEmpty(option[valueKey]) ? `${option.key}` : `${option[valueKey]}`),
        ).length > 0
        ? rest?.value
        : undefined;
    } else {
      return isArray(optionList) &&
        optionList?.filter((option) =>
          isEmpty(option[valueKey]) ? `${option.key}` : `${option[valueKey]}` == rest?.value,
        ).length
        ? rest?.value ?? defaultValue
        : defaultValue ?? undefined;
    }
  }, [defaultValue, optionList, rest?.mode, rest?.value, valueKey]);

  if (orderDetail) return <SelectCurrency {...props} />;

  return (
    <div className={`formSelect from-group ${className}` + (error ? ' error-message' : '')}>
      <label>
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <Select
        getPopupContainer={(trigger) => trigger}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        allowClear
        showArrow
        showSearch
        {...rest}
        value={value}
      >
        {isArray(optionList) &&
          optionList.map((option, key) => (
            <Select.Option key={key} value={isEmpty(option[valueKey]) ? `${option.key}` : `${option[valueKey]}`}>
              {isEmpty(option[nameKey]) ? `${option.value}` : `${option[nameKey]}`}
            </Select.Option>
          ))}
      </Select>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default React.memo(SelectOption);
