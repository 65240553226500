export const GRID_COLUMN = {
  GRID_DIAMOND_LIST: 'GRID_DIAMOND_LIST',
  GRID_DIAMOND_LIST_ADMIN: 'GRID_DIAMOND_LIST_ADMIN',
  GRID_TRANSACTION_ENQUIRY_CART: 'GRID_TRANSACTION_ENQUIRY_CART',
  GRID_TRANSACTION_ENQUIRY_OFFICE_VIEW: 'GRID_TRANSACTION_ENQUIRY_OFFICE_VIEW',
  GRID_TRANSACTION_ENQUIRY_WATCH_LIST: 'GRID_TRANSACTION_ENQUIRY_WATCH_LIST',
  GRID_TRANSACTION_ENQUIRY_NOTES: 'GRID_TRANSACTION_ENQUIRY_NOTES',
  GRID_TRANSACTION_REMINDER: 'GRID_TRANSACTION_REMINDER',
  GRID_TRANSACTION_HOLD_LIST: 'GRID_TRANSACTION_HOLD_LIST',
  GRID_TRANSACTION_ON_MEMO_LIST: 'GRID_TRANSACTION_ON_MEMO_LIST',
  GRID_TRANSACTION_OFFER: 'GRID_TRANSACTION_OFFER',
  GRID_TRANSACTION_ORDER: 'GRID_TRANSACTION_ORDER',
  GRID_DIAMOND_LIST_CORUSCATE: 'GRID_DIAMOND_LIST_CORUSCATE',
  GRID_DIAMOND_ADMIN_UPCOMING_LIST: 'GRID_DIAMOND_ADMIN_UPCOMING_LIST',
  GRID_DIAMOND_LIST_CHECK_IMAGE: 'GRID_DIAMOND_LIST_CHECK_IMAGE',
  GRID_DIAMOND_LIST_PROFORMA_REPORT: 'GRID_DIAMOND_LIST_PROFORMA_REPORT',
  GRID_DIAMOND_LIST_SE_REPORT: 'GRID_DIAMOND_LIST_SE_REPORT',
  GRID_DIAMOND_LIST_SHOW_STONE_REPORT: 'GRID_DIAMOND_LIST_SHOW_STONE_REPORT',
  GRID_CHECK_PACKET: 'GRID_CHECK_PACKET',
  GRID_DIAMOND_LIST_ADMIN_PRICE: 'GRID_DIAMOND_LIST_ADMIN_PRICE',
  GRID_ADMIN_TRANSACTION_BID: 'GRID_ADMIN_TRANSACTION_BID',
  GRID_ADMIN_TRANSACTION_BID_SALES_PERSON: 'GRID_ADMIN_TRANSACTION_BID_SALES_PERSON',
  OPEN_ENQUIRY_DIAMOND_LIST_ADMIN: 'OPEN_ENQUIRY_DIAMOND_LIST_ADMIN',
  CLOSE_ENQUIRY_DIAMOND_LIST_ADMIN: 'CLOSE_ENQUIRY_DIAMOND_LIST_ADMIN',
  GRID_ADMIN_TRANSACTION_DEAL_OF_THE_DAY: 'GRID_ADMIN_TRANSACTION_DEAL_OF_THE_DAY',
  GRID_DIAMOND_COMMON_EXCEL: "GRID_DIAMOND_COMMON_EXCEL"
};

export const DIAMOND_WEB_STATUS = {
  A: 'A',
  M: 'M',
  NA: 'NA',
  UPCOMING: 'U',
  BID: 'B',
  SHOW: 'S',
};

export const DIAMOND_WEB_STATUS_WITH_LABEL = {
  AVAILABLE: 'A',
  BLUENILE_HOLD: 'B',
  CONSIGNMENT: 'C',
  C1: 'C1',
  C2: 'C2',
  C3: 'C3',
  C4: 'C4',
  C5: 'C5',
  CONSIGNMENT_BLUENILE_HOLD: 'CB',
  CONSIGNMENT_MEMO: 'CC',
  CONSIGNMENT_HOLD: 'CH',
  CONSIGNMENT_JAMES_ALLEN_HOLD: 'CJ',
  CONSIGNMENT_INTERNATIONAL_HOLD: 'CX',
  EXTERNAL_BOTSWANA: 'E',
  EXTERNAL_BLUENILE_HOLD: 'EB',
  EXTERNAL_BOTSWANA_MEMO: 'EC',
  EXTERNAL_HOLD: 'EH',
  EXTERNAL_JAMES_ALLEN_HOLD: 'EJ',
  EXTERNAL_INTERNATIONAL_HOLD: 'EX',
  GRADING: 'G',
  LOCAL_HOLD: 'H',
  INTERNAL_MEMO: 'I',
  JAMES_ALLEN_HOLD: 'J',
  LAB: 'L',
  MEMO: 'M',
  NIGHT_HOLD: 'N',
  OFFICE_VIEW: 'O',
  REPAIR: 'R',
  SHOW: 'S',
  SHOW_BLUENILE_HOLD: 'SB',
  SHOW_HOLD: 'SH',
  SHOW_JAMES_ALLEN: 'SJ',
  SHOW_MEMO: 'SM',
  SHOW_INTERNATIONAL_HOLD: 'SX',
  TRANSIT: 'T',
  VIDEO: 'V',
  SMART_EYE: 'Y',
  GEMPRINT: 'Z',
};

export const DIAMOND_SEARCH_TYPE = {
  RECENT: 1,
  SAVE: 2,
  DEMAND: 3,
  API_SEARCH: 4,
  PAIR_SEARCH: 5,
  VOICE_SEARCH: 6,
  LUCKY_SEARCH: 7,
  ARTICLE: 8,
  COLLECTION: 9,
  INVENTORY: 10,
};

export const DIAMOND_BID = {
  TYPE: {
    BLIND: 1,
    OPEN: 2,
  },
  STATUS: {
    PENDING: 1,
    WIN: 2,
    LOSS: 3,
  },
};

export const SE_REPORT_STAGE_TYPE = { C: 'C', S: 'S', E: 'E' };

export const DIAMOND_WEB_STATUS_LIST = [
  'A',
  'B',
  'C',
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'CB',
  'CC',
  'CH',
  'CJ',
  'CX',
  'E',
  'EB',
  'EC',
  'EH',
  'EJ',
  'EX',
  'G',
  'H',
  'I',
  'J',
  'L',
  'M',
  'N',
  'O',
  'R',
  'S',
  'SB',
  'SH',
  'SJ',
  'SM',
  'SX',
  'T',
  'V',
  'Y',
  'Z',
];
