import { DateTime } from 'luxon';
import { isArray, isBoolean, isEmpty, isObject } from 'util/utils';
import { diamondParameters } from 'constants/Common';

const formatDate = (isoDate) => DateTime.fromJSDate(new Date(`${isoDate}`)).toLocaleString();
const formatTime = (isoDate) => DateTime.fromJSDate(new Date(`${isoDate}`)).toFormat('hh:mm a');

export const defaultOptions = { exclude: ['inDt', 'isFm', 'rptNo'] };

export const formatDiamondList = (list, options = defaultOptions) => {
  const { exclude, addTime } = { ...defaultOptions, ...options };

  if (!isArray(list)) return [];

  return list.map((item) => {
    const properties = [];
    const addToProperties = (key, value, nin = false) => {
      if (isObject(value) || exclude.includes(key)) return;
      const property = {
        title: `${diamondParameters?.[key]?.name || key}${nin ? ' [does not contain]' : ''}`,
        value,
      };
      if (!isEmpty(property.title) && !isEmpty(property.value)) properties.push(property);
    };

    if (addTime) addToProperties('Time', formatTime(item.updatedAt));
    if (item?.updatedAt) addToProperties('Date', formatDate(item.updatedAt));
    if (item?.expiryDate) addToProperties('Expiry Date', formatDate(item.expiryDate));

    item?.displayData &&
      Object.entries(item?.displayData).forEach(([key, value]) => {
        if (isArray(exclude) && exclude.includes(key)) return;
        const rangeList = {};
        const hasRange = (value) => !isEmpty(value?.['>=']) || !isEmpty(value?.['<=']);
        const addToRangeList = (key, value, nin = false) => {
          const rangeValue =
            !isEmpty(value?.['>=']) && !isEmpty(value?.['<='])
              ? `${value['>=']} to ${value['<=']}`
              : !isEmpty(value?.['>='])
              ? `>= ${value?.['>=']}`
              : !isEmpty(value?.['<='])
              ? `<= ${value?.['<=']}`
              : undefined;

          if (rangeValue !== undefined) {
            if (key === 'carat') key = 'crt';
            if (!rangeList[key]) rangeList[key] = [];
            rangeList[key].push(`${nin ? ' [does not contain]' : ''} ${rangeValue}`);
          }
        };

        const addItem = (key, value, nin = false) =>
          hasRange(value) ? addToRangeList(key, value, nin) : addToProperties(key, value, nin);

        const addRangeListToProperties = () =>
          Object.entries(rangeList).forEach(([key, value]) => addToProperties(key, value));

        key === 'or'
          ? value.forEach((object) => Object.entries(object).forEach(([key, value]) => addItem(key, value)))
          : key === 'excludeFilter'
          ? Object.entries(value).forEach(([key, value]) => addItem(key, value, true))
          : key === 'vnd'
          ? addItem(
              key,
              value.map((seller) => `${seller.companyName} (${seller.vendorNo})`),
            )
          : !isEmpty(value?.in)
          ? addItem(key, value?.in)
          : !isEmpty(value?.nin)
          ? addItem(key, value?.nin, true)
          : isBoolean(value)
          ? addItem(key, value ? 'Yes' : 'No')
          : addItem(key, value);

        addRangeListToProperties();
      });

    return {
      id: item?.id,
      title: item?.name,
      subTitle: `(No. of Diamonds : ${item?.searchCount})`,
      user: item?.user,
      createdBy: item?.createdBy,
      account: item?.account,
      searchCount: item?.searchCount,
      properties,
    };
  });
};
