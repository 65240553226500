import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Checkbox } from 'antd';

import Heading from 'containers/MainHeading';
import ListingWrapper from 'containers/ListingWrapper';
import ListingTableWrapper from 'containers/ListingTableWrapper';
import Table from 'components/Inventory/TableBack';

import { getExtraPer, SELECT_STONE_TERMS_NEW } from './SelectStone';
import { downloadExcelFile } from 'services/DiamondApi';
import OpenNotification from 'constants/OpenNotifications';
import SendEmailPopup from './Actions/SendEmailPopup';
import { getBankRate, showFinalCalc } from './CommonPopupFunction';
import { useCompositeState } from 'util/hooks';
import { isArray, isEmpty, isFunction, isNotEmpty } from 'util/utils';
import SingleStonePlaceOrderPopup from './SingleStonePlaceOrderPopup';

const currentType = 'finalpopup';

function FinalCalculations({ checkDiamondStatus, client, isShowConfirmStone, onClose, onConfirmStone, ...props }) {
  const [email, sendEmail] = useState('');
  const [term, setTerm] = React.useState('0');
  const [state, setState] = useCompositeState({
    data: [],
    columns: [],
    diamond: [],
    cmCharges: null,
    cmChecked: false,
    onClickConfirmStone: false,
    isCMChargeVisible: false,
  });
  const checkedData = useSelector((state) => state.diamondData.selectedRows[currentType]);
  const checked = state.data.filter((el) => (isArray(checkedData) ? checkedData.find(({ id }) => id === el?.id) : []));

  useEffect(() => {
    setState({ data: cloneDeep(props.checked) });
    getBankRate('CM_CHARGES', (cmCharges) => {
      setState({ cmCharges });
    });
  }, [props.checked, setState]);

  React.useEffect(() => {
    if (!isArray(checkedData)) return;
    if (!isEmpty(checkedData)) {
      const [diamondValues, newPricedRows] = showFinalCalc(
        checkedData,
        { term: SELECT_STONE_TERMS_NEW.find(({ id }) => id === term)?.value ?? 0 },
        state.cmCharges,
        true,
      );
      setState({ diamond: diamondValues });
      if (newPricedRows.filter((el) => el.isCm && el.isCm === 'ELIG').length) {
        setState({ isCMChargeVisible: true });
      }
    } else {
      onClose();
    }
  }, [checkedData, client, onClose, setState, state.cmCharges, term]);

  useEffect(() => {
    const _columns = props.columns;
    const index = _columns.findIndex(({ id }) => id === 'rap') || 1;
    const columns = [
      ..._columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      _columns.find(({ id }) => id === 'back'),
      _columns.find(({ id }) => id === 'ctPr'),
      _columns.find(({ id }) => id === 'amt'),
      {
        Header: () =>
          'Final Disc(' + parseFloat(getExtraPer(client?.user?.accountTerm || client?.accountTerm)).toFixed(2) + '%)',
        accessor: 'fnBack',
        id: 'fnBack',
        Cell({ row }) {
          return <>{parseFloat(row.original.calcDiscount || row.original.back || 0).toFixed(2)}</>;
        },
      },
      {
        Header: 'Final Rate',
        accessor: 'fnCtpr',
        id: 'fnCtpr',
        Cell({ row }) {
          return <>{parseFloat(row.original.calcPricePerCarat || row.original.ctPr || 0).toFixed(2)}</>;
        },
      },
      {
        Header: 'Final Value',
        accessor: 'fnAmt',
        id: 'fnAmt',
        Cell({ row }) {
          return <>{parseFloat(row.original.calcAmount || row.original.amt || 0).toFixed(2)}</>;
        },
      },
      ..._columns
        .slice(index + 1, _columns.length)
        .filter((el) => !['fnBack', 'fnCtpr', 'fnAmt', 'back', 'amt', 'ctPr'].includes(el.id)),
    ];
    setState({ columns: columns.filter(isNotEmpty) });
  }, [client, setState, props.columns]);

  return (
    <>
      <div>
        <Heading className="popupInnerTitle" title={'Final Calculation (' + checked.length + ')'} />
        <div className=" popupHalfShow finalCaculation">
          <ListingWrapper>
            <ListingTableWrapper subtractFrom=".mainHeadingTitle,.sidebarAction">
              <Table
                data={state.data}
                columns={state.columns}
                currentType={currentType}
                canSort={false}
                FilterOption={false}
                areAllChecked
                liveStatus
                noheader
                noDots
                noGrp
              />
            </ListingTableWrapper>
          </ListingWrapper>
          <div className="singleStonePlaceOrderDetail">
            {!state.onClickConfirmStone && state.isCMChargeVisible && (
              <Checkbox checked={state.cmChecked} onChange={() => setState({ cmChecked: !state.cmChecked })}>
                CM Charge
              </Checkbox>
            )}
            <div className={`DiamondDetailPopup mt-10 ${!state.onClickConfirmStone ? 'DiamondDetailPopuplist' : ''}`}>
              {state.diamond.length &&
                !state.onClickConfirmStone &&
                state.diamond.map((x) => (
                  <div key={x.id} className="DiamondDetailPopupItem w-100">
                    <span>{x.title}</span>
                    <span style={{ minWidth: 'fit-content' }}>
                      {x.title == 'Final Net Value' && !state.cmChecked && x.value.search(/[+]/) > 0
                        ? x.value.slice(0, x.value.search(/[+]/))
                        : x.title === 'Avg. Discount(%):' || x.title === 'Final Discount(%):'
                        ? x.value > 0
                          ? `+${x.value}`
                          : x.value
                        : x.value}
                    </span>
                  </div>
                ))}
                {state.diamond.length &&
                !state.onClickConfirmStone &&
              <div className="selectStoneValueItem">
                <span className="selectStoneLabel">TERMS : </span>
                <select name="term" value={term} placeholder="Select Terms" onChange={(e) => setTerm(e.target.value)}>
                  {SELECT_STONE_TERMS_NEW.map((term) => (
                    <option key={term?.id} value={term?.id}>
                      {term?.name}
                    </option>
                  ))}
                </select>
              </div>}
              {state.onClickConfirmStone && (
                <SingleStonePlaceOrderPopup
                  tab="order"
                  isFinalCal
                  setConfirmFn={(confirmFn) => setState({ confirmFn })}
                  onClose={onClose}
                  checked={checkedData}
                  client={client}
                  visible={state.onClickConfirmStone}
                />
              )}
            </div>
          </div>
          <div className="sidebarAction">
            {!isShowConfirmStone && (
              <button
                className="commonButton"
                onClick={() => {
                  if (checkDiamondStatus()) {
                    setState({ onClickConfirmStone: true });
                    if (isFunction(state.confirmFn)) state.confirmFn();
                  }
                }}
              >
                Confirm Stone
              </button>
            )}
            <button className="commonButton" onClick={onClose}>
              Cancel Stone
            </button>
            <button
              className="commonButton"
              onClick={() =>
                downloadExcelFile({ id: checked.map((item) => item.id) }, () => {
                  onClose();
                })
              }
            >
              Excel Export
            </button>
            <button
              className="commonButton"
              onClick={() => {
                if (checked.length) sendEmail('stock');
                else OpenNotification({ type: 'error', title: 'Please select any stone(s).' });
              }}
            >
              Email Stock
            </button>
            <button
              className="commonButton"
              onClick={() => {
                if (checked.length) sendEmail('img');
                else OpenNotification({ type: 'error', title: 'Please select any stone(s).' });
              }}
            >
              Email Pic
            </button>
          </div>
        </div>
      </div>
      <SendEmailPopup
        sendEmail={email}
        onCancel={() => {
          sendEmail('');
          onClose();
        }}
        ids={isArray(checked) && checked.map(({ id }) => id)}
        img={email === 'img'}
        isStock={email === 'stock'}
      />
    </>
  );
}

export default React.memo(FinalCalculations);
