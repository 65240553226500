import React from 'react';
import CommonModal from 'containers/CommonModal';
import FileUpload from 'containers/FileUpload';

function UploadImage({ visible, handleOk, handleCancel, ...props }) {
  return (
    <CommonModal
      title="Upload your image"
      submitTitle="Save"
      cancelTitle="Cancel"
      visible={visible}
      handleOk={handleOk}
      handleCancel={handleCancel}
      footerShow
    >
      <div className="searchPopupCommon text-center d-flex justify-content-center">
        <FileUpload {...props} />
      </div>
    </CommonModal>
  );
}

export default React.memo(UploadImage);
