import React from 'react';
import CommonModal from 'containers/CommonModal';
import SendEmailPopup from './SendEmailPopup';

import Messages from 'constants/Messages';
import { FORNT_SIDE_URL } from 'constants/PageUrl';

import { RowSelectService } from 'services/RowSelectService';
import * as Hooks from 'util/hooks';
import { Notify } from 'util/notify';
import { isArray, isEmpty } from 'util/utils';

import './action.less';

const ShareAction = (props) => {
  const { onClose } = props;
  const { filter = {}, checked = [], btnName } = props;

  const [sendEmail, setSendEmail] = Hooks.useBoolean();

  const cancelSendEmail = React.useCallback(() => {
    setSendEmail.false();
    onClose();
    RowSelectService.resetSelectedRows();
  }, [onClose, setSendEmail]);

  const WhatsAppShare = React.useCallback(() => {
    if (!isArray(checked) || isEmpty(checked)) return Notify.error({ message: Messages.DiamondSelectRequire });

    if (checked.length === 1) {
      let path = `${FORNT_SIDE_URL.diamondDetail}/${checked[0]?.vStnId}${btnName === 'drshare' ? '?direct-share' : ''}`;
      window.open(`https://web.whatsapp.com/send?text=${path}`);
    } else if (checked.length > 1) {
      let msg = 'Unique Diamax Pvt Ltd: Diamonds Detail%0A%0A';

      checked.forEach((element) => {
        let path = `${FORNT_SIDE_URL.diamondDetail}/${element?.vStnId}`;
        msg += path + '%0A%0A';
      });
      window.open(`https://web.whatsapp.com/send?text=${msg}`);
    }
    onClose();
    RowSelectService.resetSelectedRows();
  }, [btnName, checked, onClose]);

  const SkypeShare = React.useCallback(() => {
    if (!isArray(checked) || isEmpty(checked)) return Notify.error({ message: Messages.DiamondSelectRequire });

    if (checked.length === 1) {
      let path = `${FORNT_SIDE_URL.diamondDetail}/${checked[0]?.vStnId}`;
      window.open(`https://web.skype.com/share?url=${path}${btnName === 'drshare' ? '?direct-share' : ''}`);
    } else if (checked.length >= 2) {
      let msg = 'Unique Diamax Pvt Ltd: Diamonds Detail%0A%0A';

      checked.forEach((element) => {
        let path = `${FORNT_SIDE_URL.diamondDetail}/${element?.vStnId}`;
        msg += path + '%0A%0A';
      });
      window.open(`https://web.skype.com/share?url=${msg}`);
    }
    onClose();
    RowSelectService.resetSelectedRows();
  }, [btnName, checked, onClose]);

  const Wechat = React.useCallback(() => {
    if (!isArray(checked) || isEmpty(checked)) return Notify.error({ message: Messages.DiamondSelectRequire });

    if (checked.length === 1) {
      let path = `${FORNT_SIDE_URL.diamondDetail}/${checked[0]?.vStnId}`;
      window.open(`https://web.wechat.com/send?text=${path}${btnName === 'drshare' ? '?direct-share' : ''}`);
    } else if (checked.length >= 2) {
      let msg = 'Unique Diamax Pvt Ltd: Diamonds Detail%0A%0A';

      checked.forEach(() => {
        let path = `${FORNT_SIDE_URL.diamondDetail}/${checked[0]?.vStnId}`;
        msg += path + '%0A%0A';
      });
      window.open(`https://web.wechat.com/send?text=${msg}`);
    }
    onClose();
    RowSelectService.resetSelectedRows();
  }, [btnName, checked, onClose]);

  return (
    <>
      <CommonModal title="Share Stone" handleCancel={onClose} visible={props.visible} className="xm-size">
        <div>
          <div className="searchPopupCommon">
            <div className="commonModalButton">
              <button className="fillButton" onClick={setSendEmail.true}>
                Email
              </button>
              <button onClick={WhatsAppShare} className="fillButton ml-5">
                Whats App
              </button>
              <button onClick={SkypeShare} className="fillButton ml-5">
                Skype
              </button>
              {/* <button onClick={Wechat} className="fillButton ml-5">
                WeChat
              </button> */}
            </div>
          </div>
        </div>
      </CommonModal>
      <SendEmailPopup
        filter={filter}
        checked={checked}
        sendEmail={sendEmail}
        onCancel={cancelSendEmail}
        ids={checked.map((item) => item['id'])}
        request={props.requestUrl?.request}
        paginate={props.requestUrl?.urlMethod}
        certFile={false}
        hAFile={false}
        img={false}
        isPlt={false}
        isReal={false}
        assetFile={false}
        isStock={false}
        videoFile={false}
      />
    </>
  );
};

export default ShareAction;
