import React, { Component } from 'react';
import CommonModal from '../../containers/CommonModal';
import SelectOption from '../../containers/SelectOption';
import { pick, includes, uniq, groupBy } from 'lodash';
import Messages from '../../constants/Messages';
import { isEmpty, isFunction, isObject } from 'util/utils';
import { InsertDiamondBlock, getDiamondBlockMaster } from 'services/DiamondBlockApi';
import { DIAMOND_BLOCK, SHIP_MODE } from 'constants/Common';
import { calculate, newDiamondPrice, formatNumber } from './SelectStone';
import { fetchPartyRelatedInfo, isValue } from './CommonPopupFunction';
import PartyBuyerComponent from './PopupComponent/PartyBuyerComponent';
import SellerComponent from './PopupComponent/SellerComponent';
import BrokerComponent from './PopupComponent/BrokerComponent';
import LocationComponent from './PopupComponent/LocationComponent';
import CircularProgress from '../../components/CircularProgress';
import { showNotification } from 'services/NotificationService';

class MemoPopup extends Component {
  getState = () => ({
    user: null, //buyer
    userAccount: null, //party
    loading: false,
    sellerList: [],
    seller: null,
    countryList: [],
    shippingCountry: null,
    cityList: [],
    shippingCity: null,
    stageList: [],
    stage: null,
    assistantSeller: null,
    shipModeList: SHIP_MODE,
    shipMode: '',
    error: {},
    broker: null,
    brokerList: [],
    // checked: cloneDeep(this.props.checked),
    client: [],
    userTerms: null,
    loader: false,
    initialStageList: [],
    registrationType: null,
  });

  state = this.getState();

  setData() {
    let clientData = {};
    if (!isEmpty(this.props.client)) {
      let client = this.props.client;

      clientData.client = [client];
      clientData.user = client.user?.id;
      clientData.userAccount = client?.id;
      clientData.userTerms = client?.user?.accountTerm || client.accountTerm;
      clientData.broker = isObject(client?.broker) && client?.broker?.companyName ? client.broker?.id : null;
      clientData.brokerList =
        isObject(client?.broker) && client?.broker?.companyName
          ? [
              {
                ...client?.broker,
                value: client?.broker?.companyName,
                key: client?.broker?.id,
              },
            ]
          : [];
      if (isObject(client?.seller)) clientData.assistantSeller = clientData.seller = client.seller?.id;
      clientData.shippingCountry = client?.country;
      clientData.shippingCity = client?.city;
      clientData.registrationType = client?.registrationType;
    }
    if (this.props.isOfficeView) {
      let checkedDiamond = this.props.checked?.[0];
      clientData.client = [checkedDiamond?.user];
      clientData.user = checkedDiamond?.user?.id;
      clientData.userTerms = checkedDiamond?.user?.accountTerm || checkedDiamond?.accountTerm;
      clientData.userAccount = checkedDiamond?.user?.account?.id;
      if (isObject(checkedDiamond?.broker)) {
        clientData.broker = checkedDiamond?.broker?.id;
        clientData.brokerList = isObject(checkedDiamond?.broker)
          ? [checkedDiamond?.broker].map((d) => d?.companyName && { value: d.companyName, key: d?.id })
          : [];
      }
      clientData.shippingCountry = checkedDiamond?.user?.account?.country;
      clientData.shippingCity = checkedDiamond?.user?.account?.city;
      if (isObject(checkedDiamond?.seller)) clientData.assistantSeller = clientData.seller = checkedDiamond?.seller?.id;
    }
    this.setState({ ...this.getState(), ...clientData }, () => {
      this.userTermsDiamondCal();
      this.getStageData();
    });
  }

  componentDidMount() {
    this.setData();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps?.visible && this.props.visible) {
      this.setData();
    }
  }

  userTermsDiamondCal = () => {
    let newPricedRows = this.props.checked.map((x) =>
      newDiamondPrice(x, this.state.userTerms !== null ? this.state.userTerms : {}),
    );

    let summation = calculate(newPricedRows);

    this.setState({
      checked: newPricedRows,
      diamond: {
        totalCarat: parseFloat(summation.total_carat).toFixed(2),
        avgDisc: parseFloat(summation.final_discount).toFixed(2),
        finalAvgDisc: parseFloat(summation.final_term_discount).toFixed(2),
        finalNetRate: formatNumber(parseFloat(summation.final_rate).toFixed(2)),
        netValue: formatNumber(parseFloat(summation.final_value).toFixed(2)),
        finalNetValue: formatNumber(parseFloat(summation.final_net_value).toFixed(2)),
      },
    });
  };

  fetchClientOtherData = (client) => {
    let clientOtherData = fetchPartyRelatedInfo(client, this.state.user);
    Object.keys(clientOtherData).map((key) => {
      if (clientOtherData[key]) this.errorHandel({ fields: [key] });
    });
    this.setState({ ...clientOtherData });
  };

  getStageData = async () => {
    const [, res] = await getDiamondBlockMaster({ filter: { wSts: 'M' } });
    if (res?.code === 'OK' && res?.data.length) {
      let diamondwSts = groupBy(this.props.checked, 'wSts');
      let stageList = [];
      res.data.forEach((d) => {
        Object.keys(diamondwSts).forEach((key) => {
          if (includes(d.allowSts, key)) {
            stageList.push({ value: d.webDisplay, key: d.id, ...d });
          }
        });
      });

      this.setState({ stageList: uniq(stageList), initialStageList: uniq(stageList), stage: uniq(stageList)?.[0]?.id });
    }
  };

  stageListFilter = (registrationType = this.state.registrationType) =>
    this.state.initialStageList.filter((d) => d.allowedUserType && d.allowedUserType?.includes(registrationType));

  saveMemo = async () => {
    this.errorHandel({ isSaveMemo: true });
    let data = pick(this.state, [
      'userAccount',
      'user',
      'seller',
      'assistantSeller',
      'shippingCountry',
      'shippingCity',
      'stage',
      'broker',
    ]);

    if (isEmpty(this.state.error)) {
      let diamonds = this.props.checked.map((d) => ({
        diamond: d.id,
        blockPricePerCarat: d.calcPricePerCarat ? d.calcPricePerCarat : 0,
        blockAmount: d.calcAmount ? d.calcAmount : 0,
        vnd: d.vnd,
        vStnId: d.vStnId,
      }));
      if (isEmpty(data.broker)) {
        delete data['broker'];
      }
      this.setState({ loader: true });
      const [err, res] = await InsertDiamondBlock({
        ...data,
        shipMode: this.state.shipModeList[this.state.shipMode]?.value,
        blockType: DIAMOND_BLOCK.TYPE.MEMO,
        diamonds,
        blockSetting: data?.stage,
      });

      !err && showNotification(res);
      if (isFunction(this.props.handleCancel)) this.props.handleCancel();

      if (res?.code === 'OK' && res?.data) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (isFunction(this.props.onSuccess)) this.props.onSuccess();
        if (isFunction(this.props.changesStatus)) this.props.changesStatus();
      }

      if (isFunction(this.props.onClose)) this.props.onClose();
      this.setState({ loader: false });
    }
  };

  errorHandel = (param) => {
    let { fields, isSaveMemo } = param;
    let error = this.state.error;
    if (isSaveMemo) {
      let data = pick(this.state, [
        'userAccount',
        'user',
        'seller',
        'assistantSeller',
        'shippingCountry',
        'shippingCity',
        'stage',
        // 'broker',
      ]);

      Object.keys(data).forEach((d) => {
        if (isEmpty(data?.[d])) error[d] = Messages[`${d}Select`];
      });
    } else {
      fields.forEach((filed) => this.state[filed] && delete error[filed]);
    }
    this.setState({ error });
  };

  render() {
    return (
      <>
        <CommonModal
          handleOk={this.saveMemo}
          handleCancel={this.props.onClose ?? this.props.handleCancel}
          title="Memo Stone"
          submitTitle="Submit"
          cancelTitle="Cancel"
          visible={this.props.visible ?? true}
          footerShow
        >
          <div className="searchPopupCommon">
            <div className="holdPopupBlock smallGrayScroll">
              <PartyBuyerComponent
                error={this.state.error}
                setUserDetails={(data) => {
                  let stageList = this.state.initialStageList;
                  // if (data.registrationType) {
                  //   stageList = this.stageListFilter(data.registrationType);
                  // }
                  this.setState({ ...data, stageList }, () => {
                    this.errorHandel({ fields: Object.keys(data) });
                    this.userTermsDiamondCal();
                  });
                }}
                initialData={pick(this.state, ['client', 'userAccount', 'user'])}
              />
              <BrokerComponent
                label="Broker"
                //  error={this.state.error}
                setBrokerDetails={(data) => {
                  this.setState({ ...data }, () => {
                    this.errorHandel({ fields: ['broker'] });
                  });
                }}
                initialData={pick(this.state, ['broker', 'brokerList'])}
              />
              <SellerComponent
                error={this.state.error}
                setSellerDetails={(data) => {
                  this.setState({ ...data }, () => {
                    this.errorHandel({ fields: ['seller'] });
                  });
                }}
                initialData={pick(this.state, ['seller'])}
              />
              <SelectOption
                placeholder="Select Assistant Salesman"
                selectValue={this.state.sellerList}
                value={isValue(this.state.assistantSeller)}
                label="Assi.Salesman *"
                onChange={(assistantSeller = null) => {
                  this.setState({ assistantSeller }, () => {
                    this.errorHandel({ fields: ['assistantSeller'] });
                  });
                }}
                onSearch={() => {
                  this.setState({ assistantSeller: null });
                }}
                error={this.state.error?.assistantSeller}
              />
              <SelectOption
                placeholder="Select Stage"
                selectValue={this.state.stageList}
                value={isValue(this.state.stage)}
                label="Stage *"
                onChange={(stage = null) => {
                  this.setState({ stage }, () => {
                    this.errorHandel({ fields: ['stage'] });
                  });
                }}
                onSearch={() => {
                  this.setState({ stage: null });
                }}
                error={this.state.error.stage}
              />

              <LocationComponent
                //error handel issue than pass different error handel fun
                error={this.state.error}
                locationDetails={(data) => {
                  this.setState({ ...this.state, ...data }, () => {
                    this.errorHandel({ fields: Object.keys(data) });
                  });
                }}
                initialData={pick(this.state, ['shippingCountry', 'shippingCity'])}
              />
              <SelectOption
                nameKey="value"
                placeholder="Select Ship Mode"
                value={isValue(this.state.shipMode)}
                selectValue={this.state.shipModeList}
                label="Ship Mode"
                onChange={(shipMode = null) => {
                  this.setState({ shipMode });
                }}
                onSearch={() => {
                  this.setState({ shipMode: null });
                }}
              />
            </div>
            <div className="DiamondDetailPopup oneLineTotal memo-popup-bottom">
              <div className="DiamondDetailPopupItem">
                <span>Total Carats:</span>
                <span>{this.state.diamond?.totalCarat}</span>
              </div>
              <div className="DiamondDetailPopupItem">
                <span>Final Avg Dis.:</span>
                <span>{this.state.diamond?.finalAvgDisc}</span>
              </div>
              <div className="DiamondDetailPopupItem">
                <span>Avg. Dis.:</span>
                <span>{this.state.diamond?.avgDisc}</span>
              </div>
              <div className="DiamondDetailPopupItem">
                <span>Final Net Rate:</span>
                <span>{this.state.diamond?.finalNetRate}</span>
              </div>
              <div className="DiamondDetailPopupItem">
                <span>Net Value:</span>
                <span>{this.state.diamond?.netValue}</span>
              </div>
              <div className="DiamondDetailPopupItem">
                <span>Final Net Value:</span>
                <span>{this.state.diamond?.finalNetValue}</span>
              </div>
            </div>
          </div>
          {this.state.loader && <CircularProgress className="fixLoader" />}
        </CommonModal>
      </>
    );
  }
}
export default MemoPopup;
