import SelectOption from 'containers/SelectOption';
import React from 'react';
import { isFunction } from 'util/utils';
import { isValue, getCityData, getCountryData } from '../CommonPopupFunction';
function LocationComponent(props) {
  const [state, _setState] = React.useState({
    shippingCountry: null,
    shippingCity: null,
    cityList: [],
    countryList: [],
    loading: false,
  });
  const setState = (object = {}, cb = null) => {
    _setState((state) => ({ ...state, ...object }));
  };

  React.useEffect(() => {
    if (props.initialData?.shippingCountry) {
      getCityData((cityList) => setState({ ...props.initialData, cityList }), props.initialData.shippingCountry);
    } else {
      setState({ shippingCountry: null, cityList: [], shippingCity: null });
    }
  }, [props.initialData.shippingCountry]);

  React.useEffect(() => {
    getCountryData((countryList) => {
      props.locationDetails({ countryList });
      setState({ countryList });
    });
    // getCityData((cityList) => {
    //   setState({ cityList });
    // });
  }, []);

  return (
    <>
      <SelectOption
        placeholder="Select Ship Country"
        value={isValue(state.shippingCountry)}
        selectValue={state.countryList}
        label="Ship Country *"
        onChange={(shippingCountry = null) => {
          if (shippingCountry) {
            getCityData((cityList) => {
              setState({
                cityList,
                shippingCountry,
                shippingCity: null,
              });
              props.locationDetails({ shippingCountry, cityList });
            }, shippingCountry);
          } else {
            props.locationDetails({ shippingCountry, shippingCity: null, cityList: [], countryName: null });
          }
        }}
        onSearch={() => {
          setState({ shippingCountry: null, shippingCity: null });
        }}
        error={props.error?.shippingCountry}
      />
      <SelectOption
        placeholder="Select Ship City"
        value={isValue(state.shippingCity)}
        selectValue={state.cityList}
        label="Ship City *"
        onChange={(shippingCity = null) => {
          setState({ shippingCity });
          props.locationDetails({ shippingCity });
        }}
        onSearch={() => {
          setState({ shippingCity: null });
        }}
        error={props.error?.shippingCity}
      />
    </>
  );
}

export default LocationComponent;
