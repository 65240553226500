import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Select } from 'antd';

import * as UserAPI from 'services/UserApi';
import * as Hooks from 'util/hooks';
import { isArray, getUserName, isEmpty } from 'util/utils';

function MultiUserDropdown(props) {
  const { label, value, onChange, className, error, options, ...rest } = props;
  const [list, setList] = React.useState([]);
  const [loading, startLoading, stopLoading] = Hooks.useLoading();
  const keywordRef = React.useRef('');

  const getList = React.useMemo(
    () =>
      debounce(async () => {
        const keyword = keywordRef.current;
        if (keyword?.length < 3) return setList([]);

        startLoading();
        const [, res] = await UserAPI.getUserList(
          { page: 1, search: { keyword, keys: ['firstName', 'companyName'] } },
          { loader: false },
        );
        if (res?.code === 'OK') setList(isArray(res?.data?.list) ? res?.data?.list : []);
        stopLoading();
      }, 480),
    [startLoading, stopLoading],
  );

  const handleSearch = React.useCallback(
    (keyword) => {
      keywordRef.current = keyword;
      getList();
    },
    [getList],
  );

  React.useEffect(() => {
    setList(props?.options ?? []);
  }, [props?.options]);

  return (
    <div className={`formSelect from-group ${className}` + (error ? ' error-message' : '')}>
      {/* <div className={classNames([formSelect-from-group, className, error && ' error-message'])}> */}
      <label>{label ?? 'Select Name'}</label>
      <Select
        value={value}
        placeholder={label ?? 'Select Name'}
        notFoundContent={loading ? 'Searching...' : 'Type name to Search'}
        onChange={onChange}
        onSearch={handleSearch}
        filterOption={false}
        allowClear
        showArrow
        showSearch
        {...rest}
      >
        {list.map((record) => (
          <Select.Option value={record?.id} key={record?.id}>
            {getUserName(record)}
          </Select.Option>
        ))}
      </Select>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

MultiUserDropdown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default React.memo(MultiUserDropdown);
