import React from 'react';
import InputBlock from 'containers/InputBlock';
import { useCompositeState } from 'util/hooks';
import classNames from 'util/classNames';
import { changePassword } from 'services/CommonApi';
import { showNotification } from 'services/NotificationService';

const ChangePassword = () => {
  const [state, setState] = useCompositeState({ currentPassword: '', newPassword: '', confirmPassword: '' });
  const [error, setError] = useCompositeState({});
  let submitDisabled =
    error.currentPassword ||
    error.confirmPassword ||
    error.newPassword ||
    !(
      state.currentPassword &&
      state.confirmPassword &&
      state.newPassword &&
      state.confirmPassword === state.newPassword
    );

  const handleSubmit = React.useCallback(async () => {
    if (!submitDisabled) {
      const [err, res] = await changePassword({
        currentPassword: state.currentPassword,
        newPassword: state.newPassword,
      });

      !err && showNotification(res);
      if (res?.code === 'OK') setState({ currentPassword: '', newPassword: '', confirmPassword: '' });
    }
  }, [setState, state.currentPassword, state.newPassword, submitDisabled]);

  const handleChange = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleBlur = (e) => {
    let field = e.target.name;
    let err = '';
    if (!state[field] || state[field].trim() === '') {
      if (field === 'currentPassword') err = 'Current password is required';
      if (field === 'newPassword') err = 'New password is required';
      if (field === 'confirmPassword') err = 'Confirm password is required';
    } else if (field === 'confirmPassword' && state.confirmPassword !== state.newPassword)
      err = 'Confirm password must be same as new password';
    setError({ [field]: err });
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} className="editProfilePage">
      <div className="headeTitle">
        <h2>Change Password</h2>
      </div>
      <div className="editProfilePageInner">
        <div className="profileRight halfBlock">
          <InputBlock
            label="Current Password *"
            placeholder="Current Password"
            name="currentPassword"
            type="password"
            tabIndex="1"
            value={state.currentPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error?.currentPassword}
          />
          <InputBlock
            label="New Password *"
            placeholder="New Password"
            name="newPassword"
            type="password"
            tabIndex="2"
            value={state.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error?.newPassword}
          />
          <InputBlock
            label="Confirm Password *"
            placeholder="Confirm Password"
            name="confirmPassword"
            type="password"
            tabIndex="3"
            value={state.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error?.confirmPassword}
          />

          <button
            className={classNames(['actionButonBottom commonButton', submitDisabled && 'disableBlock'])}
            onClick={handleSubmit}
            disabled={submitDisabled}
          >
            Change Password
          </button>
        </div>
      </div>
    </form>
  );
};

export default ChangePassword;
