import React, { Component } from 'react';
import CustomTab from 'containers/Tab';
import { TabData } from 'constants/tab';
import { TabPanel } from 'react-tabs';
import QuoteSetting from './QuoteSetting';

class QuoteSettings extends Component {
  state = {
    tabIndex: 0,
    subTabIndex: 0,
  };

  render() {
    return (
      <CustomTab
        selectedIndex={this.state.tabIndex}
        onGetIndex={(ind) => {
          this.setState({ tabIndex: ind }, () => {});
        }}
        wantIndex
        data={TabData.quoteSetting.filter((d) => !d.hideTab)}
        tabHeaderClass="customerMaintab"
        className="moreTabScroll"
      >
        <div className="configurationWrapper pt-0">
          <TabPanel>
            <QuoteSetting />
          </TabPanel>
        </div>
      </CustomTab>
    );
  }
}

export default QuoteSettings;
