import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { showNotification } from 'services/NotificationService';
import { isEmpty, isObject } from 'util/utils';
import { getSetting, promotionalDisUpdate, promotionalDisSetting } from 'services/CommonApi';
import { SETTING_TYPE, HOME_SETTING_FORMAT } from 'constants/Common';
import { Checkbox } from 'antd';
import MultiUserDropdown from 'components/User/MultiUserDropdown';
import TextArea from 'containers/TextArea';
import InputBlock from 'containers/InputBlock';
import moment from 'moment';

const defaultValues = {
  maxDisPer: '',
};

const validationSchema = Yup.object().shape({
  maxDisPer: Yup.number().required('Maximum Discount is required!'),
});

function QuoteSetting() {
  const [setting, setSetting] = React.useState({});
  const [dataList, setDataList] = React.useState([]);

  const initialValues = React.useMemo(() => {
    const data = Object.fromEntries(
      Object.entries(defaultValues).map(([key, value]) => [
        key,
        isEmpty(setting?.[key])
          ? value
          : key == 'untildate' && !isEmpty(setting?.[key])
          ? moment(setting?.[key])
          : setting?.[key],
      ]),
    );

    return data;
  }, [setting]);

  const getList = React.useCallback(async () => {
    const data = { filter: { type: 24 } };
    const [, res] = await promotionalDisSetting(data);
    if (res.code === 'OK' && isObject(res?.data)) {
      setDataList(res);
      setSetting(res?.data);
    }
  }, []);

  React.useEffect(() => {
    getList();
  }, [getList]);

  const submitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const { maxAmt, data, ...rest } = setting;
    const value = {
      ...rest,
      maxDisPer: values.maxDisPer,
    };
    const [err, res] = await promotionalDisUpdate(value);

    setSubmitting(false);
    !err && showNotification(res);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({ handleChange, touched, errors, values, setValues, setFieldValue, setFieldTouched }) => (
        <Form>
          <div className="d-flex homeSettingWrapper mt-20 loginSetting">
            <div className="userDropdown">
              <InputBlock
                label="Max Discount(%)*"
                placeholder="Maximum Discount"
                type="number"
                name="maxDisPer"
                onChange={handleChange}
                value={values.maxDisPer}
                error={touched?.maxDisPer && errors?.maxDisPer}
                // count comma separated string of stone IDs //maxDis
                //value={values.stoneId?.split(',')?.filter((item) => !isEmpty(`${item}`.trim()))?.length}
              />
            </div>

            <div className="width-100 d-flex mt-20">
              <button className="commonButton mr-10" type="submit" value="Submit">
                Save
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default QuoteSetting;
