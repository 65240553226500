import React from 'react';
import "./collapse.less"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export default function CommonCollapse(props) {
    return (
        <AccordionItem className="customcollapselist">
            <AccordionItemHeading>
                <AccordionItemButton>
                    {props.title}
                    {props.NotesAction &&
                        <>
                            <div className="noteAction">
                                <img src={props.image} alt="" />
                                <span>{props.dateTime}</span>
                            </div>
                        </>
                    }
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                {props.children}
            </AccordionItemPanel>
        </AccordionItem>
    );
}