import React from "react"

const OfferWise = (props) => {
    return(
        <td colspan={props.colspan} className="tableInTableBoxWrapper">
                          <tbody className="">
                          <tr>
                            <th>Sr No.</th>
                            <th>Name</th>
                            <th>Rap Price</th>
                            <th>Rap %</th>
                            <th>Price/Carat</th>
                            <th>Amount</th>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>Rahul Shingala</td>
                            <td>6800.00</td>
                            <td>68.00</td>
                            <td>2600.00</td>
                            <td>2600.00</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Vishal Virani</td>
                            <td>6800.00</td>
                            <td>68.00</td>
                            <td>2400.00</td>
                            <td>2400.00</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Kalpesh Zalavadiya</td>
                            <td>6800.00</td>
                            <td>68.00</td>
                            <td>2200.00</td>
                            <td>2200.00</td>
                          </tr>
                        </tbody>
                        </td>
    )
} 
export default OfferWise;