import React, { Component } from 'react';
import PopupList from './list';
import PopupAdd from './add';
import * as EventAPI from 'services/EventApi';

class Slider extends Component {
  state = {
    list: true,
  };

  toggle = () => {
    this.setState({
      list: !this.state.list,
      detail: {},
    });
  };

  editEventDetail = async (editId) => {
    const [, res] = await EventAPI.findEvent(editId);

    if (res?.code === 'OK') {
      this.setState({ detail: res.data, list: !this.state.list });
    }
  };

  render() {
    return (
      <div>
        {this.state.list ? (
          <PopupList {...this.state} onClick={this.toggle} editEventDetail={this.editEventDetail} />
        ) : (
          <PopupAdd closeForm={this.toggle} update={this.state.detail} />
        )}
      </div>
    );
  }
}

export default Slider;
