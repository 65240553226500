import React from 'react';
import { Tooltip } from 'antd';

import DiamondTotalCard from 'components/DiamondListing/DiamondTotalCard';
// import MoreOptionItem from './MoreOptionItem';
import classNames from 'util/classNames';
import { useBoolean } from 'util/hooks';
import { isEmpty } from 'util/utils';
import { isToggleAllow } from 'constants/Common';

import checkboxSvg from 'assets/svg/checkbox.svg';
import excelWhiteSvg from 'assets/svg/InventoryResult/excel-white.svg';
import leftArrowWhiteSvg from 'assets/svg/left-arrow-white.svg';
import mailWhiteSvg from 'assets/svg/InventoryResult/mail-white.svg';
import printSvg from 'assets/svg/InventoryResult/print.svg';
import rightArrowWhiteSvg from 'assets/svg/right-arrow-white.svg';

import sum from 'lodash/sum';

function TableGrouping({ children, displayTotal = [], ...props }) {
  const [titleExpanded, setTitleExpanded] = useBoolean(false);

  return (
    <>
      {!props.tableHide && (
        <div
          className="tableGropupingBox"
          style={window.location.pathname.includes('offer') ? { marginTop: '3px' } : {}}
        >
          <div className="tableGroupTitle">
            {(props.Collapse || props.multipleCheckBox || props.groupingTitle || props.MoreOption) && (
              <span
                className={classNames([
                  `tableGroupTitleInner`,
                  titleExpanded && 'collpaseBlock',
                  'moreOptionShow',
                  props.MoreOption && 'moreOptionShow',
                ])}
              >
                {props.Collapse && (
                  <div className="tableCollapse" onClick={props.onClick}>
                    <img src={props.img} />
                  </div>
                )}
                {props.multipleCheckBox && (
                  <Tooltip title="Select All">
                    <div className="checkBoxSelect">
                      <img src={checkboxSvg} />
                    </div>
                  </Tooltip>
                )}
                {props.isOfferPopup && (
                  <div className="tableCollapseBox" onClick={props.toggleCollapse}>
                    <img
                      src={
                        !props.collapsed
                          ? require('assets/svg/InventoryResult/substraction.svg')
                          : require('assets/svg/plus-color.svg')
                      }
                      alt=""
                    />
                  </div>
                )}
                {children && <div className="checkBoxSelect">{children}</div>}
                {props.groupingTitle && <span>{props.groupingTitle}</span>}
                {props.Action && (
                  <div className="d-flex tableListIconMain">
                    {props.pending && (
                      <>
                        <span className="tableListIcon" onClick={props.approveClick}>
                          Approve
                        </span>
                        {props.reject ? null : <span className="tableListIcon">Reject</span>}
                      </>
                    )}
                    <span className="tableListIcon">
                      <img src={mailWhiteSvg} alt="" />
                    </span>
                    <span className="tableListIcon">
                      <img src={excelWhiteSvg} alt="" />
                    </span>
                    <span className="tableListIcon">
                      <img src={printSvg} alt="" />
                    </span>
                  </div>
                )}
                {isToggleAllow && (
                  <div className="groupClose" onClick={setTitleExpanded.toggle}>
                    {titleExpanded ? <img src={leftArrowWhiteSvg} alt="" /> : <img src={rightArrowWhiteSvg} alt="" />}
                  </div>
                )}
              </span>
            )}
            <div className="tableHeaderMain">{props.totalDiamonds ? props.totalDiamonds + ' Diamond' : ''}</div>
          </div>
        </div>
      )}
      <tr className="boxShadowNone">
        <td
          colSpan={props?.colspan ? props?.colspan : props.columns ? 2 : 3}
          className="groupingTableAmount"
          style={{ color: 'transparent' }}
        >
          0
        </td>
        {props.isOfferPopup
          ? props.calc &&
            props.calc?.map((x, key) => (
              <td key={key} className="groupingTableAmount">
                {x}
              </td>
            ))
          : props.columns &&
            props.columns.map((column, index) => (
              <td className="groupingTableAmount" key={isEmpty(column.id) ? index : column.id}>
                {displayTotal.includes(column.id) && <DiamondTotalCard row={props.row} col={column.id} />}
              </td>
            ))}
      </tr>
    </>
  );
}

export default TableGrouping;
