import { PREFIX_URL_WITH_SLASH as PREFIX_PATH } from 'constants/CommonUrl';
import { i18n } from 'util/i18n';

export const TabData = {
  InvetoryArray: [
    {
      index: 0,
      url: `${PREFIX_PATH}/inventory/single-stone/overview`,
      tabTitle: 'Single Stone',
      subList: [
        {
          index: 0,
          tabTitle: 'Overview',
          url: `${PREFIX_PATH}/inventory/single-stone/overview`,
          moreIndex: 5,
          moreList: [
            `${PREFIX_PATH}/inventory/single-stone/match-pair`,
            `${PREFIX_PATH}/inventory/single-stone/calibrated`,
            `${PREFIX_PATH}/inventory/single-stone/my-demand`,
            `${PREFIX_PATH}/inventory/single-stone/saved-search`,
            `${PREFIX_PATH}/inventory/single-stone/price-configuration`,
            `${PREFIX_PATH}/inventory/single-stone/show-stone-report`,
            `${PREFIX_PATH}/inventory/single-stone/quote-day`,
          ],
        },
        { tabTitle: 'Search', index: 1, url: `${PREFIX_PATH}/inventory/single-stone/search` },
        { tabTitle: 'Quick Search', index: 2, url: `${PREFIX_PATH}/inventory/single-stone/quick-search` },
        { tabTitle: 'New Arrival', index: 3, url: `${PREFIX_PATH}/inventory/single-stone/new-arrival` },
        { tabTitle: 'Upcoming', index: 4, url: `${PREFIX_PATH}/inventory/single-stone/upcoming` },
        { tabTitle: 'More', subTabMenu: true, singleStone: true, index: 5 },
      ],
    },
    { index: 1, subList: [], tabTitle: 'Analytics' },
    {
      index: 2,
      tabTitle: 'Configuration',
      url: `${PREFIX_PATH}/inventory/configuration/online-inventory`,
      subList: [
        {
          moreIndex: 5,
          tabTitle: 'Sync with Online Inventory',
          moreList: [
            `${PREFIX_PATH}/inventory/configuration/carat-range`,
            `${PREFIX_PATH}/inventory/configuration/cut-group`,
            `${PREFIX_PATH}/inventory/configuration/rapnet-policy`,
            `${PREFIX_PATH}/inventory/configuration/check-image-status`,
            `${PREFIX_PATH}/inventory/configuration/upload-typeIIA-cert`,
            `${PREFIX_PATH}/inventory/configuration/config-match`,
            `${PREFIX_PATH}/inventory/configuration/config-similar`,
            `${PREFIX_PATH}/inventory/configuration/diamond-column-setting`,
            `${PREFIX_PATH}/inventory/configuration/download-Excel`,
            `${PREFIX_PATH}/inventory/configuration/client-action`,
            `${PREFIX_PATH}/inventory/configuration/manage-parameters`,
            `${PREFIX_PATH}/inventory/configuration/quote-day`,
          ],
          index: 0,
          url: `${PREFIX_PATH}/inventory/configuration/online-inventory`,
        },
        { tabTitle: 'Create Collections', index: 1, url: `${PREFIX_PATH}/inventory/configuration/create-collection` },
        { tabTitle: 'Terms Details', index: 2, url: `${PREFIX_PATH}/inventory/configuration/terms-details` },
        { tabTitle: 'Volume Discount', index: 3, url: `${PREFIX_PATH}/inventory/configuration/volume-discount` },
        { tabTitle: 'Feature Stone', index: 4, url: `${PREFIX_PATH}/inventory/configuration/feature-stone` },
        { tabTitle: 'More', subTabMenu: true, configuration: true, index: 5 },
      ],
    },
  ],
  clientTabs: {
    config: {
      moreIndex: 6,
      moreList: [
        `${PREFIX_PATH}/client/interactions/mail`,
        `${PREFIX_PATH}/client/interactions/call`,
        `${PREFIX_PATH}/client/interactions/chat`,
        `${PREFIX_PATH}/client/analytics/purchase-behavior`,
        `${PREFIX_PATH}/client/analytics/search-behavior`,
        `${PREFIX_PATH}/client/engagment`,
        `${PREFIX_PATH}/client/configuration`,
        `${PREFIX_PATH}/client/configuration/change-password`,
        `${PREFIX_PATH}/client/configuration/permission`,
        `${PREFIX_PATH}/client/suggested-stock`,
      ],
    },
    list: [
      {
        id: 1,
        tabTitle: 'KYC',
        index: 0,
        url: `${PREFIX_PATH}/client/kyc/basic`,
        subList: [
          { id: 1, index: 0, tabTitle: 'General', url: `${PREFIX_PATH}/client/kyc/basic` },
          { id: 2, index: 1, tabTitle: 'Business', url: `${PREFIX_PATH}/client/kyc/business-detail` },
          { id: 3, index: 2, tabTitle: 'Banks', url: `${PREFIX_PATH}/client/kyc/bank-info` },
          // {
          //   id: 4,
          //   index: 3,
          //   tabTitle: 'Departments',
          //   url: `${PREFIX_PATH}/client/kyc/department-`,
          //   hideTab: true,
          // },
          // { id: 5, index: 4, tabTitle: 'Promoters', url: `${PREFIX_PATH}/client/kyc/promoters-detail`, hideTab: true },
          { id: 6, index: 3, tabTitle: 'Branch', url: `${PREFIX_PATH}/client/kyc/branch` },
          { id: 7, index: 4, tabTitle: 'Users', url: `${PREFIX_PATH}/client/kyc/user` },
          { id: 8, index: 5, tabTitle: 'References', url: `${PREFIX_PATH}/client/kyc/references-detail` },
          { id: 9, index: 6, tabTitle: 'Settings', url: `${PREFIX_PATH}/client/kyc/setting` },
          { id: 10, index: 7, tabTitle: 'Terms Detail', url: `${PREFIX_PATH}/client/kyc/terms-detail` },
        ],
      },
      {
        id: 2,
        tabTitle: 'Enquiry',
        index: 1,
        url: `${PREFIX_PATH}/client/enquiry/hold`,
        subList: [
          // { id: 1, index: 0, tabTitle: i18n.t('title.offer'), url: `${PREFIX_PATH}/client/enquiry/offer` },
          { id: 2, index: 1, tabTitle: 'Hold', url: `${PREFIX_PATH}/client/enquiry/hold` },
          { id: 3, index: 2, tabTitle: 'Memo', url: `${PREFIX_PATH}/client/enquiry/memo` },
          { id: 4, index: 3, tabTitle: 'Demand', url: `${PREFIX_PATH}/client/enquiry/demand` },
        ],
      },
      {
        id: 3,
        index: 2,
        url: `${PREFIX_PATH}/client/transactions/cart`,
        tabTitle: 'Transactions',
        subList: [
          { id: 1, tabTitle: 'Cart', index: 0, url: `${PREFIX_PATH}/client/transactions/cart` },
          // { id: 2, tabTitle: 'Watch List', index: 1, url: `${PREFIX_PATH}/client/transactions/watchlist` },
          { id: 3, tabTitle: 'Notes', index: 2, url: `${PREFIX_PATH}/client/transactions/notes` },
          // { id: 4, tabTitle: 'Reminder', index: 3, url: `${PREFIX_PATH}/client/transactions/reminder` },
          { id: 5, tabTitle: 'Office View', index: 4, url: `${PREFIX_PATH}/client/transactions/officeview` },
          { id: 6, tabTitle: 'Seen Stones', index: 5, url: `${PREFIX_PATH}/client/transactions/seen-stones` },
        ],
      },
      {
        id: 4,
        index: 3,
        tabTitle: 'Confirm Stone',
        url: `${PREFIX_PATH}/client/order/pending`,
        subList: [
          { id: 1, index: 0, tabTitle: 'Pending', url: `${PREFIX_PATH}/client/order/pending` },
          { id: 2, index: 1, tabTitle: 'Approved', url: `${PREFIX_PATH}/client/order/confirmed` },
          { id: 3, index: 2, tabTitle: 'Rejected', url: `${PREFIX_PATH}/client/order/rejected` },
        ],
      },
      {
        id: 5,
        index: 4,
        tabTitle: `${i18n.t('title.offer')}`,
        url: `${PREFIX_PATH}/client/offer/pending`,
        subList: [
          { id: 1, index: 0, tabTitle: 'Pending', url: `${PREFIX_PATH}/client/offer/pending` },
          { id: 2, index: 1, tabTitle: 'Approved', url: `${PREFIX_PATH}/client/offer/approved` },
          { id: 3, index: 2, tabTitle: 'Rejected', url: `${PREFIX_PATH}/client/offer/rejected` },
        ],
      },
      {
        id: 6,
        index: 5,
        tabTitle: 'Search',
        url: `${PREFIX_PATH}/client/search/saved-search`,
        subList: [
          { id: 1, index: 0, tabTitle: 'Saved Search', url: `${PREFIX_PATH}/client/search/saved-search` },
          { id: 2, index: 1, tabTitle: 'Recent Search', url: `${PREFIX_PATH}/client/search/recent-search` },
        ],
      },
      {
        id: 7,
        subMore: true,
        tabTitle: 'More',
        index: 6,
        ClientTab: true,
        subTabMenu: true,
        subArray: {
          interactions: [
            { id: 1, tabTitle: 'Mail', index: 0, url: `${PREFIX_PATH}/client/interactions/mail` },
            { id: 2, tabTitle: 'Call', index: 1, url: `${PREFIX_PATH}/client/interactions/call` },
            { id: 3, tabTitle: 'Chat', index: 2, url: `${PREFIX_PATH}/client/interactions/chat` },
          ],
          analyticsTab: [
            {
              id: 1,
              index: 0,
              tabTitle: 'Purchase Behavior',
              url: `${PREFIX_PATH}/client/analytics/purchase-behavior`,
            },
            { id: 2, index: 1, tabTitle: 'Search Behavior', url: `${PREFIX_PATH}/client/analytics/search-behavior` },
          ],
          configuration: [
            { id: 1, index: 0, tabTitle: 'Permission', url: `${PREFIX_PATH}/client/configuration/permission` },
            {
              id: 2,
              index: 1,
              tabTitle: 'Change Password',
              url: `${PREFIX_PATH}/client/configuration/change-password`,
            },
          ],
        },
      },
    ],
  },
  notification: [
    { id: 1, index: 0, tabTitle: 'New', url: `${PREFIX_PATH}/notification/new` },
    { id: 2, index: 1, tabTitle: 'Cleared', url: `${PREFIX_PATH}/notification/clear` },
  ],
  themeSetup: [
    { id: 1, tabTitle: 'Color Setup', index: 0, url: `${PREFIX_PATH}/theme-setup/color-config` },
    { id: 2, tabTitle: 'Project Details', index: 1, url: `${PREFIX_PATH}/theme-setup/project-detail` },
  ],
  Transaction: [
    {
      id: 1,
      tabTitle: `${i18n.t('title.offer')}`,
      index: 0,
      url: `${PREFIX_PATH}/transaction/offer/pending`,
      subList: [
        { id: 1, tabTitle: 'Pending', index: 0, url: `${PREFIX_PATH}/transaction/offer/pending` },
        { id: 2, index: 1, tabTitle: 'Approved', url: `${PREFIX_PATH}/transaction/offer/approved` },
        { id: 3, index: 2, tabTitle: 'Rejected', url: `${PREFIX_PATH}/transaction/offer/rejected` },
      ],
    },
    {
      id: 2,
      index: 1,
      url: `${PREFIX_PATH}/transaction/order/confirmed`,
      tabTitle: 'Confirm Stones',
      subList: [
        { id: 1, index: 0, tabTitle: 'Approved', url: `${PREFIX_PATH}/transaction/order/confirmed` },
        { id: 2, index: 1, tabTitle: 'Pending', url: `${PREFIX_PATH}/transaction/order/pending` },
        { id: 3, index: 2, tabTitle: 'Rejected', url: `${PREFIX_PATH}/transaction/order/rejected` },
      ],
    },
    {
      id: 3,
      tabTitle: 'Enquiry',
      index: 2,
      url: `${PREFIX_PATH}/transaction/enquiry/enquiry/cart`,
      subList: [
        { id: 1, index: 0, tabTitle: 'Cart', url: `${PREFIX_PATH}/transaction/enquiry/enquiry/cart` },
        // { id: 2, index: 1, tabTitle: 'Watch List', url: `${PREFIX_PATH}/transaction/enquiry/enquiry/watchlist` },
        { id: 3, index: 2, tabTitle: 'Seen Stones', url: `${PREFIX_PATH}/transaction/enquiry/enquiry/seen-stones` },
        { id: 4, index: 3, tabTitle: 'Notes', url: `${PREFIX_PATH}/transaction/enquiry/enquiry/notes` },
        // { id: 4, index: 3, tabTitle: 'Reminder', url: `${PREFIX_PATH}/transaction/enquiry/enquiry/reminder` },
      ],
    },
    {
      id: 4,
      tabTitle: 'Office View',
      index: 3,
      url: `${PREFIX_PATH}/transaction/office-view/upcoming`,
      subList: [
        { id: 1, tabTitle: 'Upcoming', index: 0, url: `${PREFIX_PATH}/transaction/office-view/upcoming` },
        { id: 2, tabTitle: 'Expired', index: 1, url: `${PREFIX_PATH}/transaction/office-view/expire` },
      ],
    },
    {
      id: 5,
      tabTitle: 'Bid',
      index: 4,
      url: `${PREFIX_PATH}/transaction/bid/running`,
      subList: [
        { id: 1, index: 0, tabTitle: 'Running', url: `${PREFIX_PATH}/transaction/bid/running` },
        { id: 2, index: 1, tabTitle: 'History', url: `${PREFIX_PATH}/transaction/bid/history` },
      ],
    },
    {
      id: 6,
      tabTitle: 'Hold',
      index: 5,
      url: `${PREFIX_PATH}/transaction/hold`,
      subList: [
        { id: 1, tabTitle: 'Hold', index: 0, url: `${PREFIX_PATH}/transaction/hold` },
        { id: 2, tabTitle: 'BN & R2NET', index: 1, url: `${PREFIX_PATH}/transaction/hold/BN&R2NET` },
      ],
    },
    {
      id: 7,
      tabTitle: 'Memo',
      index: 6,
      url: `${PREFIX_PATH}/transaction/memo`,
      subList: [
        { id: 1, tabTitle: 'Consignment', index: 0, url: `${PREFIX_PATH}/transaction/memo` },
        { id: 2, tabTitle: 'Office', index: 1, url: `${PREFIX_PATH}/transaction/memo/ShowMemo` },
        // { id: 3, tabTitle: 'External Memo', index: 2, url: `${PREFIX_PATH}/transaction/memo/ExternalMemo` },
      ],
    },

    {
      id: 8,
      tabTitle: 'S & E Report',
      index: 7,
      url: `${PREFIX_PATH}/transaction/report/s`,
      subList: [
        { id: 1, tabTitle: 'S', index: 0, url: `${PREFIX_PATH}/transaction/report/s` },
        { id: 2, tabTitle: 'C', index: 1, url: `${PREFIX_PATH}/transaction/report/c` },
        { id: 2, tabTitle: 'E', index: 2, url: `${PREFIX_PATH}/transaction/report/e` },
      ],
    },

    {
      id: 9,
      tabTitle: 'Proforma Report ',
      index: 8,
      url: `${PREFIX_PATH}/transaction/proforma-report`,
      subList: [
        { id: 1, tabTitle: 'OK', index: 0, url: `${PREFIX_PATH}/transaction/proforma-report` },
        { id: 2, tabTitle: 'Pending', index: 1, url: `${PREFIX_PATH}/transaction/proforma-report/pending` },
      ],
    },
    // {
    //   id: 8,
    //   tabTitle: 'Consignment Out',
    //   index: 7,
    //   url: `${PREFIX_PATH}/transaction/consignment-out/all`,
    //   subList: [
    //     { id: 1, tabTitle: 'Hold', index: 0, url: `${PREFIX_PATH}/transaction/consignment-out/all` },
    //     { id: 2, tabTitle: 'BN & R2NET', index: 1, url: `${PREFIX_PATH}/transaction/consignment-out/all/BN&R2NET` },
    //   ],
    // },
    // { id: 9, tabTitle: 'Consignment In', index: 8, subList: [], url: `${PREFIX_PATH}/transaction/consignment-in/all` },
  ],
  pricing: [{ id: 1, tabTitle: 'Revise', index: 0, url: `${PREFIX_PATH}/pricing/revise` }],
  dashboard: [
    { id: 1, index: 0, tabTitle: 'Dashboard', url: `${PREFIX_PATH}/dashboard` },
    { id: 2, index: 1, tabTitle: 'Enquiry', url: `${PREFIX_PATH}/dashboard/enquiry` },
    { id: 3, index: 2, tabTitle: 'Inventory', url: `${PREFIX_PATH}/dashboard/inventory` },
    { id: 4, index: 3, tabTitle: 'Search', url: `${PREFIX_PATH}/dashboard/search` },
    { id: 5, index: 4, tabTitle: 'Analysis', url: `${PREFIX_PATH}/dashboard/Analysis` },
  ],
  filterTab: [
    { tabTitle: 'Filter', staticTab: true },
    { tabTitle: 'Recent', hideTab: true, staticTab: true },
    { tabTitle: 'Saved', hideTab: true, staticTab: true },
  ],
  todo: [
    // {
    //   id: 1,
    //   tabTitle: 'All',
    //   index: 0,
    //   url: `${PREFIX_PATH}/todo/all/pending`,
    //   subList: [
    //     { id: 1, tabTitle: 'Pending', index: 0, url: `${PREFIX_PATH}/todo/all/pending` },
    //     { id: 2, index: 1, tabTitle: 'Closed', url: `${PREFIX_PATH}/todo/all/close` },
    //   ],
    // },
    {
      id: 2,
      tabTitle: 'KYC',
      index: 1,
      url: `${PREFIX_PATH}/todo/kyc/pending`,
      subList: [
        { id: 1, tabTitle: 'Pending', index: 0, url: `${PREFIX_PATH}/todo/kyc/pending` },
        { id: 2, tabTitle: 'Closed', index: 1, url: `${PREFIX_PATH}/todo/kyc/close` },
        // { id: 2, index: 1, tabTitle: 'Approved', url: `${PREFIX_PATH}/todo/kyc/approved` },
        // { id: 3, index: 2, tabTitle: 'Rejected', url: `${PREFIX_PATH}/todo/kyc/rejected` },
      ],
    },
    // {
    //   id: 3,
    //   tabTitle: 'Sales',
    //   index: 2,
    //   url: `${PREFIX_PATH}/todo/sales/pending`,
    //   subList: [
    //     { id: 1, tabTitle: 'Pending', index: 0, url: `${PREFIX_PATH}/todo/sales/pending` },
    //     { id: 2, index: 1, tabTitle: 'Approved', url: `${PREFIX_PATH}/todo/sales/approved` },
    //     { id: 3, index: 2, tabTitle: 'Rejected', url: `${PREFIX_PATH}/todo/sales/rejected` },
    //   ],
    // },
  ],
  emailsync: [
    { id: 1, tabTitle: 'Email', url: `${PREFIX_PATH}/emailsync/email` },
    { id: 2, tabTitle: 'Call Log', url: `${PREFIX_PATH}/emailsync/call-log` },
    { id: 3, tabTitle: 'Meeting', url: `${PREFIX_PATH}/emailsync/meeting` },
    { id: 4, tabTitle: 'Calendar', url: `${PREFIX_PATH}/emailsync/calendar` },
  ],
  userTab: [
    { id: 1, index: 0, tabTitle: 'OverView', url: `${PREFIX_PATH}/user` },
    { id: 2, index: 1, tabTitle: 'Actions', url: `${PREFIX_PATH}/user/action` },
    { id: 3, index: 2, tabTitle: 'Performance', url: `${PREFIX_PATH}/user/performance` },
    {
      id: 4,
      index: 3,
      tabTitle: 'Configuration',
      url: `${PREFIX_PATH}/user/configuration/user-infomation`,
      subList: [
        { id: 1, tabTitle: 'Basic Details', index: 0, url: `${PREFIX_PATH}/user/configuration/user-infomation` },
        { id: 2, tabTitle: 'Permissions', index: 1, url: `${PREFIX_PATH}/user/configuration/permissions` },
        { id: 3, tabTitle: 'Change Password', index: 2, url: `${PREFIX_PATH}/user/configuration/change-password` },
        { id: 4, tabTitle: 'Clint List', index: 3, url: `${PREFIX_PATH}/user/configuration/client-list` }
      ],
    },
    {
      id: 5,
      index: 4,
      url: `${PREFIX_PATH}/user/user-behaviour/activity`,
      subList: [
        { id: 1, tabTitle: 'Activity', index: 0, url: `${PREFIX_PATH}/user/user-behaviour/activity` },
        { id: 2, tabTitle: 'Login Log', index: 1, url: `${PREFIX_PATH}/user/user-behaviour/login-log` },
      ],
      tabTitle: 'User Behaviour',
    },
  ],
  Configuration: [
    { index: 0, tabTitle: 'Match pair Parameters' },
    { index: 1, tabTitle: 'Similar Stone Parameters' },
    { index: 2, tabTitle: 'Sync with Global Inventory Platform' },
    { index: 3, tabTitle: 'Create Collections' },
    { index: 4, tabTitle: 'Calibrated Stone Parameters' },
    { index: 5, tabTitle: 'Client Actions Configuration' },
    { index: 6, tabTitle: 'Volume Discount Configuration' },
    { index: 7, tabTitle: `${i18n.t('title.offer')} Configuration` },
    { index: 8, tabTitle: 'Auction Configuration' },
  ],
  reportsTab: [
    {
      id: 1,
      index: 0,
      tabTitle: 'OverView',
      url: `${PREFIX_PATH}/reports/overview`,
    },
    {
      id: 2,
      index: 1,
      tabTitle: 'Sales & Stock',
      url: `${PREFIX_PATH}/reports/sales-stock`,
      subList: [
        { url: PREFIX_PATH + '/reports/sales-stock', tabTitle: 'Sales & Stock', index: 0 },
        { tabTitle: 'Sales & Discount Group', index: 1, url: PREFIX_PATH + '/reports/stock-list' },
      ],
    },
  ],
  diamondDetailSubtab: [
    { index: 0, tabTitle: 'Video' },
    { index: 1, tabTitle: 'Image' },
    { index: 2, tabTitle: 'H&A' },
    { index: 3, tabTitle: 'Certificate' },
  ],
  diamondDetailtab: [
    { index: 0, tabTitle: 'Details' },
    // { index: 1, tabTitle: 'Calibrated Diamonds' },
    // { index: 2, tabTitle: 'Analytics' },
  ],
  // ------------------------------------------------------------Above are new Array Used. Before Change Ask harshil mehta-------------------------
  interactions: [
    { id: 1, tabTitle: 'Mail', urls: [`${PREFIX_PATH}/interactions/mail`] },
    { id: 2, tabTitle: 'Call', urls: [`${PREFIX_PATH}/interactions/call`] },
    { id: 3, tabTitle: 'Chat', urls: [`${PREFIX_PATH}/interactions/chat`] },
  ],
  consignmentSub: [
    { id: 1, tabTitle: 'Overview', urls: [`${PREFIX_PATH}/transaction/consignment/overview`] },
    { id: 2, tabTitle: 'Consignment List', urls: [`${PREFIX_PATH}/transaction/consignment/consignment-list`] },
  ],
  offerHistorySub: [
    { id: 1, tabTitle: 'Approved', urls: [`${PREFIX_PATH}/transaction/offer-history/approved`] },
    { id: 2, tabTitle: 'Rejected', urls: [`${PREFIX_PATH}/transaction/offer-history/rejected`] },
  ],
  transctionSub: [
    { tabTitle: 'Cart' },
    { tabTitle: 'Watchlist' },
    { tabTitle: 'Enquiry' },
    { tabTitle: i18n.t('title.offer') },
    { tabTitle: 'Hold' },
    { tabTitle: 'Consignment' },
    { tabTitle: 'Order' },
  ],
  // salesStocksubTitle: [{ tabTitle: 'Sales & Stock' }, { tabTitle: 'Sales & Discount Group' }],
  offertab: [
    { tabTitle: i18n.t('title.offer') },
    { tabTitle: `past ${i18n.t('title.offer')}` },
    { tabTitle: 'Customer KYC' },
  ],
  enquirtTab: [
    { id: 1, tabTitle: 'Confirm', urls: [`${PREFIX_PATH}/client/enquiry/confim`] },
    { id: 2, tabTitle: 'Hold', urls: [`${PREFIX_PATH}/client/enquiry/hold`] },
    { id: 3, tabTitle: i18n.t('title.offer'), urls: [`${PREFIX_PATH}/client/enquiry/offer`] },
    { id: 4, tabTitle: 'Bid', urls: [`${PREFIX_PATH}/client/enquiry/bid`] },
    { id: 5, tabTitle: 'Demand', urls: [`${PREFIX_PATH}/client/enquiry/demand`] },
  ],
  // userConfiguration: [
  //   {
  //     id: 1,
  //     tabTitle: 'Basic Details',
  //     urls: [PREFIX_URL_WITH_SLASH + '/user/configuration/user-infomation'],
  //   },
  //   {
  //     id: 2,
  //     tabTitle: 'Permissions',
  //     urls: [PREFIX_URL_WITH_SLASH + '/user/configuration/permissions'],
  //   },
  //   {
  //     id: 3,
  //     tabTitle: 'Change Password',
  //     urls: [PREFIX_URL_WITH_SLASH + '/user/configuration/change-password'],
  //   },
  // ],
  // userBehaviour: [
  //   {
  //     id: 1,
  //     tabTitle: 'Activity',
  //     urls: [PREFIX_URL_WITH_SLASH + '/user/user-behaviour/activity'],
  //   },
  //   {
  //     id: 2,
  //     tabTitle: 'Login Log',
  //     urls: [PREFIX_URL_WITH_SLASH + '/user/user-behaviour/login-log'],
  //   },
  // ],
  SubMaster: [
    { tabTitle: 'Natts' },
    { tabTitle: 'Day Terms' },
    { tabTitle: 'Currency' },
    { tabTitle: 'Fancy Color' },
    { tabTitle: 'Luster' },
    { tabTitle: 'Symmetry' },
    { tabTitle: 'Polish' },
    { tabTitle: 'Shade' },
    { tabTitle: 'Tradeshow' },
  ],
  EnquiryTab: [
    {
      id: 1,
      tabTitle: 'Overview',
      url: `${PREFIX_PATH}/enquiry`,
    },
    {
      id: 2,
      tabTitle: 'Enquiry',
      urls: [
        PREFIX_PATH + '/enquiry/enquiry/confirm',
        PREFIX_PATH + '/enquiry/enquiry/hold',
        PREFIX_PATH + '/enquiry/enquiry/tracklist',
        PREFIX_PATH + '/enquiry/enquiry/cart',
        PREFIX_PATH + '/enquiry/enquiry/watchlist',
        PREFIX_PATH + '/enquiry/enquiry/offer',
        PREFIX_PATH + '/enquiry/enquiry/order',
        PREFIX_PATH + '/enquiry/enquiry/appointment',
      ],
    },
    {
      id: 3,
      tabTitle: 'Notes',
      url: `${PREFIX_PATH}/enquiry/note`,
    },
    { tabTitle: 'Customer History' },
    { tabTitle: 'KYC' },
  ],
  EnquirySubTab: [
    { id: 1, tabTitle: 'Confirm', urls: [`${PREFIX_PATH}/enquiry/enquiry/confirm`] },
    { id: 2, tabTitle: 'Hold', urls: [`${PREFIX_PATH}/enquiry/enquiry/hold`] },
    { id: 3, tabTitle: 'Track List', urls: [`${PREFIX_PATH}/enquiry/enquiry/tracklist`] },
    { id: 4, tabTitle: 'Cart', urls: [`${PREFIX_PATH}/enquiry/enquiry/cart`] },
    // { id: 5, tabTitle: 'Watch List', urls: [`${PREFIX_PATH}/enquiry/enquiry/watchlist`] },
    { id: 6, tabTitle: `Quotation/${i18n.t('title.offer')}`, urls: [`${PREFIX_PATH}/enquiry/enquiry/offer`] },
    { id: 7, tabTitle: 'Order', urls: [`${PREFIX_PATH}/enquiry/enquiry/order`] },
    { id: 8, tabTitle: 'Appointment', urls: [`${PREFIX_PATH}/enquiry/enquiry/appointment`] },
  ],
  collectionTab: [{ tabTitle: 'Trending' }, { tabTitle: 'Best of CS' }, { tabTitle: 'Premium' }],
  // themeSetup: [
  //   {
  //     id: 1,
  //     tabTitle: 'Color Setup',
  //     url: PREFIX_URL_WITH_SLASH + '/theme-setup/color-config',
  //   },
  //   {
  //     id: 2,
  //     tabTitle: 'Project Details',
  //   },
  // ],
  homeSetting: [
    { index: 0, tabTitle: 'Popup' },
    { index: 1, tabTitle: 'Event', hideTab: true },
    // { index: 2, tabTitle: 'Announcement' },
    { index: 1, tabTitle: 'Banner' },
    { index: 3, tabTitle: 'Slider', hideTab: true },
    { index: 4, tabTitle: 'News', hideTab: true },
    { index: 2, tabTitle: 'App Version' },
    { index: 3, tabTitle: 'Show' },

    // { tabTitle: 'Send Notification' },
  ],
  projectSetting: [
    { index: 0, tabTitle: 'New Arrival', hideTab: true },
    { index: 0, tabTitle: 'Login Setting' },
  ],
  promotionalSetting: [
    { index: 2, tabTitle: 'New Arrival', hideTab: true },
    { index: 0, tabTitle: 'Promotional Discount Setting' },
    { index: 1, tabTitle: 'Show Discount Setting' },
  ],
  quoteSetting: [
    { index: 0, tabTitle: 'New Arrival', hideTab: true },
    { index: 0, tabTitle: 'Quote Setting' },
  ],
  appVersion: [
    {
      index: 0,
      tabTitle: 'App Version',
      subList: [
        { tabTitle: 'Android', index: 0 },
        { tabTitle: 'Iphone', index: 1 },
      ],
    },
  ],
};
