import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation, useRouteMatch } from 'react-router-dom';

import { fetchcolumnAsyncMemo } from 'components/Inventory/inventoryFunctions';

import { LoaderService } from 'services/LoaderService';
import { PermissionService } from 'services/PermissionService';
import { Storage } from 'services/storage';

import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH, TABLE_PAGE_LIMIT } from 'constants/Common';

import OptionsUtils from './OptionsUtils';
import {
  isObject,
  isArray,
  isFunction,
  isNotEmpty,
  isString,
  isNumber,
  queryStringToObject,
  formatInlineList,
} from './utils';

export { default as useFilters } from './useFilters';
export { default as useRowSelect } from './useRowSelect';
export { default as useSortBy } from './useSortBy';

/**
 * Custom Hooks for common functionalities
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */

export const useAutoRef = (value) => {
  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  React.useDebugValue(ref.current);
  return ref;
};

export const useCustomRef = (value) => {
  const ref = React.useRef(value);

  const refProxy = React.useMemo(() => {
    return {
      set: (value) => void (ref.current = value),
      get: () => ref?.current,
    };
  }, []);

  React.useEffect(() => {
    refProxy.set(value);
  }, [refProxy, value]);

  React.useDebugValue(ref?.current);
  return refProxy;
};

export const useBoolean = (initialValue = false) => {
  const [state, _setState] = React.useState(Boolean(initialValue));

  const setState = React.useMemo(() => {
    return {
      true: () => _setState(true),
      false: () => _setState(false),
      toggle: () => _setState((v) => !v),
    };
  }, []);

  React.useDebugValue(state);
  return [state, setState];
};

export const useCompositeState = (initialState = {}) => {
  const [state, _setState] = React.useState(initialState);
  const initialStateRef = React.useRef(initialState);

  const setState = React.useCallback((objectOrCallback, spread = true) => {
    const callback = isFunction(objectOrCallback) ? objectOrCallback : undefined;
    const object = isObject(objectOrCallback) ? objectOrCallback : {};

    _setState((state) => {
      const _object = callback ? callback(state) : object;
      return spread ? { ...state, ..._object } : { ..._object };
    });
  }, []);

  const resetState = React.useCallback(() => {
    _setState(initialStateRef.current);
  }, []);

  React.useDebugValue(state);
  return [state, setState, resetState];
};

export const useCounter = (init = 0) => {
  const [count, set] = React.useState(init);

  const inc = React.useCallback((cb = () => {}) => set((n) => ((n = n > 0 ? ++n : 1), cb(n), n)), []);
  const dec = React.useCallback((cb = () => {}) => set((n) => ((n = n > 0 ? --n : 0), cb(n), n)), []);

  React.useDebugValue(count);
  return [count, inc, dec];
};

export const useLoading = (init = false, show = true) => {
  const [count, inc, dec] = useCounter(init ? 1 : 0);
  const countRef = React.useRef(count);
  React.useEffect(() => void (countRef.current = count), [count]);

  const start = React.useCallback(() => inc(() => show && LoaderService.startLoading()), [inc, show]);
  const stop = React.useCallback(() => dec(() => show && LoaderService.stopLoading()), [dec, show]);

  React.useEffect(() => {
    if (show) return () => {};
    const hasClass = document?.body?.classList?.contains?.('progress');
    void (count > 0
      ? !hasClass && document?.body?.classList?.add?.('progress')
      : hasClass && document?.body?.classList?.remove?.('progress'));
  }, [show, count]);

  React.useEffect(() => {
    return () => {
      if (show && isNumber(countRef.current) && countRef.current > 0) {
        LoaderService.adjustCount(-Math.abs(countRef.current));
      }
    };
  }, [show]);

  React.useDebugValue(count);
  return [Boolean(count), start, stop];
};

export const usePagination = (page = 1, limit = TABLE_PAGE_LIMIT) => {
  const [state, _setState] = React.useState({ page, limit });
  const paramsRef = useAutoRef({ page, limit });

  const setPagination = React.useCallback((page, limit) => {
    _setState((state) => ({
      page: page ?? state.page,
      limit: limit ?? state.limit,
    }));
  }, []);

  const resetPagination = React.useCallback(() => {
    const { page, limit } = paramsRef.current ?? {};
    _setState({ page, limit });
  }, [paramsRef]);

  React.useDebugValue(state);
  return [state?.page, state?.limit, setPagination, resetPagination];
};

export const useList = (list = [], count = 0) => {
  const [state, _setState] = React.useState({ list, count });

  const setList = React.useCallback((list, count) => {
    _setState((state) => ({
      list: list ?? state.list,
      count: count ?? state.count,
    }));
  }, []);

  const resetList = React.useCallback(() => {
    _setState(() => ({ list: [], count: 0 }));
  }, []);

  React.useDebugValue(state);
  return [state?.list, state?.count, setList, resetList];
};

export const useIsMobile = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const setSize = () => setWidth(window.innerWidth);

  React.useEffect(() => {
    setSize();
    window.addEventListener('resize', setSize);
    return () => window.removeEventListener('resize', setSize);
  }, []);

  const output = React.useMemo(() => {
    const isMobile = width <= MOBILE_MAX_WIDTH;
    const isTablet = width <= TABLET_MAX_WIDTH;
    return [isMobile, isTablet];
  }, [width]);

  React.useDebugValue(output);
  return output;
};

export const useQueryParams = () => {
  const location = useLocation();
  const output = React.useMemo(() => queryStringToObject(location.search), [location.search]);
  React.useDebugValue(output);
  return output;
};

export const usePathname = () => {
  const match = useRouteMatch();
  const pathname = React.useMemo(() => generatePath(match.path)?.split('/')?.pop(), [match.path]);
  React.useDebugValue(pathname);
  return pathname;
};

export const useSelectedRows = (currentType, options) => {
  options = { mapFields: [], ...options };
  const optionsRef = useAutoRef(options);

  const rowList = useSelector((state) => state?.diamondData?.selectedRows?.[currentType] ?? []);

  const rowMap = React.useMemo(() => {
    const { mapFields } = optionsRef.current;
    return Object.fromEntries(mapFields.map((field) => [field, rowList.map((row) => row?.[field])]));
  }, [optionsRef, rowList]);

  React.useDebugValue({ rowList, rowMap });
  return [rowList, rowMap];
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => void clearTimeout(handler);
  }, [value, delay]);

  React.useDebugValue({ debouncedValue });
  return debouncedValue;
};

export const useForm = (initialValues, options) => {
  const [values, setValue] = React.useState(initialValues);
  const [errors, setError] = React.useState({});
  const [touched, setTouched] = React.useState(false);
  const optionsRef = useAutoRef(options);

  const handleChange = React.useCallback(
    (e) => {
      const { name, value } = e?.target ?? e;
      const { validate, transform } = optionsRef.current;

      const _value = transform?.[name]?.(value) ?? value;
      const _error = validate?.[name]?.() ?? undefined;
      setValue((_values) => ({ ..._values, [name]: _value }));
      setError((_errors) => ({ ..._errors, [name]: _error }));
      setTouched((_touched) => ({ ..._touched, [name]: true }));
    },
    [optionsRef],
  );

  const handleCheck = React.useCallback(
    (e) => {
      const { name, checked: value } = e?.target ?? e;
      handleChange({ name, value });
    },
    [handleChange],
  );

  const handleBlur = React.useCallback((e) => {
    const { name } = e?.target ?? e;
    setTouched((_touched) => ({ ..._touched, [name]: true }));
  }, []);

  const output = React.useMemo(
    () => ({ errors, handleBlur, handleChange, handleCheck, touched, values }),
    [errors, handleBlur, handleChange, handleCheck, touched, values],
  );

  React.useDebugValue(output);
  return output;
};

export const useColumns = (setDefault = false, options = {}) => {
  const [columns, _setColumns] = React.useState([]);
  const defaultOptions = useAutoRef(options);

  const setColumns = React.useCallback(
    async (params) => {
      const { type, transform, beforeSet, afterSet, ...rest } = { ...defaultOptions.current, ...params };
      if (isFunction(beforeSet)) beforeSet();
      const columns = await fetchcolumnAsyncMemo(type, rest);
      isFunction(transform) ? _setColumns(transform(columns)) : _setColumns(columns);
      if (isFunction(afterSet)) afterSet();
    },
    [defaultOptions],
  );

  React.useEffect(() => {
    if (setDefault) setColumns();
  }, [setDefault, setColumns]);

  React.useDebugValue(columns);
  return [columns, setColumns];
};

export const useCountries = () => {
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const list = await OptionsUtils.getCountryOptions();
      setList(list);
    })();
  }, []);

  React.useDebugValue(list);
  return list;
};

export const useStateByCountry = (country) => {
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const list = await OptionsUtils.getStateOptionsByCountry(country);
      setList(list);
    })();
  }, [country]);

  React.useDebugValue(list);
  return list;
};

export const useCityOptionsByCountry = (country) => {
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const list = await OptionsUtils.getCityOptionsByCountry(country);
      setList(list);
    })();
  }, [country]);

  React.useDebugValue(list);
  return list;
};

export const useCityOptionsByState = (state) => {
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const list = await OptionsUtils.getCityOptionsByState(state);
      setList(list);
    })();
  }, [state]);

  React.useDebugValue(list);
  return list;
};

/**
 * useStorage react hook
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
export const useStorage = (key, options) => {
  const keyRef = useAutoRef(key);
  const optionsRef = useAutoRef(options);

  const [value, _setValue] = React.useState(Storage.get(key, options?.decode) ?? options?.defaultValue);

  React.useEffect(() => {
    const key = keyRef.current;
    const options = optionsRef.current;
    return Storage.listen(key, (store) => {
      _setValue(store[key] ?? options?.defaultValue, options?.decode);
    });
  }, [keyRef, optionsRef]);

  const setValue = React.useCallback(
    (value) => {
      const key = keyRef.current;
      const options = optionsRef.current;
      Storage.set(key, value, options?.decode);
    },
    [keyRef, optionsRef],
  );

  React.useDebugValue(value);
  return [value, setValue];
};

/**
 * usePermissions react hook
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
export const usePermissions = (path) => {
  const [module, setModule] = React.useState();

  React.useEffect(() => {
    setModule(PermissionService.getPermission(path));
    return PermissionService.events.updateTree.listen(() => {
      setModule(PermissionService.getPermission(path));
    });
  }, [path]);

  React.useDebugValue(module);
  return [module];
};

export const useCurrentType = (...args) => {
  const currentType = React.useMemo(
    () => (isArray(args) ? args.filter(isString).filter(isNotEmpty).join('.') : `${args}`),
    [args],
  );
  return [currentType];
};

export function useListInput(initialValue) {
  const [value, setValue] = React.useState(initialValue);
  const list = React.useMemo(() => {
    formatInlineList(value ?? '', { returnString: false, allowAppend: false });
  }, [value]);

  const handleChange = React.useCallback((eventOrValue) => {
    const value = formatInlineList(eventOrValue?.target?.value ?? eventOrValue ?? '', {
      allowAppend: true,
    });
    setValue(value);
  }, []);

  React.useDebugValue(list);
  return [value, list, handleChange];
}

export const useEffectOnMount = (callback) => {
  const didMount = React.useRef(true);
  React.useEffect(() => {
    if (didMount.current) {
      callback();
      didMount.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
