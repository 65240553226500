import React from 'react';

function NoDataShow({ message, ...restProps }) {
  return (
    <div className="empty-data" id="emptyData" {...restProps}>
      <h3>{message || <span>No data to show</span>}</h3>
    </div>
  );
}

export default NoDataShow;
