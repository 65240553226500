import React from 'react';
import SearchWithSelect from 'containers/SearchWithSelect';
import SubMasterList from './SubMasterList';
import { Select } from 'antd';
import { getMastersList, updateMasterSequence } from 'services/SubMasterApi';
import { showNotification } from 'services/NotificationService';
import _findIndex from 'lodash/findIndex';
import { isEmpty } from 'util/utils';

class SubMaster extends React.Component {
  state = {
    masters: [],
    list: [],
    parentId: null,
    parentCode: null,
    sort: [{ sortingSequence: 'ASC' }],
    updateSequence: false,
    loading: false,
  };

  componentDidMount() {
    this.getMastersList();
  }

  getMastersList = async () => {
    const [err, res] = await getMastersList({
      isOnlyParents: true,
      isDeleted: false,
    });

    if (!err && res?.code == 'OK' && res?.data?.length) {
      let parentId = this.props?.location?.parentId;
      let parentCode = this.props?.location?.parentCode;

      this.setState(
        {
          masters: res.data,
          parentId: parentId ? parentId : res?.data[0]?.id,
          parentCode: parentCode ? parentCode : res?.data[0]?.code,
        },
        () => this.getSubMastersList(),
      );
    } else {
      this.setState({ masters: [], parentId: null, parentCode: null });
    }
  };

  getSubMastersList = async (search = '') => {
    if ((search?.length > 2 || !search) && !isEmpty(this.state.parentId)) {
      this.setState({ loading: true });
      let obj = {
        parentId: this.state.parentId,
        isDeleted: false,
        filter: {},
        sort: this.state.sort,
      };
      if (search) {
        obj.search = { keyword: search, keys: ['name', 'code'] };
      }
      const [err, res] = await getMastersList(obj);

      if (!err && res?.code == 'OK' && res?.data?.length) {
        this.setState({ list: res.data, loading: false });
      } else {
        this.setState({ list: [], loading: false });
      }
    }
  };

  handleSearch = (e) => {
    this.getSubMastersList(e);
  };

  moveRow = (e) => {
    this.setState({ list: e, updateSequence: true });
  };

  updateMasterSequence = async () => {
    this.setState({ loading: true });
    let list = this.state.list;
    const [err, res] = await updateMasterSequence({
      sequences: list.map(({ id, sortingSequence: sequence }) => ({ id, sequence })),
    });
    !err && showNotification(res);
    res?.code === 'OK' && this.setState({ updateSequence: false }, () => this.getSubMastersList());
  };

  handleSort = (sort) => {
    if (JSON.stringify(this.state.sort) !== JSON.stringify(sort)) {
      this.setState({ sort }, () => this.getSubMastersList());
    }
  };

  render() {
    // const { list, parentId, parentCode } = this.state;

    return (
      <div className="configurationInnerSubTb submasterTab">
        <div className="subMasterHeader">
          <SearchWithSelect
            placeholder="Search Sub Master"
            value={this.state.parentId}
            onChange={(parentId) => {
              this.setState({ parentId }, () => {
                this.getSubMastersList();
                const masterIndex = _findIndex(this.state.masters, { id: parentId });
                const masterCode = masterIndex > -1 ? this.state.masters[masterIndex].code : '';
                this.setState({ parentCode: masterCode, updateSequence: false });
              });
            }}
          >
            {this.state?.masters?.map((item) => (
              <Select.Option value={item.id} key={item.id} selected>
                {item.name}
              </Select.Option>
            ))}
          </SearchWithSelect>
        </div>
        <div className="configurationWrapper pt-0">
          <SubMasterList
            // {...{ list, parentId, parentCode }}
            {...this.state}
            // handleSort={(sort) => this.setState({ sort }, () => this.getSubMastersList())}
            initialSort={this.state.sort}
            handleSort={this.handleSort}
            getSubMastersList={this.getSubMastersList}
            handleSearch={this.handleSearch}
            updateMasterSequence={this.updateMasterSequence}
            moverow={(e) => this.moveRow(e)}
          />
        </div>
      </div>
    );
  }
}

export default SubMaster;
