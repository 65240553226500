import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { showNotification } from 'services/NotificationService';
import { isEmpty, isObject } from 'util/utils';
import { promotionalDisUpdate, promotionalDisSetting } from 'services/CommonApi';
import InputBlock from 'containers/InputBlock';
import moment from 'moment';

const defaultValues = {
  maxAmt: '',
  data: '',
};

const validationSchema = Yup.object().shape({
  maxAmt: Yup.number().required('Maximum Amount is required!'),
  data: Yup.number().required('Maximum Discount is required!'),
});

function PromotionalSetting() {
  const [setting, setSetting] = React.useState({});
  const [dataList, setDataList] = React.useState([]);

  const initialValues = React.useMemo(() => {
    const data = Object.fromEntries(
      Object.entries(defaultValues).map(([key, value]) => [
        key,
        isEmpty(setting?.[key])
          ? value
          : key == 'untildate' && !isEmpty(setting?.[key])
          ? moment(setting?.[key])
          : setting?.[key],
      ]),
    );

    return data;
  }, [setting]);

  const getList = React.useCallback(async () => {
    const data = { filter: { type: 5 } };
    const [, res] = await promotionalDisSetting(data);
    if (res.code === 'OK' && isObject(res?.data)) {
      setDataList(res);
      setSetting(res?.data);
    }
  }, []);

  React.useEffect(() => {
    getList();
  }, [getList]);

  const submitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const { maxAmt, data, ...rest } = setting;
    const value = {
      ...rest,
      maxAmt: values.maxAmt,
      data: values.data,
    };
    const [err, res] = await promotionalDisUpdate(value);

    setSubmitting(false);
    !err && showNotification(res);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({ handleChange, touched, errors, values, setValues, setFieldValue, setFieldTouched }) => (
        <Form>
          <div className="d-flex homeSettingWrapper mt-20 loginSetting">
            <div className="userDropdown">
              <InputBlock
                label="Max Discount(%)*"
                placeholder="Maximum Discount"
                type="number"
                name="data"
                onChange={handleChange}
                value={values.data}
                error={touched?.data && errors?.data}
                // count comma separated string of stone IDs //maxDis
                //value={values.stoneId?.split(',')?.filter((item) => !isEmpty(`${item}`.trim()))?.length}
              />
            </div>

            <InputBlock
              label="Max Amount*"
              placeholder="Maximum Amount"
              type="number"
              name="maxAmt"
              onChange={handleChange}
              value={values.maxAmt}
              error={touched?.maxAmt && errors?.maxAmt}
              // count comma separated string of stone IDs //maxAmt
              //value={values.stoneId?.split(',')?.filter((item) => !isEmpty(`${item}`.trim()))?.length}
            />

            <div className="width-100 d-flex mt-20">
              <button className="commonButton mr-10" type="submit" value="Submit">
                Save
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default PromotionalSetting;
