import React from 'react';
import { isValue, getBrokerData } from '../CommonPopupFunction';
import SelectOption from 'containers/SelectOption';
import Storage from 'services/storage';
import { isEmpty } from 'util/utils';

function BrokerComponent(props) {
  const [state, _setState] = React.useState({
    broker: null,
    brokerList: [],
    loading: false,
  });

  const setState = (object = {}, cb = null) => {
    _setState((state) => ({ ...state, ...object }));
    props.setBrokerDetails(object);
  };

  React.useEffect(() => {
    setState(props.initialData);
  }, [props.initialData.broker]);

  React.useEffect(() => {
    setState(props.initialData);
  }, [props.initialData.brokerList]);

  React.useEffect(() => {
    let defaultBroker = Storage.get('default-broker');

    setState({
      broker: !isEmpty(props.initialData?.broker) ? props.initialData?.broker : defaultBroker?.id,
      brokerList: !isEmpty(props.initialData?.brokerList)
        ? props.initialData?.brokerList
        : !isEmpty(defaultBroker)
        ? [{ key: defaultBroker?.id, value: defaultBroker?.companyName ?? '-' }]
        : [],
    });
  }, []);

  return (
    <>
      <SelectOption
        placeholder="Min. 3 Chars"
        // defaultValue="No Broker"
        value={state.broker}
        selectValue={state.brokerList}
        label={props?.label ? props.label : 'Broker *'}
        onChange={(broker = null) => {
          setState({ broker });
        }}
        onSearch={(keyword) => {
          getBrokerData((brokerList) => {
            setState({ brokerList });
          }, keyword);
        }}
        notFoundContent={state.loading ? 'loading...' : 'Enter a Broker.'}
        error={props.error?.broker}
      />
    </>
  );
}

export default React.memo(BrokerComponent);
