import React from "react"

const TimeConfim = (props) => {
    return(
        <div className="bookAppointmentTimeItem">
            {props.visible ?
            <div className="timeSelect" onClick={props.onClick}>{props.time}</div>
            :
            <div className="bookAppointTimeConfirm">
                <span>{props.time}</span>
                <span onClick={props.onClick}>Confirm</span>
            </div>
            }
        </div>
    )
}
export default TimeConfim;