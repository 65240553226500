import React from 'react';
import PropTypes from 'prop-types';
import BottomAction from 'containers/BottomAction';

import { isArray, isEmpty } from 'util/utils';
import InstructionForm from '../Instructions/InstructionForm';
import openNotification from 'constants/OpenNotifications';
import Messages from 'constants/Messages';
import { useBoolean } from 'util/hooks';

function BottomInstruct({ list, onSuccess }) {
  const [isOpen, setIsOpen] = useBoolean(false);

  const openModal = React.useCallback(() => {
    isArray(list) && !isEmpty(list)
      ? setIsOpen.true()
      : openNotification({ type: 'error', title: Messages.Error.Required.Stones });
  }, [list, setIsOpen]);

  const closeModal = React.useCallback(() => {
    setIsOpen.false();
  }, [setIsOpen]);

  return (
    <>
      <BottomAction title="Instruction" onClick={openModal} />
      <InstructionForm visible={isOpen} list={list} onSuccess={onSuccess} onClose={closeModal} />
    </>
  );
}

BottomInstruct.propTypes = {
  checked: PropTypes.array,
};

export default BottomInstruct;
