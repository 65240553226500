import React from 'react';
import PropTypes from 'prop-types';
import BottomAction from 'containers/BottomAction';
import Compare from 'routes/Compare';
import { useBoolean } from 'util/hooks';
import { Notify } from 'util/notify';
import Messages from 'constants/Messages';
import { isArray } from 'lodash';
import { isEmpty } from 'util/utils';

export function BottomCompareStoneButton({ ...props }) {
  return <BottomAction title="Compare" {...props} />;
}

export function CompareStoneTable({ isOpen, list, afterClose, handleClose }) {
  return isOpen && <Compare list={list} afterClose={afterClose} handleClose={handleClose} />;
}

function BottomCompareStone({ list, afterClose }) {
  const [isOpen, setIsOpen] = useBoolean(false);

  const openCompare = React.useCallback(() => {
    if (!isArray(list) || isEmpty(list)) return Notify.error({ message: Messages.Error.Required.Stones });
    if (list.length < 2) return Notify.error({ message: Messages.Error.Required.MoreThanOneStone });
    setIsOpen.true();
  }, [list, setIsOpen]);

  return (
    <>
      <BottomCompareStoneButton onClick={openCompare} />
      <CompareStoneTable isOpen={isOpen} list={list} afterClose={afterClose} handleClose={setIsOpen.false} />
    </>
  );
}

BottomCompareStone.propTypes = {
  list: PropTypes.array,
};

export default React.memo(BottomCompareStone);
