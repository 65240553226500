import React, { Component } from "react";
class TableForm extends Component {
  render() {
    return(
      <div>
        Form
      </div>
    );
  }
}

export default TableForm;
