import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isFunction } from 'util/utils';
import classNames from 'util/classNames';

const BottomAction = React.memo(function BottomAction(props) {
  const { children, link, title, onClick, hightlight, loading, ...rest } = props;

  const handleClick = React.useCallback(
    (event) => {
      if (isFunction(event?.preventDefault)) event.preventDefault();
      if (!isFunction(onClick)) return;
      onClick();
    },
    [onClick],
  );

  return link ? (
    <Link to={link} className={classNames(['bottomStickyButton', hightlight && 'buttonHlightlight'])} {...rest}>
      <span>{children ?? title}</span>
    </Link>
  ) : (
    <a
      className={classNames([
        'bottomStickyButton',
        hightlight && 'buttonHlightlight',
        loading && 'DisabledBlock progress',
      ])}
      type="button"
      href="#!"
      onClick={handleClick}
      {...rest}
    >
      <span>{children ?? title}</span>
    </a>
  );
});

BottomAction.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default BottomAction;
