import React from 'react';
import plusSvg from 'assets/svg/plus.svg';
import closeSvg from 'assets/svg/close.svg';

import classNames from 'util/classNames';
import { isEmpty, isNumber, isArray, isFunction, isNumeric, isAlphaNumeric } from 'util/utils';
import './input.less';

function Buttons({ onAdd, onRemove }) {
  return (
    (isFunction(onAdd) || isFunction(onRemove)) && (
      <div className="plusInputRemove">
        {isFunction(onAdd) && (
          <div className="plusInputItem">
            <img src={plusSvg} alt="" className="plusInput" />
          </div>
        )}
        {isFunction(onRemove) && (
          <div className="closeInoutItem">
            <img src={closeSvg} alt="" className="closeInput" />
          </div>
        )}
      </div>
    )
  );
}

export const InputField = React.memo(function InputField({ label, required, error, onAdd, onRemove, ...rest }) {
  return (
    <div className={`from-group inputBlock ${rest?.classNameHead}`}>
      <label>
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <div className={classNames(['inputWrapper', error && 'error-message', rest?.className])}>
        <input {...rest} />
        <Buttons {...{ onAdd, onRemove }} />
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
});

const InputBlock = InputField;
export default InputBlock;

export function InputPercentage(props) {
  return <InputNumber max={100} {...props} />;
}

export const InputAlphaNumeric = React.memo(function InputNumber({
  label,
  required,
  error,
  onChange,
  onBlur,
  onAdd,
  className,
  onRemove,
  min,
  max,
  ...props
}) {
  const handleChange = React.useCallback(
    (event) => {
      event.preventDefault();
      const value = event?.target?.value;
      event.target.value = '';

      if (!isAlphaNumeric(value)) return;

      onChange(value);
    },
    [onChange],
  );

  const handleBlur = React.useCallback(
    (event) => {
      handleChange(event, true); // cast to number on blur
      onBlur(event);
    },
    [handleChange, onBlur],
  );

  const value = React.useMemo(() => (isEmpty(props?.value) ? '' : props?.value), [props?.value]);

  return (
    <div className={`from-group inputBlock ${className}`}>
      <label>
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <div className={classNames(['inputWrapper', error && 'error-message', props?.className])}>
        <input {...props} value={value} onChange={handleChange} onBlur={handleBlur} />
        <Buttons {...{ onAdd, onRemove }} />
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
});

export const InputNumber = React.memo(function InputNumber({
  label,
  required,
  error,
  onChange,
  onBlur,
  onAdd,
  className,
  onRemove,
  min,
  max,
  ...props
}) {
  const handleChange = React.useCallback(
    (event, castToNumber) => {
      event.preventDefault();
      const value = event?.target?.value;
      event.target.value = '';

      if (!isNumeric(value)) return; // return if not numeric fragment
      const number = Number(value); // cast to number
      const valueIsNumber = isNumber(number);

      if (!isEmpty(value) && valueIsNumber) {
        // check limits if not empty and type is number
        if ((isNumber(min) && number < min) || (isNumber(max) && number > max)) return;
      }

      if (castToNumber) {
        return onChange(valueIsNumber && !isEmpty(value) ? number : null);
      }

      onChange(value);
    },
    [onChange, min, max],
  );

  const handleBlur = React.useCallback(
    (event) => {
      handleChange(event, true); // cast to number on blur
      onBlur && onBlur(event);
    },
    [handleChange, onBlur],
  );

  const value = React.useMemo(() => (isEmpty(props?.value) ? '' : props?.value), [props?.value]);

  return (
    <div className={`from-group inputBlock ${className}`}>
      <label>
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <div className={classNames(['inputWrapper', error && 'error-message', props?.className])}>
        <input {...props} value={value} onChange={handleChange} onBlur={handleBlur} />
        <Buttons {...{ onAdd, onRemove }} />
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
});

export const InputFloat = React.memo(function InputFloat({ label, required, error, onAdd, onRemove, ...rest }) {
  return (
    <div className="from-group inputBlock">
      <label>
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <div className={classNames(['inputWrapper', error && 'error-message', rest?.className])}>
        <input
          onKeyPress={(evt) => {
            if ((evt.which < 48 || evt.which > 57) && evt.which != 46) {
              evt.preventDefault();
            }
          }}
          {...rest}
          // min="0.00"
        />
        <Buttons {...{ onAdd, onRemove }} />
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
});

export function PhoneInputField({ label, error, onAdd, onRemove, onChange, value, ...rest }) {
  const [comopositeValue, setCompositeValue] = React.useState(isArray(value) ? value : ['', '', '']);

  const handleChange = (index, newValue) =>
    setCompositeValue((compositeValue) => {
      compositeValue[index] = newValue;
      onChange([...compositeValue]);
      return [...compositeValue];
    });

  return (
    <div className="from-group inputBlock">
      {label && <label>{label}</label>}
      <div className={classNames(['inputWrapper', error && 'error-message', 'mobileFiled'])}>
        <input
          className="smallInput"
          placeholder="ISD"
          value={comopositeValue[0]}
          onChange={(e) => handleChange(0, e.target.value)}
        />
        <input
          className="smallInput"
          placeholder="STD"
          value={comopositeValue[1]}
          onChange={(e) => handleChange(1, e.target.value)}
        />
        <input {...rest} value={comopositeValue[2]} onChange={(e) => handleChange(2, e.target.value)} />
        <Buttons {...{ onAdd, onRemove }} />
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
}
