import React from 'react';
import { Select } from 'antd';
import './search.less';

const SearchWithSelect = (props) => {
  return (
    <Select
      showSearch
      value={props.value}
      onChange={props.onChange}
      className="searchWithSelect"
      placeholder={props.placeholder}
      optionFilterProp="children"
    >
      {/* {props.selectValue.map((d,index) => (
            <Option value={d.value}>{d.value}</Option>
        ))} */}
      {props.children}
    </Select>
  );
};
export default SearchWithSelect;
