import React from 'react';
import { Tooltip } from 'antd';

const DiamondDetailAction = (props) => {
  return (
    <>
      <Tooltip title={props.tooltip} placement={props.placement}>
        <div className="diamondDetailActionItem" onClick={props.onClick}>
          <img src={props.image} alt="" />
          {/* <span>{props.title}</span> */}
        </div>
      </Tooltip>
    </>
  );
};
export default DiamondDetailAction;
