import React from 'react';
import { Progress } from 'antd';
import { formatDateTime, returnIfNotEmpty } from 'util/utils';

function TargetSellerRow(props) {
  return (
    <table>
      <tr>
        <th style={{ textAlign: 'center' }}>Sr No</th>
        <th>Seller</th>
        <th>Seller Progress</th>
        <th>Amount</th>
        <th>From Date</th>
        <th>To Date</th>
      </tr>
      {props?.data?.map((item, index) => {
        const progressData = item?.achieve ?? 0;
        return (
          <tr key={item?.id ?? index}>
            <td style={{ textAlign: 'center' }}>{index + 1}</td>
            <td>{item?.seller?.name}</td>
            <td>
              <span className="targetProgressBar">
                <Progress percent={progressData} size="small" status="active" />
              </span>
            </td>
            <td>{item.amount?.toFixed(2)}</td>
            <td>{returnIfNotEmpty(formatDateTime(item.fromDate), '-')}</td>
            <td>{returnIfNotEmpty(formatDateTime(item.toDate), '-')}</td>
          </tr>
        );
      })}
    </table>
  );
}

export default React.memo(TargetSellerRow);
