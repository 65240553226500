import React, { Component } from 'react';
import { useTable } from 'react-table';
import { Checkbox, Drawer } from 'antd';
// import { Drawer } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import _cloneDeep from 'lodash/cloneDeep';
import _each from 'lodash/each';
import _omit from 'lodash/omit';
import _find from 'lodash/find';

// import DiamondDetail from 'components/DiamondDetail';
import Status from 'components/DiamondListing/Status';
import DiamondListingHead from 'components/DiamondListing/DiamondListingHead';
import TableGrouping from './TableGrouping';
import CircularProgress from 'components/CircularProgress';
import NoDataShow from 'components/DiamondListing/NoDataShow';

// import { getDate } from 'services/commonFunc';
import { isArray, isEmpty, isFunction } from 'util/utils';
import DiamondDetail from 'components/DiamondDetail';
import { deleteSearch } from 'services/SearchApi';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';
import InlineList from 'components/List/InlineList';
import { ListItemTitle, LiveStatus } from 'components/List/ListItem';
import ListItemBody from 'components/List/ListItemBody';
import { formatDiamondList } from 'components/List/_listUtils';
import { history } from 'util/history';

export const canSortCommon = {
  stoneId: 'Packet No',
  shpNm: 'Shape',
  crt: 'Carat',
  colNm: 'Color',
  clrNm: 'Clarity',
  shape: 'Shape',
  color: 'Color',
  carat: 'Carat',
};

export const LIMIT = 250;
export const FILTER_COLUMNS = ['colNm', 'fluNm', 'shpNm', 'lbNm', 'clrNm'];
export const floatkeys = [
  'depPer',
  'ratio',
  'crt',
  'rapAvg',
  'pAng',
  'pHgt',
  'cHgt',
  'back',
  'cAng',
  'fnDis',
  'height',
  'width',
  'length',
  'grdlPer',
  'strLn',
];
export const roundkeys = ['ctPr', 'amt', 'rap', 'tblPer', 'lwrHal'];
export const DISPLAY_TOTAL = [
  'ctPr',
  'amt',
  'rap',
  'crt',
  'newDiscount',
  'newPricePerCarat',
  'newAmount',
  'rapAvg',
  'latestOfferAmt',
  'buyAmt',
];

export const NoDataFound = ({ loading, length }) =>
  loading ? <NoDataShow message={<CircularProgress />} /> : length === 0 ? <NoDataShow /> : <></>;

const headerProps = (props, { column }) => getStyles(props, column, 'header');
const cellProps = (props, { cell }) => getStyles(props, cell.column, 'cell');
const getStyles = (props, item, type) => [
  props,
  {
    style: {
      textAlign: item.cellClass ? item.cellClass.replace('text-', '') : 'center',
      width: item.width ? item.width + 'px' : '100px',
      fontWeight: type === 'cell' && ['dna', 'shpNm'].includes(item.id) ? '600' : '',
      color: type === 'cell' && item.link ? '#008cba' : '',
    },
  },
];

const TrRow = (props) => {
  let row = props.row;
  const DND_ITEM_TYPE = 'row';
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // move row
      let r1 = props.data[dragIndex];
      if (props.data[dragIndex]?.sortingSequence) {
        var b = props.data[dragIndex]?.sortingSequence;
        props.data[dragIndex].sortingSequence = props.data[hoverIndex].sortingSequence;
        props.data[hoverIndex].sortingSequence = b;
      }
      let newlist = _cloneDeep(props.data);
      newlist = [...newlist.slice(0, dragIndex), ...newlist.slice(dragIndex + 1, newlist.length)];
      newlist = [...newlist.slice(0, hoverIndex), r1, ...newlist.slice(hoverIndex, newlist.length)];
      if (props.moverow) props.moverow(newlist);
      // move row
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index: props.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  preview(drop(dropRef));
  drag(dragRef);

  return (
    <>
      {props.dragDrop && (
        <div className="tableSortingTd" ref={dragRef}>
          <img src={require('../../assets/svg/InventoryResult/sorting-light.svg')} />
        </div>
      )}
      <tr {...row.getRowProps()} ref={dropRef} style={{ opacity }}>
        {row.cells?.map((cell, index) => (
          <td
            key={index}
            {...cell.getCellProps(cellProps)}
            onClick={() => {
              if (cell.column.link && typeof cell.column.link === 'string') {
                let field = cell.column.link.slice(cell.column.link.indexOf('$') + 1, cell.column.link.length);
                field = field.slice(0, field.indexOf('$'));
                let link = cell.column.link.replace('$' + field + '$', row.original[field]);
                window.open(link);
              } else if (['dna', 'shpNm'].includes(cell.column?.id)) {
                props.openDrawer({ visible: row.original });
              } else if (!['quote', 'hours', 'Details', 'note'].includes(cell.column.id)) {
                let checked = [...(isArray(props?.checked) ? props?.checked : [])];
                let selectKey = row.original.selectionKey ? 'selectionKey' : 'stoneId';
                if (_find(checked, { [selectKey]: row.original[selectKey] })) {
                  checked = checked.filter((el) => el[selectKey] !== row.original[selectKey]);
                } else {
                  checked.push(row.original);
                }
                if (isFunction(props.handleCheck)) props.handleCheck(checked);
              }
            }}
          >
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
      {props.gobalInventory && props.index === props.inventoryIndex && (
        <td colSpan="14" className="tableInTableBoxWrapper">
          {!isEmpty(props?.gobalInventoryData?.[row.original.id]) ? (
            Object.entries(props?.gobalInventoryData)?.map(([, searchList]) => {
              const formattedList = formatDiamondList(searchList);
              return (
                isArray(formattedList) &&
                formattedList?.map((item, index) => (
                  <div key={index} className="tableinSavedSearch">
                    <ListItemBody
                      key={index}
                      header={{
                        title: <ListItemTitle title={item.title} secondaryTitle={item?.subTitle} />,
                        info: (
                          <InlineList
                            skipEmpty
                            defaultStyle
                            separator="&nbsp;|&nbsp;"
                            list={[
                              !isEmpty(item?.account?.companyName) ? (
                                <div className="customerName">
                                  {item?.account?.companyName}
                                  <LiveStatus status={item?.account?.isActive} />
                                </div>
                              ) : null,
                              item?.user?.firstName,
                              item?.user?.mobile,
                              item?.user?.email,
                            ]}
                          />
                        ),
                      }}
                      body={
                        <InlineList
                          list={item?.properties}
                          parentClassName="savedSearchValue"
                          labelClassName="savedSearchLabel"
                          separator={<span className="separator"></span>}
                        />
                      }
                      entityName="Platform Detail Search"
                      onEdit={() => {
                        history.push(`${PREFIX_URL_WITH_SLASH}/inventory/single-stone/search`, {
                          searchId: props.row.original.searchId,
                          searchData: props.row.original.searchData,
                          searchKey: item.id,
                          platform: props.row.original.id,
                        });
                      }}
                      onDelete={() => (
                        deleteSearch(item?.id),
                        props.updateDetail(props.row.original.id, {
                          searchData: props.row.original.searchData.filter((item) => item.searchId != item?.id),
                          searchId: props.row.original.searchId.filter((item) => item != item?.id),
                        })
                      )}
                    />
                  </div>
                ))
              );
            })
          ) : (
            <NoDataShow message="Platform search not exist" />
          )}
        </td>
      )}
    </>
  );
};

class Table extends Component {
  state = { visible: false };

  handleSort = (id) => {
    let sort = _cloneDeep(this.props.sort);
    let found = false;
    _each(sort, function (val) {
      if (Object.prototype.hasOwnProperty.call(val, id)) found = val;
    });
    if (!found) sort.push({ [id]: 'ASC' });
    else {
      const index = sort.indexOf(found);
      if (found[id] === 'DESC') {
        sort = sort.filter((el) => !Object.prototype.hasOwnProperty.call(el, id));
      } else {
        found[id] = 'DESC';
        sort[index] = found;
      }
    }
    this.props.handleSort(sort);
  };

  selectFilter = (x, colId, remove = false) => {
    let selection = _cloneDeep(this.props.selectedFilterArray);
    if (!remove) {
      if (selection[colId]) selection[colId].push(x);
      else selection[colId] = [x];
    } else {
      selection[colId] = selection[colId].filter((el) => el !== x);
      if (!selection[colId].length) selection = _omit(selection, colId);
    }
    this.props.selectFilter(selection);
  };

  getGroupTitle = (data, show) => {
    const country = data?.countryNm || '';
    const salesPerson = data?.seller || '';
    const date = data?.userDate || data?.createdAt;
    const companyName = data?.user?.companyName ? data.user.companyName : data?.user?.account?.companyName;
    const user = [companyName, data?.userName, data?.userMobile, data?.userEmail]
      ?.map((item) => (isEmpty(item) ? '-' : item))
      .join(' | ');

    return show?.user
      ? user
      : show?.date
      ? `${date}`
      : // : this.props?.stageShow
      // ? `${data?.wSts}`
      show?.country
      ? country
      : show?.salesPerson
      ? salesPerson
      : this.props?.stageShow
      ? `${data?.blockCode}`
      : `${date} | ${user}`;
  };

  render() {
    let columns = this.props.nocheck
      ? this.props.columns
      : [
          {
            Header: '',
            width: '50px',
            accessor: 'action',
            id: 'action',
            Cell: ({ row }) => (
              <div className="selectActionIcon">
                {!this.props.nostatus && <Status status={row.original.sSts} />}
                <div className="selectActionIconWrapper">
                  {!this.props.noCheckBox && (
                    <Checkbox
                      checked={_find(this.props.checked, {
                        [row.original.selectionKey ? 'selectionKey' : 'stoneId']: row.original[
                          row.original.selectionKey ? 'selectionKey' : 'stoneId'
                        ],
                      })}
                      // onChange={() => {
                      //   let checked = _cloneDeep(this.props.checked);
                      //   if (_find(checked, { stoneId: row.original.stoneId })) {
                      //     checked = checked.filter(el => el.stoneId !== row.original.stoneId);
                      //   } else {
                      //     checked.push(row.original);
                      //   }
                      //   this.props.handleCheck(checked);
                      // }}
                    />
                  )}
                </div>
              </div>
            ),
          },
          ...this.props.columns,
        ];

    const allCheck =
      !this.props.nocheck &&
      !this.props.noCheckBox &&
      this.props.data?.length === this.props.checked?.length &&
      this.props.data?.length !== 0 &&
      JSON.stringify(this.props.checked?.map((x) => x.stoneId).sort()) ===
        JSON.stringify(this.props.data?.map((x) => x.stoneId).sort());
    const partialCheck = !allCheck && this.props.checked?.length > 0;

    const Table = ({ columns, data }) => {
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });
      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups?.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers?.map((column, i) => (
                  <th
                    key={i}
                    {...column.getHeaderProps(headerProps)}
                    style={{ width: column.width ? column.width + 'px' : '100px' }}
                  >
                    {column.id !== 'action' && (
                      <DiamondListingHead
                        {...this.props}
                        header={column.render('Header')}
                        column={column}
                        selectFilter={this.selectFilter}
                        sortClick={this.handleSort}
                      />
                    )}
                    {column.id === 'action' && !this.props.noheader && (
                      <div className="selectActionIcon">
                        <div className="selectActionIconWrapper">
                          {!this.props.nostatus && <Status status="all" />}
                          {!this.props.noCheckBox && (
                            <Checkbox
                              checked={allCheck}
                              indeterminate={partialCheck}
                              onChange={() => this.props.handleCheck(allCheck ? [] : this.props.data)}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <DndProvider backend={HTML5Backend}>
              {!this.props.loading &&
                rows?.map((row, roi) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={roi}>
                      {window.location.pathname.includes('consignment-list') && roi % 5 === 0 && (
                        <TableGrouping
                          groupingTitle="07/07/2020"
                          columns={columns}
                          row={row.original}
                          displayTotal={[]}
                        />
                      )}
                      {row.original.isHoldHeader && (
                        <TableGrouping groupingTitle={row.original.blockCode} row={row.original} />
                      )}
                      {row.original.isMatchHeader && (
                        <TableGrouping
                          totalDiamonds={row.original.totalDiamonds}
                          displayTotal={DISPLAY_TOTAL.filter((el) => (_find(columns, { id: el }) ? true : false))}
                          groupingTitle={'ROUND IF VVS1'}
                          columns={columns}
                          row={row.original}
                        />
                      )}
                      {row.original.userGroupHold && (
                        <TableGrouping
                          totalDiamonds={row.original.totalDiamonds}
                          displayTotal={DISPLAY_TOTAL.filter((el) => (_find(columns, { id: el }) ? true : false))}
                          groupingTitle={this.getGroupTitle(row.original, {
                            user: this.props.sortUser,
                            date: this.props.sortDate,
                            country: this.props.sortCountry,
                            salesPerson: this.props.sortSalesPerson,
                          })}
                          columns={columns}
                          row={row.original}
                        />
                      )}
                      {row.original.isOfferHeader && (
                        <TableGrouping
                          groupingTitle={row?.original?.offerGrp}
                          isOfferPopup
                          toggleCollapse={() =>
                            this.props.toggleCollapse(row.original.collapsed ? false : true, row.original.offerGrp)
                          }
                          collapsed={row.original.collapsed}
                          calc={row.original.calc}
                          columns={columns}
                        />
                      )}
                      <TrRow {...this.props} row={row} index={row.index} openDrawer={(e) => this.setState(e)} />
                    </React.Fragment>
                  );
                })}
            </DndProvider>
          </tbody>
        </table>
      );
    };

    let filterRows = this.props.data;
    let selectedFilter = this.props.selectedFilterArray ? this.props.selectedFilterArray : {};
    selectedFilter.length &&
      Object.keys(selectedFilter).forEach((key) => {
        filterRows = filterRows?.filter((el) => selectedFilter[key]?.includes(el[key]));
      });

    return (
      <>
        <Table columns={columns} data={filterRows} />
        <NoDataFound loading={this.props.loading} length={this.props?.data?.length} />
        {this.state.visible && (
          <Drawer
            title={false}
            onClose={() => this.setState({ visible: false })}
            visible={true}
            wrapClassName="diamondDetailPopup"
          >
            <DiamondDetail columns={columns} data={this.state.visible} />
          </Drawer>
        )}
      </>
    );
  }
}

export default Table;
