import React from 'react';

import { cloneDeep, find, groupBy, isEmpty, last, pick } from 'lodash';
import moment from 'moment';

import TermsOfUse from 'components/TermsOfUse/TermsOfUse';

import { getAccountsListNew } from 'services/AccountApi';
import { getBroker, getCities, getCountries, getQuoteDay, getRateSettings, UserTerms } from 'services/CommonApi';
import { downloadPdfFile, getDiamondTrackUpdateStatus } from 'services/DiamondApi';
import { deleteOrder, statusChange } from 'services/OrderApi';
import { getSellerList } from 'services/SellerApi';
import { getUserList } from 'services/UserApi';
import Storage from 'services/storage';
import UtilService from 'services/util';

import { i18n } from 'util/i18n';
import { isFunction, isNotEmpty, isObject } from 'util/utils';

import { DIAMOND_BLOCK, MIME_TYPES, ORDER_STATUS, PROJECT_SETTINGS, USERS_TYPE } from 'constants/Common';
import CommonApi from 'constants/CommonApi';
import { DIAMOND_BID } from 'constants/Inventory';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

import { calculate, formatNumber, newDiamondPrice, quoteCalculation } from './SelectStone';

export const getSeller = async (cb) => {
  const [, res] = await getSellerList({ filter: { isActive: true } });
  if (res?.code === 'OK' && res?.data) {
    const sellerList = res.data.map((d) => ({ value: nameFormat(d.firstName, d.lastName), key: d.id }));
    cb(sellerList);
  } else {
    cb([]);
  }
};
export const nameFormat = (firstName = '', lastName = '') => {
  return `${firstName} ${lastName}`;
};

export const getCountryData = async (cb) => {
  const [, res] = await getCountries({});
  if (res?.code === 'OK' && res?.data?.list) {
    const countryList = res.data.list.map((d) => ({ value: d.name, key: d.id }));
    cb(countryList);
  } else {
    cb([]);
  }
};
export const getCityData = async (cb, country) => {
  const [, res] = await getCities({ country });
  if (res?.code === 'OK' && res?.data?.list) {
    const cityList = res.data.list.map((d) => ({ value: d.name, key: d.id }));
    cb(cityList);
  } else {
    cb([]);
  }
};

export const getClientData = async (cb, keyword, userId = null) => {
  if (keyword?.length < 3) {
    cb({ userAccount: null, user: null, accountList: [], userList: [], loading: false });
    return false;
  }
  cb({ loading: true });
  const user = Storage.get('user');
  const request = {
    ...(userId ? { filter: { user: userId } } : {}),
    page: 1,
    limit: 15,
    startWith: {
      keys: ['companyName', 'name', 'firstName', 'lastName', 'mobile'],
      keyword: keyword,
    },
    filter: { isActive: true },

    sort: [
      {
        createdAt: 'DESC',
      },
    ],
  };
  if (user?.type === USERS_TYPE.SELLER) {
    request.seller = user?.id;
  }
  const [, res] = await getAccountsListNew(request);

  if (res?.code === 'OK' && res?.data?.list) {
    cb({
      loading: false,
      // client: res.data.list,
      accountList: res.data.list,
    });
  } else {
    cb({ userAccount: null, user: null, userAccountList: [], userList: [], loading: false });
  }
};

export const getUserClientData = async (cb, keyword = null, account = null) => {
  if (keyword?.length < 3 && keyword != null) {
    cb({ userAccount: null, user: null, userAccountList: [], userList: [], loading: false });

    return false;
  }
  cb({ loading: true });
  // let user = Storage.get('user');

  const request = {
    ...(account ? { filter: { account } } : {}),
    page: 1,
    limit: 15,
    search: { ...(keyword ? { keyword } : {}), keys: ['companyName', 'name', 'firstName', 'lastName', 'mobile'] },
    sort: [
      {
        name: 'ASC',
      },
    ],
  };
  // if (user?.type === 8) {
  //   request.filter = { seller: user.id };
  // }
  const [, res] = await getUserList(request);

  if (res?.code === 'OK' && res?.data?.list) {
    cb({
      loading: false,
      //   client: res.data.list,
      userList: res.data.list,
      // accountList: res.data.list,
    });
  } else {
    cb({ userAccount: null, user: null, userAccountList: [], userList: [], loading: false });
  }
};
export const getBrokerData = async (cb, keyword) => {
  if (keyword?.length < 3) {
    cb({ brokerList: [] });
    return false;
  }
  const [, res] = await getBroker({
    page: 1,
    limit: 15,
    search: {
      keys: ['companyName'],
      keyword: keyword,
    },
    sort: [
      {
        createdAt: 'DESC',
      },
    ],
  });
  if (res?.code === 'OK' && res?.data) {
    const brokerList = res.data.map((d) => ({ value: d.companyName, key: d.id }));
    cb(brokerList);
  }
};

export const fetchPartyRelatedInfo = (client, mapId) => {
  //For party related other information fill
  const clientDetail = find(client, (d) => d?.id == mapId || d?.account?.id == mapId || d?.user?.id == mapId);
  const data = pick(clientDetail, ['city', 'country', 'seller', 'broker', 'brokerInfo', 'registrationType']);

  return {
    ...(data.registrationType ? { registrationType: data.registrationType } : {}),
    ...(data.city ? { shippingCity: data.city } : {}),
    ...(data.country ? { shippingCountry: data.country } : {}),
    ...(isObject(clientDetail?.broker) && clientDetail?.broker?.companyName
      ? {
          broker: clientDetail?.broker?.id,
          brokerList: [
            { ...clientDetail?.broker, value: clientDetail?.broker?.companyName, key: clientDetail?.broker?.id },
          ],
        }
      : {}),
    ...(!isEmpty(clientDetail?.seller)
      ? {
          seller: clientDetail?.seller?.id,
          assistantSeller: clientDetail?.seller?.id,
          // sellerList: [
          //   {
          //     ...data.seller,
          //     value: clientDetail?.seller?.name,
          //     key: clientDetail?.seller?.id,
          //   },
          // ],
        }
      : {}),
  };
};

export const isValue = (value) => {
  //for set place holder value
  return value ? value : undefined;
};

export const getFullClientData = async (cb, keyword) => {
  if (keyword?.length < 3) {
    cb({ clientList: [] });
    return false;
  }
  cb({ loading: true });
  const request = {
    page: 1,
    limit: 15,
    startWith: {
      keys: ['companyName', 'name', 'firstName', 'lastName', 'mobile'],
      keyword: keyword,
    },
    sort: [
      {
        createdAt: 'DESC',
      },
    ],
  };
  const user = Storage.get('user');
  if (user.type === 8) {
    request.filter = { seller: user.id };
  }
  const [, res] = await getAccountsListNew(request);

  if (res?.code === 'OK' && res?.data?.list) {
    cb({ loading: false });
    const clientList = res.data.list.map((d) => ({
      value: `${d.companyName} ${d?.user?.firstName || d?.user?.lastName ? `|` : ''} ${
        d?.user?.firstName ? d.user.firstName : ''
      } ${d?.user?.lastName ? d.user.lastName : ''}`,
      key: d?.user?.id,
      ...d,
    }));
    cb({ clientList, loading: false });
  }
};

export const fetchClientTerms = async (cb, client, checkedData, qt = false) => {
  let newPricedRows;
  let userTerms;
  if (client) {
    const [, res] = await UserTerms({ user: client });
    if (res?.data) {
      userTerms = res.data;
      newPricedRows = checkedData.map((x) => newDiamondPrice(x, userTerms, qt));
    }
  } else {
    userTerms = null;
    newPricedRows = checkedData.map((x) => newDiamondPrice(x, {}, qt));
  }
  cb({ newPricedRows, userTerms });
};

export function HeadingCalc(checked, inTab = null) {
  let quoteSum;
  const sum = calculate(checked);
  if (inTab === 'quoteApprove') {
    quoteSum = quoteCalculation(checked);
  }
  return (
    <div className="DiamondDetailPopup">
      <div className="DiamondDetailPopupItem">
        <span>CT. :</span>
        <span>{parseFloat(sum.total_carat).toFixed(2)}</span>
      </div>
      <div className="DiamondDetailPopupItem">
        <span>Disc% :</span>
        <span>{parseFloat(sum.final_discount).toFixed(2)}</span>
      </div>
      <div className="DiamondDetailPopupItem">
        <span>Price/Ct :</span>
        <span>{formatNumber(parseFloat(sum.final_price).toFixed(2))}</span>
      </div>
      {['note', 'orderApprove', 'quoteApprove', 'order', 'showStone', 'bid'].includes(inTab) && (
        <div className="DiamondDetailPopupItem">
          <span>Total Value :</span>
          <span>{formatNumber(parseFloat(sum.final_value).toFixed(2))}</span>
        </div>
      )}
      {inTab === 'quoteApprove' && (
        <>
          <div className="DiamondDetailPopupItem">
            <span>Discount Different : </span>
            <span>{parseFloat(quoteSum.discountDifferent).toFixed(2)}</span>
          </div>
          <div className="DiamondDetailPopupItem">
            <span>{i18n.t('title.offer')} : </span>
            <span>{parseFloat(quoteSum.offer).toFixed(2)}</span>
          </div>
          <div className="DiamondDetailPopupItem">
            <span>{i18n.t('title.offer')} value : </span>
            <span>{parseFloat(quoteSum.offerValue).toFixed(2)}</span>
          </div>
        </>
      )}
    </div>
  );
}

export function showFinalCalc(checked = [], terms = null, cmCharge = null, inFinalCal = false) {
  const newPricedRows = checked.map((x) => newDiamondPrice(x, terms, inFinalCal));
  if (cmCharge) {
    let charge = 0;
    newPricedRows.map((line) => {
      if (line.isCm && line.isCm === 'ELIG') {
        const found = cmCharge.filter((el) => el.from <= line.crt && (el.to ? el.to >= line.crt : true));
        if (found.length) charge += found[0].fee;
      }
      return true;
    });
    cmCharge = parseFloat(charge).toFixed(2);
  }
  const summation = calculate(newPricedRows);
  const final_term_amt = summation.after_amt_dis + summation.after_amt_dis * (terms.term / 100);
  const final_term_ctPr = final_term_amt / summation.total_carat;
  const final_term_disc = (1 - final_term_ctPr / summation?.final_rapaport) * -100;

  const diamond = {
    totalPieces: summation.total_pieces,
    totalCarat: parseFloat(summation.total_carat).toFixed(2),
    totalRap: parseFloat(summation.final_rapaport).toFixed(2),
    avgDisc: parseFloat(summation.final_discount).toFixed(2),
    totalPrCt: parseFloat(summation.final_price).toFixed(2),
    netValue: `$ ${formatNumber(parseFloat(summation.final_value).toFixed(2))}`,
    amtDisc: parseFloat(summation.amt_dis).toFixed(2),
    termDisc: parseFloat(terms.term).toFixed(2),
    finalDisc: parseFloat(final_term_disc).toFixed(2),
    finalNetRate: `$ ${formatNumber(parseFloat(final_term_ctPr).toFixed(2))}`,
    finalNetValue: `$  ${formatNumber(parseFloat(final_term_amt).toFixed(2))}`,
  };

  const diamondValues = [
    { id: 1, title: 'Total Pieces:', value: diamond.totalPieces },
    { id: 2, title: 'Total Carats:', value: diamond.totalCarat },
    { id: 3, title: 'Rap Price:', value: diamond.totalRap },
    { id: 4, title: 'Avg. Discount(%):', value: diamond.avgDisc },
    { id: 5, title: 'Total Pr/Ct:', value: diamond.totalPrCt },
    { id: 6, title: 'Net Value:', value: diamond.netValue },
    { id: 7, title: 'Amt Discount(%):', value: diamond.amtDisc },
    { id: 8, title: 'term Discount(%):', value: diamond.termDisc },
    { id: 9, title: 'Final Discount(%):', value: diamond.finalDisc },
    { id: 10, title: 'Final Net Rate:', value: diamond.finalNetRate },
    {
      id: 11,
      title: 'Final Net Value',
      value: diamond.finalNetValue + (cmCharge > 0 ? ' + $' + cmCharge + ' (CM Charges)' : ''),
    },
  ];
  return [diamondValues, newPricedRows];
}

export const quoteActionHandel = async (cb, checked, btnName, extraRequest = {}) => {
  const status = {
    approve: DIAMOND_BLOCK.OFFER.ACCEPTED,
    reject: DIAMOND_BLOCK.OFFER.REJECTED,
  };
  const request = {
    ...(!isEmpty(extraRequest) && extraRequest),
    id: checked.map((d) => d.blockId),
    offerStatus: status[btnName],
  };
  const [, res] = await getDiamondTrackUpdateStatus(request);
  if (res.code === 'OK') {
    cb('success');
    OpenNotification({ type: 'success', title: res.message });
  } else {
    cb('error');
  }
};
export const checkClientName = (diamond, cb = null) => {
  const userId = groupBy(diamond, 'user.id');
  if (diamond?.length === 1 || Object.keys(userId).length === 1) {
    if (cb !== null) cb({ client: diamond[0]?.user });
    return true;
  } else {
    OpenNotification({ type: 'error', title: Messages.sameClientSelect });
    return false;
  }
};

export function getStoneInfo(props_row) {
  const params = [
    props_row.vStnId || props_row.stoneId,
    props_row.shpNm,
    parseFloat(props_row.crt).toFixed(2),
    props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    props_row.fluNm,
  ].filter((el) => {
    return el && el;
  });
  return params;
}

export const getBankRate = async (rateType, cb) => {
  const [, res] = await getRateSettings({
    filter: {
      type: PROJECT_SETTINGS.TYPE[rateType],
    },
  });
  if (res.code === 'OK' && res.data) {
    cb(res.data.data);
  }
};

export const handlePrint = (diamond, cb) => {
  if (!diamond) diamond = [];
  const request = { filter: {} };
  const selectedIds = diamond.map((o) => o.id);
  if (!isEmpty(selectedIds)) {
    request.id = selectedIds;
    downloadPdfFile(request, cb);
  } else {
    if (isFunction(cb)) cb();
    OpenNotification({ type: 'error', title: 'Please select stone(s).' });
  }
};
export const getQuoteDayList = async (cb) => {
  const request = {
    isDeleted: false,
    sort: [{ name: 'ASC' }],
  };
  const [, res] = await getQuoteDay(request);
  if (res.code === 'OK' && res.data?.list?.length) {
    const quoteDayList = res.data.list.map((d) => ({
      value: d?.name,
      key: d?.id,
    }));
    cb(quoteDayList);
  }
};
export function getBidType() {
  let bidType = 0;
  if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) && moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)) {
    bidType = DIAMOND_BID.TYPE.OPEN;
  } else if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) || moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)) {
    bidType = DIAMOND_BID.TYPE.BLIND;
  }
  return bidType;
}
function getBidStartTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(11, 'hours');
  else return moment().startOf('day').add(15, 'hours');
}

function getBidEndTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(14, 'hours').add(59, 'minutes');
  else return moment().startOf('day').add(10, 'hours').add(59, 'minutes');
}

export const orderHandel = async (cb, checked, btnName, otherRequestData = {}) => {
  const status = {
    approve: ORDER_STATUS.APPROVE,
    reject: ORDER_STATUS.REJECTED,
  };

  const diamonds = checked.map((stone) => stone?.diamondId).filter(isNotEmpty);
  const [, res] = await statusChange({
    ...otherRequestData,
    id: checked[0].orderId,
    status: status[btnName],
    diamonds,
  });
  if (res.code === 'OK') {
    cb('success');
    OpenNotification({ type: 'success', title: res.message });
  } else {
    cb('error');
  }
};

export function onUpload(ee, cb) {
  const e = cloneDeep(ee);
  if (!e.target.files[0]) return;
  const fileType = e.target.files[0] ? last(e.target.files[0].name.split('.')) : '';
  const filereader = new FileReader();
  filereader.addEventListener('load', (event) => {
    const arr = new Uint8Array(event.target.result).subarray(0, 4);
    let header = '';
    for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }
    if ((MIME_TYPES[fileType] && MIME_TYPES[fileType].includes(header)) || fileType === 'csv') {
      const uploadedFile = e.target.files[0];
      const getExtension = uploadedFile.name.split('.').pop();
      const validExt = ['xlsx', 'xls'].includes(getExtension);
      if (!validExt) {
        OpenNotification({
          type: 'error',
          title: 'Invalid File.',
        });
        cb();
      } else {
        const obj = CommonApi.Offer.UploadExcel;
        const folder = new FormData();
        folder.append('file', e.target.files[0]);
        obj.request = folder;
        UtilService.callApi(obj, (err, data) => {
          if (data && data.code === 'OK') {
            OpenNotification({
              type: 'success',
              title: data.message,
            });
          }
          cb(data && data.code === 'OK');
        });
      }
    } else {
      OpenNotification({
        type: 'error',
        title: 'Invalid File.',
      });
      cb();
    }
  });
  filereader.readAsArrayBuffer(e.target.files[0]);
  // const e = cloneDeep(ee);
  // var blob = e.target.files[0];
  // var fileReader = new FileReader();
  // fileReader.onloadend = function (ev) {
  //   var arr = new Uint8Array(ev.target.result).subarray(0, 4);
  //   var header = '';
  //   var headerVal = '';
  //   for (var i = 0; i < arr.length; i++) {
  //     headerVal = arr[i];
  //     if (arr[i].constructor.name === 'Number' && arr[i] < 10) {
  //       headerVal = ('0' + arr[i]).slice(-2);
  //     }
  //     header += headerVal.toString(16);
  //   }
  //   if (!MIME_TYPES[blob.type] || (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))) {
  //     OpenNotification({
  //       type: 'error',
  //       title: `File format is not supported. Please upload .xls, .xlsx file.`,
  //     });
  //   } else {
  //     console.log('eeeeeeeeeee', e);
  //     let obj = CommonApi.Offer.UploadExcel;
  //     obj.request = {
  //       file: '',
  //     };
  //     // const data = new FormData();
  //     // data.append('folder', folder);
  //     // data.append('destination', folder);
  //     // data.append('file', e.target.files[0]);
  //     // let objData = API_ROUTES.File.Upload;
  //     // objData.request = data;
  //     UtilService.callApi(obj, function (err, data) {
  //       console.log('resssssssssssss', err, data);
  //       if (err) throw err;
  //       if (data && data.code === 'OK') {
  //         if (data.data.files && data.data.files[0] && data.data.files[0].absolutePath) {
  //           // let filePath = data.data.files[0].absolutePath;
  //           cb();
  //         }
  //       }
  //     });
  //   }
  // };
  // fileReader.readAsArrayBuffer(blob);
}
