import moment from 'moment';

import { COMMON_URL } from 'constants/CommonUrl';
import { DIAMOND_BID } from 'constants/Inventory';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';
import Storage from 'services/storage';
import { isEmpty, isFunction, isString } from 'util/utils';
import { CacheStore } from 'util/CacheStore';

const dateTimeCache = new CacheStore('DateTime');

/**
 *Function used to get current login info
 */
export const getLoginUser = () => Storage.get(`user`);

export const loginUserId = Storage.get(`user`)?.id;

export const getDefaultClient = () => Storage.get(`default-client`);

export const defaultClientId = Storage.get(`default-client`)?.id;

export const getDate = (date, format = 'DD/MM/YYYY') => {
  const cache = isString(date);
  if (cache) {
    const cache = dateTimeCache.get(`format-${date}-${format}`);
    if (!isEmpty(cache)) return cache;
  }
  const output = moment(date).format(format);
  if (cache) dateTimeCache.set(`format-${date}-${format}`, output);
  return output;
};

export const getDateTime = (dateTime, format = 'DD/MM/YYYY hh:mm A') => getDate(dateTime, format);

export const getDayDiff = (startDate, endDate) => {
  const cache = isString(startDate) && isString(endDate);
  if (cache) {
    const cache = dateTimeCache.get(`diff-${startDate}-${endDate}-day`);
    if (!isEmpty(cache)) return cache;
  }
  const output = moment(endDate).diff(moment(startDate), 'days');
  if (cache) dateTimeCache.set(`diff-${startDate}-${endDate}-day`, output);
  return output;
};

export const getOfficeViewDate = (start, end) => {
  const cache = isString(start) && isString(end);
  if (cache) {
    const cache = dateTimeCache.get(`officeView-${start}-${end}-date`);
    if (!isEmpty(cache)) return cache;
  }
  const output = `${getDate(start)} - [${getDate(start, 'hh:mm A')} - ${getDate(end, 'hh:mm A')}]`;
  if (cache) dateTimeCache.set(`officeView-${start}-${end}-date`, output);
  return output;
};

// Bid function
export const getBidStartTime = (bidType) => {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(11, 'hours');
  else return moment().startOf('day').add(15, 'hours');
};

export const getBidEndTime = (bidType) => {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(14, 'hours').add(30, 'minutes');
  else return moment().startOf('day').add(10, 'hours').add(50, 'minutes');
};

export const getBidEndCounter = (bidType) => {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(14, 'hours').add(30, 'minutes');
  else if (moment() < getBidStartTime(DIAMOND_BID.TYPE.OPEN))
    return moment().startOf('day').add(10, 'hours').add(50, 'minutes');
  else return moment().startOf('day').add(1, 'days').add(10, 'hours').add(50, 'minutes');
};

export const getBidType = () => {
  let bidType = 0;
  if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) && moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)) {
    bidType = DIAMOND_BID.TYPE.OPEN;
  } else if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) || moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)) {
    bidType = DIAMOND_BID.TYPE.BLIND;
  }
  return bidType;
};

/**
 * Function used to validate clienti
 * @param {*} props
 */
export const validateClient = (props) => {
  if (!isEmpty(props?.client)) return true;
  OpenNotification({ type: 'error', title: Messages.Error.Required.Company });
  return false;
};

/**
 * Function used to validate selected item
 * @param {*} props
 */
export const validateCheckedItems = (props) => {
  if (!isEmpty(props?.checked)) return true;
  OpenNotification({ type: 'error', title: Messages.Error.Required.Stones });
  return false;
};

export const downloadExcelFile = async (err, res, cb) => {
  if (!err && res && res.code === 'OK') {
    let server = COMMON_URL.EXCELFILE_URL;
    let filePath = server + res?.data?.data;
    window.open(filePath, 'Download');
    if (isFunction(cb)) cb(true);
    return true;
  } else {
    if (isFunction(cb)) cb(false);
    OpenNotification({
      type: 'error',
      title: res?.message,
    });
    return false;
  }
};
