import React, { Component } from 'react';
import { isEmpty, findIndex, replace } from 'lodash';
import moment from 'moment';

import SelectOption from 'containers/SelectOption';
import TextArea from 'containers/TextArea';
import InputBlock from 'containers/InputBlock';
import FileUpload from 'containers/FileUpload';
import BottomStickyButton from 'components/ShortCuts/BottomStickButton';

import { approveStatusLabel, LEDGER_TYPE } from 'constants/Common';
import { approveStatus } from 'constants/Common';
import { isArray, isString } from 'util/utils';
import Storage from 'services/storage';
import deleteSvg from 'assets/svg/delet.svg';
import downloadSvg from 'assets/svg/download.svg';
import { Popconfirm } from 'antd';
import OpenNotification from 'constants/OpenNotifications';
import { COMMON_URL } from 'constants/CommonUrl';

export function PrintTable({ docs, statusList, onDelete, removeFrom }) {
  const getDocumentType = (docType) => {
    let find = statusList?.find((item) => item.id == docType);
    return find?.name ?? '-';
  };

  const downloadDoc = (docs) => {
    isString(docs) && window.open(`${COMMON_URL.BASE_URL}/${docs}`);
  };

  return (
    <table width="100%">
      <tr>
        <th>Document Type</th>
        <th>Status</th>
        <th>Info</th>
        <th>Remark</th>
        {/* <th>Image</th> */}
        <th>Action</th>
      </tr>
      {!isEmpty(docs) ? (
        docs?.map((item, key) => (
          <tr key={key}>
            <td>{getDocumentType(item?.docType)}</td>
            <td>{approveStatusLabel?.[item?.status] ?? '-'}</td>
            <td>{item?.info ?? '-'}</td>
            <td>{item?.remark ?? '-'}</td>
            {/* <td>{<Image src={`${COMMON_URL.BASE_URL}${item?.path}`} width="80px" />}</td> */}
            <td>
              <div className="actionButton">
                <Popconfirm
                  placement="left"
                  title={`Are you sure you want to delete this Document?`}
                  okText={`Delete`}
                  onConfirm={() => onDelete(item?.docType, removeFrom)}
                >
                  <div className="actionAction">
                    <img src={deleteSvg} width="10" />
                  </div>
                </Popconfirm>
                {!isEmpty(item?.path) && (
                  <div className="actionAction" onClick={() => downloadDoc(item?.path)}>
                    <img src={downloadSvg} width="10" />
                  </div>
                )}
              </div>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="5" className="center">
            No Docs Uploaded
          </td>
        </tr>
      )}
    </table>
  );
}

class BusinessDetail extends Component {
  state = {
    formData: {},
    errors: {},
    accountId: '',
    saveContinue: false,
  };

  masters = undefined;
  kycStatus = { NatureOrganization: undefined, businessTypes: undefined };

  getKycMaster = () => {
    this.masters = Storage.get('dn-master');
    this.kycStatus = {
      NatureOrganization: this.masters?.NATURE_OF_ORG?.map((item) => ({ ...item, id: item.id[0] })),
      businessTypes: this.masters?.BUSINESS_TYPE?.map((item) => ({ ...item, id: item.id[0] })),
      personalDoc: this.masters?.DOC_TYPE_PERSONAL?.map((item) => ({ ...item, id: item.id[0] })),
      businessDoc: this.masters?.DOC_TYPE_BUSINESS?.map((item) => ({ ...item, id: item.id[0] })),
    };
  };

  handleInputChange = (key, value) => {
    if (key === 'founded' && value.isAfter(moment().endOf('year'))) return;
    if (key === 'age' && value.length > 3) return;
    let formData = this.state.formData;
    formData[key] = value;

    this.setState({ formData: formData });
  };

  handleKycChange = (name, value, key) => {
    const formData = this.state.formData;
    // const kyc = [...formData.kyc];
    if (name === 'path' && isEmpty(value)) return;

    if (
      name == 'docType' &&
      formData[key == 0 ? 'personalDocs' : 'businessDocs']?.find((item) => item.docType == value)
    ) {
      return OpenNotification({ type: 'error', title: 'Document already exist' });
    }

    if (key == 0) {
      this.setState({ formData: { ...formData, personal: { ...formData?.personal, [name]: value } } });
    } else if (key == 1) {
      this.setState({ formData: { ...formData, business: { ...formData?.business, [name]: value } } });
    }
    // kyc[key == 0 ? 'personal' : 'business'][name] = value;
    // console.log(kyc);
    // this.setState({ kyc }, () => console.log('update state', this.state));
  };

  handleDelete = (key) => {
    let formData = this.state.formData;
    formData[key] = {};
    this.setState({ formData: formData });
  };

  handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    // let data = { ...this.state.formData };

    // if (!data['businessType']) {
    //   formIsValid = false;
    //   errors['businessType'] = 'Please select business type.';
    // }
    // if (!data['natureOfOrg']?.length) {
    //   formIsValid = false;
    //   errors['natureOfOrg'] = 'Please select nature of organization.';
    // }

    this.setState({ errors: errors });
    return formIsValid;
  };

  handleSubmit = async (e) => {
    if (e?.preventDefault) e.preventDefault();

    if (this.handleValidation()) {
      const formData = this.state.formData;
      Object.keys(formData).forEach((key) => {
        const value = formData[key];
        if (key === 'regCert' || key === 'panCert' || key === 'gstCert') {
          const num = replace(key, 'Cert', 'No');
          const obj = {
            documentType: key,
            path: value.absolutePath,
            detail: { [num]: formData[num] || '' },
          };
          if (!formData.kyc || !formData.kyc.length || formData.kyc.length === 0) {
            formData.kyc = [obj];
          } else {
            const indx = findIndex(formData.kyc, (k) => k.documentType === key);
            if (indx >= 0) {
              formData.kyc[indx] = obj;
            } else {
              formData.kyc.push(obj);
            }
          }
          delete formData[num];
          delete formData[key];
        }

        if (key === 'founded') {
          formData[key] = moment(value).format('YYYY');
        }

        // if (!formData[key] || isEmpty(formData[key])) {
        //   delete formData[key];
        // }
      });

      const data = (() => {
        const { natureOfOrg, businessType, ledgerType, personalDocs, businessDocs, social } = formData;
        return { natureOfOrg, businessType, ledgerType, personalDocs, businessDocs, social };
      })();

      data.id = this.props?.account?.id;
      data.isDefault = this.props?.account.isDefault && data?.ledgerType?.includes('broker') ? true : false;
      data.isBrokerDefault = data.isDefault;
      data.ledgerType = data?.ledgerType?.join(',');
      data.kyc = data.personalDocs.concat(data.businessDocs);

      data.isApproved = data?.kyc?.filter((item) => `${item?.status}` === '3')?.length
        ? 3
        : data?.kyc?.filter((item) => `${item?.status}` == '1' || !item?.status)?.length
        ? 1
        : data?.kyc?.filter((item) => `${item?.status}` == '2')?.length == data?.kyc?.length
        ? 2
        : 3;

      if (data?.kyc)
        data.kyc = data?.kyc?.map((item) => {
          item.status = item?.status ? item.status : Number(1);
          return item;
        });

      const [err, res] = await this.props.updateAccount(formData.id, data);
      if (!err && res?.code === 'OK') {
        this.state.saveContinue ? this.props.updateSubTabIndex() : this.props.onCancel();
      }
      // this.setState({ formData: {} });
    }
  };

  componentDidMount() {
    this.getInitialValues();
    this.getKycMaster();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account?.id !== this.props.account?.id) {
      this.getInitialValues();
      this.getKycMaster();
    }
  }

  initialValues = {
    natureOfOrg: undefined,
    businessType: undefined,
    ledgerType: undefined,
    social: {},
    kyc: [],
    defaultDocs: [
      { status: '1', type: 'personal' },
      { status: '1', type: 'business' },
    ],
    pesonal: {},
    business: {},
    personalDocs: [],
    businessDocs: [],
  };

  getInitialValues = () => {
    const account = { ...this.props.account };

    if (!account) return this.setState({ formData: this.initialValues });
    account.businessType = account.businessType?.id;
    account.ledgerType = account?.ledgerType?.split(',');

    if (!isEmpty(account?.kyc)) {
      account.personalDocs = account.kyc.filter((item) => item.type == 'personal');
      account.businessDocs = account.kyc.filter((item) => item.type == 'business');
    }

    this.setState({
      accountId: account?.id,
      formData: Object.fromEntries(
        Object.entries(this.initialValues).map(([key, value]) => [
          key,
          isEmpty(account?.[key]) ? value : account?.[key],
        ]),
      ),
    });
  };

  deleteDocument = (docType, type) => {
    let formData = this.state.formData;
    let docs = formData?.[type]?.filter((item) => item.docType != docType);

    this.setState({
      formData: { ...formData, [type]: docs },
    });
  };

  render() {
    let { formData, errors } = this.state;

    return (
      <div {...this.props}>
        <div className="kycFormMainBlock">
          <div className="kycBlockWrapper mb-20">
            <SelectOption
              placeholder="Business Type"
              selectValue={this.kycStatus.businessTypes}
              onChange={(e) => {
                this.handleInputChange('businessType', e || null);
              }}
              label="Business Type"
              value={
                !isEmpty(formData?.businessType) &&
                this.kycStatus.businessTypes?.filter((item) => item.id == formData?.businessType).length
                  ? `${formData?.businessType}`
                  : undefined
              }
              valueKey="id"
              nameKey="name"
              error={errors?.businessType?.length > 0 && errors?.businessType}
            />
            <SelectOption
              className="multiSelect"
              selectValue={this.kycStatus.NatureOrganization}
              label="Nature of Organization"
              placeholder="Nature of Organization"
              // mode="multiple"
              valueKey="id"
              nameKey="name"
              name="natureOfOrg"
              value={
                // formData?.natureOfOrg
                isString(formData?.natureOfOrg) &&
                this.kycStatus.NatureOrganization?.filter((item) => formData?.natureOfOrg?.includes(item.id)).length > 0
                  ? this.kycStatus.NatureOrganization?.filter((item) => formData?.natureOfOrg?.includes(item.id)).map(
                      (item) => item.id,
                    )
                  : undefined
              }
              onChange={(e) => this.handleInputChange('natureOfOrg', e)}
              error={errors?.natureOfOrg?.length > 0 && errors?.natureOfOrg}
            />
            <SelectOption
              className="multiSelect"
              selectValue={LEDGER_TYPE}
              label="Ledger Type"
              placeholder="Ledger Type"
              mode="multiple"
              name="ledgerType"
              value={formData?.ledgerType}
              onChange={(e) => this.handleInputChange('ledgerType', e)}
              error={errors?.ledgerType?.length > 0 && errors?.ledgerType}
            />
            <InputBlock
              placeholder="RapNet ID"
              label="RapNet ID"
              type="text"
              value={formData?.social?.rapNetId}
              onChange={(e) => {
                this.handleInputChange('social', { ...formData?.social, rapNetId: e.target.value });
              }}
            />
            <InputBlock
              placeholder="QQ ID"
              label="QQ ID"
              type="text"
              value={formData?.social?.qqId}
              onChange={(e) => {
                this.handleInputChange('social', { ...formData?.social, qqId: e.target.value });
              }}
            />
            <InputBlock
              placeholder="Linked In"
              label="Linked In"
              type="text"
              value={formData?.social?.linkedin}
              onChange={(e) => {
                this.handleInputChange('social', { ...formData?.social, linkedin: e.target.value });
              }}
            />
          </div>

          {this.state.formData?.defaultDocs?.map((doc, i) => {
            let statusList = i == 0 ? this.kycStatus.personalDoc : this.kycStatus.businessDoc;
            let kycLabel = i == 0 ? 'Personal' : 'Business';
            let docs = i == 0 ? this.state.formData?.personalDocs : this.state.formData?.businessDocs;
            doc = i == 0 ? this.state.formData?.personal : this.state.formData?.business;

            return (
              <>
                <div key={i} className="mb-20">
                  <div className="formSubTitle">{kycLabel} Document</div>
                  <div className="document_table">
                    {
                      <PrintTable
                        docs={docs}
                        statusList={statusList}
                        removeFrom={i == 0 ? 'personalDocs' : 'businessDocs'}
                        onDelete={this.deleteDocument}
                      />
                    }
                  </div>
                  <div className="formMainSubBlock">
                    <div className="d-flex flex-wrap mb-10 kycBlockWrapper">
                      <SelectOption
                        placeholder="Document Type"
                        label="Document Type"
                        selectValue={statusList}
                        onChange={(e) => this.handleKycChange('docType', e, i)}
                        value={doc?.docType}
                        valueKey="id"
                        nameKey="name"
                      />
                      <SelectOption
                        placeholder="Status"
                        label="Approve Status"
                        selectValue={approveStatus}
                        onChange={(e) => this.handleKycChange('status', e, i)}
                        value={doc?.status ? `${doc?.status}` : '1'}
                      />
                      <InputBlock
                        placeholder="Document Info"
                        label="Document Info"
                        type="text"
                        value={doc?.info ?? ''}
                        onChange={(e) => {
                          this.handleKycChange('info', e.target.value, i);
                        }}
                      />
                      <TextArea
                        label="Remark"
                        onChange={(e) => {
                          this.handleKycChange('remark', e.target.value, i);
                        }}
                        value={doc?.remark ?? ''}
                      />

                      <div className="fileUploadedBlock d-flex from-group">
                        <FileUpload
                          name="mobileImage"
                          label="Upload Mobile Image"
                          isValidate
                          accept="JPG,JPEG,PNG,GIF"
                          size="JPG,JPEG,PNG,GIF file allowed"
                          files={isString(doc?.path) ? [doc?.path] : doc?.path}
                          onChange={(images) => this.handleKycChange('path', images, i)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kycNewBlockAdd">
                  <button
                    className="commonButton"
                    type="button"
                    role="button"
                    onClick={() => {
                      isEmpty(this.state.formData?.[i == 0 ? 'personal' : 'business']?.docType)
                        ? OpenNotification({ type: 'error', title: 'Please select document' })
                        : this.setState(
                            {
                              formData: {
                                ...this.state.formData,
                                [i == 0 ? 'personalDocs' : 'businessDocs']: [
                                  ...this.state.formData?.[i == 0 ? 'personalDocs' : 'businessDocs'],
                                  ...(i == 0
                                    ? [{ ...this.state.formData?.personal, type: 'personal' }]
                                    : [{ ...this.state.formData?.business, type: 'business' }]),
                                ],
                              },
                            },
                            () => {
                              this.setState({
                                formData: { ...this.state.formData, [i == 0 ? 'personal' : 'business']: {} },
                              });
                            },
                          );
                    }}
                  >
                    Add
                  </button>
                </div>
              </>
            );
          })}
        </div>
        <BottomStickyButton className={!this.props?.account?.id && 'disableBlock'}>
          <button
            className="commonButton"
            type="button"
            onClick={() => this.setState({ saveContinue: true }, this.handleSubmit)}
          >
            Save & Continue
          </button>
          <button
            className="commonButton"
            type="button"
            onClick={() => this.setState({ saveContinue: false }, this.handleSubmit)}
          >
            Save
          </button>
          <button className="commonOutine" type="reset" onClick={this.props.onCancel}>
            Cancel
          </button>
        </BottomStickyButton>
      </div>
    );
  }
}

export default BusinessDetail;
