import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { showNotification } from 'services/NotificationService';
import { isEmpty, isObject } from 'util/utils';
import { getSetting, loginSetting } from 'services/CommonApi';
import { SETTING_TYPE, HOME_SETTING_FORMAT } from 'constants/Common';
import { Checkbox } from 'antd';
import MultiUserDropdown from 'components/User/MultiUserDropdown';
import TextArea from 'containers/TextArea';
import DatePickerInput from 'containers/DatePickerInput';
import moment from 'moment';

const defaultValues = {
  untildate: '',
  isactive: false,
  exceptions: [],
  message: [],
};

const validationSchema = Yup.object().shape({
  untildate: Yup.string().nullable(),
});

function LoginSetting() {
  const [setting, setSetting] = React.useState({});
  const [userList, setUserList] = React.useState([]);

  const initialValues = React.useMemo(() => {
    const data = Object.fromEntries(
      Object.entries(defaultValues).map(([key, value]) => [
        key,
        isEmpty(setting?.[key])
          ? value
          : key == 'untildate' && !isEmpty(setting?.[key])
          ? moment(setting?.[key])
          : setting?.[key],
      ]),
    );

    return data;
  }, [setting]);

  const getList = React.useCallback(async () => {
    const [, res] = await getSetting({ type: SETTING_TYPE.LOGIN_RESTRICTION });

    if (res.code === 'OK' && isObject(res?.data?.settings)) {
      setUserList(res?.data?.settings?.exceptionUsers);

      let settingsData = res?.data?.settings;
      settingsData.exceptions = settingsData.exceptionUsers.map(({ id }) => id);
      setSetting(settingsData);
    }
  }, []);

  React.useEffect(() => {
    getList();
  }, [getList]);

  const submitForm = React.useCallback(async (values, { setSubmitting }) => {
    setSubmitting(true);
    const [err, res] = await loginSetting(values);

    setSubmitting(false);
    !err && showNotification(res);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({ touched, errors, values, setValues, setFieldValue, setFieldTouched }) => (
        <Form>
          <div className="d-flex homeSettingWrapper mt-20 loginSetting">
            <div className="checkBoxForm">
              <label> Active </label>
              <Checkbox
                onChange={() => setValues((values) => ({ ...values, isactive: !values?.isactive }))}
                checked={values?.isactive}
              />
            </div>
            <div className="checkBoxForm datePick">
              {/* <label>Untill Active Date</label> */}
              {/* <DatePickerInput
                onChange={(date) => setFieldValue('untildate', moment(date).format('YYYY-MM-DD h:mm:ss a'))}
                format="DD/MM/YYYY hh:mm A"
              /> */}
              <DatePickerInput
                className="w-50"
                label="Untill Active Date"
                value={values.untildate}
                onOpenChange={(open) => !open && setFieldTouched('untildate')}
                onChange={(untildate) => setValues((values) => ({ ...values, untildate }))}
                error={touched?.untildate && errors?.untildate}
                format={HOME_SETTING_FORMAT}
                noPast
                showTime
              />
            </div>

            <div className="userDropdown">
              <MultiUserDropdown
                label="Users*"
                name="attendees"
                mode="multiple"
                className="multiSelect w-50"
                placeholder="Select Users"
                options={userList}
                value={values?.exceptions}
                onChange={(value) => setFieldValue('exceptions', value)}
                error={touched?.exceptions && errors?.exceptions}
                required
              />
            </div>

            <TextArea
              label="Message"
              placeholder="Enter Message"
              onChange={(event) => setFieldValue('message', event.target.value)}
              value={values?.message}
            />

            <div className="width-100 d-flex mt-20">
              <button className="commonButton mr-10" type="submit" value="Submit">
                Save
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default LoginSetting;
