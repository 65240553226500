import React, { Component } from 'react';
import { compose } from 'redux';
import { Menu, Dropdown, Drawer } from 'antd';
import { NavLink, withRouter } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';

import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
import ClientSidebar from 'components/ShortCuts/ClientDrawer';
import UserDrawer from 'components/ShortCuts/UserDrawer';
import AddKYC from 'components/ShortCuts/KYC';

import { THEME_TYPE_LITE } from 'constants/ThemeSetting';

import { ReactComponent as Dashboard } from 'assets/svg/dashboard/dashboard.svg';
import { ReactComponent as Master } from 'assets/svg/dashboard/master.svg';
import { ReactComponent as Account } from 'assets/svg/dashboard/account.svg';
import { ReactComponent as User } from 'assets/svg/dashboard/user.svg';
import { ReactComponent as Diamond } from 'assets/svg/dashboard/diamond.svg';
import { ReactComponent as Stock } from 'assets/svg/dashboard/stock.svg';
import { ReactComponent as Setting } from 'assets/svg/dashboard/setting.svg';
import { ReactComponent as Enquiry } from 'assets/svg/dashboard/enquiry.svg';
import { ReactComponent as Transactions } from 'assets/svg/dashboard/transactions.svg';
import { ReactComponent as Price } from 'assets/svg/dashboard/price.svg';
import { ReactComponent as Feedback } from 'assets/svg/dashboard/feedback.svg';
import { ReactComponent as Media } from 'assets/svg/dashboard/media.svg';
import { ReactComponent as Cabin } from 'assets/svg/dashboard/cabin.svg';
import { ReactComponent as Stocksummary } from 'assets/svg/dashboard/stock-summary.svg';
import { ReactComponent as Appointment } from 'assets/svg/dashboard/appointment.svg';
import { ReactComponent as ParcelMaster } from 'assets/svg/dashboard/parcel-master.svg';
import { ReactComponent as Notification } from 'assets/svg/dashboard/notification.svg';
import { ReactComponent as Reports } from 'assets/svg/dashboard/reports.svg';
import { ReactComponent as Pricing } from 'assets/svg/dashboard/pricing.svg';
import { ReactComponent as Client } from 'assets/svg/dashboard/addClient.svg';
import { ReactComponent as AddUser } from 'assets/svg/dashboard/addUser.svg';
import { ReactComponent as Todo } from 'assets/svg/dashboard/todo.svg';
import { ReactComponent as Bid } from 'assets/svg/dashboard/notification.svg';
import { getPermittedMainModuleList, MAINMODULE, MODULES, getLoginPermission } from 'constants/permission';
// import { ReactComponent as KYC } from 'assets/svg/dashboard/Kyc.svg';

const Icons = {
  Dashboard: <Dashboard />,
  Master: <Master />,
  Account: <Account />,
  User: <User />,
  Diamond: <Diamond />,
  Stock: <Stock />,
  Setting: <Setting />,
  // Enquiry: <Enquiry />,
  Price: <Price />,
  Feedback: <Feedback />,
  Media: <Media />,
  Cabin: <Cabin />,
  Stocksummary: <Stocksummary />,
  Appointment: <Appointment />,
  ParcelMaster: <ParcelMaster />,
  Transactions: <Transactions />,
  Notification: <Notification />,
  Reports: <Reports />,
  Pricing: <Pricing />,
  Todo: <Todo />,
  Bid: <Bid />,
};

function getShortcut(mainModule, subModule) {
  let PermissionData = getLoginPermission();
  let MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.module === subModule)
    .filter((x) => x.permissions.insert || x.permissions.all)
    .map((x) => x.module);

  return MenuData;
}

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.setClassProps();
  }

  componentDidUpdate() {
    this.setClassProps();
  }

  setClassProps() {
    this.menuData = getPermittedMainModuleList();

    this.addKyc = Boolean(getShortcut(MAINMODULE.SHORTCUT, MODULES.SHORTCUT.KYC.NAME)?.length);
    this.addUser = Boolean(getShortcut(MAINMODULE.SHORTCUT, MODULES.SHORTCUT.USER.NAME)?.length);
  }

  state = { collapsed: false, client: false, user: false, sales: false, purchase: false, kyc: false };

  openClientDrawer = () => this.setState({ client: true });
  openUserDrawer = () => this.setState({ user: true });
  openSalesDrawer = () => this.setState({ sales: true });
  openPurchaseDrawer = () => this.setState({ purchase: true });
  openKYCDrawer = () => this.setState({ kyc: true });
  closeDrawers = () => this.setState({ client: false, user: false, sales: false, purchase: false, kyc: false });

  menu = () => {
    return (
      <Menu className="shortCutMenu">
        <h2>Add</h2>
        <Menu.Divider />
        {this.addKyc && (
          <Menu.Item key="1" onClick={this.openKYCDrawer}>
            <Client />
            Clients / KYC
          </Menu.Item>
        )}
        {this.addUser && (
          <Menu.Item key="2" onClick={this.openUserDrawer}>
            <AddUser />
            User
          </Menu.Item>
        )}
      </Menu>
    );
  };

  render() {
    const { pathname } = this.props.location;
    const themeType = THEME_TYPE_LITE;

    return (
      <React.Fragment>
        <div className="gx-sidebar-content">
          <SidebarLogo />
          <ScrollArea
            speed={0.8}
            className="settingLeftScroll area sideBarScroll"
            contentClassName="content"
            smoothScrolling
          >
            <Menu mode="inline" theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}>
              {this.menuData.map((menu) => {
                return (
                  <Menu.Item
                    className={pathname.includes(menu.path) ? 'ant-menu-item-selected' : ''}
                    key={menu.path && menu.path.replace('/', '')}
                  >
                    <NavLink to={menu.path}>
                      {Icons?.[menu?.svg]}
                      <span className="vertical-menu">{menu.name}</span>
                    </NavLink>
                  </Menu.Item>
                );
              })}
            </Menu>
          </ScrollArea>
        </div>
        <div className="gx-sidebar-content">
          {(this.addKyc || this.addUser) && (
            <Menu mode="inline">
              <Dropdown
                overlay={this.menu}
                trigger={['click']}
                overlayClassName="shortCutPopup"
                placement="bottomCenter"
              >
                <Menu.Item>
                  <svg
                    id="Capa_1"
                    enableBackground="new 0 0 512 512"
                    height="512"
                    viewBox="0 0 512 512"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="m446.605 124.392-119.997-119.997c-2.801-2.802-6.624-4.395-10.608-4.395h-210c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45v-332c0-4.09-1.717-7.931-4.395-10.608zm-115.605-73.179 68.787 68.787h-53.787c-8.271 0-15-6.729-15-15zm75 430.787h-300c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h195v75c0 24.813 20.187 45 45 45h75v317c0 8.271-6.729 15-15 15z" />
                      <path d="m346 212h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                      <path d="m346 272h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                      <path d="m346 332h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                      <path d="m286 392h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                    </g>
                  </svg>
                  <span className="vertical-menu">Shortcuts</span>
                </Menu.Item>
              </Dropdown>
            </Menu>
          )}
        </div>
        <UserProfile />
        <Drawer
          onClose={this.closeDrawers}
          visible={this.state.client}
          wrapClassName="diamondListinSidebar lg-size shortCutForm"
        >
          <ClientSidebar />
        </Drawer>
        <Drawer
          onClose={this.closeDrawers}
          visible={this.state.user}
          wrapClassName="diamondListinSidebar lg-size shortCutForm"
          destroyOnClose
        >
          <UserDrawer closeDrawer={this.closeDrawers} />
        </Drawer>
        <Drawer
          onClose={this.closeDrawers}
          visible={this.state.kyc}
          wrapClassName="diamondListinSidebar xl-size shortCutForm"
          destroyOnClose
        >
          <AddKYC selectedIndex={0} onCancel={this.closeDrawers} addAccount />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default compose(withRouter)(SidebarContent);
