import React, { Component } from 'react';
import { useTable } from 'react-table';
import { Drawer } from 'antd';
import DiamondDetail from '../DiamondDetail';
import DiamondListingHead from '../DiamondListing/DiamondListingHead';
// import TableGrouping from './TableGrouping';
// import CircularProgress from '../CircularProgress';

const _ = require('lodash');

export const LIMIT = 100;

class Table extends Component {
  state = {
    visible: false,
  };

  render() {
    let columns = this.props.columns

    const Table = ({ columns, data }) => {
      // Use the state and functions returned from useTable to build your UI
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
      });

      // Render the UI for your table
      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, key) => (
              <tr key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th key={i} {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return <td key={i} {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    let filterRows = this.props.data;
    let selectedFilter = this.props.selectedFilterArray ? this.props.selectedFilterArray : {};
    Object.keys(selectedFilter).map(key => {
      filterRows = filterRows.filter(el => selectedFilter[key].includes(el[key]));
    });

    return (
      <Table columns={columns} data={filterRows} />
    );
  }
}

export default Table;
