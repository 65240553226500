import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Checkbox } from 'antd';
import SelectOption from 'containers/SelectOption';
import * as CommonApi from 'services/CommonApi'
import InputBlock from 'containers/InputBlock';
import TextArea from 'containers/TextArea';
import DatePickerInput from 'containers/DatePickerInput';
import moment from 'moment';
import { capitalize } from 'lodash';
import { showNotification } from 'services/NotificationService';

const Add = () => {
    const [detail, setDetail] = useState();
    const [list, setList] = useState();
    const [flagImage, setFlagImage] = useState([]);

    const editEventDetail = async (editId = {}) => {
        const [, res] = await CommonApi.showDetailsView(editId);

        if (res?.code === 'OK') {
            setDetail(res.data.showDetails);
            setFlagImage(res.data.flagName)
        }
    };
    useEffect(() => {
        editEventDetail({});
    }, []);

    useEffect(() => {
        if (detail && detail.data) {
            setList({
                ...detail.data,
                startDate: detail?.data?.startDate && moment(detail?.data?.startDate),
                isShowEnable: detail.isShowEnable,
                endDaboothNote: detail?.data?.endDaboothNote && moment(detail?.data?.endDaboothNote),
                endDate: detail?.data?.endDate && moment(detail?.data?.endDate),
                // deliveryDate: detail?.data?.deliveryDate && moment(detail?.data?.deliveryDate)
            })
        }
    }, [detail])
    const submitForm = async (values, { setSubmitting }) => {
        // setSubmitting(true)
        const obj = {
            type: 27,
            isShowEnable: values.isShowEnable,
            data: {
                shortName: values.shortName,
                fullName: values.fullName,
                startDate: values.startDate,
                deliveryDate: values.deliveryDate,
                address: values.address,
                boothNo: values.boothNo,
                flagImage: values.flagImage,
                endDaboothNote: values.endDaboothNote,
                title: values.title,
                description: values.description,
                ispopupshow: values.ispopupshow,
                endDate: values.endDate
            }
        }

        const [, res] = await CommonApi.showDetailsViewUpdate(obj);
        if (res?.code === 'OK') {
            editEventDetail({});
            showNotification(res);

        }
        // setSubmitting(false)
    }
    return (
        <div>
            {list &&
                <Formik
                    initialValues={list}
                    validateOnChange
                    validationSchema={Yup.object().shape({
                        fullName: Yup.string().trim().required('Full Name is required').typeError('Title must be a string'),
                        shortName: Yup.string().required('Short Name is required'),
                        shortDescription: Yup.string()
                            .trim()
                            .required('Description is required')
                            .typeError('Description must be a string'),
                    })}
                    onSubmit={submitForm}
                >
                    {({ handleChange, handleBlur, setFieldValue, setFieldTouched, setValues, setSubmitting, touched, errors, values }) => (
                        <Form>
                            <div className="d-flex flex-wrap homeSettingWrapper mt-20">

                                <InputBlock
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title}
                                    name="title"
                                    label="Title * (Popup)"
                                    placeholder="Title"
                                    error={touched?.title && errors?.title}
                                />
                                <InputBlock
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.shortName}
                                    name="shortName"
                                    label="Short Name* (Side Menu)"
                                    placeholder="Short Name"
                                    error={touched?.shortName && errors?.shortName}
                                />
                                <InputBlock
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullName}
                                    name="fullName"
                                    label="Full Name* (Listing Page)"
                                    placeholder="Full Name"
                                    error={touched?.fullName && errors?.fullName}
                                />


                                <InputBlock
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Address"
                                    label="Address*"
                                    value={values.address}
                                    name="address"
                                    error={touched?.address && errors?.address}
                                />
                                <InputBlock
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.boothNo}
                                    name="boothNo"
                                    label="Booth No*"
                                    placeholder="Booth No"
                                    error={touched?.boothNo && errors?.boothNo}
                                />
                                <TextArea
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Description"
                                    label="Description* (Popup)"
                                    value={values.description}
                                    name="description"
                                    error={touched?.description && errors?.description}
                                />

                                <DatePickerInput
                                    onChange={(value) => setFieldValue('startDate', value)}
                                    onBlur={handleBlur}
                                    label="Start Date"
                                    value={values.startDate}
                                    error={touched?.startDate && errors?.startDate}
                                />

                                <DatePickerInput
                                    onChange={(value) => setFieldValue('endDate', value)}
                                    onBlur={handleBlur}
                                    label="End Date"
                                    value={values.endDate}
                                    error={touched?.endDate && errors?.endDate}
                                />
                                <InputBlock
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.deliveryDate}
                                    name="deliveryDate"
                                    label="Delivery Date"
                                    placeholder="Delivery Date"
                                    error={touched?.deliveryDate && errors?.deliveryDate}
                                />
                                {/* <DatePickerInput
                                    onChange={(value) => setFieldValue('deliveryDate', value)}
                                    onBlur={handleBlur}
                                    name="deliveryDate"
                                    label="Delivery Date"
                                    placeholder="Delivery Date"
                                    value={values.deliveryDate}
                                    error={touched?.deliveryDate && errors?.deliveryDate}
                                /> */}
                                <SelectOption
                                    required
                                    name="flagImage"
                                    label="Flag Image"
                                    className=""
                                    placeholder="Select Flag Image"
                                    value={values?.flagImage}
                                    error={touched?.flagImage && errors?.flagImage}
                                    onChange={(flagImage) => {
                                        setValues((values) => ({ ...values, flagImage }));
                                    }}
                                    options={

                                        flagImage.map((value) => ({ key: '/images/flags/' + value, value: capitalize(value.split('.')[0]), nameKey: value.split('.')[0] }))
                                    }
                                />
                                < div className="from-group mt-20">
                                    <Checkbox
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        checked={values.ispopupshow}
                                        name="ispopupshow"
                                        error={touched?.ispopupshow && errors?.ispopupshow}
                                    >
                                        is Popup Show
                                    </Checkbox>

                                </div>
                                <div className="from-group mt-20">
                                    <Checkbox
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        checked={values.isShowEnable}
                                        name="isShowEnable"
                                        error={touched?.isShowEnable && errors?.isShowEnable}
                                    >
                                        is Show Enable
                                    </Checkbox>

                                </div>
                                <div className="width-100 d-flex mt-40">
                                    <button className="commonButton mr-10" value="Submit" type='submit' onClick={() => { submitForm(values, setSubmitting) }}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
        </div >
    )
}

export default Add
