import React from 'react';
import { Layout } from 'antd';
import { useLocation, useRouteMatch } from 'react-router-dom';

import Sidebar from 'containers/Sidebar/index';
import App, { RouteAdmin } from 'routes/index';
import { permiableRoute } from 'constants/permission';

function MainApp() {
  const match = useRouteMatch();
  const location = useLocation();

  const redirect = React.useCallback(() => {
    const uri = location.pathname.replace(`/${RouteAdmin}`, '');
    const permiable = permiableRoute(uri);
    if (permiable) return;
    // this.props.history.push(match.url + RouteAdmin + '/dashboard');
  }, [location?.pathname]);

  React.useEffect(() => {
    redirect();
  }, [redirect]);

  return (
    <Layout className="gx-app-layout">
      <Sidebar />
      <Layout>
        <Layout.Content className="gx-layout-content">
          <App match={match} />
        </Layout.Content>
      </Layout>
      {/* <Customizer /> */}
    </Layout>
  );
}

export default MainApp;
