import { asyncHandler } from './asyncHandler';
import axios from './Api';

export const getBlobUrl = async (path) => {
  const response = await axios.get(path, { responseType: 'blob' });
  return window.URL.createObjectURL(response?.data);
};

export const viewFile = (path) =>
  asyncHandler(async () => {
    const blobUrl = await getBlobUrl(path);
    const anchor = document.createElement('a');
    void ((anchor.style.display = 'none'), (anchor.href = blobUrl), (anchor.target = '_blank'));
    void (document.body.appendChild(anchor), anchor.click(), anchor.remove(), window.URL.revokeObjectURL(blobUrl));
  });

export const printFile = (path) =>
  asyncHandler(async () => {
    const blobUrl = await getBlobUrl(path);
    const iframe = document.querySelector('#PrintFrame') ?? document.createElement('iframe');
    if (iframe?.src) window.URL.revokeObjectURL(iframe.src);
    void ((iframe.style.display = 'none'), (iframe.src = blobUrl), (iframe.height = '100%'), (iframe.width = '100%'));
    void ((iframe.id = 'PrintFrame'), document.body.appendChild(iframe), iframe.contentWindow.print());
  });

export const downloadFile = (path, name) =>
  asyncHandler(async () => {
    const blobUrl = await getBlobUrl(path);
    const anchor = document.createElement('a');
    void ((anchor.style.display = 'none'), (anchor.href = blobUrl), (anchor.download = name ?? path.split('/').pop()));
    void (document.body.appendChild(anchor), anchor.click(), anchor.remove(), window.URL.revokeObjectURL(blobUrl));
  });
