import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useTable } from 'react-table';

import { Drawer } from 'antd';
import { groupBy } from 'lodash';
import moment from 'moment';

import CheckBox from 'components/CheckBox';
import CircularProgress from 'components/CircularProgress';
import DiamondDetail from 'components/DiamondDetail';
import ListTableHeaderBack from 'components/DiamondListing/ListTableHeaderBack';
import NoDataShow from 'components/DiamondListing/NoDataShow';
import Status from 'components/DiamondListing/Status';
import FeedbackRow from 'components/Settings/Feedback/FeedbackRow';
import TargetSellerRow from 'components/Target/TargetSellerRow';

import { RowSelectService } from 'services/RowSelectService';
import { GROUP_KEY } from 'services/groupingArray';

import { classNames } from 'util/classNames';
import * as Hooks from 'util/hooks';
import useFilters from 'util/useFilters';
import useRowSelect from 'util/useRowSelect';
import useSortBy from 'util/useSortBy';
import { isEmpty, isArray, isNotEmpty, isFunction, isString } from 'util/utils';

import { FILE_URLS } from 'constants/Common';
import { COMMON_URL } from 'constants/CommonUrl';

import TableGrouping from './TableGrouping';
import RowGroupFooter from './TableGrouping/RowGroupFooter';
import RowGroupHeader from './TableGrouping/RowGroupHeader';
import * as TableConfig from './table-config';
import * as TableUtils from './table-utils';

import sortingLightSvg from 'assets/svg/InventoryResult/sorting-light.svg';
import cameraSvg from 'assets/svg/camera.svg';
import certSvg from 'assets/svg/certi.svg';
import videoSvg from 'assets/svg/video.svg';

export const canSortCommon = TableConfig.SORTABLE_COLUMNS;
export const LIMIT = TableConfig.PAGE_LIMIT;
export const FILTER_COLUMNS = TableConfig.FILTER_COLUMNS;
export const floatkeys = TableConfig.FLOAT_COLUMNS;
export const roundkeys = TableConfig.ROUND_COLUMNS;
export const DISPLAY_TOTAL = TableConfig.TOTAL_COLUMNS;

const getStyles = (props, item, type) => {
  return [
    props,
    {
      style: {
        textAlign: item.cellClass ? item.cellClass.replace('text-', '') : 'center',
        width: (item.width || '100') + 'px',
        fontWeight: type === 'cell' && TableConfig.BOLD_COLUMNS.includes(item?.id) ? '600' : '',
        color: type === 'cell' && (item.id === 'lbNm' || item.link || item.id === 'dna') ? '#008cba' : '',
        textDecoration: type == 'cell' && item.id == 'dna' && 'underline',
      },
    },
  ];
};
const headerProps = (props, { column }) => getStyles(props, column, 'header');
const cellProps = (props, { cell }) => getStyles(props, cell.column, 'cell');

export const NoDataFound = ({ loading, length }) =>
  loading ? <NoDataShow message={<CircularProgress />} /> : length === 0 ? <NoDataShow /> : <></>;

const ActionsCell = React.memo((props) => {
  const { isHeader, rows, disabled, uniqueBy, nostatus, pairStkNo } = props;
  const { areAllChecked, currentType, noCheckBox, page } = props;

  const row = React.useMemo(() => {
    const output = { ...props?.row };
    output.selectionKey = output.selectionKey ?? output.id;
    return output;
  }, [props?.row]);

  const { isRowChecked, isHeaderChecked } = useRowSelect(currentType, uniqueBy);

  React.useEffect(() => {
    if (!areAllChecked) return;
    if (isHeader) {
      RowSelectService.selectRows(currentType, rows, isHeader);
    } else if (row.isDefaultChecked) {
      RowSelectService.selectRows(currentType, [row]);
    }
  }, [areAllChecked, currentType, rows, isHeader, row]);

  const isChecked = React.useMemo(() => {
    return isHeader ? isHeaderChecked(rows) : isRowChecked(row.selectionKey);
  }, [rows, isHeader, isHeaderChecked, isRowChecked, row.selectionKey]);

  const handleChange = React.useCallback(() => {
    const _rows = isHeader ? rows : [row];
    isChecked
      ? RowSelectService.unSelectRows(currentType, _rows, isHeader)
      : RowSelectService.selectRows(currentType, _rows, isHeader, uniqueBy);
  }, [isHeader, rows, row, isChecked, currentType, uniqueBy]);

  const title = React.useMemo(() => {
    if (isHeader) return isChecked ? 'Unselect All' : 'Select All';
    return isChecked ? 'Unselect Row' : 'Select Row';
  }, [isChecked, isHeader]);

  if (nostatus && noCheckBox) return null;

  return (
    <div className="selectActionIcon">
      {!nostatus && (
        <Status
          status={isHeader ? 'ALL' : row?.wSts}
          bidType={row?.bidType}
          id={row?.id}
          loc={row?.locNm}
          lab={row?.lbNm}
          page={page}
          sStsDesc={row?.sStsDesc}
        />
      )}
      {!noCheckBox && (
        <div
          className={classNames(['selectActionIconWrapper', pairStkNo && row?.pairStkNo && 'pairStkNo'])}
          style={{ marginRight: isHeader ? '5px' : undefined }}
          title={title}
        >
          <CheckBox
            disabled={disabled}
            key={`selection_${isHeader ? 'header' : row.id}`}
            checked={isChecked}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
});

const ResourceIcon = React.memo((props) => {
  const { show, ...restProps } = props;
  return show && <img style={{ marginRight: '5px' }} width="15px" {...restProps} />;
});

const Resource = React.memo(({ row }) => {
  const [showImage, setShowImage] = Hooks.useBoolean();
  const [showVideo, setShowVideo] = Hooks.useBoolean();
  const [showCert, setShowCert] = Hooks.useBoolean();

  return (
    <>
      <ResourceIcon show={row.img} src={cameraSvg} width="15px" alt="" onClick={setShowImage.true} />
      <ResourceIcon show={row.videoFile} src={videoSvg} width="15px" alt="" onClick={setShowVideo.true} />
      <ResourceIcon show={row.certFile} src={certSvg} width="15px" alt="" onClick={setShowCert.true} />

      <Drawer visible={showImage} onClose={setShowImage.false} wrapClassName="diamondDetailPopup" destroyOnClose>
        <img src={FILE_URLS.img.replace('***', row.vStnId)} />
      </Drawer>
      <Drawer visible={showVideo} onClose={setShowVideo.false} wrapClassName="diamondDetailPopup" destroyOnClose>
        <iframe src={FILE_URLS.videoFile.replace('***', row.vStnId)} height="100%" width="100%" />
      </Drawer>
      <Drawer visible={showCert} onClose={setShowCert.false} wrapClassName="diamondDetailPopup" destroyOnClose>
        <iframe src={FILE_URLS.certFile.replace('***', row.rptNo)} height="100%" width="100%" />
      </Drawer>
    </>
  );
});

const MemoizedCell = React.memo((props) => {
  const { cell, clickHandler, row, highlightRevise, onClick } = props;
  const handleClick = React.useCallback(
    (e) => {
      if (isFunction(onClick) && cell.column.id !== 'Action') {
        onClick(row?.index);
      } else {
        clickHandler(e, row, cell);
      }
    },
    [cell, clickHandler, onClick, row],
  );
  if (cell.column.id === 'lbNm') {
    return (
      <td
        className={cell.value === 'GIA' ? 'gia-cer-color' : cell.value === 'IGI' ? 'ige-cer-color' : 'other-cer-color'}
        onClick={(e) => clickHandler(e, row, cell)}
      >
        {cell.value}
      </td>
    );
  }
  if (cell.column.id === 'colNm') {
    return (
      <td
        {...cell.getCellProps(cellProps)}
        className={classNames([highlightRevise && 'highlightRevise'])}
        onClick={handleClick}
      >
        {row.original.isFcCol ? row.original.fcColNm : cell.value}
      </td>
    );
  }
  if (cell.column.id === 'averageBidDiscount') {
    return !row.original.isFcCol ? (
      <td onClick={(e) => clickHandler(e, row, cell)}>{parseFloat(cell.value).toFixed(2)}</td>
    ) : (
      <td onClick={(e) => clickHandler(e, row, cell)}>-</td>
    );
  }

  return (
    <td
      {...cell.getCellProps(cellProps)}
      className={classNames([highlightRevise && 'highlightRevise'])}
      onClick={handleClick}
    >
      {cell.column.id !== 'Details' ? (
        row.original.altCert && TableConfig.ALT_COLUMNS.includes(cell.column.id) ? (
          <span style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            <span
              style={{ width: '100%' }}
              onClick={() => {
                if (cell.column.id === 'lbNm' && row.original.lbNm && row.original.rptNo)
                  window.open(
                    TableConfig.LAB_LINKS[row.original.lbNm.toUpperCase()]?.replace('***', row.original.rptNo),
                  );
              }}
            >
              {cell.render('Cell')}
            </span>
            <span
              style={{ width: '100%' }}
              className={classNames([
                floatkeys.includes(cell.column.id) || (roundkeys.includes(cell.column.id) && 'numberValue'),
              ])}
              onClick={() => {
                if (cell.column.id === 'lbNm' && row.original.altCert.lbNm && row.original.altCert.rptNo)
                  window.open(
                    TableConfig.LAB_LINKS[row.original.altCert.lbNm.toUpperCase()]?.replace(
                      '***',
                      row.original.altCert.rptNo,
                    ),
                  );
              }}
            >
              {TableUtils.formatValues(row.original.altCert[cell.column.id], cell.column.id)}
            </span>
          </span>
        ) : ['dna'].includes(cell.column.id) ? (
          <span>DNA</span>
        ) : ['video'].includes(cell.column.id) ? (
          <img src={videoSvg} />
        ) : (
          cell.render('Cell')
        )
      ) : (
        <Resource row={row.original} />
      )}
    </td>
  );
});

const TrRow = React.memo((props) => {
  const [showDrawer, setShowDrawer] = Hooks.useBoolean();

  const DND_ITEM_TYPE = 'row';
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const row = props?.row;

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) return;

      const dragIndex = item.index;
      const hoverIndex = props.index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      const row = props.data[dragIndex];
      if (props.data[dragIndex]?.sortingSequence) {
        const sequence = props.data[dragIndex]?.sortingSequence;
        props.data[dragIndex].sortingSequence = props.data[hoverIndex].sortingSequence;
        props.data[hoverIndex].sortingSequence = sequence;
      }

      const updatedList = (() => {
        let _output = [...props.data];
        _output = [..._output.slice(0, dragIndex), ..._output.slice(dragIndex + 1, _output.length)];
        _output = [..._output.slice(0, hoverIndex), row, ..._output.slice(hoverIndex, _output.length)];
        return _output;
      })();
      if (props.moverow) props.moverow(updatedList);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index: props.index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const opacity = React.useMemo(() => (isDragging ? 0 : 1), [isDragging]);
  preview(drop(dropRef));
  drag(dragRef);

  const handleClick = React.useCallback(
    (e, row, cell) => {
      if (cell.column.id === 'selection') return;

      if (cell.column.link && typeof cell.column.link === 'string') {
        const field = (() => {
          const value = cell?.column?.link;
          const _output = value.slice(value.indexOf('$') + 1, value.length);
          return _output.slice(0, field.indexOf('$'));
        })();
        const link = cell.column.link.replace('$' + field + '$', row.original[field]);
        return window.open(link);
      }

      if (cell.column.id === 'dna')
        return window.open(`${COMMON_URL.FRONT_SITE_URL}/dna/${row.original.vStnId ?? row.original.id}`);

      if (cell.column.id === 'video')
        return window.open(`${COMMON_URL.FRONT_SITE_URL}/diamondfullview/${row.original.id ?? row.original.vStnId}`);

      if (cell.column.id === 'ftc' && cell.value) return window.open(`/ftc/${row.original.id ?? row.original.vStnId}`);

      if (!row.original.altCert && cell.column.id === 'lbNm' && row.original.lbNm && row.original.rptNo)
        return window.open(TableConfig.LAB_LINKS[row.original.lbNm.toUpperCase()]?.replace('***', row.original.rptNo));

      if (!TableConfig.NOCHECK_COLUMNS.includes(cell.column.id)) {
        if (props.nocheck || props.noCheckBox || !TableConfig.SELECT_ROW_ON_CLICK) return;
        const parentNode = e.currentTarget.parentNode;
        if (!parentNode) return;
        const checkboxRef = parentNode.getElementsByTagName('input');
        if (checkboxRef?.length > 0) checkboxRef[0].click();
      }
    },
    [props.noCheckBox, props.nocheck],
  );

  const rowClassNames = React.useMemo(() => {
    const lab = !isEmpty(row.original?.lbNm) && `${row.original.lbNm}`.toUpperCase();
    return classNames([lab]);
  }, [row.original]);

  const revised = React.useMemo(() => {
    return props.highlightRevise ? moment().diff(moment(row.original.rvDt)) < 24 * 60 * 60 * 1000 : false;
  }, [props.highlightRevise, row.original.rvDt]);

  const rowProps = React.useMemo(
    () => ({
      'table-row': props.currentType + row.original.id,
    }),
    [props.currentType, row.original.id],
  );

  return (
    <>
      {props.dragDrop && (
        <div className="tableSortingTd" ref={dragRef}>
          <img src={sortingLightSvg} />
        </div>
      )}
      <tr
        {...row.getRowProps(rowProps)}
        className={
          row.original.averageBidAmount || row.original.status === 2
            ? 'bid-winner'
            : row.original.isRunnerUpBid
              ? 'bid-second-winner'
              : ''
        }
        style={{ opacity }}
        ref={dropRef}
      >
        {row.cells.map((cell) => (
          <MemoizedCell
            key={cell.column.id + '_cell'}
            row={row}
            cell={cell}
            clickHandler={handleClick}
            onClick={props.rowClick}
            highlightRevise={props.highlightRevise && revised}
          />
        ))}
      </tr>
      {isArray(props.feedbackIndex) && props.feedbackIndex?.includes(props.index) && (
        <tr className="tableInTableBoxWrapper b-none">
          <td colSpan="5">
            {!isEmpty(row.original?.feedback) ? (
              <FeedbackRow data={row.original?.feedback} />
            ) : (
              <NoDataShow message="Feedback not exist" />
            )}
          </td>
        </tr>
      )}
      {isArray(props.targetIndex) && props.targetIndex?.includes(props.index) && (
        <tr className="tableInTableBoxWrapper b-none activityTarget">
          <td colSpan="7">
            {!isEmpty(props.targetSeller?.[row.index]) ? (
              <TargetSellerRow data={props.targetSeller?.[row.index]} />
            ) : (
              <NoDataShow message="Detail not exist" />
            )}
          </td>
        </tr>
      )}
      <Drawer onClose={setShowDrawer.false} visible={showDrawer} wrapClassName="diamondDetailPopup" destroyOnClose>
        <DiamondDetail data={row.original} onClose={setShowDrawer.false} diamondPopup />
      </Drawer>
    </>
  );
});

function Table(props) {
  const {
    handleSort = () => { },
    dashBoardSort = () => { },
    nocheck = false,
    nostatus = false,
    noCheckBox = false,
    noGroupCheckBox = false,
    loading = false,
    nodots = false,
    noGrp,
    currentType,
    areAllChecked = false,
    FilterOption = true,
    canSort = true,
    initialSort = [],
    noSerNum = false,
    pairStkNo = true,
    salesCountry = true,
    page = 'SEARCH',
    disableRowCheck,
    uniqueBy,
    onlyGroupHeader,
  } = props;

  const data = React.useMemo(() => {
    if (!isArray(props?.data)) return [];

    const output = props.data.map((record) => ({ ...record }));
    if (output?.[0]?.pairStkNo) {
      output.sort((curr, next) => curr?.pairStkNo - next?.pairStkNo);
    }

    output.forEach((record, index) => {
      if (!noSerNum) {
        record.srNo = LIMIT * ((props?.currentPage || 1) - 1) + (index + 1);
      }

      if (record?.pairStkNo && index > 0) {
        const prevItem = output?.[index - 1];
        if (prevItem?.pairStkNo === record?.pairStkNo) {
          record.pairStkNo = undefined;
          record.pairStkNoList = prevItem?.pairStkNo;
        }
      }
    });

    return output;
  }, [noSerNum, props?.currentPage, props.data]);

  const srNoColumn = React.useMemo(() => {
    return { id: 'srNo', accessor: 'srNo', width: 20, Header: 'Sr. No' };
  }, []);

  const statusColumn = React.useMemo(() => {
    return {
      id: 'selection',
      Header({ rows }) {
        const originalRows = React.useMemo(() => rows.map((row) => row.original), [rows]);

        return (
          <ActionsCell
            currentType={currentType}
            rows={originalRows}
            uniqueBy={uniqueBy}
            areAllChecked={areAllChecked}
            noCheckBox={noCheckBox}
            nostatus={nostatus}
            disabled={!isEmpty(uniqueBy)}
            isHeader
          />
        );
      },
      Cell({ row }) {
        return (
          <ActionsCell
            page={page}
            row={row.original}
            pairStkNo={pairStkNo}
            disabled={disableRowCheck}
            uniqueBy={uniqueBy}
            nostatus={nostatus}
            areAllChecked={areAllChecked}
            currentType={currentType}
            noCheckBox={noCheckBox}
          />
        );
      },
    };
  }, [areAllChecked, currentType, disableRowCheck, noCheckBox, nostatus, page, pairStkNo, uniqueBy]);

  const columns = React.useMemo(() => {
    if (!isArray(props?.columns) || isEmpty(props?.columns)) return [];

    const output = props.columns.map((column) => ({ ...column }));
    if (!noSerNum) output.unshift(srNoColumn);
    if (!nocheck) output.unshift(statusColumn);

    return output;
  }, [props.columns, noSerNum, srNoColumn, nocheck, statusColumn]);

  const isMultiSortEvent = React.useCallback(() => true, []);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state: { sortBy, filters },
    headerGroups,
    rows,
    setSortBy,
    toggleSortBy,
  } = useTable(
    {
      data,
      columns,
      initialState: {
        sortBy: initialSort
          .map((item) => {
            return Object.entries(item).map(([id, desc]) => {
              const title = (() => {
                const col =
                  isArray(props.columns) && props.columns.find((col) => [col?.accessor, col?.id].includes(id));
                const output = col?.Header ?? col?.title ?? id;
                return output;
              })();
              desc = `${desc}`.toUpperCase() === 'DESC';
              return { id, desc, title };
            })?.[0];
          })
          .filter(isNotEmpty),
      },
      manualSortBy: true,
      isMultiSortEvent,
      disableSortBy: !canSort,
    },
    useFilters,
    useSortBy,
  );

  React.useEffect(() => {
    handleSort(sortBy.map(({ id, desc }) => ({ [id]: desc ? 'DESC' : 'ASC' })));
  }, [handleSort, sortBy]);

  React.useEffect(() => {
    sortBy.map(({ id, desc }) => dashBoardSort({ [id]: desc ? -1 : 1 }));
  }, [dashBoardSort, sortBy]);

  const getGroupTitle = React.useCallback(
    (data) => {
      const show = {
        user: props.sortUser,
        date: props.sortDate,
        country: props.sortCountry,
        salesPerson: props.sortSalesPerson,
        status: props.sortStatus,
        sellerName: props?.sortSellerName,
      };
      const country = data?.countryNm || '';
      const sellerName = data?.sellerName || '-';
      const salesPerson = data?.seller || '';
      const date = data?.userDate || data?.createdAt;
      const dateTitle = data?.dateTitle || '';
      const user = [
        data?.user?.account?.companyName || data?.user?.companyName,
        data?.userName,
        data?.userMobile,
        data?.userEmail,
      ]
        .filter(isNotEmpty)
        .map((item) => (isEmpty(item) ? '-' : item))
        .join(' | ');

      return show?.user
        ? user
        : show?.date
          ? `${dateTitle ? dateTitle : ''} ${date}`
          : props.groupStoneDate
            ? `${date} - ${data?.vStnId}`
            : props?.stageShow || show?.status
              ? `${data?.sSts ?? data?.blockCode}`
              : show?.country
                ? country
                : show?.sellerName
                  ? sellerName
                  : show?.salesPerson
                    ? salesPerson
                    : [`${dateTitle ? dateTitle + ' ' + date : date}`, user].filter(isNotEmpty).join(' | ');
    },
    [
      props.groupStoneDate,
      props.sortCountry,
      props.sortDate,
      props.sortSalesPerson,
      props?.sortSellerName,
      props.sortStatus,
      props.sortUser,
      props?.stageShow,
    ],
  );

  const totalColumns = React.useMemo(() => {
    if (!isArray(columns)) return [];
    const fields = !isEmpty(props?.displayTotal) ? props?.displayTotal : TableConfig.TOTAL_COLUMNS;
    return fields.filter((el) => Boolean(columns.find((col) => col.id === el)));
  }, [columns, props?.displayTotal]);

  const grouppedRows = React.useMemo(() => {
    const originalRows = rows.map((row) => row?.original);
    if (noGrp || !isString(originalRows?.[0]?.[GROUP_KEY])) return rows;
    return groupBy(originalRows, GROUP_KEY);
  }, [noGrp, rows]);

  // React.useEffect(
  //   () => () => {
  //     if (areAllChecked && isEmpty(rows)) return;
  //     RowSelectService.resetSelectedRows({ currentType });
  //   },
  //   [rows, areAllChecked, currentType],
  // );

  if (loading) return <CircularProgress />;

  if (!isArray(rows) || isEmpty(rows)) return <NoDataShow />;

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, k) => (
              <th key={k} {...column.getHeaderProps(headerProps)} style={{ width: (column.width || 100) + 'px' }}>
                {column.id === 'selection'
                  ? column.render('Header')
                  : column.id !== 'action' && (
                    <ListTableHeaderBack
                      toggleSortBy={toggleSortBy}
                      setSortBy={setSortBy}
                      sortBy={sortBy}
                      column={column}
                      filters={filters}
                      nodots={nodots}
                      FilterOption={FilterOption}
                    />
                  )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        <DndProvider backend={HTML5Backend}>
          {!isArray(rows.length) &&
            rows.map((row, i) => {
              prepareRow(row);
              const { isHeader, isFooter, isOfferHeader } = row.original;
              const groupKey = !noGrp && row.original?.[GROUP_KEY];
              const groupRows = !noGrp && grouppedRows?.[groupKey];

              return (
                <React.Fragment key={row.selectionKey ?? i}>
                  {!noGrp && isOfferHeader && (
                    <TableGrouping
                      groupingTitle={row?.original?.offerGrp}
                      toggleCollapse={() => props.toggleCollapse(!row.original.collapsed, row.original.offerGrp)}
                      collapsed={row.original.collapsed}
                      calc={row.original.calc}
                      columns={columns}
                      isOfferPopup
                      colspan={1}
                    />
                  )}
                  {!noGrp && isHeader && (
                    <RowGroupHeader
                      rows={groupRows}
                      currentType={currentType}
                      noCheckBox={(nocheck || noCheckBox || noGroupCheckBox) && !onlyGroupHeader}
                      total={row.original.totalDiamonds}
                      title={row.original._groupTitle_ ?? getGroupTitle(row.original)}
                    />
                  )}
                  <TrRow {...props} row={row} index={row.index} />
                  {!noGrp && isFooter && (
                    <RowGroupFooter row={row.original} columns={columns} totalColumns={totalColumns} />
                  )}
                </React.Fragment>
              );
            })}
        </DndProvider>
      </tbody>
    </table>
  );
}

function TableWrapper(props) {
  if (props.loading) return <CircularProgress />;
  if (isEmpty(props?.data) || isEmpty(props?.columns)) return <NoDataShow />;
  return <Table {...props} />;
}

export default React.memo(TableWrapper);
