import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

import { STATUS_INDICATION_LIST } from 'components/DiamondListing/Status';

import statusSvg from 'assets/svg/status.svg';
import 'styles/listingTop.less';
import { isFunction } from 'util/utils';

export function getStagePopoverContent(onClick, page) {
  const STATUS_INDICATION = page ? STATUS_INDICATION_LIST[page] : STATUS_INDICATION_LIST.SEARCH;

  return (
    <div className="d-flex flex-wrap filterDropdown">
      {Object.keys(STATUS_INDICATION).map((status, index) => {
        return (
          <a
            key={index}
            className="showSatusBlock"
            style={{ minWidth: '180px' }}
            onClick={() => isFunction(onClick) && onClick(status)}
          >
            <div style={{ backgroundColor: STATUS_INDICATION[status].color }} className="SelectcolorWiseList"></div>
            <span>{[status, STATUS_INDICATION[status].name].join(' - ')}</span>
          </a>
        );
      })}
    </div>
  );
}

const StagePopover = React.memo(function StagePopover({ onClick, page }) {
  const popoverContent = React.useMemo(() => getStagePopoverContent(onClick, page), [onClick, page]);

  return (
    <Popover content={popoverContent} placement="bottomRight" overlayStyle={{ width: '750px' }}>
      <div className="filterOption mr-10">
        <img style={{ width: '18px', height: '18px' }} src={statusSvg} alt="" />
      </div>
    </Popover>
  );
});

StagePopover.propTypes = {
  onClick: PropTypes.func,
  page: PropTypes.string,
};

export default StagePopover;
