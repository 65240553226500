import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'util/classNames';

const ListFooter = (props) => {
  const { sticky, contentCenter, contentSpaceBetween, className, ...rest } = props;

  return (
    <div
      className={classNames([
        'botoomStickyBar',
        sticky && 'positionSticky',
        contentCenter && 'justify-content-center',
        contentSpaceBetween && 'j-space-between',
        className,
      ])}
      {...rest}
    >
      {props.children}
    </div>
  );
};

ListFooter.propTypes = {
  children: PropTypes.node,
  homeSetting: PropTypes.bool,
  sticky: PropTypes.bool,
  contentCenter: PropTypes.bool,
  contentSpaceBetween: PropTypes.bool,
  className: PropTypes.string,
};

export default ListFooter;
