import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { ConnectedRouter } from 'connected-react-router';

import App from 'containers/App';

import ConnectedLoader from 'components/CircularProgress/ConnectedLoader';

import { history } from 'util/history';

import { store } from 'appRedux/store';
import 'styles/_main.less';

const NextApp = () => (
  <Provider store={store}>
    <ConnectedLoader />
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default NextApp;
