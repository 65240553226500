import React, { Component } from 'react';
import { TabPanel } from 'react-tabs';

import MainHeading from 'containers/MainHeading';
import CustomTab from 'containers/Tab';

import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import AdditionalInfo from 'components/ShortCuts/KYC/AdditionalInfo';
import BankAccountDetail from 'components/ShortCuts/KYC/BankAccountDetail';
import Branch from 'components/ShortCuts/KYC/Branch';
import BusinessDetail from 'components/ShortCuts/KYC/BusinessDetail';
// import DepartmentDetail from 'components/ShortCuts/KYC/DepartmentDetail';
import ClientKYCDetail from 'components/ShortCuts/KYC/KYC';
// import PromotersDetail from 'components/ShortCuts/KYC/PromotersDetail';
import Setting from 'components/ShortCuts/KYC/Setting';
import User from 'components/ShortCuts/KYC/User';
import TermsDetail from 'components/ShortCuts/KYC/TermsDetail';

import * as AccountAPI from 'services/AccountApi';
import { TabData } from 'constants/tab';
import { isBoolean, isEmpty, isFunction } from 'util/utils';

import classNames from 'util/classNames';
import { showNotification } from 'services/NotificationService';
import accountFields from 'routes/Client/account-fields';
import { connect } from 'react-redux';
import { TabActions } from 'appRedux/reducers/TabIssueReducer/TabReducer';
import { getPermittedInsertList, MAINMODULE, MODULES } from 'constants/permission';
import { UserActions } from 'appRedux/reducers/User';

const Wrapper = ({ component, disabled }) => (
  <div className={classNames([disabled && 'disableBlock width-100'])}>{component}</div>
);

const tablist = {
  basic: 0,
  'business-detail': 1,
  'bank-info': 2,
  branch: 3,
  user: 4,
  'references-detail': 5,
  setting: 6,
  'terms-detail': 7,
};

class AddKYC extends Component {
  state = { account: {}, generalInfo: {}, tabIndex: 1, loading: 0 };

  startLoading = () => this.setState(({ loading }) => ({ loading: ++loading }));
  stopLoading = () => this.setState(({ loading }) => ({ loading: --loading }));

  componentDidMount() {
    const tab = this.props.addAccount ? undefined : window.location.href.split('/').pop();

    this.setState({
      tabIndex: !isEmpty(tablist?.[tab]) ? tablist?.[tab] : 0,
      account: isEmpty(this.props?.account) ? {} : this.props?.account,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props?.account?.id != prevProps?.account?.id) {
      this.setState({
        account: isEmpty(this.props?.account) ? {} : this.props?.account,
      });
    }
  }

  updateSubTabIndex = () => {
    let indexList = TabData.clientTabs.list[0].subList.filter((tab) => !tab.hideTab).map((item) => item.index);
    let index = indexList.indexOf(this.state.tabIndex);

    if (index !== -1 && indexList?.[index] >= 0) {
      isFunction(this.props?.updateSubTabIndex) && this.props.updateSubTabIndex(indexList?.[index + 1]);
      this.setState({ tabIndex: indexList?.[index + 1] });
    }
  };

  createAccount = async (data) => {
    const [err, res] = await AccountAPI.createAccount(data);
    if (res?.code === 'OK') {
      this.props.resetList();
      this.props.setClientList({ customerDetail: res.data });
      this.setState({ account: res.data });
    }
    return [err, res];
  };

  setSelectedAccount = async (detail) => {
    let [, res] = await AccountAPI.getAccountDetails(detail.id, { request: { select: accountFields } });
    if (res?.code === 'OK') {
      this.setState({ accountStatus: res?.data?.detail?.isActive, account: res?.data?.detail });
      this.props.setClientList({ customerDetail: res?.data?.detail });
    }
  };

  updateAccount = async (id, data) => {
    const [err, res] = await AccountAPI.updateAccount(id, data);
    !err && showNotification(res);
    if (res?.code === 'OK') {
      if (isBoolean(data?.isActive)) this.accountStatusUpdate(data?.isActive);
      this.setSelectedAccount(this.state.account);
      this.accountStoreUpdate({ ...{ ...this.props.clientData.customerDetail, ...data } });
    }
    return [err, res];
  };

  accountStoreUpdate = (account) => {
    let list = this.props?.clientData?.clientList;

    list = list?.map((item) => {
      if (account?.id == item?.id) {
        item = account;
      }
      return item;
    });

    this.props.setClientList({ ...{ clientList: list } });
  };

  accountStatusUpdate = (accountStatus, rowId = null) => {
    let list = this.props?.clientData?.clientList;
    let account = this.props?.clientData?.customerDetail;
    if (!isEmpty(rowId)) {
      list[rowId].isActive = accountStatus;
      if (list[rowId]?.id == account.id) account.isActive = accountStatus;
    } else {
      list = list?.map((item) => {
        if (item?.id == account?.id) {
          item.isActive = accountStatus;
          account.isActive = accountStatus;
        }
        return item;
      });
    }

    const clientData = { clientList: list, customerDetail: account };
    this.props.setClientList({ ...clientData });
    this.setState({ accountStatus });
  };

  closeDrawer = () => {
    this.setState({ account: {}, tabIndex: 1 });
    this.props.setEditUser({});
    this.props.onCancel();
  };

  render() {
    if (this.state.loading) return null;

    const { tabIndex } = this.state;
    const { termDetail, setTermState, getTermsDetail, termState } = this.props;

    const { account } = this.state;
    const tabsDisabled = isEmpty(account);

    const forwardProps = {
      account: { ...account },
      onCancel: this.closeDrawer,
      updateSubTabIndex: this.updateSubTabIndex,
      getAccount: this.props?.getAccount,
      createAccount: this.createAccount,
      updateAccount: this.updateAccount,
      getClientList: this.props?.getClientList,
      setClientList: this.props?.setClientList,
    };

    let editable = getPermittedInsertList(MAINMODULE.CLIENT, MODULES.CLIENT.KYC.NAME);

    return (
      <>
        <MainHeading className="mb-10 kycAddTitle" title={`${account?.id ? 'Edit' : 'Add'} KYC`} />
        <CustomTab
          tabHeaderClass="customerMaintab"
          selectedIndex={!isEmpty(tabIndex) ? tabIndex : 0}
          data={TabData.clientTabs.list[0].subList.filter((tab) => !tab.hideTab)}
          onGetIndex={(tabIndex) => this.setState({ tabIndex })}
          wantIndex
        >
          <GridHeightContainer
            subtractFrom=".kycAddTitle,.customerMaintab,.customerSubTab,.sidebarAction"
            className="AddKycTabs"
          >
            <TabPanel>
              <ClientKYCDetail {...forwardProps} />
            </TabPanel>
            <TabPanel>
              <Wrapper disabled={tabsDisabled} component={<BusinessDetail {...forwardProps} />} />
            </TabPanel>
            <TabPanel>
              <Wrapper disabled={tabsDisabled} component={<BankAccountDetail {...forwardProps} />} />
            </TabPanel>
            <TabPanel>
              <Wrapper disabled={tabsDisabled} component={<Branch {...forwardProps} />} />
            </TabPanel>
            <TabPanel>
              <Wrapper
                disabled={tabsDisabled}
                component={
                  <User {...{ ...forwardProps, editUser: this.props?.editUser, setEditUser: this.props.setEditUser }} />
                }
              />
            </TabPanel>
            <TabPanel>
              <Wrapper disabled={tabsDisabled} component={<AdditionalInfo {...forwardProps} />} />
            </TabPanel>
            <TabPanel>
              <Wrapper disabled={tabsDisabled} component={<Setting {...forwardProps} />} />
            </TabPanel>
            <TabPanel>
              <Wrapper
                disabled={tabsDisabled}
                component={
                  <TermsDetail {...{ ...forwardProps, termDetail, setTermState, getTermsDetail, termState }} />
                }
              />
            </TabPanel>
          </GridHeightContainer>
        </CustomTab>
      </>
    );
  }
}

export default connect(
  ({ tab }) => ({
    clientData: tab.clientList,
  }),
  {
    setClientList: TabActions.setClientList,
    resetList: TabActions.resetList,
    setEditUser: UserActions.setEditUser,
  },
)(AddKYC);
