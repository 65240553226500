import React, { Component } from 'react';
import CustomTab from 'containers/Tab';
import { TabData } from 'constants/tab';
import { TabPanel } from 'react-tabs';
import LoginSetting from './LoginSetting';

class ProjectSettings extends Component {
  state = {
    tabIndex: 0,
    subTabIndex: 0,
  };

  render() {
    return (
      <CustomTab
        selectedIndex={this.state.tabIndex}
        onGetIndex={(ind) => {
          this.setState({ tabIndex: ind }, () => {});
        }}
        wantIndex
        data={TabData.projectSetting.filter((d) => !d.hideTab)}
        tabHeaderClass="customerMaintab"
        className="moreTabScroll"
      >
        <div className="configurationWrapper pt-0">
          {/* <TabPanel>
            <NewArrivalForm />
          </TabPanel> */}
          <TabPanel>
            <LoginSetting />
          </TabPanel>
        </div>
      </CustomTab>
    );
  }
}

export default ProjectSettings;
