import React from 'react';
import { Modal as AntdModal } from 'antd';
import classNames from 'util/classNames';
import './modal.less';

function Modal({ className, children, noDefaults, ...restProps }) {
  const { handleOk, handleCancel, footerShow, submitTitle, cancelTitle, disabled, ...forwardProps } = restProps;
  const defaultProps = React.useMemo(
    () =>
      !noDefaults && {
        onOk: handleOk,
        onCancel: handleCancel,
        footer: footerShow ? (
          <div className="commonModalButton">
            <button className="fillButton" type="button" onClick={handleOk} disabled={disabled}>
              {submitTitle}
            </button>
            <button className="outLineButton" type="button" onClick={handleCancel} disabled={disabled}>
              {cancelTitle}
            </button>
          </div>
        ) : null,
        centered: true,
      },
    [cancelTitle, disabled, footerShow, handleCancel, handleOk, noDefaults, submitTitle],
  );

  return (
    <AntdModal className={classNames(['commonModal', className])} {...defaultProps} {...forwardProps}>
      {children}
    </AntdModal>
  );
}

export default Modal;
