import SelectOption from 'containers/SelectOption';
import React from 'react';
import { getSeller, isValue } from '../CommonPopupFunction';

function SellerComponent(props) {
  const [state, _setState] = React.useState({
    seller: null,
    sellerList: [],
    loading: false,
  });
  const setState = (object = {}, cb = null) => {
    _setState((state) => ({ ...state, ...object }));
    props.setSellerDetails(object);
  };

  React.useEffect(() => {
    setState(props.initialData);
  }, [props?.initialData?.sellerList, props?.initialData?.seller]);

  React.useEffect(() => {
    getSeller((sellerList) => {
      setState({ sellerList });
    });
  }, []);

  return (
    <SelectOption
      placeholder={props?.placeholder || `Select Salesman`}
      value={
        state.sellerList?.filter((item) => item.key == isValue(state.seller)).length ? isValue(state.seller) : undefined
      }
      selectValue={state.sellerList}
      label={props?.label || 'Salesman *'}
      onChange={(seller) => {
        setState({ seller });
      }}
      onSearch={() => {
        setState({ seller: null });
      }}
      error={props.error?.seller}
    />
  );
}

export default React.memo(SellerComponent);
