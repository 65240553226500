import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { isEmpty } from 'util/utils';

import addSvg from 'assets/svg/plus.svg';
import editSvg from 'assets/svg/edit-white.svg';

const Icon = React.memo(function Icon({ edit }) {
  return edit ? <img src={editSvg} alt="" /> : <img src={addSvg} alt="" />;
});

function PlusIcon({ link, edit, onClick, tooltipTitle, ...restProps }) {
  return link ? (
    <Link className="addFormIcon" to={link} {...restProps}>
      <Icon {...{ edit }} />
    </Link>
  ) : (
    <Tooltip title={!isEmpty(tooltipTitle) ? tooltipTitle : undefined}>
      <div className="addFormIcon" onClick={onClick} {...restProps}>
        <Icon {...{ edit }} />
      </div>
    </Tooltip>
  );
}

export default React.memo(PlusIcon);
