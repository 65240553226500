import React, { Component } from 'react';
import '../../../styles/searchResult.less';
import ListingWrapper from '../../../containers/ListingWrapper';
import ListingTableWrapper from '../../../containers/ListingTableWrapper';
import MainHeading from '../../../containers/MainHeading';
import InputBlock from '../../../containers/InputBlock';
import SelectOption from '../../../containers/SelectOption';
import TextArea from '../../../containers/TextArea';
import DisableBlock from '../../../containers/DisableBlock';
import Table from 'components/Inventory/Table';
import { cloneDeep } from 'lodash';

class OrderApprove extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridHeight: 0,
      rupes: false,
      dollar: true,
      columns: cloneDeep(this.props.columns),
    };
  }
  componentDidMount() {}

  render() {
    let client = this.props.client;

    return (
      <>
        <div>
          <MainHeading
            className="mb-10"
            title={`${client?.companyName} (${client?.firstName} ${client?.lastName})`}
          ></MainHeading>
          <ListingWrapper>
            <ListingTableWrapper initial={30}>
              <Table
                // checked={this.props.checked}
                columns={this.state.columns}
                data={this.props.checked}
                sort={[]}
                canSort={[]}
                filterArray={{}}
                selectedFilterArray={{}}
                noheader
                noDots
                nocheck
                FilterOption={false}
              />
            </ListingTableWrapper>
          </ListingWrapper>
          <div className="orderDetailBlock d-flex flex-wrap">
            {!this.props.inOffer && (
              <>
                <SelectOption
                  orderDetail
                  dolClick={this.dollarClick}
                  rupeClick={this.rupesClick}
                  label="Currency"
                  defaultValue="$"
                ></SelectOption>
                {this.state.rupes && <InputBlock label="Currency Rate" placeholder="Currency Rate"></InputBlock>}
                {this.state.dollar && (
                  <DisableBlock className="width-50">
                    <InputBlock
                      className="width-100"
                      label="Currency Rate"
                      value="1"
                      placeholder="Currency Rate"
                    ></InputBlock>
                  </DisableBlock>
                )}
              </>
            )}

            <InputBlock label="Avg. Discount %" placeholder="Avg. Discount %"></InputBlock>
            <InputBlock label="CT Priece Discount" placeholder="CT Priece Discount"></InputBlock>
            <InputBlock label="Less" placeholder="Less" select></InputBlock>
            <InputBlock label="Final CT Price" placeholder="Final CT Price"></InputBlock>
            <TextArea label="Broker" placeholder="Broker" />
            <TextArea label="Comment" placeholder="Comment" />
          </div>
          <div className="sidebarAction">
            <button className="commonButton" onClick={this.props.approveClick}>
              Approve
            </button>
            <button className="commonOutine">Cancel</button>
          </div>
        </div>
      </>
    );
  }
}

export default OrderApprove;
