import React from 'react';
import { Link } from 'react-router-dom';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';
import { i18n } from 'util/i18n';

const SingleStoneConfiguration = () => {
  return (
    <div className="subTabInnerDropdown">
      <ul>
        {/* {TabData.subTitle.subMenuDropdown.map((d,index) => ( */}
        <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/configuration/carat-range'
              ? 'active'
              : null
          }
        >
          <Link to={PREFIX_URL_WITH_SLASH + '/inventory/configuration/carat-range'}>Carat Range</Link>
        </li>
        {/* ))} */}
        {/* Carat Range */}
        {/* PREFIX_URL_WITH_SLASH + '/inventory/configuration/carat-range' */}
        <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/configuration/cut-group' ? 'active' : null
          }
        >
          <Link to={PREFIX_URL_WITH_SLASH + '/inventory/configuration/cut-group'}>Cut Group</Link>
        </li>
        <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/configuration/rapnet-policy'
              ? 'active'
              : null
          }
        >
          <Link to={`${PREFIX_URL_WITH_SLASH}/inventory/configuration/rapnet-policy`}>Rapnet Policy</Link>
        </li>

        <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/configuration/check-image-status'
              ? 'active'
              : null
          }
        >
          <Link to={`${PREFIX_URL_WITH_SLASH}/inventory/configuration/check-image-status`}>Check Image</Link>
        </li>

        <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/configuration/upload-typeIIA-cert'
              ? 'active'
              : null
          }
        >
          <Link to={`${PREFIX_URL_WITH_SLASH}/inventory/configuration/upload-typeIIA-cert`}>Certificate Upload</Link>
        </li>

        {/* <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/configuration/config-match'
              ? 'active'
              : null
          }
        >
          <Link to={PREFIX_URL_WITH_SLASH + '/inventory/configuration/config-match'}>Match Pair/Calibrated</Link>
        </li> */}
        {/* <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/configuration/config-similar'
              ? 'active'
              : null
          }
        >
          <Link to={PREFIX_URL_WITH_SLASH + '/inventory/configuration/config-similar'}>Similar Stones</Link>
        </li> */}
        <li
          className={`menuDropdown ${
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/configuration/diamond-column-setting'
              ? 'active'
              : null
          }`}
        >
          <Link to={PREFIX_URL_WITH_SLASH + '/inventory/configuration/diamond-column-setting'}>
            Diamond List Column Setting
          </Link>
        </li>
        <li
          className={`menuDropdown ${
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/configuration/download-Excel'
              ? 'active'
              : null
          }`}
        >
          <Link to={PREFIX_URL_WITH_SLASH + '/inventory/configuration/download-Excel'}>Download Excel</Link>
        </li>
        {/* <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/configuration/client-action'
              ? 'active'
              : null
          }
        >
          <Link to={PREFIX_URL_WITH_SLASH + '/inventory/configuration/client-action'}>
            Client Actions Configuration
          </Link>
        </li> */}

        <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/configuration/quote-day' ? 'active' : null
          }
        >
          <Link to={PREFIX_URL_WITH_SLASH + '/inventory/configuration/quote-day'}>{i18n.t('title.offer')} Day</Link>
        </li>

        {/* <li className={window.location.pathname === PREFIX_URL_WITH_SLASH + "/inventory/configuration/manage-parameters" ? "active" : null}>
                    <Link to={PREFIX_URL_WITH_SLASH + '/inventory/configuration/manage-parameters'}>Manage Parameters</Link>
                </li> */}
      </ul>
    </div>
  );
};
export default SingleStoneConfiguration;
