import React, { Component } from 'react';
import { Button, Form, Input } from 'antd';
import IntlMessages from 'util/IntlMessages';

import axios from 'util/Api';
import OpenNotification from '../../../constants/OpenNotifications';
import { PROJECT_DETAILS } from 'constants/Common';
import CircularProgress from 'components/CircularProgress';
import { history } from 'util/history';

const FormItem = Form.Item;

class ResetPassword extends Component {
  state = {
    loading: false,
    resetId: this.props.match.params.resetId,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        if (!err) {
          axios
            .post('/auth/reset-password', {
              token: this.state.resetId,
              newPassword: values.confirm,
            })
            .then(({ data }) => {
              if (data.code === 'OK') {
                OpenNotification({
                  type: 'success',
                  title: data.message,
                });
                this.props.form.resetFields();
                history.push('/');
              } else if (data.message) {
                OpenNotification({
                  type: 'error',
                  title: data.message,
                });
              }
              this.setState({
                loading: false,
              });
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });
              OpenNotification({
                type: 'error',
                title: error.message,
              });
            });
        }
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="adma-login-wrap">
        {this.state.loading && <CircularProgress className="fixLoader" />}
        <div className="adma-login-container">
          <div className="loginpage">
            <div className="adma-login-main-content">
              <div className="adma-app-login-content">
                <div className="adma-logo-content-bg">
                  <img src={PROJECT_DETAILS.LOGO_1} />
                  <h2>Reset Password</h2>
                  <p>
                    <IntlMessages id="appModule.enterPasswordReset" />
                  </p>
                </div>
                <Form layout="vertical" onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0">
                  <FormItem>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                        {
                          validator: this.validateToNextPassword,
                        },
                      ],
                    })(<Input type="password" placeholder="New Password" />)}
                  </FormItem>

                  <FormItem>
                    {getFieldDecorator('confirm', {
                      rules: [
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        {
                          validator: this.compareToFirstPassword,
                        },
                      ],
                    })(<Input placeholder="Retype New Password" type="password" onBlur={this.handleConfirmBlur} />)}
                  </FormItem>
                  <div className="submit-btn">
                    <Form.Item>
                      <Button type="primary" className="adma-mb-0" htmlType="submit">
                        <IntlMessages id="app.userAuth.reset" />
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
            <div className="login-right-section">
              <video autoPlay loop muted>
                <source src={require('assets/images/FSDLogoSight.mp4')}></source>
              </video>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedResetPasswordForm = Form.create()(ResetPassword);

export default WrappedResetPasswordForm;
