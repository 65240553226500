import React, { Component } from 'react';
import { Checkbox } from 'antd';
import { TabList, Tab, Tabs } from 'react-tabs';
import { Accordion } from 'react-accessible-accordion';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import each from 'lodash/each';
import PermissionListItem from './PermissionListItem';
import Action, { nonames } from './action';
import CommonApi from 'constants/CommonApi';
import UtilService from 'services/util';
import NoDataShow from 'components/DiamondListing/NoDataShow';
import OpenNotification from 'constants/OpenNotifications';
import { PERMISSION, USER_PERMISSION } from 'constants/permission';
import CircularProgress from 'components/CircularProgress';
import BottomAction from 'containers/BottomAction';
import { USERS_TYPE } from 'constants/Common';
import { getMastersList } from 'services/SubMasterApi';
import find from 'lodash/find';

class Permission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainModule: '',
      permission: [],
      loader: false,
      tab: 1,
      roleList: [],
    };
  }

  componentDidMount() {
    this.fetchPermission();
    if (!this.props.rolePermission) this.fetchRole();
  }

  fetchRole = () => {
    // this.getMastersList();
  };

  // getMastersList = async () => {
  //   const [err, res] = await getMastersList({
  //     isOnlyParents: true,
  //     isDeleted: false,
  //   });
  //   if (!err && res?.code == 'OK' && res?.data?.length) {
  //     let parentId = find(res.data, { code: 'USER_ROLES' }) ? find(res.data, { code: 'USER_ROLES' }).id : null;
  //     if (parentId) this.getSubMastersList(parentId);
  //   }
  // };

  // getSubMastersList = async (parentId) => {
  //   let obj = {
  //     parentId,
  //     isDeleted: false,
  //     filter: {},
  //   };
  //   const [err, res] = await getMastersList(obj);
  //   if (!err && res?.code == 'OK' && res?.data?.length) {
  //     this.setState({ roleList: res.data });
  //   }
  // };

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.user && prevProps.user && this.props.user.id !== prevProps.user.id) ||
      (this.props.rolePermission && this.props.role !== prevProps.role) ||
      this.props.tab !== prevProps.tab
    ) {
      this.setState(
        {
          tab:
            this.props?.user?.type === USERS_TYPE.SELLER || this.props?.user?.type === USERS_TYPE.ADMIN
              ? this.state.tab
              : 1,
        },
        () => this.fetchPermission(),
      );
    }
  }

  fetchPermission = () => {
    if ((!this.props.user && !this.props.rolePermission) || (this.props.rolePermission && !this.props.role)) return;
    let obj = CommonApi.UserPermit.Paginate;
    obj.request = this.props.rolePermission
      ? {
          role: this.props.role,
        }
      : {
          userId: this.props.user && this.props.user.id,
        };
    let self = this;
    this.setState({ loader: true });
    UtilService.callApi(obj, (err, res) => {
      self.setState({ loader: false });
      let flag =
        (this.props.rolePermission && this.props.tabs && this.props.tab === 0) ||
        (!this.props.rolePermission && this.state.tab === 0)
          ? res?.data?.adminPermission
          : (this.props.rolePermission && this.props.tabs && this.props.tab === 1) ||
            (!this.props.rolePermission && this.state.tab === 1)
          ? res?.data?.permissions
          : this.props.rolePermission
          ? res?.data?.adminPermission
          : res?.data?.permissions;
      let constPermission =
        (this.props.rolePermission && this.props.tabs && this.props.tab === 1) ||
        (!this.props.rolePermission && this.state.tab === 1)
          ? cloneDeep(USER_PERMISSION)
          : cloneDeep(PERMISSION);
      if (!err && res && res?.data && flag) {
        let respii = flag && flag.length ? flag : cloneDeep(constPermission);
        let permission = cloneDeep(constPermission);
        permission.forEach((val) => {
          let found = respii.filter(
            (el) =>
              el.module === val.module &&
              el.mainModule === val.mainModule &&
              (!el.subModule || !val.subModule ? true : el.subModule === val.subModule),
          );
          val.permissions = found.length ? found[0].permissions : val.permissions;
          if ((val.mainModule === 'TRANSACTION' || val.mainModule === 'CLIENT') && val.subModule === 'QUOTE') {
            val.permissions.isShowClientColumn =
              found.length && found[0].permissions.hasOwnProperty('isShowClientColumn')
                ? found[0].permissions.isShowClientColumn
                : true;
          }
          if (val.mainModule === 'INVENTORY' && val.subModule === 'SINGLE_STONE' && val.module === 'UPCOMING') {
            val.permissions.isShowBack =
              found.length && found[0].permissions.hasOwnProperty('isShowBack')
                ? found[0].permissions.isShowBack
                : true;
          }
        });
        self.setState({ permission: cloneDeep(permission) });
      } else self.setState({ permission: cloneDeep(constPermission) });
    });
  };

  updatePermission = () => {
    let obj = CommonApi.UserPermit.Update;
    obj.request = this.props.rolePermission
      ? {
          role: this.props.role,
          adminPermission: this.props.tabs && this.props.tab === 1 ? undefined : this.state.permission,
          permissions: this.props.tabs && this.props.tab === 1 ? this.state.permission : undefined,
        }
      : {
          userId: this.props.user && this.props.user.id,
          adminPermission: this.state.tab === 1 ? undefined : this.state.permission,
          permissions: this.state.tab === 1 ? this.state.permission : undefined,
        };
    let self = this;
    this.setState({ loader: true });
    UtilService.callApi(obj, (err, res) => {
      self.setState({ loader: false });
      if (!err && res && res?.data) OpenNotification({ type: 'success', title: res?.message });
    });
  };

  handleChange = ({ checked, permitKey }, obj) => {
    let permission = cloneDeep(this.state.permission);
    let ind = findIndex(permission, {
      module: obj.module,
      ...(obj.mainModule ? { mainModule: obj.mainModule } : {}),
      ...(obj.subModule ? { subModule: obj.subModule } : {}),
    });
    if (permitKey !== 'all') {
      permission[ind].permissions = { ...permission[ind].permissions, [permitKey]: checked };
      if (!checked) permission[ind].permissions.all = false;
    } else {
      Object.keys(permission[ind].permissions).map((k) => {
        permission[ind]['permissions'][k] = checked;
        return true;
      });
    }
    this.setState({ permission });
  };

  handleSubCheck = ({ checked, permitKey }, subModule, mainModule) => {
    let permission = cloneDeep(this.state.permission);
    each(permission, (val) => {
      if (val.subModule === subModule && val.mainModule === mainModule) {
        if (permitKey === 'all') {
          Object.keys(val.permissions).map((k) => {
            val['permissions'][k] = checked;
            return true;
          });
        } else {
          val.permissions = { ...val.permissions, [permitKey]: checked };
          if (!checked) val.permissions.all = false;
        }
      }
    });
    this.setState({ permission });
  };

  handleAllSubCheck = (checked) => {
    let permission = cloneDeep(this.state.permission);
    each(permission, (val) => {
      if (val.mainModule === this.state.mainModule) {
        Object.keys(val.permissions).map((k) => {
          val['permissions'][k] = checked;
          return true;
        });
      }
    });
    this.setState({ permission });
  };

  display = (name, title) => {
    return title
      ? title
      : name
      ? name
          .split('_')
          .map((x) => x.toLowerCase())
          .join(' ')
      : '';
  };

  getSubPermitObject = (sub, main) => {
    const permission = this.state.permission.filter((el) => el.mainModule === main && el.subModule === sub);
    let permit = {
      view: true,
      insert: true,
      update: true,
      delete: true,
      uploadExcel: true,
      downloadExcel: true,
      mailExcel: true,
      printPDF: true,
      all: true,
    };
    Object.keys(permit).map((key) => {
      each(permission, (val) => {
        if (!val.permissions[key]) {
          permit[key] = false;
        }
      });
    });
    return permit;
  };

  handleTab = (tab) => {
    this.setState({ tab }, () => this.fetchPermission());
  };

  render() {
    let mainModules =
      (this.props.rolePermission && this.props.tabs && this.props.tab === 1) ||
      (!this.props.rolePermission && this.state.tab === 1)
        ? []
        : uniqBy(this.state.permission, 'mainModule');
    let subModules = uniqBy(
      this.state.permission.filter((el) => el.subModule && el.mainModule === this.state.mainModule),
      'subModule',
    );
    let modules = !this.state.mainModule
      ? groupBy(this.state.permission, 'mainModule')
      : groupBy(
          this.state.permission.filter((el) => el.mainModule === this.state.mainModule),
          'subModule',
        );
    let coded = {
      all: 'All',
      view: 'View',
      insert: 'Add',
      update: 'Update',
      delete: 'Delete',
      uploadExcel: 'Upload',
      downloadExcel: 'Download',
      mailExcel: 'Mail',
      printPDF: 'Print',
    };

    return (
      <>
        {this.state.loader && <CircularProgress className="fixLoader" />}
        {!this.props.rolePermission &&
          (this.props?.user?.type === USERS_TYPE.SELLER || this.props?.user?.type === USERS_TYPE.ADMIN) && (
            <Tabs className="rightStickyTab" selectedIndex={this.state.tab}>
              <TabList className="customerMaintab">
                <Tab onClick={() => this.handleTab(0)}>Admin Website</Tab>
                <Tab onClick={() => this.handleTab(1)}>Customer Website</Tab>
              </TabList>
            </Tabs>
          )}
        {!this.state.permission.length ? (
          <NoDataShow />
        ) : (
          <div
            className="menuAccessWrapper permissionWrapper mt-20"
            style={this.props.rolePermission ? { height: this.props.rolePermission ? '75vh' : '80vh' } : {}}
          >
            <div className="menuAccessBlock">
              {mainModules.length > 0 && (
                <div
                  className={`mainMenuBlock ${this.state.mainModule === '' ? 'active' : ''}`}
                  onClick={() => this.setState({ mainModule: '' })}
                >
                  All
                </div>
              )}
              {mainModules.map((x, index) => (
                <div
                  key={index}
                  className={`mainMenuBlock ${this.state.mainModule === x.mainModule ? 'active' : ''}`}
                  onClick={() => this.setState({ mainModule: x.mainModule })}
                >
                  {this.display(x.mainModule)}
                </div>
              ))}
            </div>
            {/* <div className={`d-flex align-items-center permissionList permissionHead`}>
              <div className="d-flex align-items-center permissionLeft">
                <span>{}</span>
              </div>
              <div className="d-flex align-items-center j-space-between permissionAction">
                {nonames.map((k) => {
                  return (
                    <div className="d-flex align-items-center j-space-between permissionActionList">
                      <h2>{coded[k]}</h2>
                    </div>
                  );
                })}
              </div>
            </div> */}
            <div className="inventoryMenuPermission">
              {subModules.length > 0 && (
                <div className="menuSubAccess">
                  <div className="submenuacessBlock">
                    <Checkbox
                      onChange={(e) => {
                        const checked = e.target.checked;
                        this.handleAllSubCheck(checked);
                      }}
                      checked={
                        subModules
                          .map((x) => this.getSubPermitObject(x.subModule, x.mainModule).all)
                          .filter((el) => el && el).length === subModules.length
                      }
                    />
                    <span>All</span>
                  </div>
                  {subModules.map((x, index) => (
                    <div key={index} className="submenuacessBlock">
                      <Checkbox
                        onChange={(e) =>
                          this.handleSubCheck(
                            { checked: e.target.checked, permitKey: 'all' },
                            x.subModule,
                            x.mainModule,
                          )
                        }
                        checked={this.getSubPermitObject(x.subModule, x.mainModule).all}
                      />
                      <span>{this.display(x.subModule)}</span>
                    </div>
                  ))}
                </div>
              )}
              <div className={`d-flex align-items-center permissionList permissionHead`}>
                <div className="d-flex align-items-center permissionLeft">
                  <span>{}</span>
                </div>
                <div className="d-flex align-items-center j-space-between permissionAction">
                  {nonames.map((k) => {
                    return (
                      <div className="d-flex align-items-center j-space-between permissionActionList">
                        <h2>{coded[k]}</h2>
                      </div>
                    );
                  })}
                </div>
              </div>
              {Object.keys(modules).map((key, index) => {
                let mod = modules[key];
                let subModuleCheck = mod[0]?.subModule
                  ? this.getSubPermitObject(mod[0].subModule, mod[0].mainModule)
                  : {};
                return (
                  <div key={index} className="menuInnerBlock" style={index === 0 ? { marginTop: '0px' } : {}}>
                    {((this.state.mainModule && mod[0]?.subModule) ||
                      (!this.state.mainModule && mod[0]?.mainModule)) && (
                      <div className="d-flex align-items-center permissionList permissionHead">
                        <div className="d-flex align-items-center permissionLeft">
                          <h2>{this.display(key, mod.title)}</h2>
                        </div>
                        {this.state.mainModule && (
                          <Action
                            module={subModuleCheck}
                            handleChange={(e) => this.handleSubCheck(e, mod[0]?.subModule, mod[0].mainModule)}
                          />
                        )}
                      </div>
                    )}
                    <div className="collpaseMenuAccess permissionList">
                      <Accordion allowZeroExpanded>
                        {mod.map((x, index) => (
                          <PermissionListItem
                            key={index}
                            moduleName={x.module}
                            name={this.display(x.module, x.title)}
                            className="submenuSingle"
                            module={x.permissions}
                            handleChange={(e) => this.handleChange(e, x)}
                          />
                        ))}
                      </Accordion>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {this.state.permission.length ? (
          <div
            style={{
              position: 'absolute',
              padding: '5px',
              bottom: '3px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <BottomAction title="Save" onClick={this.updatePermission} />
          </div>
        ) : null}
      </>
    );
  }
}

export default connect(
  ({ tab }) => ({
    user: tab?.userList?.userData,
  }),
  {},
)(withRouter(Permission));
