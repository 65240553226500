import React from 'react';

const BasicDetail = (props) => {
  let dt = props.data;

  return (
    <div>
      <ul className="basicDetailFull">
        <ul className="basicDetailFull">
          <li>{dt.vStnId}</li>
          <li>{dt.shpNm}</li>
          <li>{dt.crt}</li>
          <li>{dt.colNm}</li>
          <li>{dt.clrNm}</li>
          <li>2.96%</li>
          <li>{dt.cutNm}</li>
          <li>{dt.polNm}</li>
          <li>{dt.symNm}</li>
        </ul>
      </ul>
    </div>
  );
};
export default BasicDetail;
