import React, { Component } from 'react';
import ListingWrapper from '../../containers/ListingWrapper';
import ListingTableWrapper from '../../containers/ListingTableWrapper';
import MainHeading from '../../containers/MainHeading';
import Table from '../../components/Inventory/TableBack';
import $ from 'jquery';
import { find, remove, cloneDeep, findIndex, compact } from 'lodash';
import Messages from '../../constants/Messages';
import OpenNotification from 'constants/OpenNotifications';
import { connect } from 'react-redux';
import UtilService from '../../services/util';
import CommonApi from '../../constants/CommonApi';
import { HeadingCalc } from './CommonPopupFunction';

const currentType = 'commentpopup';

class CommentPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      stoneblock: false,
      gridHeight: 0,
      mainNote: '',
      notes: {},
      data: cloneDeep(this.props.checked),
      columns: [],
    };
  }

  componentDidMount() {
    let columns = this.props.columns;
    let extraColumns = [
      {
        Header: 'Note',
        accessor: 'note',
        Cell: ({ row }) => (
          <span className="tableInputValue noteAdd">
            <input
              onChange={(e) => {
                const val = e.target.value;
                let line = this.state.data;
                let ind = findIndex(line, { id: row.original.id });
                line[ind].note = val;
                this.setState({ data: line }, () => {
                  let checked = this.state.data.filter((el) => find(this.state.checked, { id: el.id }));
                  this.setState({ checked }, () => $('#' + row.original.id).focus());
                });
              }}
              id={row.original.id}
              value={row.original.note}
            />
          </span>
        ),
        id: 'note',
      },
      ...columns,
    ];
    if (this.props.inDNA) {
      let columnName = ['dna', 'action'];
      extraColumns = [...extraColumns.filter((el) => !find(columnName, (name) => name == el.id))];
    }
    this.setState({ columns: extraColumns });
  }

  onChange = (e) => {
    this.setState({
      mainNote: e.target.value,
    });
  };

  deleteComment = (id) => {
    let data = this.state.checked;
    remove(data, (d) => d.id === id);
    this.setState({ checked: data });
  };

  onAddComment = () => {
    if (this.props.client !== '') {
      let notes = compact(this.state.checked.map((d) => d.note));
      if (notes.length !== this.state.checked.length) {
        OpenNotification({
          type: 'error',
          title: Messages.CommentRequire,
        });
      } else {
        let obj = {
          method: CommonApi.Inventory.diamondComment.method,
          url: CommonApi.Inventory.diamondComment.url,
          request: {
            userAccount: this.props?.client?.id,
            diamonds: this.state.checked.map((d) => ({ diamond: d.id, remarks: d.note })),
          },
        };
        UtilService.callApi(obj, (err, data) => {
          if (data && data.code === 'OK') {
            this.props.onClose();
            if (!this.props.inDNA) {
              void this.props.unCheckedDiamond?.();
            }
            OpenNotification({
              type: 'success',
              title: data.message,
            });
          }
        });
      }
    } else {
      OpenNotification({
        type: 'error',
        title: Messages.Error.Required.Company,
      });
    }
  };

  render() {
    return (
      <>
        <div className="d-flex align-items-center oneLineTotal">
          <MainHeading className="mb-0 mr-40" title="Add Note" />
          {HeadingCalc(this.state.checkedData, 'note')}
        </div>
        <ListingWrapper>
          <ListingTableWrapper subtractFrom=".oneLineTotal,.sidebarAction" adjustment={-15}>
            {this.state.data && (
              <Table
                data={this.state.data}
                columns={this.state.columns}
                canSort={false}
                currentType={currentType}
                FilterOption={false}
                areAllChecked
                noheader
                noGrp
                nodots
              />
            )}
          </ListingTableWrapper>
        </ListingWrapper>
        <div className="sidebarAction">
          <button className="commonButton" onClick={this.onAddComment}>
            Add Note
          </button>
          <button className="commonOutine" onClick={this.props.onClose}>
            Cancel
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ diamondData }) => {
  return { checkedData: diamondData?.selectedRows?.[currentType] ?? [] };
};

export default connect(mapStateToProps, null)(CommentPopup);
