import React, { useCallback } from 'react';
import UtilService from 'services/util';
import CommonApi from 'constants/CommonApi';
import OpenNotification from 'constants/OpenNotifications';
import { DIAMOND_BLOCK, USERS_TYPE } from 'constants/Common';
import { roundkeys, floatkeys, LIMIT } from './TableBack';
import { formatNumber } from 'components/DiamondListing/SelectStone';
import _each from 'lodash/each';
import { isArray, isFunction, isString, queryStringToObject } from 'util/utils';
import { GRID_COLUMN } from 'constants/Inventory';
import ClientName from 'components/Client/ClientName';
import { getDate, getDateTime } from 'services/commonFunc';
import { FILTER_COLUMNS } from './TableBack';
import Storage from 'services/storage';
import find from 'lodash/find';
import { Popover, Tooltip } from 'antd';
import moment from 'moment';
import * as SearchAPI from 'services/SearchApi';
import { memoize } from 'lodash';

export const searchFn = async ({ isPlatformSearch = false, ...object }, callback) => {
  // remove empty keys
  _each(object, (val, key) => {
    if (isArray(val) && val.length === 0) delete object[key];
    else if (isArray(val)) object[key] = val;
  });

  const [, res] = await SearchAPI.getSearchIds(object);
  if (res?.code === 'OK') {
    if (res?.data?.[0]?.count === 0 && !isPlatformSearch)
      return OpenNotification({ type: 'error', title: "No diamond's exist" });

    let searchIds = res.data.map((d) => d?.filter?.id);
    const NOSTONE = res?.data?.[0]?.count === 0 ? 'NOSTONE' : '';
    callback(false, { NOSTONE, searchIds });
  } else {
    callback(true);
  }
};

export const getSearchIds = (params) => {
  return new Promise((resolve) => {
    searchFn(params, (err, res) => {
      resolve([err, { notFound: res?.NOSTONE === 'NOSTONE', list: res?.searchIds }]);
    });
  });
};

export function getClientList(cb) {
  const config = { ...CommonApi.Account.paginate };
  UtilService.callApi(config, (err, res) => {
    cb(res.code === 'OK' ? res : null);
  });
}

export function handleInsertMemo(available, user, cb) {
  const config = { ...CommonApi.Memo.Insert };
  config.request = {
    user,
    blockType: DIAMOND_BLOCK.TYPE.MEMO,
    diamonds: available.map((x) => ({
      diamond: x.id,
      blockPricePerCarat: x.ctPr,
      blockAmount: x.amt,
      vnd: x.vnd,
      vStnId: x.vStnId,
    })),
  };
  UtilService.callApi(config, (err, res) => {
    if (res && res.code === 'OK') {
      OpenNotification({ type: 'success', title: res.message });
      cb(true);
    } else {
      cb(false);
    }
  });
}

export const handleMatchPair = async (filter, cb) => {
  const request = { filter, page: 1, limit: LIMIT };
  const [, res] = await UtilService.callApiAsync({ ...CommonApi.MatchPair.Insert, request });
  if (res?.code === 'OK') cb(res?.data?.filter?.id);
};

export const handlePlaceOrder = (request, cb, removeDiamond = null, loadingFn) => {
  const requestConf = { ...CommonApi.DiamondConfirm.Request, request };
  loadingFn(true);
  UtilService.callApi(requestConf, (err, res) => {
    if (isFunction(cb)) cb(res?.code === 'OK');
    if (res?.code === 'OK') OpenNotification({ type: 'success', title: res.message });
  });
};

const ColumnFilter = React.memo(function ColumnFilter(props) {
  const {
    column: { filterValue = [], setFilter, preFilteredRows, id, isSorted },
  } = props;
  const availableOptions = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (!filterValue.includes(row.values[id])) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows, filterValue]);

  const toggleFilter = useCallback(
    (id) => {
      const selectedFilters = [...filterValue];
      if (selectedFilters.includes(id)) {
        selectedFilters.splice(
          selectedFilters.findIndex((x) => x == id),
          1,
        );
      } else {
        selectedFilters.push(id);
      }
      setFilter(selectedFilters);
    },
    [setFilter, filterValue],
  );

  return (
    <div className="llfilterMenuDropdown" style={isSorted ? { borderTop: '1px solid #e9ebf0' } : {}}>
      {filterValue && filterValue.length > 0 && (
        <ul className="llfilterSelect">
          <div className="llSortingTitle">Selected Filter</div>
          {filterValue.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`}>
                {x}
                <div className="closeFilter" onClick={() => toggleFilter(x)}>
                  <img src={require('../../assets/svg/InventoryResult/close-black.svg')} alt="icon" />
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {availableOptions && availableOptions.length > 0 && (
        <ul>
          <div className="llSortingTitle">Select Filter</div>
          {availableOptions.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`} onClick={() => toggleFilter(x)}>
                {x}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
});

// Define a custom filter filter function!
function filterArrayIncludes(rows, id, filterValue = []) {
  if (filterValue.length === 0) return rows;
  return rows.filter((row) => filterValue.includes(row.values[id]));
}
filterArrayIncludes.autoRemove = (val) => !val || !val.length;
export function isMobile() {
  return window.innerWidth <= 991 ? true : false;
}

/**
 *
 * @param {*} cb
 * @param {*} grid
 */
const LOCATION = {
  india: 'IND',
  beligium: 'BEL',
  newyork: 'NY',
  hongkong: 'HK',
  dubai: 'DXB',
};
export function getPopover(row, key) {
  return (
    <div className="d-flex">
      {row.original.bargains.length === 0 && '-'}
      {row.original.bargains.slice(0, 3).map((x, index) => (
        <span key={index} className={`offerPrice ${x.type}`}>
          <span>{x[key]}</span>
        </span>
      ))}
      {row.original.bargains.length > 3 && (
        <Popover
          content={
            <>
              {row.original.bargains.map((x, index) => (
                <span key={index} className={`offerPrice ${x.type}`}>
                  <span>{x[key]}</span>
                </span>
              ))}
            </>
          }
        >
          <span className="moreValue">
            <img src={require('assets/svg/InventoryResult/more-black.svg')} alt="" />
          </span>
        </Popover>
      )}
    </div>
  );
}
export const fetchcolumn = async (cb, grid, options) => {
  const fetchcolumnDefaultOptions = { shortDate: false };
  const { shortDate } = { fetchcolumnDefaultOptions, ...options };
  const userType = Storage.get('user')?.type;

  let columns = [];
  const NOTUPCOMING = userType === USERS_TYPE.SELLER ? ['amt', 'ctPr', 'rapAvg', 'back'] : [];
  const requestConf = {
    ...CommonApi.Gridcolumn.list,
    request: { name: grid ? grid : GRID_COLUMN.GRID_DIAMOND_LIST_ADMIN },
  };
  const [, res] = await UtilService.callApiAsync(requestConf);

  if (res.code !== 'OK') return cb([]);

  const columnList = isArray(res?.data?.columns) && res.data.columns.map((column) => ({ ...column }));
  columnList.forEach((column) => {
    if (column.isActive && !column.isStatic && column.field) {
      column.field = column.field.trim();
      column.title = column.title ? column.title.trim() : column.title;
      // if (column.field === 'reminderDate' && window.location.pathname.includes('reminder')) return;
      if (column.field === 'remarks' && options === 'note') column.title = 'Notes';
      if (NOTUPCOMING.includes(column.field) && window.location.pathname.includes('upcoming')) return;
      const canFilter = FILTER_COLUMNS.includes(column.field);
      if (column.field === 'dna') column.link = null;
      let master = Storage.get('dn-master');
      columns.push({
        cellClass: column.cellClass,
        accessor: column.field,
        Header: column.title,
        id: column.field,
        desc: column.desc,
        link: column.link,
        sort: column.sort,
        Filter: canFilter ? ColumnFilter : <></>,
        filter: filterArrayIncludes,
        Cell: React.memo(function Cell({ row, cell }) {
          if (column.field === 'dna') cell.value = row.original.vStnId;
          const getClient = () => <ClientName user={cell.value} />;
          let fullName =
            cell.value &&
            master &&
            column.key &&
            master[column.key] &&
            find(master[column.key], { code: cell.value }) &&
            find(master[column.key], { code: cell.value }).description
              ? find(master[column.key], { code: cell.value }).description
              : null;

          const value =
            row.original.bargains && column.field === 'newPricePerCarat' ? (
              getPopover(row, 'ctpr')
            ) : row.original.bargains && column.field === 'newAmount' ? (
              //  getPopover(row, 'amt')
              <span className={`offerPrice current`}>
                <span>{row.original?.bargains?.[0]?.amt}</span>
              </span>
            ) : row.original.bargains && column.field === 'newDiscount' ? (
              getPopover(row, 'offer')
            ) : row.original.bargains && column.field === 'bargains' ? (
              <div className="d-flex">
                {row.original.bargains.length === 0 && '-'}
                {row.original.bargains.slice(0, 3).map((x, index) => (
                  <span className={`offerPrice ${x.type}`} key={index}>
                    <Tooltip title={x.time}>
                      <span>{x.offer}</span>
                    </Tooltip>
                  </span>
                ))}
                {row.original.bargains.length > 3 && (
                  <Popover
                    content={
                      <>
                        {row.original.bargains.map((x, index) => (
                          <span className={`offerPrice ${x.type}`} key={index}>
                            <Tooltip title={x.time}>
                              <span>{x.offer}</span>
                            </Tooltip>
                          </span>
                        ))}
                      </>
                    }
                  >
                    <span className="moreValue">
                      <img src={require('assets/svg/InventoryResult/more-black.svg')} alt="" />
                    </span>
                  </Popover>
                )}
              </div>
            ) : column.isFlag && cell.value ? (
              isString(column.sLabel) ? (
                column.sLabel
              ) : (
                'Y'
              )
            ) : column.isFlag && !cell.value ? (
              isString(column.nLabel) ? (
                column.nLabel
              ) : (
                '-'
              )
            ) : cell.value === true ? (
              'Y'
            ) : !cell.value ? (
              '-'
            ) : fullName ? (
              <Tooltip key={cell.row.id + cell.column.id} title={fullName}>
                {cell.value}
              </Tooltip>
            ) : column.field === 'locNm' ? (
              LOCATION[cell.value.trim().toLowerCase()] || cell.value
            ) : column.field === 'createdAt' ? (
              shortDate ? (
                getDate(cell.value)
              ) : (
                getDateTime(cell.value)
              )
            ) : column.field === 'user' ? (
              getClient()
            ) : column.field === 'back' ? (
              row.original.ctPr < row.original.rap ? (
                `-${parseFloat(Math.abs(cell.value)).toFixed(2)}`
              ) : cell.value === 0 ? (
                Math.abs(cell.value)
              ) : (
                `+${parseFloat(Math.abs(cell.value)).toFixed(2)}`
              )
            ) : floatkeys.includes(column.field) ? (
              formatNumber(parseFloat(cell.value).toFixed(2))
            ) : roundkeys.includes(column.field) ? (
              formatNumber(Math.round(cell.value))
            ) : (
              cell.value
            );

          return (
            <div
              className={floatkeys.includes(column.field) || roundkeys.includes(column.field) ? 'numberValue' : null}
            >
              {value}
            </div>
          );
        }),
      });
    }
  });

  cb(columns);
};

// async proxy for fetchcolumn
export const fetchcolumnAsync = (...args) => new Promise((resolve) => fetchcolumn(resolve, ...args));
// memoized async proxy for fetchcolumn
export const fetchcolumnAsyncMemo = memoize(fetchcolumnAsync, (...args) => JSON.stringify(args));

export const allSortObject = (columns) =>
  isArray(columns) &&
  Object.fromEntries(columns.filter(({ sort }) => sort).map(({ sort, Header }) => [`${sort}`, Header]));

export function getNewStatus(row) {
  let diff = moment() - moment(row.inDt);
  let newInvent = diff > 7 * 24 * 60 * 60 * 1000 || row.wSts === 'B' || row.wSts === 'U' ? false : true;
  return newInvent;
}

export const canadaForeverMarkFilter = () => {
  const type = queryStringToObject()?.type;
  let extraFilter = {};

  if (type === 'CANADA_MARK') {
    extraFilter = { isCm: ['CERT', 'ELIG'] };
  } else if (type === 'FOREVER_MARK') {
    extraFilter = { isFm: ['CERT', 'ELIG'] };
  }
  return extraFilter;
};
