import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import './leftOption.less';
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const monthFormat = 'MM/YYY';

const LeftDatePicker = (props) => {
  return (
    <div className="leftSelectItem">
      <div className="leftSelectTitle">
        <h2>{props.title}</h2>
        {props.image && <img src={props.image} alt="icon" className="reloadImg" />}
      </div>
      {props.Singledate ? (
        <DatePicker
          className="SelectDateRange"
          disabledDate={(current) => {
            return current <= moment().startOf('day') || moment(current).isoWeekday() === 7;
          }}
          onChange={props.onDateChange}
          format={dateFormat}
          value={props.date ? moment(props.date) : undefined}
        />
      ) : (
        <RangePicker
          onChange={props.onChange}
          className="SelectDateRange"
          defaultValue={[
            props?.value?.from ? moment(props?.value?.from, dateFormat) : '',
            props?.value?.to ? moment(props?.value?.to, dateFormat) : '',
          ]}
          format={dateFormat}
        />
      )}
    </div>
  );
};
export default LeftDatePicker;
