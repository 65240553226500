import React from 'react';

import PropTypes from 'prop-types';

import FileUploaded from 'containers/FileUploaded';

import UtilService from 'services/util';

import classNames from 'util/classNames';
import { useLoading } from 'util/hooks';
import { isArray, isEmpty, isFunction, isString } from 'util/utils';

import CommonApi from 'constants/CommonApi';
import OpenNotification from 'constants/OpenNotifications';

import fileUploadSvg from './fileupload.svg';

import uploadSvg from 'assets/svg/upload.svg';
import './fileUpload1.less';

export const MIME_TYPES = {
  'image/png': ['89504e47'],
  'image/jpeg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb', 'ffd8ffe2'],
  'image/gif': ['47494638'],
  'image/jpg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
  'application/pdf': ['25504446'],
  'text/csv': ['73646673'],
  'application/vnd.ms-excel': ['504b0304'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['504b0304'],
  'video/x-matroska': ['1a45dfa3'],
  'video/mp4': ['00000020', '0000001c', '00000018'],
  'video/x-ms-wmv': ['3026b275'],
};

const FileUpload = ({ label, onChange, error, folder = 'image', noPreview, files: _files, children, ...props }) => {
  const [files, setFiles] = React.useState([]);
  const [storedFromProps, setStoredFromProps] = React.useState(false);
  const [loading, startLoading, stopLoading] = useLoading(false);

  const allowedTypes = React.useMemo(() => {
    return isString(props?.accept) ? props.accept.split(',').map((str) => `${str}`.trim().toLowerCase()) : undefined;
  }, [props.accept]);

  const typeErrorMessage = React.useMemo(
    () =>
      `File format is not supported. Please upload ${isArray(allowedTypes) || isString(allowedTypes)
        ? allowedTypes.join(', ')
        : '.jpg, .jpeg, .png, .gif, .mp4 or .pdf'
      } file.`,
    [allowedTypes],
  );

  const existingFiles = React.useMemo(() => {
    if (storedFromProps) return undefined;
    if (!isArray(_files)) return undefined;

    const filteredFiles = _files?.filter(Boolean);
    if (filteredFiles?.length < 1) return undefined;

    return props?.multiple ? filteredFiles : [filteredFiles[0]];
  }, [storedFromProps, _files, props?.multiple]);

  React.useEffect(() => {
    if (storedFromProps || !isArray(existingFiles)) return;
    setFiles(existingFiles);
    setStoredFromProps(true);
  }, [existingFiles, storedFromProps]);

  React.useEffect(() => {
    if (!isArray(files) || !isFunction(onChange)) return;
    const newFiles = files
      .map((file) =>
        !isEmpty(file?.absolutePath)
          ? window.location.pathname == '/uniquediamax/notification/bulk-email'
            ? file?.path
            : file?.absolutePath
          : isString(file)
            ? file
            : undefined,
      )
      .filter(Boolean);
    onChange(props?.multiple ? newFiles : newFiles?.[0] || '');
    if (isFunction(props.onFileObject)) props.onFileObject(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, props?.multiple]);

  const uploadDocument = React.useCallback(
    (file) => {
      const fileReader = new FileReader();
      fileReader.onloadend = function (ev) {
        const arr = new Uint8Array(ev.target.result).subarray(0, 4);
        let header = '';
        let headerVal = '';
        arr.forEach((value, index) => {
          headerVal = arr[index];
          if (arr[index].constructor.name === 'Number' && arr[index] < 10) {
            headerVal = ('0' + arr[index]).slice(-2);
          }

          header += headerVal.toString(16);
          console.log("headerVal", header);

        });
        console.log(file?.type, 'file?.type', header, 'header', arr);
        if (!MIME_TYPES?.[file?.type] || (MIME_TYPES?.[file?.type] && !MIME_TYPES?.[file?.type].includes(header))) {
          return OpenNotification({ type: 'error', title: typeErrorMessage });
        }

        const request = new FormData();
        request.append('folder', folder);
        request.append('destination', folder);
        request.append('file', file);

        startLoading();
        UtilService.callApi({ ...CommonApi.File.Upload, request }, (err, res) => {
          if (res && res.code === 'OK') {
            if (isEmpty(res?.data?.files?.[0])) return;
            if (!storedFromProps) setStoredFromProps(true);
            props?.multiple
              ? setFiles((files) => [...files, res?.data?.files?.[0]])
              : setFiles([isEmpty(res?.data?.files?.[0]) ? '' : res?.data?.files?.[0]]);
          }
          stopLoading();
        });
      };
      fileReader.readAsArrayBuffer(file);
    },
    [folder, props?.multiple, startLoading, stopLoading, storedFromProps, typeErrorMessage],
  );

  const removeFile = React.useCallback(
    (index) => {
      if (!isArray(files)) return; // Return if not array
      if (index < 0 || index >= files.length) return; // return if index is invalid
      // remove from index
      files.splice(index, 1);
      // setState
      props?.multiple ? setFiles([...files]) : setFiles([]);
    },
    [files, props?.multiple],
  );

  const handleChange = React.useCallback(
    (ev) => {
      // Copy FileList to an Array
      const fileList = [...ev.target?.files];
      // Clear file input
      if (ev?.target?.value) ev.target.value = '';

      // Validate extension if specified
      if (props?.isValidate) {
        const validate = fileList.filter((file) => {
          const fileExtension = `${file.name}`.split('.').pop().toLowerCase();
          return !props?.accept
            ?.split(',')
            ?.map((extVal) => `${extVal}`.trim().toLowerCase())
            ?.toString()
            ?.includes(fileExtension);
        });
        // Notify and Return if invalid
        if (validate?.length) {
          return OpenNotification({ type: 'error', title: typeErrorMessage });
        }
      }

      // Upload files
      fileList.forEach(uploadDocument);
    },
    [props?.accept, props?.isValidate, typeErrorMessage, uploadDocument],
  );

  return (
    <>
      {props?.diamondUpload ? (
        <div className={`select-file ${props.className}`}>
          <input
            {...{ type: 'file', id: 'image', name: 'image', ...props, accept: props.fileType }}
            onChange={handleChange}
          />
          {!noPreview && !_files?.filter((item) => item)?.length && (
            <div className="upload_images_content">
              <img src={uploadSvg} alt="upload" />
              <p className="title">Select File</p>
              <p className="desc">Drop file here or Browse</p>
              {loading ? (
                <div className="message">Uploading Files...</div>
              ) : (
                error && <div className="error">{error}</div>
              )}
            </div>
          )}

          {!noPreview && isArray(_files) && (
            <div className="fileUploadedBlock">
              {children}
              {files.map((file, index) => {
                return (
                  file && <FileUploaded size="smallSize" key={index} file={file} onDelete={() => removeFile(index)} />
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={`from-group mb-15 ${props.fullWidth}`}>
            <div
              className={classNames([
                'uploadeDocumentWrapper ',
                !loading && error && 'error-message-file',
                props?.className,
              ])}
            >
              <div className="uploadeDocument">
                <img src={fileUploadSvg} alt="file upload" />
              </div>
              <div className="uploadeTitle">{label ? label : 'Image'}</div>
              <input type="file" id="image" name="image" onChange={handleChange} onError={(e) => { console.log(e, 'e') }} accept={props.fileType} {...props} />
            </div>
            {loading ? (
              <div className="message">Uploading Files...</div>
            ) : (
              error && <div className="error">{error}</div>
            )}
            {props?.size && <span className="sizeText">{props?.size}</span>}
          </div>
          {!noPreview && isArray(_files) && (
            <div className="fileUploadedBlock">
              {children}
              {files.map(
                (file, index) =>
                  file && <FileUploaded size="smallSize" key={index} file={file} onDelete={() => removeFile(index)} />,
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

FileUpload.propTypes = {
  folder: PropTypes.string, // Folder to save files in
  error: PropTypes.string,
  label: PropTypes.string, // Input Label
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
};

export default React.memo(FileUpload);
