import { store } from 'appRedux/store';
import { LoaderActions } from 'appRedux/reducers/LoaderReducer';

export class LoaderService {
  static startLoading() {
    return store.dispatch(LoaderActions.start());
  }

  static stopLoading() {
    return store.dispatch(LoaderActions.stop());
  }

  static adjustCount(count) {
    return store.dispatch(LoaderActions.adjust({ count }));
  }

  static resetLoading() {
    return store.dispatch(LoaderActions.reset());
  }
}
