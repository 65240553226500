import React, { Component } from 'react';
import { TabPanel } from 'react-tabs';
import { TabData } from '../../constants/tab';
import CustomTab from '../../containers/Tab';
import DiamondDetailAction from './Action';
import BasicDetail from './BasicDetail';
import { formatNumber } from 'components/DiamondListing/SelectStone';
import { createDiamondTrack } from 'services/DiamondApi';
import { find } from 'lodash';
import DownloadAction from 'components/DiamondListing/Actions/DownloadAction';
import ShareAction from 'components/DiamondListing/Actions/ShareAction';
import { handlePrint } from 'components/DiamondListing/CommonPopupFunction';
import { FILE_URLS } from '../../constants/Common';
import MagnifierImage from './MagnifierImage';
import { showNotification } from 'services/NotificationService';

class DiamondDetail extends Component {
  state = { tabIndex: 0, subIndex: 0, commentPopup: false, columns: [], sendEmail: false, visible: false, btnName: '' };
  componentDidMount() {
    let columnName = ['dna', 'action'];
    let columns = this.props.columns?.filter((el) => !find(columnName, (name) => name == el.id)) || [];
    this.setState({ columns });
  }

  handleInsertTrack = async (trackType) => {
    let diamonds = [
      {
        diamond: this.props.data.id,
        trackPricePerCarat: this.props.data.ctPr,
        trackAmount: this.props.data.amt,
      },
    ];
    const [err, res] = await createDiamondTrack({ trackType, diamonds, user: '' });
    !err && showNotification(res);
  };
  onVerifyCertificate = () => {
    if (this.props.data?.rptNo) window.open(`https://gia.edu/report-check?reportno=${this.props.data.rptNo}`);
  };
  render() {
    let data = this.props.data || {};

    return (
      <div className="diamondDetailWrapper">
        <CustomTab
          onGetIndex={(ind) => {
            this.setState({ tabIndex: ind });
          }}
          wantIndex
          selectedIndex={this.state.tabIndex}
          data={TabData.diamondDetailtab.filter((d) => !d.hideTab)}
          tabHeaderClass="customerMaintab diamondDetailTopTab"
        >
          <div className="diamondDetailInner">
            <div className="diamondDetailBottomBox">
              <div className="diamondDetailBottomAction">
                <div className="d-flex">
                  <DiamondDetailAction
                    image={require('../../assets/svg/diamondDetail/download-zip.svg')}
                    tooltip="Download"
                    placement="bottomLeft"
                    onClick={() => this.setState({ visible: !this.state.visible, btnName: 'download' })}
                    visible={this.state.visible}
                  />
                  <DiamondDetailAction
                    image={require('../../assets/svg/diamondDetail/share.svg')}
                    tooltip="Share"
                    onClick={() => this.setState({ btnName: 'share', visible: !this.state.visible })}
                  />
                  <DiamondDetailAction
                    image={require('../../assets/svg/diamondDetail/print.svg')}
                    tooltip="Print"
                    onClick={() => handlePrint([data])}
                  />
                  {this.state.visible && (
                    <>
                      {this.state.btnName == 'download' && (
                        <DownloadAction
                          {...data}
                          checked={[data]}
                          onClose={() => this.setState({ visible: !this.state.visible })}
                        />
                      )}
                      {this.state.btnName == 'share' && (
                        <ShareAction
                          {...data}
                          checked={[data]}
                          onClose={() => this.setState({ visible: !this.state.visible })}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <TabPanel className="diamondDetailMainTab">
              <div className="detailHalfWhite"></div>
              <div className="detailHalfGray"></div>
              <div className="diamondDetailLeft">
                {/* <div className="diamondAllPrice">
                  <PriceBlock />
                </div> */}
                <div>
                  <CustomTab
                    wantIndex
                    selectedIndex={this.state.subIndex}
                    data={TabData.diamondDetailSubtab.filter((d) => !d.hideTab)}
                    onGetIndex={(ind) => {
                      this.setState({ subIndex: ind });
                    }}
                    tabHeaderClass="customerMaintab"
                  >
                    <div className="diamondDetailInnerDetail">
                      <TabPanel>
                        <div className="diamondDetailImageBox">
                          <iframe src={FILE_URLS.videoFile.replace('***', data.vStnId)} title="vFile" />
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="diamondDetailImageBox">
                          <MagnifierImage className="imageBlock" image={FILE_URLS.img.replace('***', data.vStnId)} />
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="diamondDetailImageBox">
                          <MagnifierImage className="imageBlock" image={FILE_URLS.hAFile.replace('***', data.vStnId)} />
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="diamondDetailImageBox">
                          <iframe src={FILE_URLS.certFile.replace('***', data.rptNo)} height="100%" title="certi" />
                        </div>
                      </TabPanel>
                    </div>
                  </CustomTab>
                </div>
              </div>
              <div className="diamondDetailRight">
                <BasicDetail data={data} />

                <div className="diamondDetailMore">
                  <h2 className="detailSmallTitle">Basic Details</h2>
                  <div className="diamondDetailMoreHalf">
                    <ul>
                      <li>
                        <span>Price/Carat</span>
                        <span>{formatNumber(parseFloat(data.ctPr).toFixed(2))}</span>
                      </li>
                      <li>
                        <span>Amount</span>
                        <span>{formatNumber(parseFloat(data.amt).toFixed(2))}</span>
                      </li>
                      <li>
                        <span>Lab</span>
                        <span>{data.lbNm || '-'}</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>H&A</span>
                        <span>{data.hANm || '-'}</span>
                      </li>
                      <li>
                        <span>Color Shade</span>
                        <span>{data.shdNm || '-'}</span>
                      </li>
                      <li>
                        <span>Seller Stone No</span>
                        <span>{data.stoneId || '-'}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="diamondDetailMore">
                  <h2 className="detailSmallTitle">Parameter Details</h2>
                  <div className="diamondDetailMoreHalf">
                    <ul>
                      <li>
                        <span>Measurement</span>
                        <span>-</span>
                      </li>
                      <li>
                        <span>Table%</span>
                        <span>{formatNumber(parseFloat(data.tblPer || 0).toFixed(2))}</span>
                      </li>
                      <li>
                        <span>Depth%</span>
                        <span>{formatNumber(parseFloat(data.depthPer || 0).toFixed(2))}</span>
                      </li>
                      <li>
                        <span>Ratio</span>
                        <span>{formatNumber(parseFloat(data.ratio || 0).toFixed(2))}</span>
                      </li>
                      <li>
                        <span>Length</span>
                        <span>{formatNumber(parseFloat(data.length || 0).toFixed(2))}</span>
                      </li>
                      <li>
                        <span>Width</span>
                        <span>{formatNumber(parseFloat(data.width || 0).toFixed(2))}</span>
                      </li>
                      <li>
                        <span>Height</span>
                        <span>{formatNumber(parseFloat(data.depth || 0).toFixed(2))}</span>
                      </li>
                      <li>
                        <span>Crown Height</span>
                        <span>{formatNumber(parseFloat(data.cHgt || 0).toFixed(2))}</span>
                      </li>
                      <li>
                        <span>Crown Angle</span>
                        <span>{formatNumber(parseFloat(data.cAng || 0).toFixed(2))}</span>
                      </li>
                      <li>
                        <span>Star Length%</span>
                        <span>{formatNumber(parseFloat(data.strLn || 0).toFixed(0))}</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>Lower Half%</span>
                        <span>{formatNumber(parseFloat(data.lwrHal || 0).toFixed(0))}</span>
                      </li>
                      <li>
                        <span>Girdle</span>
                        <span>{formatNumber(parseFloat(data.grdlPer || 0).toFixed(0))}</span>
                      </li>
                      <li>
                        <span>Girdle</span>
                        <span>{data.girdleStr || '-'}</span>
                      </li>
                      <li>
                        <span>Girdle Condition</span>
                        <span>{data.grdlCondNm || '-'}</span>
                      </li>
                      <li>
                        <span>Culet</span>
                        <span>{data.cultNm || '-'}</span>
                      </li>
                      <li>
                        <span>Culet Condition</span>
                        <span>{data.cultCond || '-'}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="diamondDetailMore">
                  <h2 className="detailSmallTitle">Other Information</h2>
                  <div className="diamondDetailMoreHalf diamondFullBlock">
                    <ul>
                      <li>
                        <span>Key To Symbol</span>
                        <span>{data.kToSStr || '-'}</span>
                      </li>
                      <li>
                        <span>Laboratory Comments</span>
                        <span>{data.lbCmt || '-'}</span>
                      </li>
                      <li>
                        <span>Member Comments</span>
                        <span>-</span>
                      </li>
                      <li>
                        <span>Seller Comments</span>
                        <span>-</span>
                      </li>
                      <li>
                        {/* <span>Note</span>
                        <span className="addNotesOption">
                          <textarea></textarea>
                          <button className="commonButton">Add</button>
                        </span> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </TabPanel>
            {/* <TabPanel className="diamondDetailMainTab">
              <DiamondDetailCalibrated />
            </TabPanel>
            <TabPanel className="diamondDetailMainTab">
              <Analytics />
            </TabPanel> */}
          </div>
        </CustomTab>
      </div>
    );
  }
}
export default DiamondDetail;
