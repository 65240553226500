import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import { API_ROUTES } from 'constants/CommonApi';

import Storage from './storage';

export const getAccountDetails = async (id, request = {}, config) => {
  const user = id || Storage.get('user')?.account?.id;
  if (isEmpty(user)) return [];

  return UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.Account.get, [user]),
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getAccountsList = (request, config) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Account.paginate,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getAccountsListNew = (request, config) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Account.paginateNew,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const createAccount = (request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Account.create,
    request,
  });
};

export const updateAccount = (id, request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Account.update,
    request: { id, ...request },
  });
};

export const changeAccountStatus = (request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Account.changeStatus,
    request,
  });
};

export const exportAllClient = (request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Account.excportClient,
    request,
  });
};
