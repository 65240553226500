import React from 'react';
import SelectOption from 'containers/SelectOption';
import 'react-calendar/dist/Calendar.css';
import TextArea from 'containers/TextArea';
import { fetchPartyRelatedInfo, isValue } from '../CommonPopupFunction';
import { head, isEmpty, pick, last, map, split } from 'lodash';
import Messages from 'constants/Messages';
import { MEETING_TYPE } from 'constants/Common';
import OpenNotification from 'constants/OpenNotifications';
import moment from 'moment';
import { createOffice } from 'services/OfficeApi';
import PartyBuyerComponent from '../PopupComponent/PartyBuyerComponent';
import SellerComponent from '../PopupComponent/SellerComponent';
import BrokerComponent from '../PopupComponent/BrokerComponent';
import { isObject } from 'util/utils';

class AppointmentForm extends React.Component {
  state = {
    user: this.props.client?.user?.id ? this.props.client?.user?.id : null,
    userAccount: this.props.client?.id ? this.props.client.id : null,
    seller: isObject(this.props.client?.seller) ? this.props.client.seller?.id : null,
    sellerList: [],
    loading: false,
    assistantSeller: isObject(this.props.client?.seller) ? this.props.client.seller?.id : null,
    error: {},
    broker: isObject(this.props.client?.broker) ? this.props.client.broker?.id : null,
    brokerList: isObject(this.props.client?.broker)
      ? [
          {
            ...this.props.client?.broker,
            value: this.props.client?.broker?.companyName,
            key: this.props.client?.broker?.id,
          },
        ]
      : [],
    client: !isEmpty(this.props.client) ? [this.props.client] : [],
    comment: '',
    virtualType: '1',
  };

  errorHandel = (param) => {
    let { fields, isSendInvite } = param;
    let error = this.state.error;
    if (isSendInvite) {
      let data = pick(this.state, ['userAccount', 'user', 'seller']);
      Object.keys(data).forEach((d) => {
        if (isEmpty(data?.[d])) error[d] = Messages[`${d}Select`];
      });
    } else {
      fields.forEach((filed) => this.state[filed] && delete error[filed]);
    }
    this.setState({ error });
  };

  fetchClientOtherData = () => {
    let clientOtherData = fetchPartyRelatedInfo(this.state.client, this.state.user);
    this.setState({ ...this.state, ...clientOtherData });
  };
  onClickSendInvite = async () => {
    this.errorHandel({ isSendInvite: true });

    let { virtualType, user, userAccount, error, comment, broker, seller } = this.state;
    let { slot, apiSlot, date, checked } = this.props;

    if (!slot) return OpenNotification({ type: 'error', title: 'Please select slot.' });

    if (!date) return OpenNotification({ type: 'error', title: 'Please select date.' });

    if (isEmpty(error)) {
      let start = moment(`${date} ${head(split(slot, ' - '))}`, 'YYYY-MM-DD HH:mm:ss A').toISOString();
      let end = moment(`${date} ${last(split(slot, ' - '))}`, 'YYYY-MM-DD HH:mm:ss A').toISOString();

      let obj = {
        companyId: userAccount,
        customerId: user,
        purpose: comment,
        ...(broker ? { broker } : {}),
        seller,
        type: 2,
        start: start,
        end: end,
        date: end,
        cabinSlot: [apiSlot],
        diamonds: map(checked, 'id'),
        meetingType: virtualType,
      };
      this.props.setLoader();
      let [, res] = await createOffice(obj);
      if (res.code == 'OK') {
        this.props.setLoader();
        this.props.onClose();
        void this.props.unCheckedDiamond?.();
        OpenNotification({
          type: 'success',
          title: res.message,
        });
      } else {
        this.props.setLoader();
      }
    }
  };
  render() {
    return (
      <div className="d-flex flex-wrap align-items-normal">
        <PartyBuyerComponent
          error={this.state.error}
          fetchClientOtherData={this.fetchClientOtherData}
          setUserDetails={(data) => {
            this.setState({ ...data }, () => {
              this.errorHandel({ fields: Object.keys(data) });
            });
          }}
          initialData={pick(this.state, ['client', 'userAccount', 'user'])}
        />
        <SellerComponent
          error={this.state.error}
          setSellerDetails={(data) => {
            this.setState({ ...data }, () => {
              this.errorHandel({ fields: ['seller'] });
            });
          }}
          initialData={pick(this.state, ['seller'])}
        />

        <BrokerComponent
          error={this.state.error}
          label="Broker"
          setBrokerDetails={(data) => {
            this.setState({ ...data }, () => {
              this.errorHandel({ fields: ['broker'] });
            });
          }}
          initialData={pick(this.state, ['broker', 'brokerList'])}
        />
        {/* <SelectOption
          defaultValue="Select Available Room"
          selectValue={Appointemt.availableRoom}
          label="Available Room"
        /> */}
        <SelectOption
          placeholder="Select Assistant Salesman"
          selectValue={this.state.sellerList}
          value={isValue(this.state.assistantSeller)}
          label="Assi.Salesman"
          onChange={(assistantSeller = null) => {
            this.setState({ assistantSeller });
          }}
          onSearch={() => {
            this.setState({ assistantSeller: null });
          }}
        />
        <TextArea
          label="Comments"
          placeholder="Enter Comments"
          onChange={(e) => {
            this.setState({ comment: e.target.value });
          }}
          value={this.state.comment}
        />
        <div className="selectVirtualAction">
          <SelectOption
            placeHolder="Select Virtual Type"
            onChange={(virtualType = null) => {
              this.setState({ virtualType });
            }}
            onSearch={() => {
              this.setState({ virtualType: null });
            }}
            value={isValue(this.state.virtualType)}
            selectValue={MEETING_TYPE}
            label="Available Room"
          />
          <button onClick={this.onClickSendInvite}>Send Invite</button>
        </div>
      </div>
    );
  }
}

export default AppointmentForm;
