import React, { useReducer, useRef, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import InputBlock from 'containers/InputBlock';
import TextArea from 'containers/TextArea';
import FileUpload from 'containers/FileUpload';
import SelectOption from 'containers/SelectOption';
import { Checkbox } from 'antd';
import { storeMasterDetail, updateMasterDetail } from 'services/SubMasterApi';
import { showNotification } from 'services/NotificationService';
import { isEmpty } from 'util/utils';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export const groupData = [
  { value: 'Round', key: 'Round' },
  { value: 'Princess', key: 'Princess' },
  { value: 'Emerald', key: 'Emerald' },
  { value: 'Sq. Emerald', key: 'Sq. Emerald' },
];

const Form = (props) => {
  let submitAction = 'save';
  const [state, setState] = useState({
    formData: {
      isActive: true,
      isWebVisible: true,
    },
    errors: {},
  });

  useEffect(() => {
    setState({ ...state.formData, formData: props.detail });
  }, [props.detail?.id]);

  const handleChange = (name, value) => {
    if (name == 'likeKeyWords') {
      value = value ? value.split(',') : [];
    }
    setState({ formData: { ...state.formData, [name]: value } });
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    let data = { ...state.formData };
    if (!data['name']) {
      formIsValid = false;
      errors['name'] = 'Please enter name.';
    }
    if (!data['code']) {
      formIsValid = false;
      errors['code'] = 'Please enter code.';
    }

    setState({ ...state, errors });
    return formIsValid;
  };

  let formRef = React.createRef();
  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = { ...state.formData };
    data.parentId = props.parentId;
    data.group = data.group || '';

    if (handleValidation()) {
      if (isEmpty(data.sortingSequence)) {
        delete data?.sortingSequence;
      }
      if (!props?.detail?.id) {
        const [, res] = await storeMasterDetail(data);
        showNotification(res);
      } else {
        data.updateId = props?.detail?.id;
        const [, res] = await updateMasterDetail(data);
        showNotification(res);
      }

      if (submitAction == 'continue') {
        if (isEmpty(props?.detail?.id)) {
          formRef.current.reset();
          setState({
            formData: {
              isActive: true,
              isWebVisible: true,
              isDefault: false,
            },
          });
        }
      } else {
        props.onClick();
      }
    }
  };

  const { errors } = state;

  return (
    <div className="masterWrapper">
      <form onSubmit={handleSubmit} ref={formRef}>
      <SunEditor setOptions={{
				    height: 200}} placeholder="Please type here..." height="400"  autoFocus={true} />
				     <div className="configActionButton">
                <button className="commonButton" type="submit" onClick={() => (submitAction = 'save')}>
                  Save
                </button>
                <button className="commonOutine" type="button" onClick={props.onClick}>
                  Back
                </button>
        </div>
      </form>
    </div>
  );
};

export default Form;








