import React from 'react';

import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import Image from 'containers/Image';
import MainHeading from 'containers/MainHeading';
import CommonBackButton from 'containers/CommonBackButton';
import DiamondListingAction from 'components/DiamondListing/DiamondListingAction';

import { diamondParameters as DiamondFields } from 'constants/Common';
import Messages from 'constants/Messages';
import { getImagePath, isArray, isEmpty, isFunction } from 'util/utils';
import { Notify } from 'util/notify';

import closeRedSvg from 'assets/svg/close-red.svg';

// prettier-ignore
const FieldNameList = ['img',  'vStnId', 'stoneId', 'locNm', 'lbNm', 'rptNo', 'shpNm', 'crt', 'clrNm', 'colNm', 'shdNm', 'rap', 'back', 'ctPr', 'fluNm', 'cutNm', 'polNm', 'symNm', 'blkTblNm', 'blkSdNm', 'wSdNm', 'wTblNm', 'opTblNm', 'opCrwnNm', 'opPavNm', 'eClnNm', 'depPer', 'tblPer', 'length', 'width', 'height', 'cAng', 'cHgt', 'pAng', 'pHgt', 'girdleStr', 'cultNm', 'lsrInc', 'brlncyNm', 'hANm', 'kToSStr', 'lbCmt'];
const ExcludeFieldNameList = [];
const FieldMapEntries = FieldNameList.map((field) => [
  field,
  DiamondFields?.[field]?.desc || DiamondFields?.[field]?.name || `${field}`.toUpperCase(),
]);
const FieldMap = Object.fromEntries(FieldMapEntries);

const CompareHead = React.memo(function CompareHead({ afterClose, handleClose, count }) {
  const closeCompare = React.useCallback(() => {
    if (isFunction(handleClose)) handleClose();
    if (isFunction(afterClose)) afterClose();
  }, [afterClose, handleClose]);

  return (
    <div className="d-flex flex-wrap compare-head">
      <CommonBackButton onClick={closeCompare} />
      <MainHeading title={`Compare(${count})`} />
    </div>
  );
});

const CompareTable = React.memo(function CompareTable({ list, removeItem }) {
  return (
    <table>
      <tbody>
        {Object.entries(list).map(
          ([field, values]) =>
            !ExcludeFieldNameList.includes(field) &&
            (field === 'img' ? (
              <tr>
                <td className="compareHead">Diamond Image</td>
                {values.map((value, index) => (
                  <td className="compareHead compareImage" key={index}>
                    {list?.img?.[index] && list?.vStnId?.[index] && (
                      <Image src={getImagePath(`${list?.vStnId?.[index]}.jpg`)} className="diamondCompareImg" alt="" />
                    )}
                    <a className="closeImage" onClick={removeItem(list?.vStnId?.[index])}>
                      <img src={closeRedSvg} alt="" />
                    </a>
                  </td>
                ))}
              </tr>
            ) : (
              <tr key={field}>
                <td className="compareHead">{FieldMap?.[field]}</td>
                {values.map((value, index) => (
                  <td key={index}>{isEmpty(value) ? '-' : value}</td>
                ))}
              </tr>
            )),
        )}
      </tbody>
    </table>
  );
});

function Compare({ afterClose, handleClose, list }) {
  const [removed, setRemoved] = React.useState([]);

  React.useEffect(() => {
    if (!isArray(list) || isEmpty(list)) {
      Notify.error({ message: Messages.Error.Required.Stones });
      handleClose();
      return;
    }

    if (list.length < 2) {
      Notify.error({ message: Messages.Error.Required.MoreThanOneStone });
      handleClose();
      return;
    }
  }, [list, handleClose]);

  const formattedList = React.useMemo(() => {
    const output = Object.fromEntries(FieldNameList.map((field) => [field, []]));
    FieldNameList.forEach((field) => {
      list
        .filter((stone) => !removed.includes(stone?.vStnId))
        .forEach((stone) => {
          output[field].push(isEmpty(stone?.[field]) ? undefined : stone?.[field]);
        });
    });
    return output;
  }, [list, removed]);

  const count = React.useMemo(() => formattedList?.vStnId?.length, [formattedList?.vStnId?.length]);

  const removeStone = React.useCallback(
    (stoneId) => () => {
      if (count > 1) return setRemoved((removed) => [...removed, stoneId]);
      handleClose();
      afterClose();
    },
    [afterClose, count, handleClose],
  );

  return (
    <div className="compareMainPage">
      <CompareHead {...{ afterClose, handleClose, count }} />
      <GridHeightContainer className="compareTable" subtractFrom=".compare-head,.botoomStickyBar" adjustment={-8}>
        <CompareTable list={formattedList} removeItem={removeStone} />
      </GridHeightContainer>
      <DiamondListingAction checkedData={list} emailStock emailPic ActionFalse={false} />
    </div>
  );
}

export default React.memo(Compare);
