import React from 'react';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';
// import { useLoading } from 'util/hooks';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress';
import { PROJECT_DETAILS } from 'constants/Common';
import { showNotification } from 'services/NotificationService';
import 'styles/signin/signin.less';
import { forgotPassword } from 'services/CommonApi';
import { history } from 'util/history';

const ForgotPassword = (props) => {
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setLoading(true);
        props.form.validateFields(async (err, values) => {
          if (!err) {
          const [err, res] = await forgotPassword({ username: values.email });
          showNotification(res);
          props.form.resetFields();
          history.push('/');
          }
        });
    } finally {
      setLoading(false);
    }
  };
  const { getFieldDecorator } = props.form;

  return (
    <div className="adma-login-wrap">
      {loading && <CircularProgress className="fixLoader" />}
      <div className="adma-login-container">
        <div className="loginpage forget-pwd">
          <div className="adma-login-main-content">
            <div className="adma-app-login-content">
              <div className="adma-logo-content-bg">
                <img src={PROJECT_DETAILS.LOGO_1} />
                <h2 className="login-title">
                  Forgot Your Password ?
                  <span> Don't worry. Recovering the password is easy. Just tell us the email.</span>
                </h2>
              </div>
              <Form layout="vertical" onSubmit={handleSubmit} className="gx-login-form gx-form-row0 ">
                <Form.Item>
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: 'Please enter the email ID.' },
                      { type: 'email', message: 'Please enter the valid email ID.' },
                    ],
                  })(<Input type="email" placeholder="Enter your Email ID" />)}
                </Form.Item>
                <div className="submit-btn d-flex mt-20 j-space-between">
                  <Button type="primary" className="adma-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.send" />
                  </Button>
                  <span className="gx-signup-form-forgot gx-link">
                    Back To <Link to={`${PREFIX_URL_WITH_SLASH}/signin`}> Login</Link>
                  </span>
                </div>
              </Form>
            </div>
          </div>
          <div className="login-right-section">
            {/* <video autoPlay loop muted>
              <source src={require('assets/images/FSDLogoSight.mp4')}></source>
            </video> */}
            {/* <div className="media">
                <div className="media-left">
                  <img src={require('assets/images/price-guarantee.svg')} alt="price-guarantee" />
                </div>
                <div className="media-body">
                  <h3 className="serives-name">Best Prices Guarantee</h3>
                  <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default WrappedForgotPasswordForm;
