import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Checkbox, Form, Input } from 'antd';

import CircularProgress from 'components/CircularProgress';

import Storage from 'services/storage';

import IntlMessages from 'util/IntlMessages';
import { isEmpty } from 'util/utils';

import { PROJECT_DETAILS } from 'constants/Common';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import 'styles/signin/signin.less';
import OpenNotification from 'constants/OpenNotifications';

import { userGetOTP, userSignIn } from 'appRedux/actions/Auth';

class SignIn extends React.Component {
  state = {
    values: Storage.get('dn-r-up') && Storage.get('dn-up'),
    remember: Storage.get('dn-r-up'),
    loader: false,
  };

  constructor(props) {
    super(props);
    if (isEmpty(Storage.get('dn-r-up'))) Storage.delete(['dn-up']);
  }

  rememberUser = (e) => {
    const remember = Boolean(e?.target?.checked);
    this.setState({ remember }, () => {
      Storage.set('dn-r-up', remember);
      if (!remember) Storage.delete(['dn-up']);
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({ loader: true });
      if (this.state.remember === true) {
        Storage.set('dn-up', values);
      }
      this.props.userGetOTP(values, (cb) => {
        if (cb.error) OpenNotification({ type: 'error', title: cb.error });
        this.setState({ loader: false });
        if ((!isEmpty(cb) && !isEmpty(this.props.token)) || !isEmpty(this.props?.defaultPassword?.userId)) {
          this.props.history.push('/');
          // this.props.history.push(PREFIX_URL_WITH_SLASH + '/inventory');
        } else if (!isEmpty(this.props?.userData?.username) && !isEmpty(this.props?.userData?.password)) {
          this.props.history.push(PREFIX_URL_WITH_SLASH + '/otp-verification');
        }
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="adma-login-wrap">
        {this.state.loader && <CircularProgress className="fixLoader" />}
        <div className="adma-login-container">
          <div className="loginpage">
            <div className="adma-login-main-content">
              <div className="adma-app-login-content">
                <div className="adma-logo-content-bg">
                  <img src={PROJECT_DETAILS.LOGO_1} />
                  <h2 className="login-title">
                    Log in <span>to your account</span>
                  </h2>
                </div>
                <Form onSubmit={this.handleSubmit} className="adma-signin-form adma-form-row0">
                  <Form.Item>
                    {getFieldDecorator('username', {
                      rules: [{ required: true, message: 'The input is not valid Username!' }],
                      initialValue: this.state.values?.username,
                    })(<Input placeholder="Username" defaultValue={this.state.values?.username} />)}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: 'Please input your Password!' }],
                      initialValue: this.state.values?.password,
                    })(<Input type="password" placeholder="Password" defaultValue={this.state.values?.password} />)}
                  </Form.Item>
                  <Form.Item>
                    <div className="forget-pwd">
                      <Checkbox checked={this.state.remember} onChange={this.rememberUser}>
                        Remember Me
                      </Checkbox>
                      <span className="adma-signup-form-forgot adma-link" />
                      <Link to={PREFIX_URL_WITH_SLASH + '/forgot-password'}>
                        <span className="adma-signup-form-forgot adma-link">
                          <IntlMessages id="app.userAuth.forgotPassword" /> ?
                        </span>
                      </Link>
                    </div>
                  </Form.Item>
                  <div className="submit-btn">
                    <Form.Item>
                      <Button type="primary" className="adma-mb-0" htmlType="submit">
                        <IntlMessages id="app.userAuth.signIn" />
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
            <div className="login-right-section">
              {/* <video autoPlay loop muted>
                <source src={require('assets/images/FSDLogoSight.mp4')}></source>
              </video> */}
              {/* <div className="media">
                <div className="media-left">
                  <img src={require('assets/images/price-guarantee.svg')} alt="price-guarantee" />
                </div>
                <div className="media-body">
                  <h3 className="serives-name">Best Prices Guarantee</h3>
                  <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { defaultPassword, token, userData, isVerified } = auth;
  return { defaultPassword, token, userData, isVerified };
};

export default connect(mapStateToProps, { userGetOTP, userSignIn })(WrappedNormalLoginForm);
