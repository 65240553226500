import React, { useState, useEffect } from 'react';
import { Checkbox, Input } from 'antd';
import { bidValidator, promotionalDisSetting } from 'services/CommonApi';
import { showNotification } from 'services/NotificationService';
import { isObject } from 'util/utils';

const BidValidator = () => {
  const { TextArea } = Input;
  const [stopOrder, setStopOrder] = useState(false);
  const [remark, setRemark] = useState('');

  const getData = async () => {
    const data = { filter: { type: 29 } };
    const [, res] = await promotionalDisSetting(data);
    if (res?.code === 'OK' && isObject(res?.data)) {
      setStopOrder(res?.data?.stopOrder);
      setRemark(res?.data?.remark);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [err, res] = await bidValidator({ stopOrder, remark, type: 29 });
    !err && showNotification(res);
    if (res?.code === 'OK') {
      getData();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="editProfilePage">
      <div className="headeTitle">
        <h2>Bid Validator</h2>
      </div>
      <div className="editProfilePageInner">
        <div className="profileRight halfBlock">
          <Checkbox
            checked={stopOrder}
            onChange={(e) => setStopOrder(e?.target?.checked)}
            style={{ marginBottom: '15px' }}
          >
            Stop Order on Bid Stone
          </Checkbox>
          <div>
            <label htmlFor="remark" style={{ fontSize: '14px', fontWeight: '600' }}>
              Message:
            </label>
            <TextArea
              style={{ marginTop: '5px' }}
              id="remark"
              rows={4}
              defaultValue={remark}
              value={remark}
              onChange={(e) => setRemark(e?.target?.value)}
              placeholder="Type Message.."
            />
          </div>
          <button className="actionButonBottom commonButton">Submit</button>
        </div>
      </div>
    </form>
  );
};

export default BidValidator;
