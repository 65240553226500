import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import React from 'react';
import classNames from 'util/classNames';
import { isArray } from 'util/utils';

const style = { height: '100vh' };

function ListingTableWrapper({ allClasses, classList, className, ...restProps }) {
  const substractFrom = React.useMemo(() => {
    return isArray(classList) ? classList : isArray(allClasses) ? allClasses.map((className) => `.${className}`) : [];
  }, [classList, allClasses]);

  return (
    <GridHeightContainer
      subtractFrom={substractFrom.join(',')}
      className={classNames([`searchResultTable`, className])}
      style={style}
      {...restProps}
    />
  );
}

export default ListingTableWrapper;
