import React from 'react';
import './staticContent.less';
import Heading from 'containers/Heading';
import { i18n } from 'util/i18n';

const TermsOfUse = () => {
  return (
    <div className="staticBoxMain">
      <div className="statiContentBox">
        <div className="headeTitle">
          <h2>Policy for Sales & Marketing</h2>
        </div>
        <div className="staticContentWrapper">
          <Heading title="Billing & Invoicing" className="popupInnerTitle" />
          <div className="staticList">
            <ul>
              <li>
                Once any stone is confirmed and billed in INR, the Bank Rate which is higher either at the time of
                confirmation or time of invoice will be considered.
              </li>
              <li>
                Once any stone is sold but gets cancelled for any reason, it will be uploaded back in the system and
                available to sale for any client. The stone cannot be kept on HOLD beyond the approved time in the
                system.
              </li>
              <li>
                Any stone taken for Office View (O) or Memo (M) in the name of XYZ client & if confirmed by the client
                then the invoice will be made in the same name.
              </li>
              <li>
                No direct shipment, memo and office view will be made without proper documentation/KYC of the clients.
              </li>
              <li>
                For local billing, only online payments will be accepted. No other form of payment will be accepted.
              </li>
              <li>
                Any sale that involves the broker and the stone is shipped to our associate office in HK & NY. The
                brokerage amount will be deducted from the invoice.
              </li>
              <li>
                Any sale that involves the broker and the stone is shipped directly to the customer, the brokerage will
                be paid locally from India office.
              </li>
              <li>
                During the registration if the sales person does not add the name of the broker it will not be added
                later at the time of sale.
              </li>
              <li>
                If the broker is mentioned in any company, his name should always be mentioned in office view/memo under
                broker section.
              </li>
            </ul>
          </div>
        </div>
        <div className="staticContentWrapper">
          <Heading title="Shipments" className="popupInnerTitle" />
          <div className="staticList">
            <ul>
              <li>C1: Local memo (Mumbai) for 1 night. Applicable for less 2%</li>
              <li>
                C2: Local memo for 2 to 5 nights. It is applicable for less 1% and if it’s over 5 nights then it will be
                Net Sale
              </li>
              <li>
                C3: This is international memo. Upon approval, the stone will be shipped to our associate office (HK &
                NY) & client will come to inspect the stone. It is applicable for less 2%
              </li>
              <li>
                C4: This is international memo. Upon approval, the stone will be shipped directly to the customer or our
                associate office will ship it. No less 2% will be given on this stage and it is applicable for Cash Memo
              </li>
              <li>
                C5: This is special case local and international memo. An approval will be needed and discount will be
                considered after discussion with the sales head.
              </li>
              <li>
                Customer will get free shipments to our associate offices in New York and Hong Kong. They can make
                payments and pick up the stones.
              </li>
              <ul>
                <p>
                  <b>For direct shipments,</b>
                </p>
                <li>If the value is above $15000 there will be no shipping charge.</li>
                <li>When the value is less than $15000 there will be shipping charge of $125.</li>
              </ul>
              <ul>
                <p>
                  <b>Before giving any information on international shipments speak to the sales head</b>
                </p>
              </ul>
              <ul>
                <p>
                  <b>For shipments to associate office in Antwerp,</b>
                </p>
                <li>
                  If the value is below $15000 there will be a charge of $150 & the parcel will be shipped once we have
                  the minimum value of $15000.
                </li>
                <li>
                  If the value is below $15000 and customer wants the shipment on priority then there will be a shipment
                  charge of $125 over and above $150
                </li>
                <li>If the value is above $15000, discount will be 1% on the value of the stone.</li>
              </ul>
              <ul>
                <p>
                  <b>For shipments to associate office in Antwerp only bank transfers will be accepted.</b>
                </p>
              </ul>
              <ul>
                <p>
                  <b>For shipments to associate office in Dubai</b>
                </p>
                <li>
                  If the value is less than $15000 & customer wants his shipment on priority then there will be a charge
                  of $225
                </li>
                <li>
                  If the value is less than $15000 & customer is ready to wait for minimum value of $15000 for shipment
                  then there will be a charge of $100
                </li>
                <li>If the shipment value is above $15000 then there will be no charge on shipment</li>
                <p>
                  <b>VAT will be added in the invoice for the stones sold in Dubai</b>
                </p>
              </ul>
            </ul>
          </div>
        </div>
        <div className="staticContentWrapper">
          <Heading title={i18n.t('title.offer')} className="popupInnerTitle" />
          <div className="staticList">
            <ul>
              <li>
                If the customer places a “{i18n.t('title.offer')}” by 1 Rap Point and if the “`$
                {i18n.t('title.offer')}`” gets rejected, the customer cannot {i18n.t('title.offer')} for the same stone
                again for 48 hours from the time of rejection.
              </li>
              <li>
                If the customer places a “{i18n.t('title.offer')}” by 0.5 Rap Point and if the “`$
                {i18n.t('title.offer')}`” is rejected, the customer cannot {i18n.t('title.offer')} for the same stone
                again for 48 hours from the time of rejection.
              </li>
              <li>
                If the customer places a “{i18n.t('title.offer')}” and it is approved, the customer will have to buy the
                stone.
              </li>
              <li>
                For the stones that are 3 Ct and up, customers can place a {i18n.t('title.offer')} for them once they
                are added in regular stock.
              </li>
              <li>
                For the stones that are 2.99 Ct and below, customers cannot place a {i18n.t('title.offer')} for next 7
                days from the day they are added in regular stock.
              </li>
            </ul>
          </div>
        </div>
        <div className="staticContentWrapper">
          <Heading title="Registrations & Approvals" className="popupInnerTitle" />
          <div className="staticList">
            <ul>
              <li>
                Registration of a customer does not mean the customer belongs to that respective sales person. Only a
                sale to that customer means the customer belongs to a sales person.
              </li>
              <li>
                If there is no business with the existing customer of a sales person for 6 months from the date of last
                sale, other sales person are eligible to work with the customer. And, if they make a sale then the
                customer is theirs. They don’t have to consult anyone to make changes in the system.
              </li>
              <li>
                Sales person should avoid working with the existing customers who are doing regular business with their
                team members.
              </li>
              <li>
                During registration, if the details of the customer is incomplete then such registrations will not be
                approved.
              </li>
              <li>The local registrations will be approved after discussions with the management.</li>
              <li>
                If there is RapNet inquiry from an existing customer doing regular business then that query should be
                handed over to respective sales person.
              </li>
            </ul>
          </div>
        </div>
        <div className="staticContentWrapper">
          <Heading title="Stages" className="popupInnerTitle" />
          <div className="staticList">
            <ul>
              <li>
                Any stone on C 1, C 2, C 3, C 4 and C 5 stage are not available for sale. Get in touch with respective
                sales person for more information.
              </li>
              <li>Any stone going on consignment (C3) to our associate office the minimum value should be $10,000.</li>
              <li>
                Any stone going on consignment (C4) to our associate office or directly to client the minimum value
                should be $15000.
              </li>
              <li>
                Stones that are on Ext. Memo (E) stage cannot be sold and delivered in India. They can be sold and
                delivered to other locations after speaking to the sales head
              </li>
              <li>Any stone on Blue Nile (B) and James Allen (J) stage are not available for sale.</li>
            </ul>
          </div>
        </div>
        <div className="staticContentWrapper">
          <Heading title="Additional Service Charges" className="popupInnerTitle" />
          <div className="staticList">
            <ul className="">
              <div className="">
                <p>
                  <b>The Canada Mark charges are as follows:</b>
                </p>
                <div>
                  <table>
                    <tr>
                      <th>Size</th>
                      <td>0.01 - 0.09 CT</td>
                      <td>0.10 - 0.19 CT</td>
                      <td>0.20 - 0.49 CT</td>
                      <td>0.50 - 0.69 CT</td>
                      <td>0.70 - 0.99 CT</td>
                      <td>1.00 - 1.49 CT</td>
                      <td>1.00 - 1.49 CT</td>
                      <td>1.50 CT Up</td>
                    </tr>
                    <tr>
                      <th>Royalty (USD)</th>
                      <td>$2</td>
                      <td>$4</td>
                      <td>$10</td>
                      <td>$25</td>
                      <td>$38</td>
                      <td>$65</td>
                      <td>$65</td>
                      <td>$120</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="mt-20">
                <p>
                  <b>The Diamond Origin Report (DOR) charges are as follows:</b>
                </p>
                <div>
                  <table>
                    <tr>
                      <th>Size</th>
                      <td>0.15 – 0.46 CT</td>
                      <td>0.47 – 0.69 CT</td>
                      <td>0.70 – 0.99 CT</td>
                      <td>1.00 – 1.19 CT</td>
                      <td>1.20 – 1.49 CT</td>
                      <td>1.50 – 1.99 CT</td>
                      <td>2.00 – 2.99 CT</td>
                      <td>3.00 – 3.99 CT</td>
                      <td>4.00 – 4.99 CT</td>
                    </tr>
                    <tr>
                      <th>Free (USD)</th>
                      <td>$75</td>
                      <td>$85</td>
                      <td>$100</td>
                      <td>$138</td>
                      <td>$150</td>
                      <td>$175</td>
                      <td>$275</td>
                      <td>$417</td>
                      <td>$579</td>
                    </tr>
                  </table>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div className="staticContentWrapper">
          <Heading title="Stock Department" className="popupInnerTitle" />
          <div className="staticList">
            <ul>
              <li>
                Currently there are 110 stones available in stock which as ranging above $50,000 and they will be kept
                in 4 different boxes (2 for round, 1 for Fancy & 1 for Big size stones).
              </li>
              <li>These stones will be kept under supervision of Ashok Mamaji.</li>
              <li>Every Inward & Outward stone will be manually checked and updated in software by Vishal / Grader.</li>
              <li>Every Memo / Consignment will have disclaimer.</li>
              <li>
                Stones above 3.00 carat & amount $50,000.00 won't be issued on 20min hold process. If salesperson need
                to inspect the stone for client will issue in the name of client and inspect the stone & same for video
                also. Salesperson need to go at Ashok Mamaji place and take the video of mentioned stones(s)
              </li>
            </ul>
          </div>
        </div>
        <div className="staticContentWrapper">
          <Heading title="Sales Team" className="popupInnerTitle" />
          <div className="staticList">
            <ul>
              <li>
                Only Gaurav, Chirag & Varul will be responsible to showcase stones worth $50000 and above to the
                customers in office view, this will be applicable for other sales persons customers as well.
              </li>
              <li>Gaurav, Chirag or Varul has to present while the customer is viewing the stone in the office.</li>
              <li>Stones are to be taken in special tray with tweezer for showcasing.</li>
              <li>Request customer to view the stones without using the tweezer.</li>
              <li>Stones sent for show will be handled in similar format.</li>
              <li>Every Memo / Consignment will have Disclaimer.</li>
              <li>
                Sales person can take image or video of stones worth $50000 and above under the observation of Ashok
                Mamaji in stock room.
              </li>
            </ul>
          </div>
        </div>
        <div className="staticContentWrapper">
          <Heading title="Memo" className="popupInnerTitle" />
          <div className="staticList">
            <ul>
              <li>
                I have checked the diamonds mentioned in memo physically before taking the delivery. Any damage / loss
                caused to the diamonds before returning to you will solely be my responsibility.
              </li>
              <li>
                The goods described overleaf are received by me/us for inspection or to be shown to prospective buyers
                and it is your property.
              </li>
              <li>
                I/We will not sell or pledge or mortgage the said diamonds or otherwise deal with them in any manner
                which is prejudicial to your right.
              </li>
              <li>The diamonds will be held by me/us while in my/our custody at my/our risk in all respect</li>
              <li>
                I/We will be responsible for the return of the said diamonds in the same condition in which it was given
                to me/us on demand.
              </li>
              <ul>
                <p>
                  <b>Notes:-</b>
                </p>
                <li>
                  The diamonds herein invoiced are exclusively of natural origin and untreated based on personal
                  knowledge or written guarantees provided by the supplier of these diamonds.
                </li>
                <li>Exemption as per Clause 14(a) of GST Notification 03/2018 Dated: 23/01/2018</li>
                <li>
                  GST Tax Values mentioned are for information purpose only. Tax is not payable on goods issued on
                  approval basis/delivery challan.
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
