import React, { Component } from 'react';
import MainHeading from 'containers/MainHeading';
import { connect } from 'react-redux';
import ListingWrapper from 'containers/ListingWrapper';
import ListingTableWrapper from 'containers/ListingTableWrapper';
import Table from 'components/Inventory/TableBack';
import * as inventoryFunctions from 'components/Inventory/inventoryFunctions';
import SelectOption from 'containers/SelectOption';
import TextArea from '../../containers/TextArea';
import InputField from 'containers/InputBlock';
import { Checkbox } from 'antd';
import { cloneDeep, find, pick } from 'lodash';
import { getMasterDetailsByCode } from 'services/SubMasterApi';
import Messages from 'constants/Messages';
import { fetchPartyRelatedInfo, isValue, HeadingCalc, getBankRate } from './CommonPopupFunction';
import { isArray, isEmpty, isObject } from 'util/utils';
import { INVOICE_DATE, REFERENCE_BY, SHOW_SELECTION } from 'constants/Common';
import moment from 'moment';
import { createShowSelection } from 'services/CommonApi';
import DisableBlock from 'containers/DisableBlock';
import BrokerComponent from './PopupComponent/BrokerComponent';
import SellerComponent from './PopupComponent/SellerComponent';
import PartyBuyerComponent from './PopupComponent/PartyBuyerComponent';
import LocationComponent from './PopupComponent/LocationComponent';
import CircularProgress from 'components/CircularProgress';
import ClientComponent from './PopupComponent/ClientComponent';
import OpenNotification from 'constants/OpenNotifications';
import * as AccountAPI from 'services/AccountApi';
import accountFields from 'routes/Client/account-fields';
import Storage from 'services/storage';

const currentType = 'SingleStonePlaceOrderPopup';

let authUser = Storage.get('user');

class SingleStonePlaceOrderPopup extends Component {
  state = {
    show: true,
    stoneblock: false,
    checked: [],
    data: cloneDeep(this.props.checked),
    loader: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.checked.length !== this.state.checked.length && this.state.checked.length < 1) {
      if (!this.props.isFinalCal) this.props.onClose();
    }
  }

  static getDerivedStateFromProps(props) {
    if (props.visible && isArray(props.checkedData))
      return {
        checked: props.checkedData?.length
          ? [...props.checkedData]
          : props.checked?.length && props.isFinalCal
          ? [...props.checked]
          : [],
      };
    return null;
  }

  getAccount = async (client) => {
    const [, res] = await AccountAPI.getAccountDetails(client, { request: { select: accountFields } });
    return res?.data?.detail;
  };

  //FIXME:Only for order tab****************
  async componentDidMount() {
    authUser = Storage.get('user');
    if (
      ['order', 'quoteApprove', 'finalCalculation', 'orderApprove', 'showStone', 'holdMemoApprove'].includes(
        this.props.tab,
      )
    ) {
      let data = {
        user: null,
        userAccount: null,
        seller: null,
        sellerList: [],
        // loading: false,
        countryList: [],
        shippingCountry: null,
        cityList: [],
        shippingCity: null,
        stageList: [],
        stage: null,
        assistantSeller: null,
        error: {},
        broker: null,
        brokerList: [],
        shipDirect: false,
        client: [],
        billType: null,
        billTypeList: [],
        courierNameList: [],
        courierName: null,
        sourceOfSaleList: [],
        sourceOfSale: null,
        termsList: [],
        terms: null,
        comment: '',
        invoiceDate: '0',
        bankRateList: [],
        referenceBy: '1',
        showName: null,
        showNameList: [],
        inquirySourceList: [],
        inquirySource: null,
        countryName: null,
        isBankRateDisable: false,
        userTerms: {},
      };
      let tab = this.props.tab;
      let client = this.props.client;

      if (!isEmpty(client)) {
        data.client = [client];
        data.user = client?.user?.id;
        data.userAccount = client?.id;
        data.broker = isObject(client?.broker) && client?.broker?.companyName ? client.broker?.id : null;
        data.brokerList =
          isObject(client?.broker) && client?.broker?.companyName
            ? [{ ...client?.broker, value: client?.broker?.companyName, key: client?.broker?.id }]
            : [];
        data.assistantSeller = data.seller = isObject(client?.seller) ? client.seller?.id : null;
        data.shippingCountry = client?.country;
        data.shippingCity = client?.city;
      }

      if (['orderApprove', 'quoteApprove', 'holdMemoApprove'].includes(tab)) {
        let checkedDiamond = this.props.checked?.[0];
        data.client = [checkedDiamond?.user];
        data.user = checkedDiamond?.user?.id;
        data.userAccount = checkedDiamond?.user?.account?.id;
        if (!isEmpty(checkedDiamond?.broker)) {
          data.broker = checkedDiamond?.broker?.id;
          data.brokerList = [{ value: checkedDiamond?.broker?.companyName, key: checkedDiamond?.broker?.id }];
        }
        data.shippingCountry = checkedDiamond?.user?.account?.country;
        data.shippingCity = checkedDiamond?.user?.account?.city;
        if (!isEmpty(checkedDiamond?.seller)) data.assistantSeller = data.seller = checkedDiamond?.seller?.id;
      }

      if (!isEmpty(client) && this.props.tab == 'showStone') {
        data.client = [
          {
            key: client.user?.id,
            value: `${client?.companyName} ${client?.user?.firstName || client?.user?.lastName ? `|` : ''} ${
              client?.user?.firstName ? client.user.firstName : ''
            } ${client?.user?.lastName ? client.user.lastName : ''}`,
            ...client,
          },
        ];
        data.user = client.user?.id;
      }

      this.setState({ ...data }, () => {
        !isEmpty(this.state.client) && this.fetchClientOtherData();
      });

      this.fetchMasterData();
      getBankRate('BANK_RATES', (data) => {
        this.setState({ bankRateList: data });
      });
      if (this.props.isFinalCal) {
        this.props.setConfirmFn(this.onClickFn);
      }
    }
  }

  errorHandel = (param) => {
    let { fields, isConfirmStone } = param;
    let error = this.state.error;
    if (isConfirmStone) {
      let data = pick(this.state, [
        'userAccount',
        'user',
        'seller',
        'shippingCountry',
        'shippingCity',
        // 'broker',
        'billType',
        'sourceOfSale',
        'invoiceDate',
      ]);
      let showStoneData = [];
      if (this.props.tab == 'showStone') {
        data.showName = this.state.showName;
        data.referenceBy = this.state.referenceBy;
        showStoneData = [
          // 'broker',
          'invoiceDate',
          'userAccount',
          'billType',
          this.state.referenceBy == 1 ? 'seller' : 'user',
        ];
      }
      if (['orderApprove', 'quoteApprove', 'showStone'].includes(this.props.tab)) {
        ['shippingCountry', 'shippingCity', 'sourceOfSale', ...showStoneData].forEach((d) => delete data[d]);
      }

      // if (this.state.shipDirect) {
      //   data.courierName = this.state.courierName;
      // }
      Object.keys(data).forEach((d) => {
        if (isEmpty(data?.[d])) error[d] = Messages[`${d}Select`];
      });
      if (this.state.error?.user && this.props.tab == 'showStone') {
        error.user = Messages.clientNameSelect;
      }
    } else {
      fields.forEach((filed) => this.state[filed] && delete error[filed]);
    }
    this.setState({ error });
  };

  fetchClientOtherData = () => {
    let clientOtherData = fetchPartyRelatedInfo(this.state.client, this.state.user);

    this.setState({ ...this.state, ...clientOtherData }, () => {
      Object.keys(clientOtherData).map((key) => {
        if (clientOtherData[key]) this.errorHandel({ fields: [key] });
      });
    });
  };

  onClickFn = async () => {
    this.errorHandel({ isConfirmStone: true });
    if (['order', 'quoteApprove', 'orderApprove', 'holdMemoApprove'].includes(this.props.tab)) {
      if (isEmpty(this.state.error)) {
        let invoiceDateList = {
          0: moment().toISOString(),
          1: moment().add(1, 'days').toISOString(),
          2: 'Later',
        };
        let request = pick(this.state, [
          'user',
          'broker',
          'shippingCity',
          'assistantSeller',
          'billType',
          'terms',
          'seller',
          'shippingCountry',
          'bankRate',
          'invoiceDate',
          'sourceOfSale',
          'comment',
        ]);
        Object.keys(request).forEach((key) => {
          if (request[key] == null || request[key] == '') {
            delete request[key];
          }
        });
        if (this.state.isBankRateDisable) {
          delete request['bankRate'];
        }

        // if (request.broker) {
        //   request.broker = request.broker?.id;
        // }
        request.companyName = this.state.userAccount;
        request.account = this.state.userAccount;
        request.invoiceDate = invoiceDateList[request.invoiceDate];
        request.diamonds = this.state.checked.map((x) => x.id);
        if (['order', 'holdMemoApprove'].includes(this.props.tab)) {
          inventoryFunctions.handlePlaceOrder(request, this.props.onClose, this.props.removeDiamond, (loader) => {
            this.setState({ loader });
          });
        } else {
          if (this.props.tab === 'orderApprove') {
            ['shippingCity', 'assistantSeller', 'shippingCountry', 'companyName', 'sourceOfSale'].forEach(
              (key) => delete request[key],
            );
            request.account = this.state.userAccount;
            request.inquirySource = this.state.inquirySource;
          }
          this.props.approveClick((loader) => this.setState({ loader }), this.state.checked, request);
        }
      }
    }
    if (this.props.tab == 'showStone' && isEmpty(this.state.error)) {
      let request = {
        vStnIds: this.props.checked.map((d) => d.vStnId),
        type: SHOW_SELECTION.SHOW,
        referenceType: this.state.referenceBy,
        show: this.state.showName,
        remarks: this.state.comment,
      };
      if (request.referenceType == 1) {
        request.userAccount = this.state.user;
      } else {
        request.seller = this.state.seller;
      }

      this.setState({ loader: true });
      let [, res] = await createShowSelection(request);
      if (res.code == 'OK') {
        OpenNotification({ type: 'success', title: res.message });
        // this.props.unCheckedDiamond();
      }
      this.setState({ loader: false });
      if (!this.props.isFinalCal) this.props.onClose();
    }
  };

  fetchMasterData = async () => {
    const masters =
      this.props.tab == 'showStone' ? ['SHOW_TYPE'] : ['BILL_TYPE', 'SOURCE_OF_SALE', 'DAY_TERM', 'INQUIRY_SOURCE'];
    const [, res] = await getMasterDetailsByCode({
      masters,
      isWebVisible: 'true',
    });
    let data = {};
    if (res.code == 'OK' && res.data) {
      if (this.props.tab == 'showStone') {
        data.showNameList = res.data.SHOW_TYPE.map((d) => ({ value: d.name, key: d.id[0] }));
      } else {
        data.billTypeList = res.data.BILL_TYPE.map((d) => ({ value: d.name, key: d.id[0], code: d.code }));
        data.billType = data.billTypeList[0]?.key;
        // data.courierNameList = res.data.COURIER_NAME.map((d) => ({ value: d.name, key: d.id[0] }));
        // data.courierName = data.courierNameList[0]?.key;
        data.sourceOfSaleList = res.data.SOURCE_OF_SALE.map((d) => ({ value: d.name, key: d.id[0] }));

        data.termsList = res.data.DAY_TERM.map((d) => ({ value: d.name, key: d.id[0] }));
        data.terms = data.termsList[0]?.key;
        data.bankRate = find(this.state.bankRateList, (d) => data.terms == d?.dayTermId)?.exchangeRate;
        if (data.bankRate) data.bankRate = parseFloat(data.bankRate).toFixed(2);
        data.inquirySourceList = res.data.INQUIRY_SOURCE.map((d) => ({ value: d.name, key: d.id[0] }));
      }
      this.setState({ ...this.state, ...data });
    }
  };

  titleList = {
    order: 'Confirm Stone',
    quoteApprove: 'Approve',
    finalCalculation: 'Final Calculation',
    orderApprove: 'Approve',
    showStone: 'Show Stone',
    holdMemoApprove: 'Confirm Stone',
  };
  changeTermsAndBankRate = (countryId = this.state.shippingCountry, initialCountryList = []) => {
    if (countryId) {
      let countryName = !isEmpty(this.state.countryList)
        ? this.state.countryList.find((d) => d.key === countryId)?.value
        : initialCountryList.find((d) => d.key === countryId)?.value;

      let billTypeData = this.state.billTypeList?.find(
        (d) => d.code === (countryName !== 'India' ? 'DOLLAR_BILL' : 'RUPEE_BILL'),
      );

      this.setState({
        countryName,
        billType: billTypeData?.key,
        isBankRateDisable: billTypeData?.code === 'DOLLAR_BILL',
      });
    }
  };
  render() {
    let state = this.state;
    let tab = this.props.tab;
    return (
      <>
        {!this.props.isFinalCal && (
          <div className="d-flex align-items-center oneLineTotal">
            <MainHeading
              className={tab == 'quoteApprove' ? 'popupInnerTitle mb-0 mr-40 mt-0' : 'popupInnerTitle mb-0 mr-40 mt-0'}
              title={`${this.titleList[tab]} (${this.state.checked.length}) `}
            />
            {HeadingCalc(this.state.checked, tab)}
          </div>
        )}
        <div className={!this.props.isFinalCal ? 'popupHalfShow finalCaculation' : 'w-100'}>
          {!this.props.isFinalCal && (
            <ListingWrapper>
              <ListingTableWrapper classList={['.mainHeadingTitle', '.sidebarAction']} initial={30}>
                <Table
                  data={this.state.data}
                  columns={this.props.columns}
                  canSort={false}
                  currentType={currentType}
                  FilterOption={false}
                  areAllChecked={true}
                  noheader
                  noDots
                  noGrp
                />
              </ListingTableWrapper>
            </ListingWrapper>
          )}
          {['order', 'orderApprove', 'showStone', 'quoteApprove', 'holdMemoApprove'].includes(tab) && (
            <>
              <div
                className={
                  this.props.isFinalCal ? 'd-flex flex-wrap' : 'singleStonePlaceOrderDetail mt-20 d-flex flex-wrap'
                }
              >
                {['order', 'holdMemoApprove'].includes(tab) && (
                  <>
                    <PartyBuyerComponent
                      error={this.state.error}
                      setUserDetails={(data) => {
                        data.terms = data.userTerms?.dayTermsId;
                        this.setState({ ...data }, () => {
                          this.errorHandel({ fields: Object.keys(data) });
                          if (data.shippingCountry) {
                            this.changeTermsAndBankRate(data.shippingCountry);
                          }
                          // this.fetchClientOtherData();
                        });
                      }}
                      initialData={pick(this.state, ['client', 'userAccount', 'user'])}
                    />
                  </>
                )}

                {['quoteApprove'].includes(tab) && (
                  <>
                    <DisableBlock className="from-group d-flex width-66">
                      <PartyBuyerComponent
                        errorHandel={() => this.errorHandel({ fields: ['userAccount', 'user'] })}
                        error={this.state.error}
                        setUserDetails={(data) => {
                          this.setState({ ...data }, () => {
                            this.fetchClientOtherData();
                          });
                        }}
                        initialData={pick(this.state, ['client', 'userAccount', 'user'])}
                        disable
                      />
                    </DisableBlock>
                  </>
                )}

                {tab == 'showStone' && (
                  <>
                    <SelectOption
                      placeholder="Show Name"
                      value={isValue(this.state.showName)}
                      onChange={(showName = null) => {
                        this.setState({ showName }, () => {
                          this.errorHandel({ fields: ['showName'] });
                        });
                      }}
                      selectValue={this.state.showNameList}
                      label="Show Name *"
                      error={this.state.error?.showName}
                      onSearch={() => {
                        this.setState({ showName: null });
                      }}
                    />
                    {/* <InputField
                      placeholder="Show Name"
                      label="Show Name *"
                      onChange={(e) => {
                        this.setState({ showName: e.target.value });
                      }}
                      value={isValue(this.state.showName)}
                      error={state.error?.showName}
                    /> */}
                    <SelectOption
                      placeholder="Reference By"
                      value={isValue(this.state.referenceBy)}
                      onChange={(referenceBy = null) => {
                        const data = referenceBy
                          ? { user: null, client: [], seller: null }
                          : { user: state.user, client: state.client, seller: state.seller };
                        this.setState({ referenceBy, ...data }, () => {
                          this.errorHandel({ fields: ['referenceBy', 'user', 'seller'] });
                        });
                      }}
                      selectValue={REFERENCE_BY}
                      label="Reference By *"
                      onSearch={() => this.setState({ referenceBy: null })}
                      error={state.error?.referenceBy}
                    />
                    {this.state.referenceBy == 1 && (
                      <ClientComponent
                        error={{ client: state.error?.user }}
                        setClientData={(data) => {
                          this.setState({ client: data.clientList, user: data.client }, () => {
                            this.errorHandel({ fields: ['user'] });
                          });
                        }}
                        initialData={{ clientList: state.client, client: state.user }}
                      />
                    )}
                    {this.state.referenceBy == 2 && (
                      <SellerComponent
                        error={this.state.error}
                        setSellerDetails={(data) => {
                          this.setState({ ...data }, () => {
                            this.errorHandel({ fields: ['seller'] });
                          });
                        }}
                        initialData={pick(this.state, ['seller', 'sellerList'])}
                      />
                    )}
                  </>
                )}
                {['orderApprove'].includes(tab) && (
                  <>
                    <DisableBlock className="from-group d-flex width-66">
                      <PartyBuyerComponent
                        error={this.state.error}
                        setUserDetails={(data) =>
                          this.setState({ ...data }, () => {
                            this.errorHandel({ fields: Object.keys(data) });
                          })
                        }
                        initialData={pick(this.state, ['client', 'userAccount', 'user'])}
                        disable
                      />
                    </DisableBlock>
                  </>
                )}

                {['order', 'quoteApprove', 'orderApprove', 'holdMemoApprove'].includes(tab) && (
                  <>
                    <SellerComponent
                      error={this.state.error}
                      setSellerDetails={(data) => {
                        this.setState({ ...data }, () => this.errorHandel({ fields: ['seller'] }));
                      }}
                      initialData={pick(this.state, ['seller', 'sellerList'])}
                    />
                    <BrokerComponent
                      error={this.state.error}
                      setBrokerDetails={(data) => {
                        this.setState({ ...data }, () => this.errorHandel({ fields: ['broker'] }));
                      }}
                      label="Broker"
                      initialData={pick(this.state, ['broker', 'brokerList'])}
                    />
                  </>
                )}

                {['order', 'orderApprove', 'holdMemoApprove'].includes(tab) && (
                  <>
                    <LocationComponent
                      //error handel issue than pass different error handel fun
                      error={this.state.error}
                      locationDetails={(data) => {
                        if (data.shippingCountry || data.countryList) {
                          this.changeTermsAndBankRate(data.shippingCountry, data.countryList);
                        }
                        this.setState({ ...this.state, ...data }, () => {
                          this.errorHandel({ fields: Object.keys(data) });
                        });
                      }}
                      setCountryData={(countryList) => this.setState({ countryList })}
                      initialData={pick(this.state, ['shippingCountry', 'shippingCity'])}
                    />
                  </>
                )}
                {['order', 'quoteApprove', 'orderApprove', 'holdMemoApprove'].includes(tab) && (
                  <>
                    <SelectOption
                      placeholder="Select Invoice Date"
                      value={isValue(this.state.invoiceDate)}
                      onChange={(invoiceDate = null) => {
                        this.setState({ invoiceDate }, () => {
                          this.errorHandel({ fields: ['invoiceDate'] });
                        });
                      }}
                      selectValue={INVOICE_DATE}
                      label="Invoice Date *"
                      error={this.state.error?.invoiceDate}
                      onSearch={() => {
                        this.setState({ invoiceDate: null });
                      }}
                    />
                    <SelectOption
                      placeholder="Select Bill Type"
                      value={isValue(state.billType)}
                      selectValue={
                        state.countryName !== 'India' && state.countryName != null
                          ? state.billTypeList.filter((d) => d.code === 'DOLLAR_BILL')
                          : state.billTypeList
                      }
                      allowClear={false}
                      label="Bill Type *"
                      onChange={(billType = null) => {
                        this.setState(
                          {
                            billType,
                            isBankRateDisable:
                              state.billTypeList?.find((d) => d.key === billType)?.code == 'DOLLAR_BILL',
                          },
                          () => {
                            this.errorHandel({ fields: ['billType'] });
                          },
                        );
                      }}
                      onSearch={() => {
                        this.setState({ billType: null });
                      }}
                      error={this.state.error?.billType}
                    />
                  </>
                )}
                {/* {['order'].includes(tab) && (
                  <SelectOption
                    placeholder="Select Courier Name"
                    value={isValue(state.courierName)}
                    selectValue={state.courierNameList}
                    label={`Courier Name ${this.state.shipDirect ? ' * ' : ''}`}
                    onChange={(courierName = null) => {
                      this.errorHandel({ fields: ['courierName'] });
                      this.setState({ courierName });
                    }}
                    onSearch={() => {
                      this.setState({ courierName: null });
                    }}
                    error={this.state.error?.courierName}
                  />
                )} */}
                {['order', 'holdMemoApprove'].includes(tab) && (
                  <SelectOption
                    placeholder="Select Source Of Sale"
                    value={isValue(state.sourceOfSale)}
                    selectValue={state.sourceOfSaleList}
                    label="Source Of Sale *"
                    onChange={(sourceOfSale = null) => {
                      this.setState({ sourceOfSale }, () => {
                        this.errorHandel({ fields: ['sourceOfSale'] });
                      });
                    }}
                    onSearch={() => {
                      this.setState({ sourceOfSale: null });
                    }}
                    error={this.state.error?.sourceOfSale}
                  />
                )}
                {['order', 'orderApprove', 'holdMemoApprove'].includes(tab) && (
                  <SelectOption
                    placeholder="Select Assistant Salesman"
                    selectValue={this.state.sellerList}
                    value={this.state?.assistantSeller}
                    label="Assi.Salesman"
                    onChange={(assistantSeller = null) => {
                      this.setState({ assistantSeller });
                    }}
                    onSearch={() => {
                      this.setState({ assistantSeller: null });
                    }}
                  />
                )}
                {['order', 'quoteApprove', 'orderApprove', 'holdMemoApprove'].includes(tab) && (
                  <>
                    <SelectOption
                      placeholder="Select Terms"
                      value={isValue(state.terms)}
                      selectValue={state.termsList}
                      label="Terms"
                      onChange={(terms = null) => {
                        let bankRate = find(this.state.bankRateList, (d) => terms == d?.dayTermId);
                        this.setState({
                          terms,
                          bankRate: bankRate?.exchangeRate ? parseFloat(bankRate.exchangeRate).toFixed(2) : '',
                        });
                      }}
                      onSearch={() => {
                        this.setState({ terms: null });
                      }}
                    />
                    <InputField
                      className="disableBlock"
                      placeholder="Bank Rate"
                      label="Bank Rate"
                      type="number"
                      onKeyPress={(e) => {
                        if (e.key == '-') e.preventDefault();
                      }}
                      onChange={(e) => {
                        this.setState({ bankRate: parseFloat(Math.abs(e.target.value) || 0).toFixed(2) });
                      }}
                      value={state.isBankRateDisable ? '0.00' : isValue(this.state.bankRate)}
                    />
                  </>
                )}
                {['orderApprove'].includes(tab) && (
                  <SelectOption
                    placeholder="Select Inquiry Source "
                    value={isValue(this.state.inquirySource)}
                    onChange={(inquirySource = null) => {
                      // this.errorHandel({ fields: ['invoiceDate'] });
                      this.setState({ inquirySource });
                    }}
                    selectValue={state.inquirySourceList}
                    label="Inquiry Source"
                    // error={this.state.error?.invoiceDate}
                    onSearch={() => {
                      this.setState({ inquirySource: null });
                    }}
                  />
                )}

                <TextArea
                  label="Comments"
                  placeholder="Enter Comments"
                  onChange={(e) => {
                    this.setState({ comment: e.target.value });
                  }}
                  value={this.state.comment}
                />
                {['order', 'holdMemoApprove'].includes(tab) && (
                  <Checkbox
                    onCheck={this.state.shipDirect}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        this.errorHandel({ fields: ['courierName'] });
                      }
                      this.setState({ shipDirect: e.target.checked });
                    }}
                  >
                    Ship Direct
                  </Checkbox>
                )}
              </div>
            </>
          )}
          {this.state.loader && <CircularProgress className="fixLoader" />}
        </div>
        <div className="sidebarAction">
          <button className="commonButton" onClick={this.onClickFn}>
            {this.titleList[tab]}
          </button>
          <button className="commonOutine" onClick={() => this.props.onClose()}>
            Cancel
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ diamondData }) => {
  return {
    checkedData: isArray(diamondData?.selectedRows?.[currentType]) ? [...diamondData?.selectedRows?.[currentType]] : [],
  };
};

export default connect(mapStateToProps, null)(SingleStonePlaceOrderPopup);
