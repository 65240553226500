
import React from 'react';
import { Modal } from 'antd';

const CommonModal = (props) => {
  return (
    <div>
      <Modal
        title={props.title}
        className={`commonModal`}
        visible={props.visible}
        style={{
          position: 'static',
        }}
        maskClosable={props.maskClosable}
        //onOk={props.handleOk}
        onCancel={props?.handleCloseIcon || props.handleCancel}
        footer={
          props.footerShow ? (
            <div className="commonModalButton">
              <button className="fillButton" onClick={props.handleOk}>
                {props.submitTitle}
              </button>
              <button className="outLineButton" onClick={props.handleCancel}>
                {props.cancelTitle}
              </button>
            </div>
          ) : props.footerShowOne ? (<div className="commonModalButton">
            <button className="fillButton" onClick={props.handleOk}>
              {props.submitTitle}
            </button>

          </div>
          ) : null
        }
      >
        {props.children}
      </Modal>
    </div>
  );
};

export default CommonModal;