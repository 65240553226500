import React from 'react';

const FeedbackRow = (props) => {
  return (
    <table>
      <tr>
        <th>Sr No</th>
        <th>Question</th>
        <th>Answer</th>
      </tr>
      {props?.data?.map((item, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{item.question}</td>
          <td>{item.answer}</td>
        </tr>
      ))}
    </table>
  );
};
export default FeedbackRow;
