import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Modal } from 'antd';

import CircularProgress from 'components/CircularProgress';
import SelectOption from 'containers/SelectOption';
import TextArea from 'containers/TextArea';

import { getMasterDetailsByCode } from 'services/SubMasterApi';
import * as ProformaReportAPI from 'services/ProformaReportApi';
import Storage from 'services/storage';

import * as hooks from 'util/hooks';
import classNames from 'util/classNames';
import { isArray, isEmpty, isFunction } from 'util/utils';
import { showNotification } from 'services/NotificationService';

const defaultValues = { instructionType: undefined, comment: undefined };
const getInitialValues = (existingValues) => ({ ...defaultValues, ...existingValues });

const validationSchema = Yup.object().shape({
  instructionType: Yup.array()
    .of(Yup.string().trim())
    .min(1, 'Atleast one Instruction is required')
    .typeError('Instructions are required')
    .required('Instructions are required'),
  comment: Yup.string().trim().nullable(),
});

function ModalTitle({ title, list }) {
  return (
    <div className="modal-title">
      {title}{' '}
      <span className="modal-title-list">
        {isArray(list) &&
          list.map((item, index) => (
            <span key={index} className="modal-title-list-item">
              {item}
              {index < list.length - 1 && ', '}
            </span>
          ))}
      </span>
    </div>
  );
}

const getInstructionTypes = async () => {
  const key = 'PROFORMA_INSTRUCTION_TYPES';
  const types = Storage.get('dn-master')?.[key];
  if (isArray(types) && !isEmpty(types)) return types;

  const [, res] = await getMasterDetailsByCode({ masters: [key] });
  return res.code === 'OK' && isArray(res?.data?.[key]) ? res?.data?.[key] : [];
};

function InstructionForm({ className, list, onSuccess, onClose, ...props }) {
  const [instructionTypes, setInstructionTypes] = React.useState([]);

  hooks.useEffectOnMount(async () => {
    const types = await getInstructionTypes();
    setInstructionTypes(types.map(({ id, name }) => ({ key: id?.[0], value: name })));
  });

  const entries = React.useMemo(() => {
    return isArray(list) && list.filter((stone) => stone?.vStnId);
  }, [list]);

  const [vStnIds, stoneIds] = React.useMemo(() => {
    const vStnIds = isArray(entries) && entries.map((stone) => stone?.vStnId).filter(Boolean);
    const stoneIds = isArray(entries) && entries.map((stone) => stone?.id).filter(Boolean);
    return [vStnIds, stoneIds];
  }, [entries]);

  const initialValues = React.useMemo(() => {
    const existingValues =
      isArray(entries) && entries.length === 1
        ? { instructionType: entries?.[0]?.instructionType, comment: entries?.[0]?.comment }
        : {};
    return getInitialValues(existingValues);
  }, [entries]);

  const handleReset = React.useCallback(onClose, [onClose]);

  const handleSubmit = React.useCallback(
    async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const [, res] = await ProformaReportAPI.addInstruction({ id: stoneIds, ...values });
      if (res?.code === 'OK') {
        resetForm();
        showNotification(res);
        if (isFunction(onSuccess)) onSuccess();
        if (isFunction(onClose)) onClose();
      }
      setSubmitting(false);
    },
    [stoneIds, onSuccess, onClose],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={props?.visible ? initialValues : {}}
      validateOnBlur
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleChange,
        handleBlur,
        setValues,
        setFieldTouched,
        resetForm,
        submitForm,
        touched,
        errors,
        values,
        isSubmitting,
      }) => (
        <Form>
          {isSubmitting && <CircularProgress />}
          <Modal
            className={classNames(['commonModal', className])}
            title={<ModalTitle title="Add Instructions" list={vStnIds} />}
            footer={
              <div className="commonModalButton">
                <button className="fillButton" type="submit" onClick={submitForm}>
                  Add Instructions
                </button>
                <button
                  className="outLineButton"
                  type="reset"
                  onClick={() => {
                    resetForm();
                    handleReset();
                  }}
                >
                  Cancel
                </button>
              </div>
            }
            onCancel={onClose}
            centered
            {...props}
          >
            <SelectOption
              name="instructionType"
              label="Instructions"
              placeholder="Select Instructions"
              mode="multiple"
              value={values.instructionType}
              options={instructionTypes}
              onChange={(value) => setValues((values) => ({ ...values, instructionType: value }))}
              onBlur={() => setFieldTouched('instructionType')}
              onDropdownVisibleChange={(visible) => !visible && setFieldTouched('instructionType')}
              error={touched?.instructionType && errors?.instructionType}
            />
            <TextArea
              name="comment"
              label="Comments"
              placeholder="Comments"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.comment}
              error={touched?.comment && errors?.comment}
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
}

InstructionForm.propTypes = {
  list: PropTypes.array,
  className: PropTypes.string,
  onSUccess: PropTypes.func,
  onClose: PropTypes.func,
};

export default InstructionForm;
