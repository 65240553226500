import { API_ROUTES } from 'constants/CommonApi';
import UtilService from 'services/util';
import { isEmpty } from 'util/utils';

export const getMastersList = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Master.paginate, request, ...(!isEmpty(config) && { config }) });

export const storeMasterDetail = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Master.create, request, ...(!isEmpty(config) && { config }) });

export const findMasterDetail = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.Master.getDetail, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const updateMasterDetail = ({ updateId, ...request }, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.Master.update, [updateId]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const deleteMasterDetail = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Master.delete, request, ...(!isEmpty(config) && { config }) });

export const getMasterDetailsByCode = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Master.listByCode, request, ...(!isEmpty(config) && { config }) });

export const updateMasterSequence = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Master.sequenceUpdate, request, ...(!isEmpty(config) && { config }) });
