import React from "react";

const SortingClose = props => {
  return (
    <div className="shortingClose" onClick={props.onClick}>
      <img
        src={require("../../assets/svg/InventoryResult/close.svg")}
        alt="icon"
      />
    </div>
  );
};
export default SortingClose;
