import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Checkbox } from 'antd';
import * as Yup from 'yup';

import InputBlock, { InputAlphaNumeric } from 'containers/InputBlock';
import SelectOption from 'containers/SelectOption';
import Heading from 'containers/MainHeading';

import BottomStickyButton from 'components/ShortCuts/BottomStickButton';
import * as SellerAPI from 'services/SellerApi';
import Storage from 'services/storage';
import { formatInlineList, isArray, isEmpty, isObject, isString } from 'util/utils';
import OptionsUtils from 'util/OptionsUtils';
import classNames from 'util/classNames';

import _findIndex from 'lodash/findIndex';
import { DateTime } from 'luxon';
import AccountDropdown from 'components/User/AccountDropdown';

import { KYC_STATUS, REGISTRATION_TYPES } from 'constants/Common';
import { connect } from 'react-redux';
import TextArea from 'containers/TextArea';

class ClientKYCDetail extends Component {
  static propTypes = {
    account: PropTypes.any,
    createAccount: PropTypes.func,
    updateAccount: PropTypes.func,
  };

  state = {
    groups: [],
    sellers: [],
    cities: [],
    states: [],
    countries: [],
    loading: true,
    saveContinue: false,
  };

  masters = undefined;
  broker = undefined;
  groups = [];
  kycStatus = [];

  componentDidMount() {
    this.handleMount();
    this.getKycMaster();
  }

  getKycMaster = () => {
    this.masters = Storage.get('dn-master');
    this.groups = this.masters?.COMPANY_GROUP?.map((item) => ({
      ...item,
      id: item.id[0],
    }));
  };

  handleMount = async () => {
    await Promise.all([this.getSalesPerson(), this.initCountryStateCity()]);

    this.setState({ loading: false });
  };

  initCountryStateCity = async () => {
    await this.getCountries();

    const countryId = this.props?.account?.country?.id;
    if (!isEmpty(countryId)) this.getStates(countryId);

    const stateId = this.props?.account?.state?.id;
    if (!isEmpty(stateId)) this.getCities(stateId);
  };

  getSalesPerson = async () => {
    const [err, res] = await SellerAPI.getSellerList({ filter: { isActive: true } });
    const success = !err && res?.code === 'OK';

    if (success && isArray(res.data)) {
      this.setState({
        sellers: res.data.map(({ id, firstName, lastName }) => ({
          id,
          name: `${firstName}${lastName !== '_' ? ' ' + lastName : ''}`,
        })),
      });
    }
  };

  getCountries = async () => {
    const countries = await OptionsUtils.getCountryOptions();
    this.setState({ countries });
  };

  getStates = async (country) => {
    this.setState({ states: [] });
    const states = await OptionsUtils.getStateOptions(country);
    this.setState({ states });
  };

  getCities = async (state) => {
    this.setState({ cities: [] });
    const cities = await OptionsUtils.getCityOptions(state);
    cities.push({ key: 'other', value: 'Other' });
    this.setState({ cities });
  };

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const { account } = this.props;

    const groups = account?.group || [];
    const groupIndex = _findIndex(groups, { isActive: true });
    const groupId = groupIndex > -1 ? groups[groupIndex].id : '';
    values.isDefault = values.isDefault && account.ledgerType.split(',').includes('broker') ? true : false;
    values.isBrokerDefault = values.isDefault;

    let group = [];
    if (values?.group && values?.group != groupId) {
      group = (!isString(account?.group) && !isEmpty(account?.group) ? account?.group : [])?.map((item) => ({
        ...item,
        isActive: false,
      }));
      group.push({ id: values?.group, time: DateTime.local(), isActive: true });
    } else {
      group = groups;
    }

    if (account.isApproved != Number(values?.isApproved) && isArray(account?.kyc) && !isEmpty(account?.kyc)) {
      values.kyc = account.kyc.map((item) => {
        item.status = Number(values?.isApproved);
        return item;
      });
    }

    if (values.isSpam === true) values.isActive = false;

    values.approveStatusChanged = account.isApproved !== values.isApproved;

    if (!isArray(values.mobiles)) values.mobiles = values.mobiles?.split(',').map((item) => item.trim());
    if (!isArray(values.emails)) values.emails = values.emails?.split(',').map((item) => item.trim());
    if (!isArray(values.faxes)) values.faxes = values.faxes?.split(',').map((item) => item.trim());
    values.broker = isObject(values.broker) ? values?.broker?.id : values.broker;

    if (values.city == 'other') values.city = null;
    else values.cityText = '';

    setSubmitting(true);
    const [err, res] = await (isEmpty(account?.id)
      ? this.props.createAccount({ ...values, group, isVerified: values.isVerified ? 2 : 1 })
      : this.props.updateAccount(account?.id, { ...values, group, isVerified: values.isVerified ? 2 : 1 }));
    setSubmitting(false);

    if (!isEmpty(account?.id) && this.props?.filter?.isActive === 'spam' && values.isSpam !== account.isSpam) {
      this.props.getClientList();
    }

    if (isEmpty(account?.id)) {
      this.props.setClientList({ customerDetail: res?.data });
    }

    if (!err && res?.code === 'OK') {
      if (this.state.saveContinue) this.props.updateSubTabIndex();
      else {
        resetForm();
        this.props.onCancel();
      }
    }
  };

  initialValues = {
    country: undefined,
    state: undefined,
    city: undefined,
    cityText: '',
    companyName: '',
    vendorNo: '',
    address: '',
    emails: '',
    zipCode: '',
    isActive: false,
    isSpam: false,
    isVerified: false,
    isEmailVerified: false,
    isApproved: 1,
    seller: undefined,
    group: undefined,
    broker: undefined,
    // isFm: false,
    isDefault: false,
    name: '',
    email: '',
    Website: '',
    mobiles: '',
    faxes: '',
    contactEmail: '',
    contactMobile: '',
    vendorLogo: '',
    registrationType: undefined,
    syncId: '',
    isCOD: false,
  };

  getInitialValues = () => {
    let account = { ...this.props.account };

    this.broker = undefined;

    account.isVerified = account.isVerified === 2 ? true : false;
    let groups = account?.group || [];
    const groupIndex = _findIndex(groups, { isActive: true });
    account.group = groupIndex > -1 ? groups[groupIndex].id : '';
    account.isEmailVerified = account?.user?.isEmailVerified ?? false;
    account.isDefault = account.isDefault && account.ledgerType.split(',').includes('broker') ? true : false;

    // assign Ids to prepopulated fields
    const populatedFieldNames = ['country', 'state', 'city', 'seller'];
    populatedFieldNames.forEach((fieldName) => (account[fieldName] = account?.[fieldName]?.id));

    if (!isEmpty(account?.cityText)) account.city = 'other';

    return Object.fromEntries(
      Object.entries(this.initialValues).map(([key, value]) => [key, isEmpty(account?.[key]) ? value : account?.[key]]),
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.account.id !== this.props.account.id) {
      this.handleMount();
      this.getKycMaster();
    }
  }

  validationSchema = () =>
    Yup.object().shape({
      companyName: Yup.string().trim().required('Please enter company name'),
      vendorNo: Yup.string().trim().nullable(),
      address: Yup.string().trim().required('Please enter address'),
      emails: Yup.array().of(Yup.string().email('Invalid Email Address')).required('Please enter emails'),
      mobiles: Yup.array().of(Yup.string()).required('Please enter phone no'),
      country: Yup.string().trim().required('Please selct country'),
      state: Yup.string().trim().required('Please select state'),
      city: Yup.string().trim().required('Please select city'),
      cityText: Yup.string()
        .trim()
        .test('city-text', 'Please enter city name', function (value) {
          return !(isEmpty(value) && this.parent.city === 'other');
        }),
      zipCode: Yup.string()
        .trim()
        .when('registrationType', {
          is: (v) => ['2'].includes(v),
          then: (s) => s.min(6),
          otherwise: (s) => s.min(4),
        })
        .when('registrationType', {
          is: (v) => ['2'].includes(v),
          then: (s) => s.max(6),
          otherwise: (s) => s.max(10),
        }),
      isActive: Yup.boolean(),
      isSpam: Yup.boolean(),
      // isFm: Yup.boolean(),
      isDefault: Yup.boolean(),
      isCOD: Yup.boolean(),
      seller: Yup.string().required('Please select sales person'),
      group: Yup.string().nullable(),
      // name: Yup.string().required('Please enter name'),
      // contactEmail: Yup.string().email('Please enter valid email').required('Please enter email'),
      // contactMobile: Yup.string()
      // .trim()
      // .matches(/^[0-9]*$/, 'Invalid Phone no')
      // .min(10, 'Phone no must be of 10 digits')
      // .max(10, 'Phone no must be of 10 digits')
      // .required('Please enter phone no'),
      // Website: Yup.string().url('Invalid URL'),
      // faxes: Yup.string().trim(),
      // vendorLogo: Yup.string().trim().required('Please choose logo'),
    });

  render() {
    const { cities, states, countries, sellers } = this.state;

    return (
      <Formik
        enableReinitialize
        initialValues={this.getInitialValues()}
        validationSchema={this.validationSchema}
        validateOnChange
        onSubmit={this.submitForm}
      >
        {({
          handleChange,
          handleBlur,
          setValues,
          setFieldValue,
          setFieldTouched,
          submitForm,
          touched,
          errors,
          values,
        }) => (
          <Form>
            <div className={classNames(['kycFormMainBlock'])}>
              <div className="kycBlockWrapper mb-20">
                <InputBlock
                  name="companyName"
                  label="Company Name *"
                  placeholder="Company Name"
                  type="text"
                  value={values?.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.companyName && errors?.companyName}
                />
                <InputBlock
                  name="vendorNo"
                  label="Company Code"
                  placeholder="Company Code"
                  type="text"
                  value={values?.vendorNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.vendorNo && errors?.vendorNo}
                />
                <InputBlock
                  name="address"
                  label="Address *"
                  placeholder="Address"
                  type="text"
                  value={values?.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.address && errors?.address}
                />
                <InputBlock
                  name="emails"
                  label="Email *"
                  placeholder="Email"
                  type="text"
                  value={values?.emails}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (isString(value))
                      setValues((values) => ({
                        ...values,
                        emails: formatInlineList(value, { allowAppend: true }).split(','),
                      }));
                  }}
                  onBlur={handleBlur}
                  error={touched?.emails && errors?.emails}
                />
                <InputBlock
                  name="mobiles"
                  label="Phone no. *"
                  placeholder="Phone no."
                  type="text"
                  value={values?.mobiles}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (isString(value))
                      setValues((values) => ({
                        ...values,
                        mobiles: formatInlineList(value, { allowAppend: true }).split(','),
                      }));
                  }}
                  onBlur={handleBlur}
                  error={touched?.mobiles && errors?.mobiles}
                />
                <InputBlock
                  name="zipCode"
                  label="Zip/Pin Code"
                  placeholder="Zip/Pin Code"
                  type="text"
                  max={[1, 2].includes(values?.registrationType) ? 6 : 10}
                  min={[1, 2].includes(values?.registrationType) ? 6 : 4}
                  value={values?.zipCode}
                  onChange={(event) => {
                    const value = event.target.value;
                    setValues((values) => ({ ...values, zipCode: value }));
                  }}
                  onBlur={handleBlur}
                  error={touched?.zipCode && errors?.zipCode}
                />
                <SelectOption
                  value={countries?.filter((item) => item.key == values?.country).length ? values?.country : undefined}
                  onChange={(value) => {
                    this.getStates(value);
                    setValues((values) => ({ ...values, city: undefined, state: undefined, country: value }));
                  }}
                  onBlur={() => setFieldTouched('country', true)}
                  name="country"
                  error={errors?.country}
                  label="Country *"
                  placeholder="Select Country"
                  selectValue={countries}
                  showSearch
                />
                <SelectOption
                  value={states?.filter((item) => item.key == values?.state).length ? values?.state : undefined}
                  onChange={(value) => {
                    this.getCities(value);
                    setValues((values) => ({ ...values, state: value, city: undefined }));
                  }}
                  onBlur={() => setFieldTouched('state', true)}
                  name="state"
                  error={errors?.state}
                  label="State *"
                  placeholder="Select State"
                  selectValue={states}
                  showSearch
                />
                <SelectOption
                  value={cities?.filter((item) => item.key == values?.city).length ? values?.city : undefined}
                  onChange={(value) => setValues((values) => ({ ...values, city: value }))}
                  onBlur={() => setFieldTouched('city', true)}
                  name="city"
                  error={errors?.city}
                  label="City *"
                  placeholder="Select City"
                  selectValue={cities}
                  showSearch
                />
                {values?.city == 'other' && (
                  <InputBlock
                    name="cityText"
                    label="City *"
                    placeholder="City"
                    type="text"
                    value={values?.cityText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched?.cityText && errors?.cityText}
                  />
                )}
                <SelectOption
                  value={sellers?.filter((item) => item.id == values?.seller).length ? values?.seller : undefined}
                  onChange={(value) => setValues((values) => ({ ...values, seller: value }))}
                  onBlur={() => setFieldTouched('seller', true)}
                  name="seller"
                  error={touched?.seller && errors?.seller}
                  label="Sales Person *"
                  placeholder="Select Sales Person"
                  selectValue={sellers}
                  nameKey="name"
                  valueKey="id"
                  showSearch
                />
                <SelectOption
                  value={this.groups?.filter((item) => item.id == values?.group).length ? values?.group : undefined}
                  onChange={(value) => setValues((values) => ({ ...values, group: value }))}
                  onBlur={() => setFieldTouched('group', true)}
                  name="group"
                  error={touched?.group && errors?.group}
                  label="Group"
                  placeholder="Select Group"
                  selectValue={this.groups}
                  nameKey="name"
                  valueKey="id"
                  showSearch
                />
                <AccountDropdown
                  initialValue={values?.broker}
                  value={values?.broker?.id}
                  placeholder="Broker"
                  label={`Broker ${this.props?.account.hasBroker ? '(' + this.props?.account?.brokerInfo?.brokerName + ')' : ''
                    }`}
                  name="broker"
                  onChange={(broker) => {
                    setFieldValue('broker', broker);
                  }}
                  ledgerType="broker"
                />
                <SelectOption
                  value={
                    isEmpty(values?.registrationType) || values?.registrationType == 0
                      ? undefined
                      : `${values?.registrationType}`
                  }
                  onChange={(value) => setValues((values) => ({ ...values, registrationType: value || 0 }))}
                  onBlur={() => setFieldTouched('registrationType', true)}
                  name="registrationType"
                  error={touched?.registrationType && errors?.registrationType}
                  label="Registration Type"
                  selectValue={Object.entries(REGISTRATION_TYPES).map(([key, value]) => ({ key, value }))}
                  showSearch
                  placeholder="Select Registration Type"
                />
                <SelectOption
                  value={
                    KYC_STATUS?.filter((item) => item.key == values?.isApproved).length
                      ? `${values?.isApproved}`
                      : undefined
                  }
                  onChange={(value) => setFieldValue('isApproved', value || '1')}
                  onBlur={() => setFieldTouched('isApproved', true)}
                  name="isApproved"
                  error={touched?.isApproved && errors?.isApproved}
                  label="KYC Approve"
                  selectValue={KYC_STATUS}
                  showSearch
                  disabled={values?.isSpam === true}
                />
                {values?.city != 'other' && <div className="from-group"></div>}
                <InputBlock
                  name="syncId"
                  label="Account Id"
                  placeholder="Account Id"
                  type="text"
                  value={values?.syncId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextArea
                  label="Remarks"
                  placeholder="Remarks"
                  name="remark"
                  value={values?.remark}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.remark && errors?.remark}
                />
                <div className="checkBoxForm">
                  <label>Spam</label>
                  <Checkbox
                    onChange={(e) => {
                      e.target.checked
                        ? setValues((values) => ({ ...values, isActive: false, isSpam: !values?.isSpam }))
                        : setValues((values) => ({ ...values, isSpam: !values?.isSpam }));
                    }}
                    checked={values?.isSpam}
                  />
                </div>
                <div className="checkBoxForm">
                  <label>Verified</label>
                  <Checkbox
                    onChange={() => setFieldValue('isVerified', !values?.isVerified)}
                    checked={values?.isVerified}
                    disabled={values?.isSpam === true}
                  />
                </div>
                <div className="checkBoxForm">
                  <label>Active</label>
                  <Checkbox
                    onChange={() => setFieldValue('isActive', !values?.isActive)}
                    checked={values?.isActive}
                    disabled={values?.isSpam === true}
                  />
                </div>
                <div className="checkBoxForm">
                  <label>Email Verified?</label>
                  <Checkbox
                    onChange={() => setFieldValue('isEmailVerified', !values?.isEmailVerified)}
                    checked={values?.isEmailVerified}
                  //disabled={false}
                  />
                </div>
                <div className="checkBoxForm">
                  <label>Default Broker</label>
                  <Checkbox
                    onChange={() => setFieldValue('isDefault', !values?.isDefault)}
                    checked={values?.isDefault}
                  />
                </div>
                <div className="checkBoxForm">
                  <label>COD Term</label>
                  <Checkbox
                    onChange={(e) => setFieldValue('isCOD', !values?.isCOD)}
                    checked={values?.isCOD}
                  />
                </div>
                {/* <div className="checkBoxForm">
                  <label>KYC Approved?</label>
                  <Checkbox
                    onChange={() => setFieldValue('isApproved', !values?.isApproved)}
                    checked={values?.isApproved}
                  />
                </div> */}
              </div>
              <div className="d-flex j-space-between mb-20">
                <Heading className="smallTitle p-0" title="Contact Details" />
              </div>
              <div className="kycBlockWrapper mb-20 addRemoveinputBlock">
                <InputBlock
                  name="name"
                  label="Contact Person Name"
                  placeholder="Contact Person Name"
                  type="text"
                  value={values?.name}
                  onChange={handleChange}
                // onBlur={handleBlur}
                // error={touched?.name && errors?.name}
                />
                <InputBlock
                  name="contactEmail"
                  label="Email"
                  placeholder="Email"
                  type="text"
                  value={values?.contactEmail}
                  onChange={handleChange}
                // onBlur={handleBlur}
                // error={touched?.contactEmail && errors?.contactEmail}
                />
                <InputBlock
                  name="Website"
                  label="Website"
                  placeholder="Website"
                  type="text"
                  value={values?.Website}
                  onChange={handleChange}
                // onBlur={handleBlur}
                // error={touched?.Website && errors?.Website}
                />
                <InputBlock
                  name="contactMobile"
                  label="Mobile Number"
                  onKeyPress={(evt) => {
                    if (evt.which < 48 || evt.which > 57 || evt.target.value.length >= 10) {
                      evt.preventDefault();
                    }
                  }}
                  placeholder="Mobile Number"
                  type="text"
                  value={values?.contactMobile}
                  onChange={handleChange}
                // onBlur={handleBlur}
                // error={touched?.contactMobile && errors?.contactMobile}
                />
                {/* <InputBlock
                  name="faxes"
                  label="FAX Numbers"
                  placeholder="FAX Numbers"
                  type="text"
                  value={values?.faxes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.faxes && errors?.faxes}
                /> */}
              </div>
              {/* <div className="tabinnerTitle">Upload Document *</div>
              <FileUpload
                onChange={(value) => setFieldValue('vendorLogo', value)}
                onBlur={() => setFieldTouched('vendorLogo', true)}
                error={touched?.vendorLogo && errors?.vendorLogo}
                label="Choose Document"
                files={[values?.vendorLogo]}
              /> */}
            </div>
            <BottomStickyButton>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: true }, submitForm)}
              >
                Save & Continue
              </button>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: false }, submitForm)}
              >
                Save
              </button>
              <button className="commonOutine" type="reset" onClick={this.props.onCancel}>
                Cancel
              </button>
            </BottomStickyButton>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(
  ({ accountFilter }) => ({
    filter: accountFilter,
  }),
  {},
)(ClientKYCDetail);
