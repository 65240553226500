import React from 'react';
import { Link } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { DateTime } from 'luxon';

import CommonSearch from 'containers/CommonSearch';
import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import ListingWrapper from 'containers/ListingWrapper';
import PlusIcon from 'containers/PlusIcon';
import StatusBlock from 'containers/StatusBlock';
import Table from 'components/Inventory/TableBack';
import Form from './Form';

import { showNotification } from 'services/NotificationService';
import * as SubMasterAPI from 'services/SubMasterApi';
import { isArray, isEmpty } from 'util/utils';
import { getState } from 'components/Inventory/UpcomingDiamond';
import { getModuleFromUrl } from 'constants/permission';
import { useCompositeState } from 'util/hooks';

const initialSort = [{ sortingSequence: 'ASC' }];

const EmailConfiguration = () => {
  const [form, setForm] = React.useState(true);
  const [state, setState] = useCompositeState(
    getState({
      tab: false,
      checked: [],
      data: [
        {
          status: 'kyc',
          title: 'New KYC added Parul B. (Bhayani Corp.)',
          basic: 'Today, 12:56 PM (Created by Kalpesh Z.)',
          type: 'KYC',
        },
        {
          status: 'kyc',
          title: 'New KYC added Parul B. (Bhayani Corp.)',
          basic: 'Today, 12:56 PM (Created by Kalpesh Z.)',
          type: 'KYC',
        },
        {
          status: 'kyc',
          title: 'New KYC added Parul B. (Bhayani Corp.)',
          basic: 'Today, 12:56 PM (Created by Kalpesh Z.)',
          type: 'KYC',
        },
      ],
      sort: initialSort,
      loading: true,
    }),
  );

  React.useEffect(() => {
    getMastersList();
  }, [getMastersList]);

  const getMastersList = React.useCallback(
    async (search = null) => {
      setState({ loading: false });
      // const requestConf = {
      //   isOnlyParents: true,
      //   isDeleted: false,
      //   sort: state.sort,
      //   filter: {},
      // };
      // if (search) requestConf.search = { keyword: search, keys: ['name', 'code'] };
      // const [, res] = await SubMasterAPI.getMastersList(requestConf);

      // setState(
      //   res?.code == 'OK' && isArray(res?.data) && !isEmpty(res?.data)
      //     ? { data: res.data, count: res.data.length, loading: false }
      //     : { data: [], count: 0, loading: false },
      // );
    },
    [setState, state.sort],
  );

  const openForm = () => setForm(false);

  const closeForm = () => {
    setState({ detail: {} });
    setForm(true);
    getMastersList();
  };

  const editMasterDetail = React.useCallback(
    async (editId) => {
      const [, res] = await SubMasterAPI.findMasterDetail(editId);
      if (res?.code === 'OK') {
        setState({ detail: res.data });
        setForm(false);
      }
    },
    [setState],
  );

  const deleteMasterDetail = React.useCallback(
    async (updateId) => {
      const [err, res] = await SubMasterAPI.updateMasterDetail({ updateId, isDeleted: true });
      !err && showNotification(res);

      if (res?.code === 'OK') {
        getMastersList();
      }
    },
    [getMastersList],
  );

  const handleSearch = (value) => {
    if (value?.length > 2 || isEmpty(value)) getMastersList(value);
  };

  const updateActiveStatus = async (id, status) => {
    const [err, res] = await SubMasterAPI.updateMasterDetail({ updateId: id, isActive: status });
    !err && showNotification(res);
    return !err && res.code === 'OK';
  };

  const columns = React.useMemo(
    () => [
      { Header: 'Title', accessor: 'Title', sort: 'Title', Cell({row}) { return (
        <span>123</span>
      )} },
      { Header: 'Code', accessor: 'code' , Cell({row}) { return (
        <span>SDERFG</span>
      )}},
      { Header: 'Subject', accessor: 'Subject', sort: 'Subject', Cell({row}) { return (
        <span>abc</span>
      )} },
      {
        Header: 'Content',
        accessor: 'Content',
        id: 'Content', Cell({row}) { return (
          <span>abc 123</span>
        )}
      },
      {
        Header: 'Action',
        Cell({ row }) {
          return (
            <div className="actionButton">
              {getModuleFromUrl().update && (
                <div className="actionAction" onClick={() => editMasterDetail(row.original.id)}>
                  <img src={require('assets/svg/edit.svg')} />
                </div>
              )}
              <Popconfirm
                placement="left"
                title={`Are you sure you want to delete this Master?`}
                okText={`Delete`}
                onConfirm={() => deleteMasterDetail(row.original.id)}
              >
                <div className="actionAction">
                  <img src={require('assets/svg/delet.svg')} />
                </div>
              </Popconfirm>
            </div>
          );
        },
      },
    ],
    [deleteMasterDetail, editMasterDetail],
  );

  const handleSort = React.useCallback(
    (sort) => {
      if (JSON.stringify(state.sort) !== JSON.stringify(sort)) {
        setState({ sort }, () => getMastersList());
      }
    },
    [getMastersList, setState, state.sort],
  );

  return (
    <div className="configurationInnerSubTb">
      <div className="d-flex j-space-between p-15 align-items-center masterTopBlock">
        <div className="headeTitle">
          <h2 className="mb-0">Email Configuration</h2>
        </div>
      </div>
      {form ? (
        <ListingWrapper>
          {getModuleFromUrl().insert && <PlusIcon onClick={openForm} />}
          <GridHeightContainer
            id="MasterTable"
            className="searchResultTable"
            subtractFrom=".masterTopBlock"
            style={{ height: 'auto', overflow: 'auto' }}
          >
            <Table
              data={state.data}
              loading={state.loading}
              columns={columns}
              initialSort={initialSort}
              handleSort={handleSort}
              FilterOption={false}
              nocheck
            />
          </GridHeightContainer>
        </ListingWrapper>
      ) : (
        <Form onClick={closeForm} detail={state.detail} />
      )}
    </div>
  );
};

export default EmailConfiguration;
