import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import classNames from 'util/classNames';

import svgLeftArrow from 'assets/svg/left-arrow.svg';
import svgRightArrow from 'assets/svg/right-arrow.svg';

const LIMIT_OPTIONS = [50, 100, 250, 500, 1000];
const BID_LIMIT_OPTIONS = [50, 100, 250, 500, 1000, 'All'];
const OVERFLOW_DOTS = '...';

function getPages(totalPage, page) {
  const pages = [];
  const prevPage = page > 1 ? page - 1 : 1;

  if (prevPage > 1) {
    pages.push(1);
    if (page > 3) pages.push(OVERFLOW_DOTS);
  }

  for (let i = prevPage; i <= prevPage + 2 && i <= totalPage; i++) {
    if (pages[i - 2] === i + 1) pages.pop();
    pages.push(i);
  }

  if (prevPage + 3 < totalPage) pages.push(OVERFLOW_DOTS);
  if (prevPage + 2 < totalPage) pages.push(totalPage);
  return pages;
}

function Pagination({
  page,
  limit,
  count,
  length,
  onPaginationChange,
  noItemCount,
  noLimitChange,
  className,
  ...props
}) {

  const totalPage = React.useMemo(() => (count === 0 ? 1 : parseInt((count + limit - 1) / limit)), [count, limit]);
  const dataLength = React.useMemo(() => length || props?.data?.length || 0, [length, props?.data?.length]);
  const formattedPages = React.useMemo(() => getPages(totalPage, page), [page, totalPage]);

  const gotoPrevPage = React.useCallback(() => {
    if (page !== 1) onPaginationChange(page - 1, limit);
  }, [limit, onPaginationChange, page]);

  const gotoPage = React.useCallback(
    (page) => () => {
      if (page !== OVERFLOW_DOTS) onPaginationChange(page, limit);
    },
    [limit, onPaginationChange],
  );

  const gotoNextPage = React.useCallback(() => {
    if (page !== totalPage) onPaginationChange(page + 1, limit);
  }, [limit, onPaginationChange, page, totalPage]);

  const handleLimitChange = React.useCallback(
    (limit) => {
      onPaginationChange(page, limit);
    },
    [onPaginationChange, page],
  );
  const limitValue = props.limitOption ? BID_LIMIT_OPTIONS : LIMIT_OPTIONS;
  const pageLimitTo = limit == 'All' ? 0 : (count > 0 ? (page - 1) * limit + 1 : 0)
  const pageLimitFrom = limit == 'All' ? 0 : (count > 0 ? (page - 1) * limit + dataLength : 0)
  return (
    <div className={classNames(['listingPagePagination', className])}>
      <div className="clientPagination">
        {!noLimitChange && (
          <Select value={limit} onChange={handleLimitChange} className="paginationSelect">
            {limitValue.map((limit, index) => (
              <Select.Option value={limit} key={index}>
                {limit}
              </Select.Option>
            ))}
          </Select>
        )}
        <ul>
          <li onClick={gotoPrevPage}>
            <img src={svgLeftArrow} alt="arrow" />
          </li>
          {formattedPages.map((thisPage, index) => (
            <li key={index} className={classNames([thisPage === page && 'active'])} onClick={gotoPage(thisPage)}>
              {thisPage}
            </li>
          ))}
          <li onClick={gotoNextPage}>
            <img src={svgRightArrow} alt="arrow" />
          </li>
        </ul>
        {!noItemCount && (
          <span className="paginationText">
            {pageLimitTo} - {pageLimitFrom} of {count}{' '}
            items
          </span>
        )}
      </div>
    </div>
  );
}

Pagination.propTypes = {
  page: PropTypes.number, // current page
  limit: PropTypes.number, // current limit
  count: PropTypes.number, // total count
  length: PropTypes.number, // data.length
  noLimitChange: PropTypes.bool, // limit dropdown
  noItemCount: PropTypes.bool, // display items 1-10 of 1000
  onPaginationChange: PropTypes.func, // display limit Selection
};

export default Pagination;
