import React, { Component } from 'react';
import MainHeading from '../../../containers/MainHeading';
import { Tooltip } from 'antd';
import OfficeSolt from './OfficeSolt';
import SelectOption from '../../../containers/SelectOption';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TimeConfim from './TimeConfim';
import LeftMultipleSelect from '../../../containers/LeftOption/MultiSelect';
import LeftDatePicker from '../../../containers/LeftOption/DatePicker';
import YourSoltBook from './YourSoltBook';
import { timeSolt } from '../../../constants/solt';
import TimeDate from './TimeDate';
import AppointmentForm from './AppointmentForm';
import moment from 'moment';
import { MEETING_TYPE } from 'constants/Common';
import { isValue } from '../CommonPopupFunction';
import { getOfficeList } from 'services/OfficeApi';
import SlotItem from './SlotItem';
import { uniq, trim } from 'lodash';
import CircularProgress from 'components/CircularProgress';
export const Appointemt = {
  location: [{ value: 'India' }, { value: 'Spain' }, { value: 'Hong Kong' }, { value: 'Dubai' }],
  virtualType: [{ value: 'Phone Call' }, { value: 'Web Conferen' }],
};
class OfficeSidebar extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
      date: moment().toISOString(),
      timedate: false,
      slots: [],
      bidSlots: [],
      clickSlot: '',
      apiSlot: '',
      loader: false,
    };
  }
  onChange = (date) => this.setState({ date });
  bookAppointment = () => {
    this.setState({
      show: !this.state.show,
      timedate: false,
    });
  };
  bookAppointmentWithTime = () => {
    this.setState({
      timedate: !this.state.timedate,
      show: false,
    });
  };
  // ******************************
  componentDidMount() {
    this.getCabinWiseListing();
  }
  getWorkingDays = (workingDays) => {
    let workingDates = [];
    let date = moment(new Date());
    while (workingDays > 0) {
      date = date.add(1, 'days');
      if (date.isoWeekday() !== 7) {
        workingDays -= 1;
        workingDates.push(moment(date).toISOString());
      }
    }
    return workingDates;
  };
  getCabinWiseListing = async () => {
    let [, res] = await getOfficeList({ sort: [{ end: 'ASC' }] });
    if (res.code == 'OK' && res.data?.list) {
      let bidSlots = [];
      let data = res.data?.list.forEach((ls) => {
        //If in bid page
        let start = moment(ls.start).format('hh:mm A');
        let end = moment(ls.end).format('hh:mm A');
        let bidTime = start < '11:00 AM' && end > '03:00 PM';
        if (!bidTime) {
          bidSlots.push(ls);
        }
      });
      this.setState({ slots: uniq(res.data.list), bidSlots });
    }
  };

  render() {
    let finalSlots = this.props.bid ? this.state.bidSlots : this.state.slots;
    return (
      <div className="officeBookWrapper">
        <div className="officeBookLeft">
          <div className="officeDateListing">
            <div className="officeTopLeft">
              {/* <LeftMultipleSelect
                label="Location"
                defaultValue="Select Location"
                selectValue={Appointemt.location}
                mode
              /> */}
              <LeftDatePicker
                date={this.state.date ? this.state.date : ''}
                Singledate
                onDateChange={(dt) => this.setState({ date: moment(dt).toISOString(), clickSlot: '', apiSlot: '' })}
              />
            </div>
            <div className="cardListinOffice">
              {this.getWorkingDays(7).map((date, index) => (
                <div
                  onClick={() => this.setState({ date, clickSlot: '', apiSlot: '' })}
                  className={`cardListOfficeItem ${
                    moment(this.state.date).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY') && `active`
                  }`}
                  key={index}
                >
                  <div className="cardListOfficeTop">
                    <div className="cardListOfficeDate"> {moment(date).format('ddd, MMM Do YYYY')}</div>
                    <div className="cardListOfficeTotal">04</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="officeBookRight">
          <div className="soltWrapper">
            <div className="soltBookLeft">
              <div className="soltBookTop">
                <div className="appoinmentOfficeRightTop">
                  <MainHeading title="Appointment" />
                  <span className="appointmentDate">
                    {this.state.date ? moment(this.state.date).format('ddd, MMM Do YYYY') : ''}
                  </span>
                </div>

                <div className="officeBookSolt smallScroll grayScroll">
                  <div className="officeBookSoltItem">
                    <div className="officeBookSoltTitle">Select Time</div>
                    <div className="officeBookSoltInner">
                      {finalSlots.map((d, index) => {
                        let start = d.start ? moment(d.start).format('hh:mm A') : '';
                        let end = d.end ? moment(d.end).format('hh:mm A') : '';
                        let display = `${start} - ${end}`;
                        let disabled = moment(`${moment(this.state.date).format('YYYY-MM-DD')} ${end}`) < moment();
                        return (
                          <>
                            {trim(display) != '-' ? (
                              <SlotItem
                                key={index}
                                onClick={() => this.setState({ clickSlot: display, apiSlot: d })}
                                time={display}
                                className={
                                  this.state.clickSlot === display ? `soltBook` : disabled ? `allReadyBook` : ''
                                }
                                tooltip={'Book Appointment'}
                              />
                            ) : (
                              ''
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  {/* <OfficeSolt title="Virtual Appointment" data={timeSolt.solt1} /> */}
                  {/* <OfficeSolt
                    title="Available Cabin - 1 (4 Seater)"
                    OnClick={this.bookAppointment.bind(this)}
                    data={timeSolt.solt2}
                  />
                  <OfficeSolt title="Available Cabin - 2 (4 Seater)" data={timeSolt.solt3} />
                  <OfficeSolt title="Available Cabin - 3 (6 Seater)" data={timeSolt.solt4} />
                  <OfficeSolt title="Available Cabin - 4 (4 Seater)" data={timeSolt.solt5} />
                  <OfficeSolt title="Available Cabin - 5 (4 Seater)" data={timeSolt.solt6} />
                  <OfficeSolt title="Available Cabin - 6 (4 Seater)" data={timeSolt.solt7} /> */}
                </div>
              </div>
              <div className="soltBookBottom smallScroll grayScroll">
                <div className="officeBookingArea">
                  <div className="officeBookingRight">
                    <AppointmentForm
                      buttonFasle
                      setLoader={() => {
                        this.setState({ loader: !this.state.loader });
                      }}
                      apiSlot={this.state.apiSlot}
                      date={this.state.date}
                      checked={this.props.checked}
                      slot={this.state.clickSlot}
                      onClose={this.props.onClose}
                      client={this.props.client}
                      unCheckedDiamond={this.props.unCheckedDiamond}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.state.loader && <CircularProgress className="fixLoader" />}
            {/* //Right side lisitng */}
          </div>
        </div>
      </div>
    );
  }
}

export default OfficeSidebar;
