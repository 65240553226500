import React from 'react';
import './checkBox.less';

function CheckBox({ label, ...props }) {
  return (
    <div className="customCheckBox d-flex flex-wrap">
      <input type="checkbox" {...props} />
      <span className="customCheckBoxLebel"></span>
      {label && <label>{label}</label>}
    </div>
  );
}

export default React.memo(CheckBox);
