import React, { Component } from 'react';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import { connect } from 'react-redux';
import MainHeading from 'containers/MainHeading';
import Table from 'components/Inventory/TableBack';
import { calculate, formatNumber, newDiamondPrice, getExtraPer } from './SelectStone';
// import { DIAMOND_BID } from 'constants/Common';
// import BIDStoneMobile from './BIDStoneMobile';
import { getBidType, HeadingCalc } from './CommonPopupFunction';
import { DIAMOND_BID } from 'constants/Inventory';
import ListingWrapper from 'containers/ListingWrapper';
import ListingTableWrapper from 'containers/ListingTableWrapper';
// import { createBidStone } from 'services/BidApi';
import CircularProgress from 'components/CircularProgress';
import { isEmpty } from 'util/utils';

const currentType = 'bidStones';

class BidPopup extends Component {
  state = {
    columns: [],
    checked: [],
    data: [],
    userTerms: this?.props?.client?.user?.accountTerm || this?.props?.client?.accountTerm,
    loader: false,
  };

  getNewBid = (stoneId, minus) => {
    let data = cloneDeep(this.state.data);
    let index = findIndex(data, { stoneId });
    let val = minus ? data[index].bidPricePerCarat - 20 : data[index].bidPricePerCarat + 20;
    if (val < data[index].ctPr && minus) return;
    data[index].bidPricePerCarat = val;
    data[index].bidAmount = (val || 0) * data[index].crt;
    let sum = calculate([{ ...data[index], ctPr: val, amt: data[index].bidAmount }]);
    data[index].bidDiscount = sum.final_discount;
    data[index] = newDiamondPrice(data[index], this.state.userTerms, 'bid');
    this.setState({ data }, () => {
      let checked = this.state.data.filter((el) => find(this.state.checked, { id: el.id }));
      this.setState({ checked });
    });
  };

  getBidPrCell = (row) => {
    return (
      <div className="tableInput">
        <img
          src={require('../../assets/images/minus.png')}
          width="15px"
          alt=""
          onClick={() => this.getNewBid(row.stoneId, true)}
        />
        <input style={{ width: '70%' }} value={row.bidPricePerCarat} className="invColumnBold" />
        <img
          src={require('../../assets/images/plus.png')}
          width="15px"
          alt=""
          onClick={() => this.getNewBid(row.stoneId, false)}
        />
      </div>
    );
  };

  componentDidMount() {
    let data = this.props.checked
      .map((x) => ({
        ...x,
        bidPricePerCarat: Math.round(parseFloat((x.bidPricePerCarat ? x.bidPricePerCarat : x.ctPr) || 0)),
        bidAmount: x.bidAmount ? x.bidAmount : x.amt,
        bidDiscount: x.bidDiscount ? x.bidDiscount : x.back,
      }))
      .map((x) => newDiamondPrice(x, this.state.userTerms, 'bid'));
    let Columns = this.props.columns;
    let index = findIndex(Columns, { id: 'rap' });
    if (!index) index = 1;
    let columns = [
      ...Columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      find(Columns, { id: 'back' }),
      find(Columns, { id: 'ctPr' }),
      find(Columns, { id: 'amt' }),
      {
        Header: 'Bid Price/Ct',
        accessor: 'bidPricePerCarat',
        id: 'bidPricePerCarat',
        width: 230,
        Cell: ({ row }) => this.getBidPrCell(row.original),
      },
      {
        Header: 'Bid Disc(%)',
        accessor: 'bidDiscount',
        id: 'bidDiscount',
        Cell: ({ row }) => (
          <div className="tableInput">
            <input value={parseFloat(row.original.bidDiscount || 0).toFixed(2)} className="invColumnBold" />
          </div>
        ),
      },
      {
        Header: 'Bid Value',
        accessor: 'bidAmount',
        id: 'bidAmount',
        Cell: ({ row }) => (
          <div className="tableInput">
            <input value={parseFloat(row.original.bidAmount || 0).toFixed(2)} className="invColumnBold" />
          </div>
        ),
      },
      {
        Header:
          'Final Disc(' +
          parseFloat(getExtraPer(this.state.userTerms)).toFixed(2) +
          (getBidType() === DIAMOND_BID.TYPE.BLIND ? '+0.5' : '') +
          '%)',

        accessor: 'fnBack',
        id: 'fnBack',
        Cell: ({ row }) => <>{parseFloat(row.original.calcDiscount).toFixed(2)}</>,
      },
      {
        Header: 'Final Rate',
        accessor: 'fnCtpr',
        id: 'fnCtpr',
        Cell: ({ row }) => <>{parseFloat(row.original.calcPricePerCarat).toFixed(2)}</>,
      },
      {
        Header: 'Final Value',
        accessor: 'fnAmt',
        id: 'fnAmt',
        Cell: ({ row }) => <>{parseFloat(row.original.calcAmount).toFixed(2)}</>,
      },
      ...Columns.slice(index + 1, Columns.length).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
    ].filter((el) => el && el);
    this.setState({ columns, data, checked: data });
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible) {
      if (isEmpty(this.props.checkedData) && !isEmpty(prevProps.checkedData)) {
        return this.props.onClose();
      }
      if (this.props.checkedData?.length !== prevProps.checkedData?.length) {
        this.setState({ checked: this.props.checkedData });
      }
    }
  }

  // submit = async () => {
  //   let request = {
  //     bidType: getBidType(),
  //     user: this.props.client?.user?.id,
  //     diamonds: this.state.checked.map((d) => ({
  //       diamond: d?.id,
  //       bidPricePerCarat: d?.bidPricePerCarat,
  //       bidAmount: d?.bidAmount,
  //       vnd: d?.vnd,
  //       vStnId: d?.vStnId,
  //     })),
  //   };
  //   this.setState({ loader: !this.state.loader });
  //   let [, res] = await createBidStone(request);
  //   if (res.code == 'OK') {
  //     this.setState({ loader: !this.state.loader });
  //     OpenNotification({ type: 'success', title: res.message });
  //     this.props.onClose();
  //   } else {
  //     this.setState({ loader: !this.state.loader });
  //     this.props.onClose();
  //   }
  // };

  render() {
    const summation = calculate(this.state.checked);
    const totalCarat = parseFloat(summation.total_carat).toFixed(2);
    const netValue = formatNumber(parseFloat(summation.final_value).toFixed(2));
    const avgDisc = parseFloat(summation.final_discount).toFixed(2);
    const finalAvgDisc = parseFloat(summation.final_term_discount).toFixed(2);
    const finalNetRate = formatNumber(parseFloat(summation.final_rate).toFixed(2));
    const finalNetValue = formatNumber(parseFloat(summation.final_net_value).toFixed(2));
    const VALUES = [
      { title: 'Total Carats:', value: totalCarat },
      { title: 'Final Net Rate:', value: finalNetRate },
      { title: 'Avg. Discount:', value: avgDisc },
      { title: 'Final Avg Discount:', value: finalAvgDisc },
      { title: 'Net Value:', value: netValue },
      { title: 'Final Net Value:', value: finalNetValue },
    ];

    return (
      <div className="bid-stone">
        <div className="d-flex align-items-center oneLineTotal">
          <MainHeading className="popupInnerTitle mt-0 mb-0 mr-40" title="Bid Stone" />
          {HeadingCalc(this.state.checked, 'bid')}
        </div>
        <div className="searchPopupCommonTable">
          {this.state.loader && <CircularProgress className="fixLoader" />}
          <div className="popupHalfShow">
            <ListingWrapper>
              <ListingTableWrapper subtractFrm=".mainHeadingTitle,.sidebarAction">
                <Table
                  {...this.state}
                  nodots
                  areAllChecked={true}
                  handleCheck={(e) => this.setState({ checked: e })}
                  currentType={currentType}
                  canSort={false}
                  FilterOption={false}
                  noGrp
                  liveStatus
                />
              </ListingTableWrapper>
            </ListingWrapper>
            <div className="singleStonePlaceOrderDetail">
              <div className="mt-10 finalCalDetail">
                <div className="DiamondDetailPopup">
                  {VALUES.map((x, index) => (
                    <div key={index} className="DiamondDetailPopupItem">
                      <span>{x.title} : </span>
                      <span>{x.value}</span>
                    </div>
                  ))}
                </div>
                <p className="offerNote mt-20">
                  <b>Note:</b> The additional {parseFloat(getExtraPer(this.state.userTerms)).toFixed(2)}
                  {getBidType() === DIAMOND_BID.TYPE.BLIND ? '+0.5' : ''}% on amount value has already been added in the
                  final calculation.
                </p>
              </div>
            </div>
          </div>
          <div className="sidebarAction">
            {/* <button className="commonButton" onClick={this.submit}>
              Bid Stone
            </button> */}
            <button className="commonButton" onClick={this.props.onClose}>
              Cancel Bid
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ diamondData }) => ({
  checkedData: diamondData.selectedRows[currentType] || [],
}))(BidPopup);
