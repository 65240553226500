import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { LocaleProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import URLSearchParams from 'url-search-params';
import Cookies from 'js-cookie';

import ForgotPassword from 'routes/UserAuth/ForgotPassword';
import ResetPassword from 'routes/UserAuth/ResetPassword';
import UpdateDefaultPasswordByUser from 'routes/UserAuth/UpdateDefaultPasswordByUser';
import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import OTPVerification from '../OTPVerification';

import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';
import { getUser, setInitUrl } from 'appRedux/actions/Auth';
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'appRedux/actions/Setting';
import axios from 'util/Api';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from 'constants/ThemeSetting';
import { isEmpty } from 'util/utils';

const RestrictedRoute = ({ component: Component, token, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      token ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    }
  />
);

class App extends Component {
  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  UNSAFE_componentWillMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);
    if (params.has('theme')) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has('nav-style')) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has('layout-type')) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + nextProps.token;
    }
    if (nextProps.token && !nextProps.authUser) {
      this.props.getUser();
    }
  }

  render() {
    const { match, location, themeType, layoutType, navStyle, locale, initURL, defaultPassword } = this.props;
    let token = Cookies.get('token');

    if (themeType === THEME_TYPE_DARK) document.body.classList.add('dark-theme');
    if (location.pathname === '/') {
      if (isEmpty(token) && defaultPassword && defaultPassword.userId) {
        return <Redirect to={PREFIX_URL_WITH_SLASH + '/update-default-password'} />;
      } else if (isEmpty(token)) {
        return <Redirect to={PREFIX_URL_WITH_SLASH + '/signin'} />;
      } else if (initURL === '' || initURL === '/' || initURL === PREFIX_URL_WITH_SLASH + '/signin') {
        return <Redirect to={PREFIX_URL_WITH_SLASH} />;
      } else {
        return <Redirect to={PREFIX_URL_WITH_SLASH} />;
      }
    }

    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <Switch>
            <Route exact path={PREFIX_URL_WITH_SLASH + '/signin'} component={SignIn} />
            <Route exact path={PREFIX_URL_WITH_SLASH + '/otp-verification'} component={OTPVerification} />
            <Route exact path={PREFIX_URL_WITH_SLASH + '/forgot-password'} component={ForgotPassword} />
            <Route
              exact
              path={PREFIX_URL_WITH_SLASH + '/update-default-password'}
              component={UpdateDefaultPasswordByUser}
            />
            <Route exact path={PREFIX_URL_WITH_SLASH + '/reset-password/:resetId'} component={ResetPassword} />
            <Route exact path="/signup" component={SignUp} />
            <RestrictedRoute path={`${match.url}`} token={token} component={MainApp} />
            {/* <Redirect from="*" to={'/mvdadmin'} /> */}
          </Switch>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, themeType, layoutType } = settings;
  const { authUser, token, initURL, defaultPassword } = auth;
  return { locale, token, defaultPassword, navStyle, themeType, layoutType, authUser, initURL };
};
export default connect(mapStateToProps, { setInitUrl, getUser, setThemeType, onNavStyleChange, onLayoutTypeChange })(
  App,
);
