import React from 'react';
import { Link } from 'react-router-dom';
import { PREFIX_URL_WITH_SLASH as PREFIX_URL } from 'constants/CommonUrl';

function Clientropdown() {
  const { pathname } = window.location;
  const path = React.useMemo(() => pathname, [pathname]);

  return (
    <div className="subTabInnerDropdown">
      <ul>
        {/* <li className={path.includes('client/interactions') ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/client/interactions/mail`}>Interactions</Link>
        </li> */}
        <li className={path.includes('client/analytics/') ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/client/analytics/purchase-behavior`}>Analytics</Link>
        </li>
        {/* <li className={path === '/client/engagment' ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/client/engagment`}>Engagment</Link>
        </li> */}
        {/* <li className={path === '/client/configuration' ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/client/configuration`}>Configuration</Link>
        </li> */}
        <li className={path === '/client/suggested-stock' ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/client/suggested-stock`}>Suggested Stock</Link>
        </li>
      </ul>
    </div>
  );
}
export default Clientropdown;
