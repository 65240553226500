import React from 'react';
import CommonModal from 'containers/CommonModal';
import CircularProgress from 'components/CircularProgress';
import { Formik, Form } from 'formik';
import OpenNotification from 'constants/OpenNotifications';
import TextArea from 'containers/TextArea';
import { getDiamondTrackChangeStatus } from 'services/DiamondApi';
import { EnquiryTab } from 'constants/Common';

const EnquiryClosePopup = (props) => {
  const [loader, setLoader] = React.useState(false);

  const submitForm = async (values) => {
    setLoader(true);
    let [, res] = await getDiamondTrackChangeStatus({
      status: EnquiryTab.CLOSE,
      diamondBlocks: props.checked.map((d) => d.blockId),
      ...values,
    });
    if (res.code === 'OK') {
      props.clearAllSelectedRows();
      props.fetch();
      OpenNotification({ type: 'success', title: res.message });
    }

    props.onCancel();
    setLoader(false);
  };

  const initialValues = {
    remarks: undefined,
  };

  return (
    <>
      <CommonModal modalSize="sm-size" visible={true} handleCancel={props.onCancel} title={'Enquiry Close'}>
        <Formik initialValues={initialValues} validateOnChange onSubmit={submitForm}>
          {({ setFieldValue, values }) => (
            <div className="caratRangeWrapper p-15">
              <Form>
                <div className="caratRangeCol">
                  <TextArea
                    label="Comment"
                    name="comment"
                    onChange={(e) => {
                      setFieldValue('comment', e.target.value);
                    }}
                    value={values.comment}
                    placeholder="Comment"
                  />
                </div>

                <div className="configActionButton">
                  <button className="commonButton" type="submit" value="Submit">
                    Save
                  </button>
                  <button type="reset" className="commonOutine" onClick={props.onCancel}>
                    Cancel
                  </button>
                  {loader && <CircularProgress className="fixLoader" />}
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </CommonModal>
    </>
  );
};

export default React.memo(EnquiryClosePopup);
