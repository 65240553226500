import React from 'react';

import { flatten } from 'lodash';

import { classNames, isArray, isNotEmpty } from 'util/utils';
import './textarea.less';

const TextArea = React.memo((props) => {
  const { label, required, error: _error, rows, fullWidth, children, value, defaultValue, ...restProps } = props;
  const error = isArray(_error) ? _error.filter(isNotEmpty)?.[0] : _error;
  const values = isArray(value) ? value.toString() : value;

  return (
    <div className={classNames(['from-group', fullWidth && 'full-width', error && 'error-message'])}>
      <label className="commonLabel">
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <textarea className="commonTextArea" rows={rows} {...restProps}>
        {children ?? values}
      </textarea>
      {Boolean(error) && <div className="error">{error}</div>}
    </div>
  );
});
TextArea.displayName = 'TextArea';

export default TextArea;
