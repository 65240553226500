import React from 'react';
import _sum from 'lodash/sum';
import _divide from 'lodash/divide';
import _count from 'lodash/countBy';
import { floatkeys } from '../Inventory/TableBack';
import { colors } from '@amcharts/amcharts4/core';

function DiamondTotalCard(props) {
  const { row, col } = props;
  const length = row.groupData.crt.length;
  const rap = _divide(_sum(row.groupData.rap), Number(row.groupData.rap.length));
  const rapAvg = _sum(row.groupData.rap_avg);
  const ctpr = _divide(_sum(row?.groupData.amt || 0), _sum(row?.groupData.crt || 0));
  const back = (1 - _divide(rap, ctpr)) * 100;
  const bidPricePerCarat = _divide(_sum(row?.groupData.bidAmount || 0), _sum(row?.groupData.crt || 0));
  const bidDiscount = rapAvg
    ? (1 - bidPricePerCarat / _divide(rapAvg, _sum(row?.groupData.crt || 0))) * -100
    : (1 - _divide(rap, bidPricePerCarat)) * 100;
  const prefix = React.useMemo(() => (col !== 'crt' ? '$' : ''), [col]);

  const result = React.useMemo(() => {
    const sum = parseFloat(
      // col !== 'ctpr'
      !['ctPr', 'rap', 'back', 'bidDiscount', 'bidPricePerCarat'].includes(col)
        ? _sum(row?.groupData[col] || 0)
        : col === 'ctPr'
        ? ctpr
        : col === 'rap'
        ? rap
        : col === 'back'
        ? back
        : col === 'bidPricePerCarat'
        ? bidPricePerCarat
        : bidDiscount,
    ).toFixed(floatkeys.includes(col) ? (col === 'crt ' ? 3 : 2) : 0);

    return `${prefix}${isNaN(sum) ? 0 : sum}`;
  }, [row, col, prefix]);

  return <span className="selectAmount">{result}</span>;
}
export default DiamondTotalCard;
