import ConstantApi from 'constants/CommonApi';
import UtilService from 'services/util';
import { isEmpty } from 'util/utils';

export const getEventList = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Event.paginate, request, ...(!isEmpty(config) && { config }) });

export const storeEvent = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Event.create, request, ...(!isEmpty(config) && { config }) });

export const findEvent = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.Event.find, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const updateEvent = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.Event.update, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });
