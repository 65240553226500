import SelectOption from 'containers/SelectOption';
import { compact } from 'lodash';
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'util/utils';
import { fetchPartyRelatedInfo, getClientData, getUserClientData, isValue, nameFormat } from '../CommonPopupFunction';

function PartyBuyerComponent(props) {
  const [state, _setState] = useState({
    user: null,
    userAccount: null,
    loading: false,
    userTerms: null,
    accountList: [],
    userList: [],
  });

  const setState = React.useCallback(
    (object = {}, cb = null) => {
      _setState((state) => ({ ...state, ...object }));
    },
    [_setState],
  );

  useEffect(() => {
    setState({
      ...props.initialData,
      ...(!isEmpty(props.initialData?.client) ? { accountList: props.initialData?.client } : {}),
      ...((!isEmpty(props.initialData?.client) && props.initialData?.client?.findIndex((d) => d?.user) >= 0) ||
      props.initialData?.client?.findIndex((d) => d?.name) >= 0
        ? { userList: props.initialData?.client }
        : {}),
    });
  }, [props.initialData.client]);

  return (
    <>
      <SelectOption
        className={props.disable ? 'width-100' : ''}
        placeholder="Min. 3 Chars"
        value={isValue(state.userAccount)}
        selectValue={compact(
          state.accountList.map(
            (d) =>
              (d?.companyName || d?.account?.companyName) && {
                value: d?.companyName || d?.account?.companyName,
                key: d?.account?.id || d?.id,
              },
          ),
        )}
        disable={props.disable}
        label="Party *"
        onChange={(id = null) => {
          // props.errorHandel({ fields: ['userAccount', 'user'] });
          props.setUserDetails({ userAccount: id });
          let userAccountList = state.accountList.find((d) => d.id == id || d?.account?.id == id);

          if (id == null) {
            setState({
              userAccount: null,
              accountList: [],
              user: null,
              userList: [],
            });
            props.setUserDetails({
              user: null,
              userAccount: null,
              userTerms: null,
              seller: null,
              shippingCity: null,
              shippingCountry: null,
              shippingCityList: [],
              brokerList: [],
              broker: null,
              assistantSeller: null,
              countryName: null,
            });
          }

          if (id) {
            getUserClientData(
              ({ userList }) => {
                setState({
                  userAccount: id,
                  userList,
                  user: userList?.length && userList?.[0]?.id,
                  userTerms: userAccountList?.user?.accountTerm
                    ? userAccountList?.user?.accountTerm
                    : userAccountList?.accountTerm ?? null,
                });

                props.setUserDetails({
                  userTerms: userAccountList?.user?.accountTerm
                    ? userAccountList?.user?.accountTerm
                    : userAccountList?.accountTerm ?? null,
                  user: userList?.length && userList?.[0]?.id,
                  ...fetchPartyRelatedInfo(state.accountList, id),
                });
              },
              null,
              id,
            );
          }
        }}
        onSearch={(keyword) => {
          getClientData(
            (data) => {
              setState({ ...data });
            },
            keyword,
            state.user,
          );
        }}
        notFoundContent={state.loading ? 'loading...' : 'Enter Party Name.'}
        error={props.error?.userAccount}
      />
      <SelectOption
        className={props.disable ? 'width-100' : ''}
        placeholder="Min. 3 Chars"
        value={isValue(state.user)}
        selectValue={
          state.userList?.length > 0
            ? state.userList.map((d) => ({
                value: nameFormat(d?.user?.firstName || d?.firstName, d?.user?.lastName || d?.lastName),
                key: d?.user?.id || d?.id,
              }))
            : null
        }
        label="Buyer Name *"
        onChange={(id = null) => {
          // props.errorHandel({ fields: ['userAccount', 'user'] });
          props.setUserDetails({
            user: id,
          });

          let userAccountList = compact(state.userList.map((d) => (d?.id == id ? d : null)));
          if (id == null) {
            setState({
              userAccount: null,
              accountList: [],
              user: null,
              userList: [],
            });
            props.setUserDetails({
              user: null,
              userTerms: null,
              seller: null,
              shippingCity: null,
              shippingCountry: null,
              shippingCityList: [],
              brokerList: [],
              broker: null,
              assistantSeller: null,
              countryName: null,
              registrationType: null,
            });
          } else {
            setState({
              userAccount: id ? userAccountList?.[0]?.account?.id : null,
              user: id,
              userTerms: id
                ? userAccountList[0]?.accountTerm
                : !isEmpty(userAccountList[0]?.user?.accountTerm)
                ? userAccountList[0]?.user?.accountTerm
                : null,
              userList: id ? state.userList : [],
              accountList: id ? userAccountList : [],
              ...(id ? {} : { client: null }),
            });
            props.setUserDetails({
              user: id,
              userTerms: id
                ? userAccountList[0]?.accountTerm
                : !isEmpty(userAccountList[0]?.user?.accountTerm)
                ? userAccountList[0]?.user?.accountTerm
                : null,
              userAccount: id ? userAccountList?.[0]?.account?.id : null,
              ...fetchPartyRelatedInfo(userAccountList, id),
            });
          }
        }}
        onSearch={(keyword) => {
          getUserClientData(
            (data) => {
              setState({ ...data });
            },
            keyword,
            state.userAccount,
          );
        }}
        notFoundContent={state.loading ? 'loading...' : 'Enter a Buyer Name.'}
        error={props.error?.user}
        disable={props.disable}
      />
    </>
  );
}

export default React.memo(PartyBuyerComponent);
