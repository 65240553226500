import React from 'react';
import { Dropdown, Tooltip } from 'antd';
import SortingFilterDropdown from './OverlayDropdownBack';
import { FILTER_COLUMNS } from '../Inventory/TableBack';
import { diamondParameters } from 'constants/Common';

import sortingSvg from 'assets/svg/sorting.svg';
import shortinglistupWhiteSvg from 'assets/svg/InventoryResult/shortinglistup-white.svg';
import shortinglistdownWhiteSvg from 'assets/svg/InventoryResult/shortinglistdown-white.svg';
import closeSvg from 'assets/svg/close.svg';
import shortinglistupSvg from 'assets/svg/InventoryResult/shortinglistup.svg';
import shortinglistdownSvg from 'assets/svg/InventoryResult/shortinglistdown.svg';
import { isString } from 'util/utils';

function ListTableHeaderBack({ column, FilterOption, nodots, sortBy = [], setSortBy, toggleSortBy }) {
  const asc = React.useMemo(() => column?.isSortedDesc === false, [column?.isSortedDesc]);
  const sortedColumnIndex = React.useMemo(() => column?.sortedIndex, [column?.sortedIndex]);

  const tooltipTitle = React.useMemo(
    () =>
      column?.desc || diamondParameters?.[column?.id]?.desc || diamondParameters?.[column?.id]?.name || column?.Header,
    [column],
  );

  const overlay = React.useMemo(
    () =>
      column?.isSorted || (FilterOption !== false && FILTER_COLUMNS.includes(column?.id)) ? (
        <SortingFilterDropdown
          setSortBy={setSortBy}
          sortBy={sortBy}
          toggleSortBy={toggleSortBy}
          FilterOption={FilterOption}
          column={column}
        />
      ) : (
        <></>
      ),
    [FilterOption, column, setSortBy, sortBy, toggleSortBy],
  );

  const ignoreClick = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const clearSortBy = React.useCallback(
    (e) => {
      ignoreClick(e);
      column.clearSortBy();
    },
    [ignoreClick, column],
  );

  return (
    <Dropdown
      getPopupContainer={(trigger) => trigger}
      overlayClassName="tabelDropdownClass"
      key={`filterOverlayDropdown${column.id}`}
      overlay={overlay}
      trigger={['click']}
    >
      <div
        // title={title}
        className={`tableHeaderMain ${nodots && 'squeceChange'}
        ${column.sort && !column.isSorted && 'shortingBoxShow'} ${column?.isSorted && 'shortingApply'}`}
        onClick={ignoreClick}
      >
        {!nodots && (
          <span className="listIcon">
            <img src={sortingSvg} alt="icon" />
          </span>
        )}
        {tooltipTitle ? (
          <Tooltip key={`headerDesc${tooltipTitle}`} title={tooltipTitle} placement="bottom">
            <span className="taleHeadTitle">{column.render('Header')}</span>
          </Tooltip>
        ) : (
          <span className="taleHeadTitle">{column.render('Header')}</span>
        )}
        {column?.isSorted && (
          <div className="selectShortingMainBlock">
            <div className="selectShorting" {...column.getSortByToggleProps()}>
              <span className="selectShortingCount" style={!column.isSorted ? { margin: 0 } : {}}>
                {sortedColumnIndex + 1}
              </span>
              {column.isSorted && (
                <span className="selectShortingArrow">
                  <img src={asc ? shortinglistupWhiteSvg : shortinglistdownWhiteSvg} />
                </span>
              )}
            </div>
            <div className="shortingClose" onClick={clearSortBy}>
              <img src={closeSvg} alt="icon" />
            </div>
          </div>
        )}
        {column.sort && !column.isSorted && (
          <div className="shortingRound" {...column.getSortByToggleProps()}>
            <div className="shortingRoundInner">
              <img src={shortinglistupSvg} alt="icon" />
              <img src={shortinglistdownSvg} alt="icon" />
            </div>
          </div>
        )}
      </div>
    </Dropdown>
  );
}

export default React.memo(ListTableHeaderBack);
