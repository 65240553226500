import React, { Component } from 'react';
import InputBlock from 'containers/InputBlock';
import { Checkbox, Radio } from 'antd';
import { isEmpty } from 'util/utils';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { showNotification } from 'services/NotificationService';
import * as VersionAPI from './VersionApi';

class SliderAdd extends Component {
  state = {
    saveContinue: false,
  };

  initialValues = {
    name: '',
    number: undefined,
    platform: '',
    isHardUpdate: false,
    forceDelete: false
  };

  getInitialValues = () => {
    const { update } = this.props;

    return Object.fromEntries(
      Object.entries(this.initialValues).map(([key, value]) => [key, isEmpty(update?.[key]) ? value : update?.[key]]),
    );
  };

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const { update } = this.props;
    const { saveContinue } = this.state;

    setSubmitting(true);
    const [err, res] = await (!isEmpty(update?.id)
      ? VersionAPI.updateVersion({ ...update, ...values })
      : VersionAPI.storeVersion(values));
    setSubmitting(false);

    if (!err) showNotification(res);

    if (!err && isEmpty(update?.id)) resetForm();
    if (!err && !saveContinue) this.props.closeForm();
  };

  render() {
    let { update } = this.props;
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validateOnChange
        validationSchema={Yup.object().shape({
          name: Yup.string().trim().required('Version Name is required').typeError('Version Name must be a string'),
          number: Yup.number().required('Version Number is required').typeError('Version Number must be a number'),
          platform: Yup.string().trim().required('Platform selection is required'),
        })}
        onSubmit={this.submitForm}
      >
        {({ handleChange, handleBlur, touched, errors, values }) => (
          <Form>
            <div className="d-flex flex-wrap homeSettingWrapper mt-20">
              <InputBlock
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                name="name"
                label="Version Name *"
                placeholder="Version Name"
                error={touched?.name && errors?.name}
              />

              <InputBlock
                onKeyPress={(evt) => {
                  if ((evt.which < 48 || evt.which > 57) && evt.which != 46) {
                    evt.preventDefault();
                  }
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.number}
                name="number"
                label="Version Number *"
                placeholder="Version Number"
                error={touched?.number && errors?.number}
              />

              <div className="width-50">
                <label className="commonLabel mb-10">Platform *</label>
                <Radio.Group onChange={handleChange} name="platform" value={values?.platform}>
                  <Radio value={1}>Android</Radio>
                  <Radio value={2}>Iphone</Radio>
                </Radio.Group>
              </div>

              <div className="from-group mt-20">
                <Checkbox
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.isHardUpdate}
                  name="isHardUpdate"
                  error={touched?.isHardUpdate && errors?.isHardUpdate}
                >
                  Hard Update
                </Checkbox>
                <Checkbox
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.forceDelete}
                  name="forceDelete"
                  error={touched?.forceDelete && errors?.forceDelete}
                >
                  Force Logout
                </Checkbox>
              </div>
              <div className="width-100 d-flex mt-40">
                <button className="commonButton mr-10" type="submit" value="Submit">
                  {isEmpty(update?.id) ? 'Save' : 'Update'}
                </button>
                <button className="commonOutine" type="reset" onClick={this.props.closeForm}>
                  Back
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default SliderAdd;
