import React, { Component } from 'react';
import PopupAdd from './add';

class ShowDetails extends Component {

    render() {
        return (
            <div>
                <PopupAdd />
            </div>
        );
    }
}

export default ShowDetails;
