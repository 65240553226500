import React, {Component} from "react"
import CustomTab from "../../containers/Tab"
import MainHeading from "../../containers/MainHeading";
class ClientDrawer extends Component {
    render(){
        return(
          <>
            <MainHeading title="Add Client"/>
            {/* <CustomTab data={TabData.diamondDetailtab} tabHeaderClass="customerMaintab diamondDetailTopTab">

            </CustomTab> */}
          </>
        )
    }
}

export default ClientDrawer; 