import SelectOption from 'containers/SelectOption';
import React, { useEffect, useState } from 'react';
import { getFullClientData, isValue } from '../CommonPopupFunction';

function ClientComponent(props) {
  const [state, _setState] = useState({ clientList: [], client: null, loading: false });
  const setState = (object = {}) => {
    _setState((state) => ({ ...state, ...object }));
    props.setClientData(object);
  };

  useEffect(() => {
    setState(props.initialData);
  }, [props.initialData.client, props.initialData.clientList]);

  return (
    <SelectOption
      placeholder="Min. 3 Chars"
      value={isValue(state.client)}
      selectValue={state.clientList}
      label="Client Name *"
      onChange={(client = null) => {
        // props.removeError('client');
        setState({ client, clientList: client == null ? [] : state.clientList });
      }}
      onSearch={(keyword) => {
        getFullClientData((data) => {
          setState(data);
        }, keyword);
      }}
      notFoundContent={state.loading ? 'loading...' : 'Enter a Client Name.'}
      error={props.error?.client}
    />
  );
}

export default ClientComponent;
