import { i18n } from 'util/i18n';

export const Messages = {
  CommentRequire: 'Please Enter Note.',
  FilterSelect: 'Please Select Filter.',

  //MemoPopupModal
  userAccountSelect: 'Please Enter Party.',
  userSelect: 'Please Enter buyer.',
  sellerSelect: 'Please Select Salesman.',
  shippingCountrySelect: 'Please Select Shipping Country.',
  shippingCitySelect: 'Please Select Shipping City.',
  stageSelect: 'Please Select Stage.',
  assistantSellerSelect: 'Please Select Assistant Salesman.',
  shipModeSelect: 'Please Select ShipMode.',
  brokerSelect: 'Please Enter Broker.',

  // HoldPopUp
  clientNameSelect: 'Please Select Client Name.',
  typeSelect: 'Please Select Type.',
  timeSelect: 'Please Enter time between 60 to max.',
  highEndClient: 'Please Select HighEnd Client.',

  //confirm stone
  billTypeSelect: 'Please Select Bill Type.',
  sourceOfSaleSelect: 'Please Select Source Of Sale.',
  courierNameSelect: 'Please Select Courier Name.',
  invoiceDateSelect: 'Please Select Invoice Date',

  //offer message
  sameClientSelect: 'Please Select Similar Customer Diamond.',
  //quoteDay
  quoteDaySelect: 'Please Select ' + i18n.t('title.offer') + ' Title.',
  //showStone
  referenceBySelect: 'Please Select Reference By.',
  showNameSelect: 'Please Select Show Name.',
  reconfirmRequire: 'Please Enter Reconfirm with.',

  Todo: {
    Title: 'Please enter title.',
    successMessage: 'To do is added successfully.',
  },

  Error: {
    Required: {
      Company: 'Please Select Company Name.',
      Stones: 'Please Select Diamonds.',
      Bid: 'Please Select Bids.',
      MoreThanOneStone: 'Please select minimum two diamonds to compare.',
      MemoAndHoldStone: "You can't Confirm Hold/Memo Stones.",
    },
    NotFound: {
      Stones: 'No Stones Found',
    },
  },

  Target: {
    targetName: 'Please Enter Target Name.',
    totalTarget: 'Please Enter Total Target.',
    distributedTarget: 'Please Enter Distributed Target.',
  },
};

export default Messages;
