import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { useTable } from 'react-table';
import PlusIcon from 'containers/PlusIcon';
import StatusBlock from 'containers/StatusBlock';
import { LIMIT } from 'components/Client/Table';
import * as EventAPI from 'services/EventApi';
import { EVENT, BASE_URL, POPUP_TYPES } from 'constants/Common';
import { DateTime } from 'luxon';
import { showNotification } from 'services/NotificationService';
import ListFooter from 'components/List/ListFooter';
import Pagination from 'components/List/Pagination';
import { Popconfirm } from 'antd';
import { NoDataFound } from 'components/Inventory/Table';
import { getModuleFromUrl } from 'constants/permission';
import Image from 'containers/Image';
import { capitalize } from 'util/utils';

class SliderList extends Component {
  state = {
    page: 1,
    limit: LIMIT,
    data: [],
    count: 0,
  };

  componentDidMount() {
    this.getEvents();
  }

  getEvents = async () => {
    this.setState({ loading: true });
    let { page, limit } = this.state;
    const [, res] = await EventAPI.getEventList({ page, limit, isDeleted: false, type: EVENT.TYPE.POPUP });

    const list = res?.data?.list;

    this.setState(
      res.code === 'OK' && list?.constructor?.name === 'Array'
        ? { data: list, count: res.data.count, loading: false }
        : { data: [], count: 0, loading: false },
    );
  };

  updateEventDetail = async ({ id, ...request }) => {
    const [, res] = await EventAPI.updateEvent(id, { ...request });
    showNotification(res);

    if (res?.code === 'OK') this.getEvents();
  };

  render() {
    const columns = [
      { Header: 'SR Number', width: '50px', accessor: 'srno', Cell: ({ row }) => ++row.id },
      {
        Header: 'Image',
        accessor: 'imagename',
        Cell: ({ row }) => (
          <div className="imageBlockWrapper">
            <Image src={BASE_URL + row.original.image} />
          </div>
        ),
      },
      { Header: 'Title', accessor: 'name' },
      {
        Header: 'Type',
        accessor: 'showTypes',
        Cell: ({ row }) => {
          return (
            Object.entries(POPUP_TYPES)
              ?.filter((item) => row.original?.showTypes?.includes(`${item[1]}`))
              ?.map((item) => capitalize(item[0]))
              ?.join(', ') ?? '-'
          );
        },
      },
      {
        Header: 'From Date',
        accessor: 'fromdateTime',
        Cell: ({ row }) => (
          <div>
            <span>{DateTime.fromISO(row.original.fromdateTime).toLocaleString(DateTime.DATETIME_SHORT)}</span>
          </div>
        ),
      },
      {
        Header: 'To Date',
        accessor: 'toDateTime',
        Cell: ({ row }) => (
          <div>
            <span>{DateTime.fromISO(row.original.toDateTime).toLocaleString(DateTime.DATETIME_SHORT)}</span>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <div
            onClick={() =>
              this.updateEventDetail({
                id: row.original.id,
                isActive: !row.original.isActive,
                name: row.original.name,
              })
            }
          >
            <StatusBlock
              status={row.original.isActive ? 'active' : 'InActive'}
              title={row.original.isActive ? 'Active' : 'InActive'}
            />
          </div>
        ),
      },
      { Header: 'Description', accessor: 'shortDescription' },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <div className="actionButton j-flex-start">
            {getModuleFromUrl().update && (
              <div className="actionAction" onClick={() => this.props.editEventDetail(row.original.id)}>
                <img src={require('assets/svg/edit.svg')} />
              </div>
            )}
            {getModuleFromUrl().delete && (
              <Popconfirm
                placement="left"
                title={`Are you sure you want to delete this Popup?`}
                okText={`Delete`}
                onConfirm={() =>
                  this.updateEventDetail({
                    id: row.original.id,
                    isDeleted: !row.original.isDeleted,
                    name: row.original.name,
                  })
                }
              >
                <div className="actionAction">
                  <img src={require('assets/svg/delet.svg')} />
                </div>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ];
    const Table = ({ columns, data }) => {
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
      });
      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, key) => (
              <tr key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, key) => (
                  <th key={key} {...column.getHeaderProps()} width="100px">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, key) => (
                    <td key={key} width="100px" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    };
    return (
      <>
        {getModuleFromUrl().insert && <PlusIcon onClick={this.props.onClick} />}
        <div className="contactDirectoryWrapper">
          <div className="contactListMain">
            <div className="contactList">
              <Table columns={columns} data={this.state.data} />
              <NoDataFound length={this.state.data?.length} />
            </div>
          </div>
        </div>
        <ListFooter className="homeSettingfooter">
          <span style={{ margin: 'auto' }}>
            <Pagination
              page={this.state?.page}
              limit={this.state?.limit}
              count={this.state?.count}
              length={this.state?.data.length}
              onPaginationChange={(page, limit) => this.setState({ page, limit }, () => this.getEvents())}
            />
          </span>
        </ListFooter>
      </>
    );
  }
}

export default withRouter(SliderList);
