import React from 'react';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';
import { history } from 'util/history';

import logoImage from 'assets/images/sidebar_logo.png';

function SidebarLogo() {
  const goToMainPage = React.useCallback(() => {
    history.push(PREFIX_URL_WITH_SLASH);
  }, []);

  return (
    <div className="gx-layout-sider-header pointer" onClick={goToMainPage}>
      <div className="gx-linebar">
        <img width="50px" src={logoImage} alt="Unique Diamax Pvt Ltd" />
      </div>
    </div>
  );
}

export default SidebarLogo;
