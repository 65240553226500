import React from 'react';
import { Checkbox } from 'antd';

export const nonames = [
  'all',
  'view',
  'insert',
  'update',
  'delete',
  'uploadExcel',
  'downloadExcel',
  'mailExcel',
  'printPDF',
];

const Action = (props) => {
  let permission = props.module || {};
  let handleChange = (e) => {
    if (props.handleChange) props.handleChange(e);
  };
  let keys = [
    { name: 'All', permitKey: 'all' },
    { name: 'View', permitKey: 'view' },
    { name: 'Add', permitKey: 'insert' },
    { name: 'Update', permitKey: 'update' },
    { name: 'Delete', permitKey: 'delete' },
    { name: 'Upload', permitKey: 'uploadExcel' },
    { name: 'Download', permitKey: 'downloadExcel' },
    { name: 'Mail', permitKey: 'mailExcel' },
    { name: 'Print', permitKey: 'printPDF' },
  ];
  if (permission.hasOwnProperty('isShowClientColumn'))
    keys.push({ name: 'Show Client Name', permitKey: 'isShowClientColumn' });
  if (permission.hasOwnProperty('isShowBack')) keys.push({ name: 'Show Back', permitKey: 'isShowBack' });

  if (props.moduleName === 'HIDE_GRID_COLUMNS') {
    keys = [];
    Object.keys(permission).map((item) => {
      keys.push({ name: item, permitKey: item });
    });
  }

  return (
    <div className="d-flex align-items-center j-space-between permissionAction">
      {keys.map((k) => {
        return (
          <div
            className="d-flex align-items-center j-space-between permissionActionList"
            style={nonames.includes(k.permitKey) ? {} : { width: '90px' }}
          >
            <Checkbox
              checked={permission.all || permission[k.permitKey]}
              onChange={(e) => handleChange({ checked: e.target.checked, permitKey: k.permitKey })}
            />
            <h2>{nonames.includes(k.permitKey) ? '' : k.name}</h2>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Action);
