import React, { Component } from 'react';
import './search.less';

class CommonSearch extends Component {
  state = {
    clientDetail: true,
    SearchValue: '',
  };

  handlechange = (e) => {
    this.setState(
      {
        SearchValue: e,
      },
      () => this.props.handlechange(e),
    );
  };

  handleCancel = () => {
    this.setState(
      {
        SearchValue: '',
      },
      () => this.props.handlechange(),
    );
  };

  render() {
    return (
      <div className={`commonSearch ${this.props.size ? this.props.size : ''}`}>
        {this.props.value ? (
          <input placeholder={this.props.placeholder} onChange={(e) => this.handlechange(e.target.value)} />
        ) : (
          <input
            placeholder={this.props.placeholder}
            value={this.props.value ? this.props.value : this.state.SearchValue}
            onChange={(e) => this.handlechange(e.target.value)}
          />
        )}
        {this.state.SearchValue === '' || !this.props.value === '' ? (
          <img src={require('../../assets/svg/search.svg')} />
        ) : (
          <img src={require('../../assets/svg/close-color.svg')} onClick={this.handleCancel} className="close-icon" />
        )}
        {/* <img src={require("../../assets/svg/search.svg")} alt=""/> */}
      </div>
    );
  }
}

export default CommonSearch;
