import React from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import SidebarContent from './SidebarContent';

import * as ThemeSetting from 'constants/ThemeSetting';
import classNames from 'util/classNames';

import 'styles/sidebar.less';
import { isEmpty } from 'util/utils';

export function Sidebar() {
  const themeType = useSelector(({ settings }) => settings?.themeType);
  const navStyle = useSelector(({ settings }) => settings?.navStyle);
  const location = useLocation();

  const drawerStyle = React.useMemo(() => {
    switch (navStyle) {
      case ThemeSetting.NAV_STYLE_FIXED:
        return '';

      case ThemeSetting.NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return 'gx-mini-sidebar gx-mini-custom-sidebar';

      case ThemeSetting.NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return 'gx-custom-sidebar';

      case ThemeSetting.NAV_STYLE_MINI_SIDEBAR:
        return 'gx-mini-sidebar';

      case ThemeSetting.NAV_STYLE_DRAWER:
        return 'gx-collapsed-sidebar';

      default:
        return 'gx-collapsed-sidebar';
    }
  }, [navStyle]);

  const showSidebar = React.useMemo(() => {
    const list = ['/dna', '/viewImage'];
    return isEmpty(list.filter((pathname) => location.pathname.includes(pathname)));
  }, [location]);

  return (
    showSidebar && (
      <Layout.Sider
        className={classNames([
          'gx-app-sidebar hkSidebarMenu customTheme',
          drawerStyle,
          themeType !== ThemeSetting.THEME_TYPE_SEMI_DARK && 'gx-layout-sider-dark',
        ])}
        theme={themeType === ThemeSetting.THEME_TYPE_LITE ? 'lite' : 'dark'}
      >
        <SidebarContent />
      </Layout.Sider>
    )
  );
}

export default Sidebar;
