import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import backSvg from 'assets/svg/Offer/back.svg';
import './backbutton.less';

function CommonBackButtonWrapper({ link, children, ...rest }) {
  return link ? (
    <Link to={link} className="backOption" {...rest}>
      {children}
    </Link>
  ) : (
    <a className="backOption" {...rest}>
      {children}
    </a>
  );
}

function CommonBackButton({ link, title, ...rest }) {
  return (
    <CommonBackButtonWrapper {...{ link, title, ...rest }}>
      <img src={backSvg} alt="back" />
      <span>{title || 'Back'}</span>
    </CommonBackButtonWrapper>
  );
}

CommonBackButton.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default React.memo(CommonBackButton);
