import React from 'react';
import PropTypes from 'prop-types';

function CellInputHeader(props) {
  const { label, inputOnly } = props;
  const { onChange, args } = props;
  const inputRef = React.useRef();

  const handleBlur = React.useCallback(() => {
    onChange(inputRef.current.value, args);
    inputRef.current.value = '';
  }, [args, onChange]);

  const input = React.useMemo(() => {
    return (
      <div className="tableInput tableTopOfferInput">
        <input ref={inputRef} onBlur={handleBlur} />
      </div>
    );
  }, [handleBlur]);

  if (inputOnly) return input;

  return (
    <span style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{label}</span>
      <td className="groupingTableAmount">{input}</td>
    </span>
  );
}

CellInputHeader.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default React.memo(CellInputHeader);
