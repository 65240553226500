import React, {Component} from "react"
import {Link, withRouter} from "react-router-dom"
import { useTable , useFlexLayout} from "react-table";
import { Tooltip } from "antd";

const data = [
    { 
        srno: "1", 
        name:"Admin",
        assigneduser:"2",
    },
    { 
        srno: "2", 
        name:"User",
        assigneduser:"1",
    },
    { 
        srno: "3", 
        name:"Client",
        assigneduser:"2",
    },
    { 
        srno: "4", 
        name:"Admin",
        assigneduser:"2",
    },
    { 
        srno: "5", 
        name:"Admin",
        assigneduser:"2",
    },
  ];
class PermissionList extends Component {
  constructor(props) {
    super(props)
        this.state = {
            tab:false,
            form:true,
            smallLeft:true,
        };
    }
    toggle = () => {
        this.setState({
        tab:true
        })
    }
    toggle1 = () => {
        this.setState({
            form:false
        })
    }
    closeForm = () => {
        this.setState({
            form:true
        })
    }
    toggle = () => {
        this.setState({
            smallLeft:!this.state.smallLeft,
        });
    }
   render(){
    const { location } = this.props;
    const userListPath = location.pathname === "/admaadmin/user/user-list/list";
    const userFormPath = location.pathname === "/admaadmin/user/user-list/form";
    const columns = [
        {
          Header: "SR Number",
          width:"50px",
          accessor: "srno",
        },
        {
          Header: "Role Name",
          accessor: "name",
          shortingSelect:true,
          shortingCount:"1"
        },
        {
          Header: "Status",
          accessor: "active",
          Cell:({row,cell})=>(
            <div className="activeStatus">
                <span>Active</span>
            </div>
        )
        },
        {
            Header: "Assigned Users",
            accessor: "assigneduser",
            Cell:({row,cell})=>(
               <span onClick={this.toggle1.bind(this)}>{row.original.assigneduser}</span>
            )
        },
        {
            Header: "Action", 
            Cell:({row,cell})=>(
              <div className="actionButton j-flex-start">
                  <div className="actionAction">
                      <img src={require("../../assets/svg/edit.svg")} />
                  </div>
                  <div className="actionAction">
                      <img src={require("../../assets/svg/delet.svg")} />
                  </div>
              </div>
          )
        },
      ];
      const Table = ({ columns, data }) => {
        const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow,
        } = useTable(
            {
                columns,
                data
            },
        )
        return (
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()} width="100px">
                          {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                      <>
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <td width="100px" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                          </td>;
                      })}
                    </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          );
        };
        return(
        //  <div className="configurationInnerSubTb">
        <>
            <>
                <div className="addFormIcon" onClick={this.props.onClick}>
                    <img src={require("../../assets/svg/plus.svg")} alt=""/>
                </div>
                <div className="contactDirectoryWrapper">
                    <div className="contactListMain">
                        <div className="contactList">
                        <Table columns={columns} data={data} />
                        </div>
                    </div>
                </div>
                </>
            </>
        //  </div>
        )
    }
}

export default withRouter(PermissionList); 