import React from 'react';
import { Dropdown } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import InputBlock from 'containers/InputBlock';
import ColorPicker from 'components/ColorPicker/ColorPicker';
import Image from 'containers/Image';
import UploadImage from './UploadImage';

import Storage from 'services/storage';
import { BASE_URL } from 'constants/Common';
import { getModuleFromUrl } from 'constants/permission';
import { showNotification } from 'services/NotificationService';
import { updateUserDetail } from 'services/UserApi';
import { useCompositeState, useBoolean } from 'util/hooks';
import { isEmpty } from 'util/utils';
import classNames from 'util/classNames';

import noImageSvgWhite from 'assets/svg/noimage-white.svg';

const EditProfile = () => {
  const [profilePic, setProfilePic] = React.useState(null);
  const [user, setUser] = useCompositeState({ firstName: '', lastName: '' });
  const [upload, setUpload] = useBoolean();

  React.useEffect(() => {
    const user = Storage.get('user');
    setUser(user);
    setProfilePic(user?.photoId);
  }, [setUser]);

  const setColor = React.useCallback(
    async (bgColor) => {
      if (!isEmpty(bgColor)) bgColor = `${bgColor}`;
      const oldColor = user?.bgColor;
      setUser({ bgColor });
      const [, res] = await updateUserDetail(user?.id, { bgColor });

      if (!isEmpty(res?.data?.[0])) {
        const user = res?.data?.[0];
        Storage.set('user', user);
        setUser(user);
      } else {
        setUser({ bgColor: oldColor });
      }
    },
    [setUser, user],
  );

  const handleImageUpload = React.useCallback(async () => {
    const [, res] = await updateUserDetail(user?.id, { photoId: profilePic });
    if (!isEmpty(res?.data?.[0])) {
      const user = res?.data?.[0];
      setUser(user);
      Storage.set('user', user);
    }
    setUpload.false();
  }, [profilePic, setUpload, setUser, user?.id]);

  const handleSubmit = React.useCallback(
    async (values, { setSubmitting }) => {
      let { firstName, lastName } = values;
      setSubmitting(true);
      const [err, res] = await updateUserDetail(user?.id, { firstName, lastName });
      setSubmitting(false);

      if (!isEmpty(res?.data?.[0])) {
        const user = res?.data?.[0];
        Storage.set('user', user);
        setUser(user);
      }

      !err && showNotification(res);
    },
    [setUser, user?.id],
  );

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    isInitialValid: false,
    initialValues: Object.fromEntries(Object.entries(user).map(([name, value]) => [name, value])),
    validationSchema: Yup.object().shape({
      firstName: Yup.string().trim().required('First Name is required'),
      lastName: Yup.string().trim().required('Last Name is required'),
    }),
    onSubmit: handleSubmit,
  });

  const submitDisabled = React.useMemo(
    () =>
      !isEmpty(getModuleFromUrl().update) && !getModuleFromUrl().update
        ? !getModuleFromUrl().update
        : !form.dirty || !form.isValid,
    [form.dirty, form.isValid],
  );

  const submitRef = React.createRef();

  return (
    <form onSubmit={form.handleSubmit} onReset={form.handleReset} className="editProfilePage">
      <div className="headeTitle">
        <h2>My Settings</h2>
      </div>
      <div className="editProfilePageInner">
        <div className="profileLeft">
          <Dropdown
            overlayClassName="colorDropdownWrapper"
            trigger={['click']}
            overlay={
              <div className="profilecolorDropdown">
                <ColorPicker selected={user?.bgColor} onChange={setColor} />
                <button className="commonButton" onClick={setUpload.true}>
                  Add Custom Avatar
                </button>
              </div>
            }
          >
            <span className={classNames(['userProfileRound', !isEmpty(user?.bgColor) && `color${user?.bgColor}`])}>
              {isEmpty(profilePic) ? (
                user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase()
              ) : (
                <Image src={`${BASE_URL}${profilePic}`}>
                  <img className="noimagefound" src={noImageSvgWhite} />
                </Image>
              )}
            </span>
          </Dropdown>
        </div>
        <div className="profileRight">
          <InputBlock
            label="First Name *"
            placeholder="First Name"
            name="firstName"
            type="text"
            tabIndex="1"
            value={form.values?.firstName}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.touched?.firstName && form.errors?.firstName}
          />
          <InputBlock
            label="Last Name *"
            placeholder="Last Name"
            name="lastName"
            type="text"
            tabIndex="2"
            value={form.values?.lastName}
            onChange={form.handleChange}
            onBlur={() => {
              form.handleBlur && submitRef.current.focus();
            }}
            error={form.touched?.lastName && form.errors?.lastName}
          />
          <InputBlock label="Email" readOnly value={user.email} placeholder="Enter Email" />

          <button
            type="submit"
            ref={submitRef}
            className={classNames(['actionButonBottom commonButton', submitDisabled && 'disableBlock'])}
          >
            Save Change
          </button>
        </div>
      </div>
      <UploadImage
        // Modal Props
        visible={upload}
        handleOk={handleImageUpload}
        handleCancel={setUpload.false}
        // File Upload Props
        name="mobileImage"
        label="Choose File"
        accept="JPG,JPEG,PNG"
        size="JPG,JPEG,PNG file allowed"
        files={[form.values?.photoId]}
        onChange={setProfilePic}
        isValidate
      />
    </form>
  );
};

export default EditProfile;
