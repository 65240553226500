import React from 'react';
import * as moment from 'moment';
import { DatePicker } from 'antd';
import { isEmpty } from 'util/utils';

const DatePickerInput = (props) => {
  const { label, required, error, noFuture, noPast, min, max } = props;
  const isInPast = (date) => date < moment().startOf('day');
  const isInFuture = (date) => date > moment().endOf('day');
  const isLowerThan = (date, min) => date < moment(min).startOf('day');
  const isHigherThan = (date, max) => date > moment(max).endOf('day');

  const checkIfDisabled = React.useCallback(
    (date) =>
      (noPast && isInPast(date)) ||
      (noFuture && isInFuture(date)) ||
      (!isEmpty(min) && isLowerThan(date, min)) ||
      (!isEmpty(max) && isHigherThan(date, max)),
    [max, min, noFuture, noPast],
  );

  return (
    <div className={'from-group inputBlock ' + (error && 'error-message')}>
      <label>
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <DatePicker disabledDate={checkIfDisabled} {...props} />
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default DatePickerInput;
