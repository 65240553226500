import React, { Component } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import { InputPercentage } from 'containers/InputBlock';
import SelectOption from 'containers/SelectOption';
import BottomStickyButton from '../../BottomStickButton';
import { isEmpty } from 'util/utils';

import * as TermsDetailAPI from 'services/TermsDetailApi';
import OpenNotification from 'constants/OpenNotifications';
import { ACCOUNT_TERM_TYPE } from 'constants/Common';
import { showNotification } from 'services/NotificationService';
import Storage from 'services/storage';
import { TermDetailActions } from 'appRedux/reducers/TermsDetail';
import { connect } from 'react-redux';

let masters = Storage.get('dn-master');
let currency = masters?.CURRENCY?.map((item) => ({ ...item, id: item.id[0] }));
let dayTerms = masters?.DAY_TERM?.map((item) => ({ ...item, id: item.id[0] }));
class TermsDetail extends Component {
  initialValues = {
    currencyId: undefined,
    dayTermsId: undefined,
    extraPer: undefined,
    rapPer: undefined,
    splExtraPer: undefined,
    splRapPer: undefined,
    ntyUpExtraPer: undefined,
    ntyUpRapPer: undefined,
    addPer: undefined,
  };

  submitAction = 'save';

  componentDidMount() {
    masters = Storage.get('dn-master');
    currency = masters?.CURRENCY?.map((item) => ({ ...item, id: item.id[0] }));
    dayTerms = masters?.DAY_TERM?.map((item) => ({ ...item, id: item.id[0] }));
  }

  getInitialValues = () => {
    const termDetail = this.props.termsDetail?.editTerm;

    return Object.fromEntries(
      Object.entries(this.initialValues).map(([key, value]) => [
        key,
        isEmpty(termDetail?.[key]) ? value : termDetail?.[key],
      ]),
    );
  };

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const termDetail = this.props.termsDetail?.editTerm;
    const { onCancel } = this.props;
    //find currency with code usd and pass as default currency as mentioned by rahul sir
    if (isEmpty(values?.currencyId)) {
      let findCurrency = currency?.find((item) => item.code == 'USD');
      if (isEmpty(findCurrency)) return OpenNotification({ type: 'error', title: 'Currency not exist' });
      values.currencyId = findCurrency.id;
    }

    values.account = this.props?.account?.id;
    values.user = this.props?.account?.user?.id;
    values.type = ACCOUNT_TERM_TYPE?.INDIVIDUAL;

    // if (isEmpty(values.rapPer)) values.rapPer = 0;
    // if (isEmpty(values.extraPer)) values.extraPer = 0;

    // if ((isEmpty(values.extraPer) && isEmpty(values.rapPer)) || (values.rapPer === 0 && values.extraPer === 0))
    //   return OpenNotification({ type: 'error', title: "Ext % and Rap % can't be 0 or empty" });

    if (!values.account) return OpenNotification({ type: 'error', title: 'Account not selected' });

    setSubmitting(true);
    const [err, res] = await (!isEmpty(termDetail?.id)
      ? TermsDetailAPI.updateTermsDetail({ accountTerm: { ...values, id: termDetail?.id } })
      : TermsDetailAPI.storeTermsDetail(values));
    setSubmitting(false);

    !err && showNotification(res);

    if (res?.code === 'OK') {
      resetForm();
      this.props.getTermDetailsList(this.props?.account?.id);
      onCancel();
    }
  };

  render() {
    return (
      <Formik
        initialValues={this.getInitialValues()}
        enableReinitialize
        validateOnChange
        validationSchema={Yup.object().shape({
          currencyId: Yup.string()
            .oneOf(
              currency.map((item) => item.id),
              'Invalid Currency',
            )
            .nullable()
            .typeError('Currency must be a string'),
          dayTermsId: Yup.string()
            .oneOf(
              dayTerms.map((item) => item.id),
              'Invalid Day Term',
            )
            .required('Please select day term')
            .typeError('Day Terms must be a string'),
          extraPer: Yup.number()
            .min(-100, "Ext % can't be less than 0")
            .max(100, "Ext % can't be more than 100")
            .nullable(),
          rapPer: Yup.number()
            .min(-100, "Rap % can't be less than 0")
            .max(100, "Rap % can't be more than 100")
            .nullable(),
          splExtraPer: Yup.number()
            .min(-100, "Special Ext % can't be less than 0")
            .max(100, "Special Ext % can't be more than 100")
            .nullable(),
          splRapPer: Yup.number()
            .min(-100, "Special Rap % can't be less than 0")
            .max(100, "Special Rap % can't be more than 100")
            .nullable(),
          ntyUpExtraPer: Yup.number()
            .min(-100, "Ext % can't be less than 0")
            .max(100, "Ext % can't be more than 100")
            .nullable(),
          ntyUpRapPer: Yup.number()
            .min(-100, "Rap % can't be less than 0")
            .max(100, "Rap % can't be more than 100")
            .nullable(),
          addPer: Yup.number()
            .min(-100, "Rap % can't be less than 0")
            .max(100, "Rap % can't be more than 100")
            .nullable(),
        })}
        onSubmit={this.submitForm}
      >
        {({ handleBlur, setFieldValue, setFieldTouched, touched, errors, values }) => (
          <Form>
            <div className="kycFormMainBlock">
              <div className="kycBlockWrapper">
                <SelectOption
                  onChange={(value) => setFieldValue('currencyId', value)}
                  onBlur={() => setFieldTouched('currencyId')}
                  value={values.currencyId}
                  placeholder="Select currency"
                  valueKey="id"
                  nameKey="name"
                  name="currencyId"
                  label="Currency"
                  selectValue={currency}
                  error={errors?.currencyId}
                />

                <SelectOption
                  onChange={(value) => setFieldValue('dayTermsId', value)}
                  onBlur={() => setFieldTouched('dayTermsId')}
                  value={values.dayTermsId}
                  placeholder="Select day term"
                  valueKey="id"
                  nameKey="name"
                  name="dayTermsId"
                  label="Day Term *"
                  selectValue={dayTerms}
                  error={errors?.dayTermsId}
                />

                <InputPercentage
                  onChange={(value) => setFieldValue('extraPer', value)}
                  onBlur={handleBlur}
                  value={values.extraPer}
                  name="extraPer"
                  label="Ext %"
                  placeholder="Ext %"
                  min="-100"
                  error={touched?.extraPer && errors?.extraPer}
                />

                <InputPercentage
                  onChange={(value) => setFieldValue('rapPer', value)}
                  onBlur={handleBlur}
                  value={values.rapPer}
                  name="rapPer"
                  label="Rap %"
                  min="-100"
                  placeholder="Rap %"
                  error={touched?.rapPer && errors?.rapPer}
                />

                <InputPercentage
                  onChange={(value) => setFieldValue('splExtraPer', value)}
                  onBlur={handleBlur}
                  value={values.splExtraPer}
                  name="splExtraPer"
                  label="Special Ext %"
                  placeholder="Special Ext %"
                  min="-100"
                  error={touched?.splExtraPer && errors?.splExtraPer}
                />

                <InputPercentage
                  onChange={(value) => setFieldValue('splRapPer', value)}
                  onBlur={handleBlur}
                  value={values.splRapPer}
                  name="splRapPer"
                  label="Special Rap %"
                  placeholder="Special Rap %"
                  min="-100"
                  error={touched?.splRapPer && errors?.splRapPer}
                />

                <InputPercentage
                  onChange={(value) => setFieldValue('ntyUpExtraPer', value)}
                  onBlur={handleBlur}
                  value={values.ntyUpExtraPer}
                  name="ntyUpExtraPer"
                  label="0.9 Up Ext %"
                  placeholder="0.9 Up Ext %"
                  min="-100"
                  error={touched?.ntyUpExtraPer && errors?.ntyUpExtraPer}
                />

                <InputPercentage
                  onChange={(value) => setFieldValue('ntyUpRapPer', value)}
                  onBlur={handleBlur}
                  value={values.ntyUpRapPer}
                  name="ntyUpRapPer"
                  label="0.9 Up Rap %"
                  placeholder="0.9 Up Rap %"
                  min="-100"
                  error={touched?.ntyUpRapPer && errors?.ntyUpRapPer}
                />

                <InputPercentage
                  onChange={(value) => setFieldValue('addPer', value)}
                  onBlur={handleBlur}
                  value={values.addPer}
                  name="addPer"
                  label="Post Percentage(%)"
                  placeholder="Post Percentage(%)"
                  min="-100"
                  error={touched?.addPer && errors?.addPer}
                />
              </div>
            </div>
            <BottomStickyButton>
              <button className="commonButton" type="submit" value="Submit">
                Save
              </button>
              <button className="commonOutine" type="reset" onClick={this.props.onCancel}>
                Cancel
              </button>
            </BottomStickyButton>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(({ termsDetail }) => ({ termsDetail }), {
  getTermDetailsList: TermDetailActions.getTermDetailsList,
  setEditTerm: TermDetailActions.setEditTerm,
})(TermsDetail);
