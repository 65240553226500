import React from 'react';
import VersionList from './list';
import VersionAdd from './add';
import * as VersionAPI from './VersionApi';

class AppVersion extends React.Component {
  state = {
    list: true,
  };

  toggle = () => this.setState({ list: !this.state.list, detail: {} });

  editVersionDetail = async (editId) => {
    const [, res] = await VersionAPI.findVersion(editId);

    if (res?.code === 'OK') {
      this.setState({ detail: res.data, list: !this.state.list });
    }
  };

  render() {
    return (
      <div>
        {this.state.list ? (
          <VersionList
            {...this.state}
            platform={this.props.platform}
            onClick={this.toggle}
            editVersionDetail={this.editVersionDetail}
          />
        ) : (
          <VersionAdd closeForm={this.toggle} update={this.state.detail} />
        )}
      </div>
    );
  }
}

export default AppVersion;
