import React, {Component} from "react"
import MainHeading from "../../../containers/MainHeading"
import {Tooltip} from "antd"
import OfficeSolt from "./OfficeSolt"
import SelectOption from "../../../containers/SelectOption";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TimeConfim from "./TimeConfim"
import LeftMultipleSelect from "../../../containers/LeftOption/MultiSelect"
import LeftDatePicker  from "../../../containers/LeftOption/DatePicker"
import YourSoltBook from "./YourSoltBook"
import {timeSolt} from "../../../constants/solt"
export const Appointemt = {
    type:[
        {value:"In Person Metting"},
        {value:"Phone Call"},
        {value:"Web Conferen"}
    ],
    availableRoom:[
        {value:"Room No. 1"},
        {value:"Room No. 2"},
        {value:"Room No. 3"}
    ],
    clientName:[
        {value:"Vishal Virani"},
        {value:"Vishal Virani"},
        {value:"Vishal Virani"}
    ],
    location:[
        {value:"India"},
        {value:"Spain"},
        {value:"Hong Kong"},
        {value:"Dubai"},
    ],
    virtualType:[
        {value:"Phone Call"},
        {value:"Web Conferen"}
    ],
    buyerName:[
        {value:"Kalpesh Zalavadiya"},
        {value:"Kalpesh Zalavadiya"},
        {value:"Kalpesh Zalavadiya"}
    ],
    salesMan:[
        {value:"Johe Doe"},
        {value:"Johe Doe"},
        {value:"Johe Doe"}
    ],
    brokerName:[
        {value:"Johe Doe"},
        {value:"Johe Doe"},
        {value:"Johe Doe"}
    ],
    assinSales:[
        {value:"Vivek Jain"},
        {value:"Vivek Jain"},
        {value:"Vivek Jain"}
    ]
}
class TimeDate extends Component {
    constructor() {
        super();
        this.state = {
          show: true,
          visible:true,
          visible1:true,
          visible2:true,
          visible3:true,
          visible4:true,
          visible5:true,
          visible6:true,
          visible7:true,
          visible8:true,
          visible9:true,
          visible10:true,
          date: new Date(),
        };
      }
      onChange = date => this.setState({ date })
      bookAppointment = () =>{
        this.setState({
            show: !this.state.show,
          });
      }
      appointmentConfim = () =>{
        this.setState({
            visible: !this.state.visible,
          });
      }
      appointmentConfim1 = () =>{
        this.setState({
            visible1: !this.state.visible1,
          });
      }
      appointmentConfim2 = () =>{
        this.setState({
            visible2: !this.state.visible2,
          });
      }
      appointmentConfim3 = () =>{
        this.setState({
            visible3: !this.state.visible3,
          });
      }
      appointmentConfim4 = () =>{
        this.setState({
            visible4: !this.state.visible4,
          });
      }
      appointmentConfim5 = () =>{
        this.setState({
            visible5: !this.state.visible5,
          });
      }
      appointmentConfim6 = () =>{
        this.setState({
            visible6: !this.state.visible6,
          });
      }
      appointmentConfim7 = () =>{
        this.setState({
            visible7: !this.state.visible7,
          });
      }
      appointmentConfim8 = () =>{
        this.setState({
            visible8: !this.state.visible8,
          });
      }
      appointmentConfim9 = () =>{
        this.setState({
            visible9: !this.state.visible9,
          });
      }
      appointmentConfim10 = () =>{
        this.setState({
            visible10: !this.state.visible10,
          });
      }
    render(){
        return(
            <div className="officeBookingLeft">
                <div>
                    <SelectOption defaultValue="Select Type" selectValue={Appointemt.type} label="Type"/>
                </div>
                <div className="">
                    <MainHeading title="Select Date & Time"/>
                    <div className="bookappointmentTimeDate">
                        <Calendar
                        className="customCalender"
                        onChange={this.onChange}
                        value={this.state.date}
                        />
                        <div className="bookAppointmentTime smallScroll">
                            <TimeConfim onClick={this.appointmentConfim.bind(this)} visible={this.state.visible} time="9:00 AM"/>
                            <TimeConfim onClick={this.appointmentConfim1.bind(this)} visible={this.state.visible1} time="9:30 AM"/>
                            <TimeConfim onClick={this.appointmentConfim2.bind(this)} visible={this.state.visible2} time="10:00 AM"/>
                            <TimeConfim onClick={this.appointmentConfim3.bind(this)} visible={this.state.visible3} time="10:30 AM"/>
                            <TimeConfim onClick={this.appointmentConfim4.bind(this)} visible={this.state.visible4} time="11:00 AM"/>
                            <TimeConfim onClick={this.appointmentConfim5.bind(this)} visible={this.state.visible5} time="11:30 AM"/>
                            <TimeConfim onClick={this.appointmentConfim6.bind(this)} visible={this.state.visible6} time="12:00 AM"/>
                            <TimeConfim onClick={this.appointmentConfim7.bind(this)} visible={this.state.visible7} time="12:30 AM"/>
                            <TimeConfim onClick={this.appointmentConfim8.bind(this)} visible={this.state.visible8} time="1:00 AM"/>
                            <TimeConfim onClick={this.appointmentConfim9.bind(this)} visible={this.state.visible9} time="1:30 AM"/>
                            <TimeConfim onClick={this.appointmentConfim10.bind(this)} visible={this.state.visible10} time="2:00 AM"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TimeDate; 