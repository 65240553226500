import ConstantApi from 'constants/CommonApi';
import UtilService from 'services/util';
import { isEmpty } from 'util/utils';

export const getChequeDetailsList = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.chequeDetails.paginate, request, ...(!isEmpty(config) && { config }) });


export const updateStatus = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.chequeDetails.update, request, ...(!isEmpty(config) && { config }) });


