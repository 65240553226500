import React from 'react';
import './disableblock.less';

const DisableBlock = ({ children, className, ...rest }) => (
  <div className={`disableBlock ${className}`} {...rest}>
    {children}
  </div>
);

export default DisableBlock;
