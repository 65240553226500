import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { useTable } from 'react-table';
import PlusIcon from 'containers/PlusIcon';
import { Popconfirm } from 'antd';
import StatusBlock from 'containers/StatusBlock';
import { DateTime } from 'luxon';
import { BANNER_TYPES, BANNER_TYPES_IMAGE, BASE_URL } from 'constants/Common';
import { showNotification } from 'services/NotificationService';
import * as BannerAPI from 'services/BannerApi';
import ListFooter from 'components/List/ListFooter';
import Pagination from 'components/List/Pagination';
import { LIMIT, NoDataFound } from 'components/Inventory/Table';
import { isArray, isEmpty } from 'util/utils';
import { getModuleFromUrl } from 'constants/permission';
import Image from 'containers/Image';

const bannerTypes = Object.fromEntries(Object.entries(BANNER_TYPES).map((entries) => entries.reverse()));

class BannerList extends Component {
  state = {
    page: 1,
    limit: LIMIT,
    data: [],
    count: 0,
  };

  componentDidMount() {
    this.getList();
  }

  getList = async () => {
    this.setState({ loading: true });
    let { page, limit } = this.state;
    const [, res] = await BannerAPI.getBannerList({ page, limit, filter: { bannerType: BANNER_TYPES_IMAGE.Image } });

    const list = res?.data?.list;
    if (res.code === 'OK' && isArray(list)) {
      this.setState({ data: list, count: res.data.count, loading: false });
    }
    this.setState({ loading: false });
  };

  updateBannerDetail = async ({ id, ...request }) => {
    const [, res] = await BannerAPI.updateBanner(id, { ...request });
    if (res?.code === 'OK') {
      showNotification(res);
      this.getList();
    }
  };

  deleteBannerDetail = async (id) => {
    const [, res] = await BannerAPI.deleteBanner(id);
    showNotification(res);

    if (res?.code === 'OK') this.getList();
  };

  render() {
    const columns = [
      { Header: 'SR Number', width: '50px', accessor: 'srno', Cell: ({ row }) => ++row.id },
      {
        Header: 'Image',
        Cell: ({ row }) => {
          if (!isEmpty(row?.original?.images)) {
            let ext = row?.original?.images?.[0]?.split('.').pop().toLowerCase();
            let file = BASE_URL + row?.original?.images?.[0];
            if (['mkv', 'wmv', 'mp4']?.map((item) => item.trim().toLowerCase()).indexOf(ext) !== -1) {
              file = require('assets/images/video.svg');
            }
            return (
              <div className="imageBlockWrapper">
                <Image src={file} />
              </div>
            );
          } else {
            return (
              <div className="imageBlockWrapper">
                <Image src="" />
              </div>
            );
          }
        },
      },
      {
        Header: 'Type',
        Cell: ({ row }) => (
          <div>
            <span>{bannerTypes[row.original.type]}</span>
          </div>
        ),
      },
      { Header: 'Title', accessor: 'eventName' },
      {
        Header: 'From Date',
        accessor: 'fromdateTime',
        Cell: ({ row }) => (
          <div>
            <span>{DateTime.fromISO(row.original.fromdateTime).toLocaleString(DateTime.DATETIME_SHORT)}</span>
          </div>
        ),
      },
      {
        Header: 'To Date',
        accessor: 'toDateTime',
        Cell: ({ row }) => (
          <div>
            <span>{DateTime.fromISO(row.original.toDateTime).toLocaleString(DateTime.DATETIME_SHORT)}</span>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <div
            onClick={() =>
              this.updateBannerDetail({
                id: row.original.id,
                isActive: !row.original.isActive,
                name: row.original.name,
              })
            }
          >
            <StatusBlock
              status={row.original.isActive ? 'active' : 'InActive'}
              title={row.original.isActive ? 'Active' : 'InActive'}
            />
          </div>
        ),
      },
      { Header: 'Description', accessor: 'description' },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <div className="actionButton j-flex-start">
            {getModuleFromUrl().update && (
              <div className="actionAction" onClick={() => this.props.editDetails(row.original.id)}>
                <img src={require('assets/svg/edit.svg')} />
              </div>
            )}
            {getModuleFromUrl().delete && (
              <Popconfirm
                placement="left"
                title={`Are you sure you want to delete this Banner?`}
                okText={`Delete`}
                onConfirm={() => this.deleteBannerDetail(row.original.id)}
              >
                <div className="actionAction">
                  <img src={require('assets/svg/delet.svg')} />
                </div>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ];
    const Table = ({ columns, data }) => {
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
      });
      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th key={i} {...column.getHeaderProps()} width="100px">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => (
                      <td key={i} width="100px" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      );
    };
    return (
      <>
        {getModuleFromUrl().insert && <PlusIcon onClick={this.props.onClick} />}
        <div className="contactDirectoryWrapper">
          <div className="contactListMain">
            <div className="contactList">
              <Table columns={columns} data={this.state.data} />
              <NoDataFound length={this.state.data?.length} />
            </div>
          </div>
        </div>
        <ListFooter className="homeSettingfooter">
          <span style={{ margin: 'auto' }}>
            <Pagination
              page={this.state?.page}
              limit={this.state?.limit}
              count={this.state?.count}
              length={this.state?.data.length}
              onPaginationChange={(page, limit) => this.setState({ page, limit }, () => this.getList())}
            />
          </span>
        </ListFooter>
      </>
    );
  }
}

export default withRouter(BannerList);
