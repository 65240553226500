import SelectOption from 'containers/SelectOption';
import React, { useEffect, useState } from 'react';
import { getQuoteDay } from 'services/CommonApi';
import { i18n } from 'util/i18n';
import { isFunction } from 'util/utils';
import { getQuoteDayList, isValue } from '../CommonPopupFunction';

function QuoteDayComponent(props) {
  const [state, _setState] = useState({ quoteDayList: [], quoteDay: null, loading: false });
  const setState = (object = {}) => {
    _setState((state) => ({ ...state, ...object }));
    props.setQuoteDetail(object);
  };

  React.useEffect(() => {
    getQuoteDayList((quoteDayList) => {
      setState({ quoteDayList, quoteDay: props.quoteDayList ? quoteDayList[0]?.key : null });
    });
  }, []);

  return (
    <SelectOption
      className={props.className}
      placeholder={`Select ${i18n.t('title.offer')} Title`}
      value={isValue(state.quoteDay)}
      selectValue={state.quoteDayList}
      label={props.isNotLabel ? '' : `Select ${i18n.t('title.offer')} Title *`}
      onChange={(quoteDay = null) => {
        if (quoteDay && isFunction(props.removeError)) props.removeError();
        setState({ quoteDay });
      }}
      error={props.error?.quoteDay}
    />
  );
}

export default React.memo(QuoteDayComponent);
