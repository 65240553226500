import React from 'react';
import { connect } from 'react-redux';
import InputBlock from 'containers/InputBlock';
import SelectOption from 'containers/SelectOption';
import BottomStickyButton from '../../BottomStickButton';
import Heading from 'containers/MainHeading';
import { isArray, isEmpty, isFunction, isString } from 'util/utils';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { USERS_TYPE } from 'constants/Common';
import { Checkbox } from 'antd';
import { showNotification } from 'services/NotificationService';
import * as UserAPI from 'services/UserApi';
import { UserActions } from 'appRedux/reducers/User';
import * as SellerAPI from 'services/SellerApi';
import OpenNotification from 'constants/OpenNotifications';
import { TabActions } from 'appRedux/reducers/TabIssueReducer/TabReducer';
import * as MasterApi from 'services/SubMasterApi';

export const user = {
  usertype: [{ value: 'Sub User' }],
};

class User extends React.Component {
  state = {
    users: [],
    roleList: [],
    sellers: [],
  };
  previousNotifyEmailArray = [];

  masterList = async () => {
    const [, res] = await MasterApi.getMastersList({
      filter: {
        parentCode: 'USER_ROLES',
        group: ['CUSTOMER'],
        isActive: true,
      },
    });

    if (res && res.data && res.data.length) {
      this.setState({
        roleList: res.data.map(({ id, name }) => ({
          key: id,
          value: name,
        })),
      });
    }
  };

  getSalesPerson = async () => {
    const [err, res] = await SellerAPI.getSellerList({ filter: { isActive: true } });
    const success = !err && res?.code === 'OK';

    if (success && isArray(res.data)) {
      this.setState({
        sellers: res.data.map(({ id, firstName, lastName }) => ({
          key: id,
          value: `${firstName}${lastName !== '_' ? ' ' + lastName : ''}`,
        })),
      });
    }
  };

  fields = [
    {
      name: 'users',
      label: 'User Details',
      type: 'array',
      fields: [
        { name: 'firstName', type: 'text', label: 'First Name', initialValue: '', required: true },
        { name: 'lastName', type: 'text', label: 'Last Name', initialValue: '' },
        {
          name: 'type',
          type: 'select',
          label: 'Select User Type',
          options: () =>
            Object.entries(USERS_TYPE)
              .filter((item) => item[0] == 'PRIMARY' || item[0] == 'SECONDARY' || item[0] == 'API_USER')
              .map(([value, key]) => ({ key, value: value.replace('_', ' ') })),
          initialValue: undefined,
          required: true,
        },

        { name: 'email', type: 'text', label: 'Email', initialValue: '', required: true },
        { name: 'mobile', type: 'text', label: 'Mobile No', initialValue: '', required: true },
        { type: 'show' },
        { name: 'username', type: 'text', label: 'Username', initialValue: '', required: true },
        { name: 'password', type: 'password', label: 'Password', initialValue: '', required: true },
        { name: 'confirm_password', type: 'password', label: 'Confirm Password', initialValue: '', required: true },
        { name: 'whatsapp', type: 'text', label: 'Whatsapp', initialValue: '' },
        { name: 'skype', type: 'text', label: 'Skype', initialValue: '' },
        { name: 'wechat', type: 'text', label: 'Wechat', initialValue: '' },
        { name: 'syncId', type: 'text', label: 'User Id', initialValue: '' },
        {
          name: 'seller',
          type: 'select',
          label: 'Select Salesman',
          options: this.state.sellers,
          initialValue: undefined,
          required: false,
        },
        {
          name: 'roles',
          type: 'select',
          label: 'Select User Role',
          initialValue: [],
        },
        {
          name: 'notify_email',
          type: 'text',
          label: 'Notify Emails',
          initialValue: [],
          required: false,
        },
      ],
    },
  ];

  getInitialValues = () => {
    let data = {};
    if (!isEmpty(this.props?.user?.editUser) && !isEmpty(this.props?.account)) {
      data = { users: [this.props?.user?.editUser] };
    } else {
      data = { users: [...(this.state.users || 0)] };
    }

    return Object.fromEntries(
      this.fields.map(({ type, fields, name, initialValue }) =>
        ['array'].includes(type) && isEmpty(data?.[name])
          ? [name, [Object.fromEntries(fields.map(({ name, initialValue }) => [name, initialValue]))]]
          : [name, isEmpty(data?.[name]) ? initialValue : data[name]],
      ),
    );
  };

  validationSchema = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required('Please enter firstname'),
        lastName: Yup.string().trim(),
        type: Yup.number()
          .oneOf([USERS_TYPE.PRIMARY, USERS_TYPE.SECONDARY, USERS_TYPE.API_USER], 'Invalid Type')
          .required('Please select type'),
        email: Yup.string().email('Invalid Email').required('Please enter email'),
        mobile: Yup.string().required('Please enter mobile'),
        username: Yup.string().required('Please enter username'),
        password: Yup.string().required('Please enter password'),
        confirm_password: Yup.string()
          .test('passwords-match', 'Confirm Password must be same as password', function (value) {
            return this.parent.password === value;
          })
          .required('Please enter confirm password'),
      }),
    ),
  });

  validationEditSchema = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required('Firstname is required'),
        lastName: Yup.string().trim().required('Lastname is required'),
        type: Yup.number()
          .oneOf([USERS_TYPE.PRIMARY, USERS_TYPE.SECONDARY, USERS_TYPE.API_USER], 'Invalid Type')
          .required('Type is required'),
        email: Yup.string().email('Invalid Email').required('Email is required'),
        mobile: Yup.string().required('Mobile is required'),
        username: Yup.string().required('Username is required'),
        confirm_password: Yup.string().test(
          'passwords-match',
          'Confirm Password must be same as password',
          function (value) {
            return this.parent.password === value;
          },
        ),
      }),
    ),
  });

  // submitForm = async (values, { setSubmitting, resetForm }) => {
  //   console.log(values);
  //   const { account } = this.props;

  //   let processedUsers = values.users.map((item) => {
  //     item.type = Number(item.type);
  //     // const firstNameArray = item.firstName.split(',').map((name) => name.trim()); // Split and trim the names
  //     const notifyEmailsArray = item?.notify_email?.split(',').map((email) => email?.trim()); // Split and trim the names

  //     return {
  //       account: item.account,
  //       firstName: item.firstName, // Store the firstName value as an array of separate names
  //       lastName: item.lastName,
  //       type: item.type,
  //       email: item.email,
  //       mobile: item.mobile,
  //       username: item.username,
  //       password: item.password,
  //       whatsapp: item.whatsapp,
  //       skype: item.skype,
  //       syncId: item.syncId,
  //       wechat: item.wechat,
  //       seller: item.seller,
  //       roles: item.roles,
  //       // notify_email: notifyEmailsArray,
  //     };
  //   });

  //   const payload = {
  //     users: processedUsers, // Include the processedUsers array in the payload
  //   };

  //   setSubmitting(true);
  //   const [err, res] = await (isEmpty(this.props?.user?.editUser)
  //     ? UserAPI.createMany(payload) // Use the payload to create multiple users
  //     : UserAPI.updateUserDetail(processedUsers[0]?.id, processedUsers[0]));
  //   setSubmitting(false);

  //   if (res?.code === 'OK' && isEmpty(res?.data?.failed)) {
  //     !err && showNotification(res);
  //     // if (!isEmpty(this.props?.user?.editUser) && isFunction(this.props?.setEditUser)) this.props.setEditUser();
  //     this.props.getUsersList(account?.id);
  //     if (this.state.saveContinue) this.props.updateSubTabIndex();
  //     else {
  //       resetForm();
  //       isFunction(this.props?.onCancel) && this.props.onCancel();
  //     }

  //     let editUser = this.props?.user?.editUser;
  //     if (!isEmpty(editUser) && res?.data?.[0].type == USERS_TYPE.PRIMARY) {
  //       this.accountStoreUpdate(account.id, res?.data?.[0]);
  //     } else if (editUser.type == USERS_TYPE.PRIMARY && res?.data?.[0].type != USERS_TYPE.PRIMARY) {
  //       this.accountStoreUpdate(account.id);
  //     } else if (!isEmpty(res?.data?.created)) {
  //       res.data.created.map((item) => {
  //         if (item.type == USERS_TYPE.PRIMARY) {
  //           this.accountStoreUpdate(account.id, item);
  //         }
  //       });
  //     }
  //   } else if (!isEmpty(res?.data?.failed)) {
  //     res.data.failed.map((item) => {
  //       OpenNotification({ type: 'error', title: item?.error?.message });
  //     });
  //   }
  // };

  // Main
  // submitForm = async (values, { setSubmitting, resetForm }) => {
  //   console.log(values);
  //   const { account } = this.props;
  //   values.users = values.users.map((user) => ({ account: account?.id, ...user }));
  //   let users = values.users.map((item) => {
  //     item.type = Number(item.type);
  //     const notifyEmailArray = item?.notify_email?.split(',').map((email) => email?.trim());
  //     return (({
  //       id,
  //       account,
  //       firstName,
  //       lastName,
  //       type,
  //       email,
  //       mobile,
  //       username,
  //       password,
  //       whatsapp,
  //       skype,
  //       wechat,
  //       syncId,
  //       seller,
  //       roles,
  //       notify_email: notifyEmailArray,
  //     }) => ({
  //       id,
  //       account,
  //       firstName,
  //       lastName,
  //       type,
  //       email,
  //       mobile,
  //       username,
  //       password,
  //       whatsapp,
  //       skype,
  //       syncId,
  //       wechat,
  //       seller,
  //       roles,
  //       notify_email: notifyEmailArray,
  //     }))(item);
  //   });
  //   console.log(user);

  //   setSubmitting(true);
  //   const [err, res] = await (isEmpty(this.props?.user?.editUser)
  //     ? UserAPI.createMany({ ...values })
  //     : UserAPI.updateUserDetail(users[0]?.id, users[0]));
  //   setSubmitting(false);

  //   if (res?.code === 'OK' && isEmpty(res?.data?.failed)) {
  //     !err && showNotification(res);
  //     // if (!isEmpty(this.props?.user?.editUser) && isFunction(this.props?.setEditUser)) this.props.setEditUser();
  //     this.props.getUsersList(account?.id);
  //     if (this.state.saveContinue) this.props.updateSubTabIndex();
  //     else {
  //       resetForm();
  //       isFunction(this.props?.onCancel) && this.props.onCancel();
  //     }

  //     let editUser = this.props?.user?.editUser;
  //     if (!isEmpty(editUser) && res?.data?.[0].type == USERS_TYPE.PRIMARY) {
  //       this.accountStoreUpdate(account.id, res?.data?.[0]);
  //     } else if (editUser.type == USERS_TYPE.PRIMARY && res?.data?.[0].type != USERS_TYPE.PRIMARY) {
  //       this.accountStoreUpdate(account.id);
  //     } else if (!isEmpty(res?.data?.created)) {
  //       res.data.created.map((item) => {
  //         if (item.type == USERS_TYPE.PRIMARY) {
  //           this.accountStoreUpdate(account.id, item);
  //         }
  //       });
  //     }
  //   } else if (!isEmpty(res?.data?.failed)) {
  //     res.data.failed.map((item) => {
  //       OpenNotification({ type: 'error', title: item?.error?.message });
  //     });
  //   }
  // };

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const { account } = this.props;
    values.users = values?.users?.map((user) => ({ account: account?.id, ...user }));
    let users = values?.users?.map((item) => {
      item.type = Number(item?.type);
      // const notifyEmailArray = item?.notify_email?.split(',')?.map((email) => email?.trim());
      // const notifyEmailArray =
      //   typeof item?.notify_email === 'string'
      //     ? item?.notify_email.split(',')?.map((email) => email?.trim())
      //     : [];

      // const notifyEmailArray =
      //   typeof item?.notify_email === 'string'
      //     ? item?.notify_email.split(',')?.map((email) => email?.trim())
      //     : (this.props?.user?.editUser?.notify_email || [])
      //         .map((email) => email.trim())
      //         .filter((email) => email !== '');

      let notifyEmailArray =
        typeof item?.notify_email === 'string'
          ? item?.notify_email.split(',')?.map((email) => email?.trim())
          : (this.props?.user?.editUser?.notify_email || [])
              .map((email) => email.trim())
              .filter((email) => email !== '');

      if (notifyEmailArray.length === 1 && notifyEmailArray[0] === '') {
        notifyEmailArray = [];
      }

      return {
        id: item.id,
        account: item.account,
        firstName: item.firstName,
        lastName: item.lastName,
        type: item.type,
        email: item.email,
        mobile: item.mobile,
        username: item.username,
        password: item.password,
        whatsapp: item.whatsapp,
        skype: item.skype,
        syncId: item.syncId,
        wechat: item.wechat,
        seller: item.seller,
        roles: item.roles,
        notify_email: notifyEmailArray || [],
      };
    });

    setSubmitting(true);
    const [err, res] = await (isEmpty(this.props?.user?.editUser)
      ? UserAPI.createMany({ ...values })
      : UserAPI.updateUserDetail(users[0]?.id, users[0]));
    setSubmitting(false);

    if (res?.code === 'OK' && isEmpty(res?.data?.failed)) {
      !err && showNotification(res);
      // if (!isEmpty(this.props?.user?.editUser) && isFunction(this.props?.setEditUser)) this.props.setEditUser();
      this.props.getUsersList(account?.id);
      if (this.state.saveContinue) this.props.updateSubTabIndex();
      else {
        resetForm();
        isFunction(this.props?.onCancel) && this.props.onCancel();
      }

      let editUser = this.props?.user?.editUser;
      if (!isEmpty(editUser) && res?.data?.[0].type === USERS_TYPE.PRIMARY) {
        this.accountStoreUpdate(account.id, res?.data?.[0]);
      } else if (editUser.type === USERS_TYPE.PRIMARY && res?.data?.[0].type !== USERS_TYPE.PRIMARY) {
        this.accountStoreUpdate(account.id);
      } else if (!isEmpty(res?.data?.created)) {
        res.data.created.map((item) => {
          if (item.type === USERS_TYPE.PRIMARY) {
            this.accountStoreUpdate(account.id, item);
          }
        });
      }
    } else if (!isEmpty(res?.data?.failed)) {
      res.data.failed.map((item) => {
        OpenNotification({ type: 'error', title: item?.error?.message });
      });
    }
  };

  accountStoreUpdate = (accountId, user = null) => {
    let list = this.props?.clientData?.clientList;
    list = list?.map((item) => {
      if (accountId == item?.id && user) {
        item.user = user;
      } else if (accountId == item?.id && !user) {
        item.user = {};
      }
      return item;
    });

    this.props.setClientList({ ...{ clientList: list } });
  };

  cancelForm = () => {
    if (!isEmpty(this.props?.user?.editUser) && isFunction(this.props?.setEditUser)) this.props.setEditUser();
    this.props.onCancel();
  };

  componentDidMount() {
    this.getSalesPerson();
    this.masterList();
  }

  render() {
    const initialValues = this.getInitialValues();

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={isEmpty(this.props?.user?.editUser) ? this.validationSchema : this.validationEditSchema}
        validateOnChange
        onSubmit={this.submitForm}
      >
        {({ handleChange, handleBlur, setFieldValue, setFieldTouched, submitForm, touched, errors, values }) => (
          <Form>
            <div className="kycFormMainBlock">
              <Heading className="smallTitle p-0 mb-20" title="User Details"></Heading>
              {this.fields.map(
                (field) =>
                  field.type === 'array' &&
                  values[field.name].map((item, index) => (
                    <React.Fragment key={index}>
                      <FieldArray
                        name={field.name}
                        render={(fieldArrayHelpers) => (
                          <>
                            <div className="kycBlockWrapper formMainSubBlock mb-20 pb-0">
                              {field.fields.map(({ name, type, label, options, required, props }) => {
                                const namePrefix = `${field.name}.${index}.`;
                                const htmlName = `${namePrefix}${name}`;

                                if (
                                  !isEmpty(this.props?.user?.editUser) &&
                                  (name == 'password' || name == 'confirm_password')
                                )
                                  return;

                                let selectValue = [];
                                if (isFunction(options)) {
                                  switch (name) {
                                    case 'stateId':
                                      selectValue = options()?.[values?.[field.name]?.[index]?.['countryId']] || [];
                                      break;

                                    case 'cityId':
                                      selectValue = options()?.[values?.[field.name]?.[index]?.['stateId']] || [];
                                      break;

                                    default:
                                      selectValue = options();
                                      break;
                                  }
                                }

                                return type === 'show' ? (
                                  <div key={index} className="from-group"></div>
                                ) : type === 'select' ? (
                                  <SelectOption
                                    {...{ name: htmlName, ...props }}
                                    mode={name === 'roles' ? 'multiple' : 'default'}
                                    className={name === 'roles' ? 'multiSelect' : ''}
                                    label={required ? label + ' *' : label}
                                    placeholder={label}
                                    value={
                                      isString(values[field.name]?.[index]?.[name])
                                        ? name === 'roles'
                                          ? values[field.name][index][name].split(',')
                                          : values[field.name][index][name]
                                        : undefined
                                    }
                                    selectValue={
                                      name === 'seller'
                                        ? this.state.sellers
                                        : name === 'roles'
                                        ? this.state.roleList
                                        : selectValue
                                    }
                                    onChange={(value) => {
                                      if ([`countryId`].includes(name)) {
                                        setFieldValue(`${namePrefix}stateId`, undefined);
                                        setFieldValue(`${namePrefix}cityId`, undefined);
                                        this.getStates(value);
                                      }
                                      if ([`stateId`].includes(name)) {
                                        setFieldValue(`${namePrefix}cityId`, undefined);
                                        this.getCities(value);
                                      }
                                      setFieldValue(htmlName, `${value}`);
                                    }}
                                    onBlur={() => setFieldTouched(htmlName, true)}
                                    error={touched[field.name]?.[index]?.[name] && errors[field.name]?.[index]?.[name]}
                                    key={htmlName}
                                    showSearch
                                  />
                                ) : type === 'bool' ? (
                                  <div className="checkBoxForm">
                                    <label>{required ? label + ' *' : label}</label>
                                    <Checkbox
                                      {...{ name: htmlName, label, ...props }}
                                      onChange={() => setFieldValue(htmlName, !values[field.name]?.[index]?.[name])}
                                      onBlur={() => setFieldTouched(htmlName, true)}
                                      error={
                                        touched[field.name]?.[index]?.[name] && errors[field.name]?.[index]?.[name]
                                      }
                                      checked={values[field.name]?.[index]?.[name]}
                                      key={htmlName}
                                    />
                                  </div>
                                ) : (
                                  <InputBlock
                                    {...{ name: htmlName, type, ...props }}
                                    placeholder={label}
                                    label={required ? label + ' *' : label}
                                    onKeyPress={(evt) => {
                                      if (
                                        ((name == 'mobile' || name == 'whatsapp') &&
                                          evt.which != 8 &&
                                          evt.which != 0 &&
                                          (evt.which < 48 || evt.which > 57) &&
                                          evt.which != 45) ||
                                        ((name == 'mobile' || name == 'whatsapp') && evt.target.value.length >= 10)
                                      ) {
                                        evt.preventDefault();
                                      }
                                      if (
                                        (name == 'firstName' || name == 'lastName') &&
                                        (evt.which < 97 || evt.which > 122) &&
                                        (evt.which < 65 || evt.which > 90) &&
                                        evt.which != 32
                                      ) {
                                        evt.preventDefault();
                                      }
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched[field.name]?.[index]?.[name] && errors[field.name]?.[index]?.[name]}
                                    value={values[field.name]?.[index]?.[name]}
                                    key={htmlName}
                                  />
                                );
                              })}
                            </div>
                            {isEmpty(this.props?.user?.editUser) && (
                              <div className="kycNewBlockAdd">
                                <button
                                  className="commonButton"
                                  type="button"
                                  role="button"
                                  onClick={() => fieldArrayHelpers.push(initialValues[field.name][0])}
                                >
                                  Add
                                </button>
                                {values?.['users']?.length > 1 && (
                                  <button
                                    className="commonButton ml-5"
                                    type="button"
                                    role="button"
                                    onClick={() => values[field.name]?.length > 1 && fieldArrayHelpers.remove(index)}
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </React.Fragment>
                  )),
              )}
            </div>
            <BottomStickyButton className={!this.props?.account?.id && 'disableBlock'}>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: true }, submitForm)}
              >
                Save & Continue
              </button>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: false }, submitForm)}
              >
                Save
              </button>
              <button className="commonOutine" type="reset" onClick={this.cancelForm}>
                Cancel
              </button>
            </BottomStickyButton>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(
  ({ user, tab }) => ({
    user,
    clientData: tab.clientList,
  }),
  {
    getUsersList: UserActions.getUsersList,
    setClientList: TabActions.setClientList,
  },
)(User);
