import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'antd';
import { map } from 'lodash';

import Modal from 'containers/CommonModal';
import OpenNotification from 'constants/OpenNotifications';
import { MEDIA_URL } from 'constants/CommonUrl';
import { downloadExcelFile, downloadFile, downloadVideo, downloadZip } from 'services/DiamondApi';

import './share-action.less';
import CircularProgress from 'components/CircularProgress';
import { FILE_URLS } from 'constants/Common';

function DownloadOption({ checked, id, onChange, title, uiid, ...props }) {
  const handleChange = React.useCallback(() => {
    onChange(!id);
  }, [onChange, id]);

  return (
    <li id={uiid} onClick={handleChange} {...props}>
      <div className="dropdownBottomItem">
        <div className="dropdownIconCheckBox">
          <Checkbox onChange={onChange} checked={checked}></Checkbox>
        </div>
        <span className="shareOptionLabel">{title}</span>
      </div>
    </li>
  );
}

function DownloadAction({ onClose, visible, clearAll, onCloseDownloadPopup, ...props }) {
  const dispatch = useDispatch();
  const [isExcel, setIsExcel] = React.useState(false);
  const [isCert, setIsCert] = React.useState(false);

  const [isImg, setIsImg] = React.useState(false);
  const [isVideo, setIsVideo] = React.useState(false);

  const [certificates, setCertificates] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const [allChecked, setAllChecked] = React.useState(false);

  const flag = React.useMemo(
    () => {
      // prettier-ignore
      const array = [ isCert , isExcel , isImg, isVideo  ];
      return array.filter(Boolean).length;
    },
    // prettier-ignore
    [ isCert , isExcel , isImg, isVideo],
  );

  const anyOptionsChecked = React.useMemo(
    () => {
      // prettier-ignore
      return ( isCert || isExcel || isImg|| isVideo);
    },
    // prettier-ignore
    [ isCert , isExcel , isImg, isVideo],
  );

  const downloadStoneDetails = React.useCallback(async () => {
    if (anyOptionsChecked) {
      const wSts =
        props.currentType === 'NewArrivalDiamondList' ? 'B' : props.currentType === 'upcomingList' ? 'U' : undefined;
      const isUpcoming = props.currentType === 'upcomingList' || undefined;

      const filter = {
        vStnId: map(props.checked, 'vStnId'),
        wSts,
      };

      if (props.checked.length >= 1) {
        if (isExcel && flag === 1) {
          setLoader(true);
          if (props.currentType === 'matchPairList') filter.isPairExcel = true;
          if (props.currentType === 'layoutList') {
            filter.isPairExcel = true;
            filter.isLayoutExcel = true;
          }
          // await downloadExcelFile({ filter });
          props.downloadAllDiamond();
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (flag >= 2 || props.checked.length > 1) {
          let obj = {
            filter,
            isUpcoming,
            isExcel: isExcel,
            img: isImg,
            certFile: isCert,
            videoFile: isVideo,
            zip: true,
          };

          setLoader(true);
          await downloadZip(obj);
          setLoader(false);

          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (isCert) {
          let obj = {
            path: FILE_URLS.certFile?.replace('***', props.checked[0].rptNo),
            fileName: `certFile_${props.checked[0].rptNo}`,
            ext: '.pdf',
          };
          setLoader(true);
          await downloadFile(obj);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (isVideo) {
          let obj = {
            path: FILE_URLS.videoFile?.replace('***', props.checked[0].vStnId),
            fileName: `VideoFile_${props.checked[0].vStnId}`,
            ext: '.mp4',
          };
          setLoader(true);
          await downloadFile(obj);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        } else if (isImg) {
          ////Image
          let obj = {
            path: FILE_URLS.img?.replace('***', props.checked[0].vStnId),
            fileName: `Image_${props.checked[0].vStnId}`,
            ext: '.jpg',
          };
          setLoader(true);
          await downloadFile(obj);
          setLoader(false);
          onClose();
          dispatch({ type: 'RESET_SELECTED_ROWS' });
        }
      }
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please Select atleast 1 Option to Download.',
      });
    }
  }, [
    anyOptionsChecked,
    props.currentType,
    props.checked,
    flag,
    isExcel,
    isCert,
    isVideo,
    clearAll,
    onCloseDownloadPopup,
    onClose,
  ]);

  return (
    <Modal title="Download" handleCancel={onClose} visible={visible ?? true} className="md-size">
      <div>
        <div className="searchPopupCommon">
          <div className="shareOptionSet">
            <div className="shareOptionSetItem shareOptionImage">
              <ul>
                <div className="dropdownRightCheck">
                  <DownloadOption title="Image" checked={isImg} onChange={() => setIsImg(!isImg)} />
                  <DownloadOption title="Video" checked={isVideo} onChange={() => setIsVideo(!isVideo)} />
                  <DownloadOption title="Certificate" checked={isCert} onChange={() => setIsCert(!isCert)} />
                  <DownloadOption checked={isExcel} onChange={() => setIsExcel(!isExcel)} title="Excel" />
                </div>
              </ul>
            </div>
          </div>
          <div className="commonModalButton mt-50">
            <button className="fillButton" onClick={downloadStoneDetails}>
              Download
            </button>
            {/* <button className="outLineButton" onClick={onClose}>
              Cancel
            </button> */}

            {loader && <CircularProgress className="fixLoader" />}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default connect(
  (reducers) => ({
    ...reducers,
  }),
  {},
)(withRouter(DownloadAction));
