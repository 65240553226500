import concat from 'lodash/concat';
import _each from 'lodash/each';
import omit from 'lodash/omit';
import _uniq from 'lodash/uniq';

import { downloadExcel, printPdf } from 'services/CommonApi';
import Storage from 'services/storage';
import UtilService from 'services/util';

import { isArray, isEmpty, isFunction, isString } from 'util/utils';

import { CommonPrintExcel, USERS_TYPE } from 'constants/Common';
import ConstantApi from 'constants/CommonApi';
import { COMMON_URL } from 'constants/CommonUrl';
// import { getIpv4 } from './getIpAddress';

export const sendPricingEmail = (request = {}) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Pricing.sendEmail,
    request,
  });
};

export async function handleBidDelete(id, cb) {
  // const ip = await getIpv4();
  const request = { id, ip: "127.0.0.1" };
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondBid.bidDelete,
    request,
  });
}

export function handleBidCartDelete(id, cb) {
  const request = { id };
  return UtilService.callApiAsync({
    ...ConstantApi.BidCartHistoryList.delete,
    request,
  });
}

export const downloadWinnerBidExcel = (request = {}) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondBid.downloadExcel,
    request,
  });
};

export const downloadRunningExcel = (request = {}) => {
  return UtilService.callApiAsync({
    ...ConstantApi.RunningBid.downloadExcel,
    request,
  });
};

export const sendBidEmail = (request = {}) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondBid.sendEmail,
    request,
  });
};
// Diamond
export const getWithoutDiamondList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Inventory.imageCheck,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const placeOrderHandel = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondConfirm.Request,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

// Diamond track
export const getGridColumnList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Gridcolumn.list, request, ...(!isEmpty(config) && { config }) });
};

export const saveGridColumnList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Gridcolumn.update, request, ...(!isEmpty(config) && { config }) });
};

/**
 * Function used to get saved search list
 * @param {*} request
 */
export const savedSearchList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Diamond.search, request, ...(!isEmpty(config) && { config }) });
};

/**
 * Function used to get total diamond count
 * @param {*} filter
 * @param {*} cb
 */
export const getCount = ({ isUpcoming, ...filter }, cb) => {
  _each(filter, (val, key) => {
    if (isArray(val) && val.length === 0) delete filter[key];
    else if (isArray(val)) filter[key] = _uniq(val);
  });
  const modifiedFilter = { ...filter }

  if (modifiedFilter?.back) {
    const from = modifiedFilter?.back['>='];
    const to = modifiedFilter?.back['<='];
    if (from < 0 && to < 0) {
      const _from = from < to ? from : to
      const _to = from < to ? to : from
      const back = {
        '>=': _from,
        '<=': _to,
      };
      modifiedFilter['back'] = { ...back };
    }
  }

  // if (filter.lb) {
  //   const or = [{ lbIds: filter.lb }, { lb: filter.lb }];
  //   filter = omit(filter, ['lb']);
  //   filter = { ...filter, or: filter.or ? concat(or, filter.or) : or };
  // }

  UtilService.callApi(
    {
      ...ConstantApi.Diamond.paginate,
      request: { isNotReturnTotal: true, isReturnCountOnly: true, isSkipSave: true, isUpcoming, filters: [modifiedFilter] },
      config: { loader: false },
    },
    (err, res) => cb(res?.data?.[0]?.count),
  );
};

export const getCountAsync = (...args) => new Promise((resolve) => getCount(...args, resolve));

// Diamond track
export const getDiamondTrackList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondTrack.paginate,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

// Bid Cart History list
export const getDiamondBidCartHistoryList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.BidCartHistoryList.paginate,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getDiamondCommentList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Inventory.diamondCommentPaginate,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getDiamondBidList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.DiamondBid.paginate, request, ...(!isEmpty(config) && { config }) });
};

export const getDiamondTrackRemove = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.DiamondTrack.delete, request, ...(!isEmpty(config) && { config }) });
};

export const getDiamondTrackChangeStatus = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondTrack.changeStatus,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getDiamondTrackUpdateStatus = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondTrack.statusUpdate,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const createDiamondTrack = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.DiamondTrack.create, request, ...(!isEmpty(config) && { config }) });
};

// Transaction Hold/Memo
export const getTransactionHoldMemo = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Memo.paginate, request, ...(!isEmpty(config) && { config }) });
};

//End Diamond track

//Diamond excel

export const diamondFtc = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Inventory.ftc, request, ...(!isEmpty(config) && { config }) });
};

export const sendEmailAttachment = (oldRequest = {}) => {
  const request = { ...oldRequest, sendEmail: true };
  //set new changes in api
  return UtilService.callApiAsync({
    ...ConstantApi.Inventory.exportExcel,
    request,
  });
};

export const sendXRayEmailAttachment = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Inventory.xRayMail, request, ...(!isEmpty(config) && { config }) });
};

export const downloadExcelFile = async (request = {}, cb) => {
  const [err, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.exportExcel,
    request,
  });

  return new Promise((resolve) => {
    if (err || res?.code !== 'OK' || !isString(res?.data?.data)) return;

    const server = COMMON_URL.EXCELFILE_URL;
    const filePath = server + res?.data?.data;
    window.open(filePath, 'Download');
    if (isFunction(cb)) cb(true);
    resolve();
  });
};

/**
 * Function used to download pdf file
 * @param {*} request
 */

export const downloadPdfFile = async (diamond, requestUrl, filter = {}, moduleParam, cb, excelPDFTab = null) => {
  if (isFunction(cb)) cb(true);
  if (CommonPrintExcel[moduleParam]) {
    const user = Storage.get('user');
    if (user?.type != USERS_TYPE.SELLER) delete CommonPrintExcel[moduleParam]?.filter?.['sSts'];
  }
  const request = {
    ...(isArray(diamond) && diamond?.length
      ? {
        ...(excelPDFTab
          ? { excelType: excelPDFTab, filter: { id: diamond?.map((o) => o.blockId || o.id) } }
          : { id: diamond?.map((o) => o.id) }),
      }
      : // moduleParam == 'inOrder'
      // ? { orderDiamond: diamond?.map((o) => o.id) }
      {}),
    ...(!isEmpty(filter) ? { filter } : {}),
    ...(CommonPrintExcel[moduleParam] ? CommonPrintExcel[moduleParam] : {}),
  };
  // console.log('filter', request);
  if (!isEmpty(requestUrl)) {
    ['page', 'limit'].map((d) => delete requestUrl?.request[d]);
  }
  const [err, res] =
    isEmpty(requestUrl) || (isArray(diamond) && diamond?.length) || !isEmpty(filter)
      ? await printPdf(request)
      : await requestUrl?.urlMethod({ ...requestUrl?.request, isPdf: true });

  if (err || res?.code !== 'OK' || !isString(res?.data)) {
    if (isFunction(cb)) cb(false);
    return;
  }
  const filePath = COMMON_URL.EXCELFILE_URL + res?.data;
  window.open(filePath, 'Download');
  if (isFunction(cb)) cb(false);
};

export const downloadZip = async (request = {}, cb) => {
  const [err, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.downloadZip,
    request,
  });
  return new Promise((resolve) => {
    if (err || res?.code !== 'OK' || !isString(res?.data)) return;
    const filePath = COMMON_URL.BASE_URL + '/' + res.data;
    window.open(filePath, 'Download');
    if (isFunction(cb)) cb();
    resolve();
  });
};

/**
 * Function used to download file
 * @param {*} request
 * @param {*} cb
 */
export const downloadFile = async (request = {}, cb) => {
  const [err, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.downloadFile,
    request,
  });
  return new Promise((resolve) => {
    if (err && res?.code !== 'OK') return;
    try {
      const server = COMMON_URL.EXCELFILE_URL;
      const file_path = server + res.data.path;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', file_path, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(this.response);
        const tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = request.fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.send();
    } catch (error) {
      return error;
    }
    if (isFunction(cb)) cb();
    resolve();
  });
};

export const downloadVideo = (apiObj, cb) => {
  return new Promise((resolve) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', apiObj.path, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(this.response);
        const tag = document.createElement('a');
        tag.href = imageUrl;
        tag.target = '_blank';
        tag.download = apiObj.fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.onerror = (error) => alert(error);
      xhr.send();
    } catch (error) {
      return error;
    }
    if (isFunction(cb)) cb();
    resolve();
  });
};

export const downloadCertificate = async (request = {}) => {
  const [err, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.downloadCertificate,
    request,
  });
  if (err && res?.code !== 'OK') return;

  try {
    const server = COMMON_URL.EXCELFILE_URL;
    const filePath = server + res.data;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', filePath, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const url = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = url;
      tag.download = request.fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  } catch (error) {
    return error;
  }
};

export const downloadDiamondExcel = async (
  diamond,
  requestUrl,
  filter = {},
  moduleParam,
  cb,
  excelPDFTab = null,
  extraRequest = {},
) => {
  if (isFunction(cb)) cb(true);
  if (CommonPrintExcel[moduleParam]) {
    const user = Storage.get('user');

    if (user?.type != USERS_TYPE.SELLER) delete CommonPrintExcel[moduleParam]?.filter?.['sSts'];
  }

  const request = {
    ...(isArray(diamond) && diamond?.length
      ? {
        ...(excelPDFTab
          ? { excelType: excelPDFTab, filter: { id: diamond?.map((o) => o.blockId || o.id) } }
          : { id: diamond?.map((o) => o.id), ...extraRequest }),
      }
      : // : moduleParam == 'inOrder'
      // ? { orderDiamond: diamond?.map((o) => o.id) }
      {}),
    ...(!isEmpty(filter) ? { filter } : {}),
    ...(CommonPrintExcel[moduleParam] ? CommonPrintExcel[moduleParam] : {}),
  };
  // console.log('filter', request);
  if (!isEmpty(requestUrl)) {
    ['page', 'limit'].map((d) => delete requestUrl?.request[d]);
  }
  const [, res] =
    isEmpty(requestUrl) || (isArray(diamond) && diamond?.length) || !isEmpty(filter)
      ? await downloadExcel(request)
      : await requestUrl?.urlMethod({ ...requestUrl?.request, isExcel: true });

  if (res?.code === 'OK') {
    if (isFunction(cb)) cb(false);
    const path = `${COMMON_URL.EXCELFILE_URL}${res?.data?.data}`;
    window.open(path, 'Download');
  }
  if (isFunction(cb)) cb(false);
  return;
};

export const getOfficeList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.office.paginate, request, ...(!isEmpty(config) && { config }) });
};

export const uploadDiamondsExcel = (request = {}) => {
  return UtilService.callApiAsync({ ...ConstantApi.Diamond.upload, request });
};

export const diamondSearchUpsert = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Demand.Upsert, request, ...(!isEmpty(config) && { config }) });

export const diamondSearchPartialUpsert = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Demand.PartialUpsert, request, ...(!isEmpty(config) && { config }) });

export const getDynamicDiamondBidList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondBid.dynamicBid,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getMatchPairList = (request, config) =>
  UtilService.callApiAsync({ ...ConstantApi.MatchPair.Paginate, request, ...(!isEmpty(config) && { config }) });

export const declareBidWinner = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondBid.winner,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const manulResult = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondBid.manualResult,
    request,
    ...(!isEmpty(config) && { config }),
  });
};
