import React from 'react';
import classNames from 'util/classNames';
import './listingWrapper.less';

const ListingWrapper = ({ className, children, ...restProps }) => (
  <div className={classNames([`searchResultListWrapper`, className])} {...restProps}>
    <div className={classNames([`searchInnerResult`])}>{children}</div>
  </div>
);

export default ListingWrapper;
