import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'util/classNames';
import StagePopover from 'components/StagePopover';

import 'styles/listingTop.less';
import { history } from 'util/history';
import { PREFIX_URL } from 'constants/CommonUrl';
import gridSvg from 'assets/svg/settings.svg';
import { Tooltip } from 'antd';
import { isEmpty } from 'util/utils';
import Image from 'containers/Image';

export const FilterTopContainer = React.memo(function FilterTopContainer({
  className,
  absolute,
  children,
  ...restProps
}) {
  return (
    <div
      className={classNames([
        'd-flex j-space-between filterTopMainBlock',
        absolute && 'selectStoneValueBlock',
        className,
      ])}
      {...restProps}
    >
      {children}
    </div>
  );
});
FilterTopContainer.propTypes = {
  className: PropTypes.string,
  absolute: PropTypes.bool,
  children: PropTypes.node,
};

export const FilterTopInnerField = React.memo(function FilterTopInnerField({
  className,
  children,
  onStatusClick,
  noPopup,
  page,
  grid,
  noGridButton,
  ...props
}) {
  const handleGrid = React.useCallback(() => {
    history.push(`/${PREFIX_URL}/inventory/configuration/diamond-column-setting`, { grid });
  }, [grid]);

  return (
    <div className={classNames(['searchInnerFilterMain', className])} {...props}>
      {!noPopup && <StagePopover onClick={onStatusClick} page={page} />}
      {!isEmpty(grid) && (
        <Tooltip placement="top" title="Configure Column">
          <div className="filterOption mr-10" onClick={handleGrid}>
            <Image src={gridSvg} alt="filter-icon" />
          </div>
        </Tooltip>
      )}
      {children}
    </div>
  );
});

FilterTopInnerField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
