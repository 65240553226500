import Messages from 'constants/Messages';
import React, { Component } from 'react';
import { isEmpty } from 'util/utils';
import CommonModal from '../../containers/CommonModal';
import CircularProgress from 'components/CircularProgress';
import TextArea from 'containers/TextArea';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import * as ChequeDetailApi from 'services/ChequeDetailApi';
import OpenNotification from 'constants/OpenNotifications';

const TabName = {
  approve: { tabName: 'Approve', status: 2 },
  reject: { tabName: 'Reject', status: 3 },
};

const initialValues = { comment: '' };

const UtilApprove = (props) => {
  const [loader, setLoader] = React.useState(false);

  const onSubmitFn = async (values) => {
    setLoader(true);
    Object.keys(values).forEach((key) => {
      if (isEmpty(values[key])) {
        delete values[key];
      }
    });
    const [, res] = await ChequeDetailApi.updateStatus({
      id: props.checked.map((d) => d.id),
      ...values,
      status: TabName[props.actionName]?.status,
    });
    if (res.code === 'OK') {
      OpenNotification({ title: res.message, type: 'success' });
    }
    setLoader(false);
  };

  return (
    <>
      <CommonModal
        modalSize="sm-size"
        visible={true}
        title={TabName[props.actionName]?.tabName}
        submitTitle="Save"
        cancelTitle="Cancel"
        handleCancel={props.handleCancel}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // validateOnBlur
          // validationSchema
          onSubmit={onSubmitFn}
        >
          {({ handleBlur, setValues, setFieldTouched, touched, errors, values }) => (
            <Form>
              <div className="searchPopupCommon">
                <div>
                  <TextArea
                    label="Comment"
                    onChange={(e) => {
                      setValues((values) => ({ ...values, comment: e.target.value }));
                    }}
                    value={values?.comment}
                    placeholder="Comment"
                    // onBlur={handleBlur}
                    // error={touched?.trackNo && errors?.trackNo}
                  />
                </div>
              </div>
              <div className="sidebarAction">
                <button className="commonButton" type="submit">
                  Submit
                </button>
                <button className="commonOutine" type="reset" onClick={props.handleCancel}>
                  Cancel
                </button>
                {loader && <CircularProgress className="fixLoader" />}
              </div>
            </Form>
          )}
        </Formik>
      </CommonModal>
    </>
  );
};

export default UtilApprove;
