import React from 'react';
import { isFunction } from 'util/utils';
import SelectOption from 'containers/SelectOption';

const SelectCurrency = (props) => {
  return (
    <SelectOption
      label="Currency"
      defaultValue="$"
      options={[
        { key: 1, value: '$' },
        { key: 2, value: '₹' },
      ]}
      onChange={(value) => {
        switch (`${value}`) {
          case `1`: {
            if (isFunction(props.dolClick)) props.dolClick();
            return;
          }
          case `2`: {
            if (isFunction(props.rupesClick)) props.rupesClick();
            return;
          }
          default:
            break;
        }
      }}
    />
  );
};

export default SelectCurrency;
