import React from 'react';
import { isEmpty } from 'util/utils';
import InlineList from 'components/List/InlineList';

const ClientName = (props) => {
  const { user } = props;
  if (!user || isEmpty(user)) return <></>;

  user.firstName = user.firstName ? user.firstName : '';
  user.lastName = user.lastName ? user.lastName : '';
  const fullName =
    user.firstName.charAt(0).toUpperCase() +
    user.firstName.slice(1).toLowerCase() +
    ' ' +
    user.lastName.charAt(0).toUpperCase() +
    user.lastName.slice(1).toLowerCase();

  const companyName = user?.account?.companyName ? user.account.companyName : user?.companyName ? user.companyName : '';

  return (
    <InlineList
      skipEmpty
      separator=" | "
      list={[!isEmpty(companyName) ? <a className="tableLink">{companyName}</a> : undefined, fullName]}
    />
  );
};
export default ClientName;
