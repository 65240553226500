import React from 'react';
import './status.less';
import { isFunction } from 'util';

const StatusBlock = (props) => (
  <div
    className={`statusBlock ${props.status}`}
    onClick={props.onClick && isFunction(props.onClick) ? props.onClick : () => {}}
  >
    <span>{props.title}</span>
  </div>
);

export default StatusBlock;
