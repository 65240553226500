import { API_ROUTES } from 'constants/CommonApi';
import { callApiAsync } from 'services/util';

export const getOfficeList = (request = {}) => callApiAsync({ ...API_ROUTES.office.list, request });

export const createOffice = (request = {}) => callApiAsync({ ...API_ROUTES.office.create, request });

export const getOfficeViewList = (request = {}) => callApiAsync({ ...API_ROUTES.officeView.list, request });

export const deleteOffice = (request = {}) => callApiAsync({ ...API_ROUTES.office.destroy, request });

export const changeStatus = (request = {}) => callApiAsync({ ...API_ROUTES.office.statusUpdate, request });

export const getOfficeConfig = (request = {}) => callApiAsync({ ...API_ROUTES.office.config, request });
