import React from 'react';
import { Dropdown, Tooltip } from 'antd';
import SortingClose from './SortingClose';
import SortingRound from './SortingRound';
import SortingFilterDropdown from './SortingFilterDropdown';
import { FILTER_COLUMNS } from '../Inventory/Table';

const _ = require('lodash');

const DiamondListingHead = (props) => {
  let item = !props.sort ? [] : props.sort.filter((el) => el.hasOwnProperty(props.column.sort));
  let asc = item.length && item[0][props.column.sort] === 'ASC';
  let desc = item.length && item[0][props.column.sort] === 'DESC';

  return (
    <Dropdown
      overlayClassName="tabelDropdownClass"
      overlay={
        (props.sort && props.sort.length) || (props.FilterOption && FILTER_COLUMNS.includes(props?.column?.id)) ? (
          props.column && props.column.id && <SortingFilterDropdown {...props} column={props.column.id} />
        ) : (
          <></>
        )
      }
      trigger={['click']}
    >
      <div
        className={`tableHeaderMain${props?.column?.id == 'Note' ? 'demo' : ''}`}
        onClick={(e) => e.preventDefault()}
      >
        {props.noDots ? (
          ''
        ) : (
          <span className="listIcon">
            <img src={require('../../assets/svg/InventoryResult/shorting.svg')} alt="icon" />
          </span>
        )}
        {props.column?.desc ? (
          <Tooltip title={props.column?.desc}>
            <span className="taleHeadTitle">{props.header}</span>
          </Tooltip>
        ) : (
          <span className="taleHeadTitle">{props.header}</span>
        )}
        {props.canSort && props.canSort.hasOwnProperty(props.column.sort) && (asc || desc) ? (
          <div className="selectShortingMainBlock">
            <div
              className="selectShorting"
              onClick={() => props.column && props.column.sort && props.sortClick(props.column.sort)}
            >
              <span className="selectShortingCount" style={!(asc || desc) ? { margin: 0 } : {}}>
                {props.sort.indexOf(item[0]) + 1}
              </span>
              {asc || desc ? (
                <span className="selectShortingArrow">
                  <img
                    src={
                      asc
                        ? require('../../assets/svg/InventoryResult/shortinglistup.svg')
                        : require('../../assets/svg/InventoryResult/shortinglistdown-white.svg')
                    }
                  />
                </span>
              ) : null}
            </div>
            <SortingClose
              onClick={() => {
                let sort = [];
                let id = props.column.sort;
                _.each(props.sort, (val) => {
                  if (!val.hasOwnProperty(id)) sort.push(val);
                });
                props.handleSort(sort);
              }}
            />
          </div>
        ) : null}
        {props.canSort && props.canSort.hasOwnProperty(props.column.sort) && !(asc || desc) && (
          <SortingRound
            onClick={() => {
              props.column && props.column.sort && props.sortClick(props.column.sort);
            }}
          />
        )}
      </div>
    </Dropdown>
  );
};
export default DiamondListingHead;
